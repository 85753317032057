import { Tooltip } from '@mui/material';
import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { FullScreenIcon } from '../../styles';
import { StyledIconButton } from './shared-styles';

interface FullScreenToggleProps {
  fullScreenHandle: FullScreenHandle;
}

export const FullScreenToggle = (props: FullScreenToggleProps) => {
  const { fullScreenHandle } = props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    void fullScreenHandle.enter();
  };

  return (
    <Tooltip title={t('common:commonValues.tooltips.fullscreen')} placement="top">
      <StyledIconButton onClick={handleClick} size="small" aria-label="Toggle Fullscreen">
        <FullScreenIcon className="intercom_toggle_fullscreen" />
      </StyledIconButton>
    </Tooltip>
  );
};
