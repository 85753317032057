import { orderBy, sum, uniqBy } from 'lodash';
import sortBy from 'lodash.sortby';
import moment from 'moment';
import { parse, toSql } from 'pgsql-ast-parser/lib';
import * as ast from 'pgsql-ast-parser/lib/syntax/ast';
import { match, P } from 'ts-pattern';
import { HierarchyItem } from '../../common/components/filter/types';
import { Granularity, Months, TimeSliderState } from '../../common/components/timeslider/types';
import {
  CONFIDENTIAL_VALUE,
  Domains,
  employmentTemporalityField,
  startDateField,
  UNDEFINED_DISPLAY_KEY,
} from '../../constants';
import {
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  EmployeeDataFields,
  EvaluationDataFields,
  Operations,
} from '../../types';
import {
  fieldSorter,
  getKeyFromDataFieldWithDataType,
  hashCode,
  hierarchicalFieldFullLabel,
  hierarchicalFieldLabel,
  nonHierarchicalFieldLabel,
} from '../../utils';
import { addYear, date, fiscalYearStart, format, removeDay } from '../../utils-date';
import { DomainPreferences, PerformanceCycle } from '../types';
import { getFilterKey, isHierarchical } from '../utils';
import { DataService } from './service';
import { CustomSqlQueryValueBackendType, EMPLOYMENT_TEMPORALITY, FilterDataManager, Ord, Ordering } from './types';

export const getDataHierarchy = (
  levelsOfEntities: (string | null)[][],
  dimension: DataFieldWithDataType
): HierarchyItem[] => {
  let tree: HierarchyItem[] = buildHierarchyItems(removeConfidentialBranches(levelsOfEntities), dimension);
  tree = removeUnnecessaryNulls(tree);
  tree = unsetLeafSubitems(tree);
  return tree;
};

/**
 * After running this, the hierarchy looks something like
 * [
 * [Design, TechDesign, Confidential]
 * [Design, FinDesign, Confidential]
 * [Marketing, DigitalMarketing, Confidential]
 * ]
 */
const removeConfidentialBranches = (hierarchy: (string | null)[][]): (string | null)[][] =>
  hierarchy.map((row) => {
    const confidentialIndex = row.indexOf(CONFIDENTIAL_VALUE);
    const nonConfidentalRow = confidentialIndex === -1 ? row : row.slice(0, confidentialIndex);
    // i.e. we remove everything from the hierarchy after the first confidential value
    return nonConfidentalRow;
  });

const buildHierarchyItems = (levelsOfEntities: (string | null)[][], dimension: DataFieldWithDataType) => {
  const tree: HierarchyItem[] = [];
  levelsOfEntities.forEach((entity) => {
    addEntityToTree(tree, entity, 0, dimension);
  });
  return tree;
};

const findItemInTreeByName = (tree: HierarchyItem[], name: string | null): HierarchyItem | null => {
  return tree.find((item) => item.name === name) ?? null;
};

// WARNING: self-recursive method!
const addEntityToTree = (
  tree: HierarchyItem[],
  levels: (string | null)[],
  level: number,
  dimension: DataFieldWithDataType
) => {
  // we have reached the max level
  if (levels.length <= level) {
    return;
  }
  const levelValue: string | null = levels[level];

  let next: HierarchyItem;

  const maybeItem = findItemInTreeByName(tree, levelValue);
  if (maybeItem) {
    next = maybeItem;
  } else {
    const values = levels.slice(0, level + 1);
    const newItem: HierarchyItem = {
      key: getFilterKey(dimension, values),
      values,
      name: levelValue?.toString() ?? null,
      dimension,
      labelFn: (t, defaultValue, full) => {
        return full
          ? hierarchicalFieldFullLabel(t, values, dimension, defaultValue)
          : hierarchicalFieldLabel(t, levelValue, dimension, defaultValue);
      },
      isSelectable: true,
      subItems: [],
    };
    tree.push(newItem);
    next = newItem;
  }

  addEntityToTree(next.subItems ?? [], levels, level + 1, dimension);
};

const unsetLeafSubitems = (tree: HierarchyItem[]) => {
  tree.forEach((elem) => {
    if (elem.subItems && elem.subItems.length === 0) {
      delete elem.subItems;
    } else {
      if (elem.subItems) {
        elem.subItems = unsetLeafSubitems(elem.subItems);
      }
    }
  });
  return tree;
};

const removeUnnecessaryNulls = (tree: HierarchyItem[]): HierarchyItem[] => {
  return tree.filter((t) => {
    if (t.subItems) {
      t.subItems = removeUnnecessaryNulls(t.subItems);
    }
    if (t.name) {
      return true;
    } else {
      return tree.length > 1 || (t.subItems && t.subItems.length > 0);
    }
  });
};

const sortHierarchy = (
  hierarchyItem: HierarchyItem[],
  preferences: DomainPreferences,
  level: number
): HierarchyItem[] => {
  if (hierarchyItem.length === 0) {
    return [];
  }
  const sortedSubHierarchy: HierarchyItem[] = hierarchyItem.map((item) => {
    if (item.subItems && item.subItems.length > 0) {
      return { ...item, subItems: sortHierarchy(item.subItems, preferences, level + 1) };
    } else {
      return item;
    }
  });
  const dimension = hierarchyItem[0].dimension;
  const field = isHierarchical(dimension)
    ? { ...dimension, dataField: `${dimension.dataField}_LEVEL_${level}` as DataFields }
    : dimension;
  const sortOrder = fieldSorter(field, preferences);
  if (sortOrder) {
    return sortBy(sortedSubHierarchy, (i) => {
      const index = sortOrder.indexOf(i.name);
      return index === -1 ? null : index;
    });
  } else {
    return sortedSubHierarchy.sort((a, b) => hierarchyItemOrd.compare(a, b));
  }
};

export const fieldToSql = (dataField: DataFieldWithDataType): string =>
  isHierarchical(dataField)
    ? hierarchicalfieldToExprs(dataField)
        .map((r: ast.ExprRef) => ({ expr: r }))
        .map((r: ast.SelectedColumn) => toSql.selectionColumn(r))
        .join(',')
    : toSql.selectionColumn({ expr: nonHierarchicalfieldToExpr(dataField) });

// WARNING: this only handles 10 hierarhical levels
export const hierarchicalfieldToExprs = (datafield: DataFieldWithDataType): ast.ExprRef[] => {
  return Array(10)
    .fill(0)
    .map((_, i) => ({
      type: 'ref',
      table: { name: datafield.dataType.toUpperCase() },
      name: `${datafield.dataField.toUpperCase()}_LEVEL_${i + 1}`,
    }));
};

export const hierarchicalfieldToExpr = (datafield: DataFieldWithDataType): ast.ExprRef => {
  return {
    type: 'ref',
    table: { name: datafield.dataType.toUpperCase() },
    name: `${datafield.dataField.toUpperCase()}_LEVEL_1`,
  };
};

export const nonHierarchicalfieldToExpr = (datafield: DataFieldWithDataType): ast.ExprRef => {
  return {
    type: 'ref',
    table: { name: datafield.dataType.toUpperCase() },
    name: datafield.dataField.toUpperCase(),
  };
};

export const dataTypeToExpr = (dataType: DataTypes): ast.Expr => parse(`"${dataType.toUpperCase()}"`, 'expr');

export const getQuartersInRange = (
  start: { year: number; quarterOfYear: number },
  end: { year: number; quarterOfYear: number }
): { year: number; quarterOfYear: number }[] => {
  const quartersInRange: { year: number; quarterOfYear: number }[] = [];
  for (let year = start.year; year <= end.year; year++) {
    const startQuarter = year === start.year ? start.quarterOfYear : 1;
    const endQuarter = year === end.year ? end.quarterOfYear : 4;
    for (let quarter = startQuarter; quarter <= endQuarter; quarter++) {
      quartersInRange.push({ year, quarterOfYear: quarter });
    }
  }
  return quartersInRange;
};

export const timeSliderStateToExpr = (
  timeSliderState: TimeSliderState,
  range = false,
  dataType: DataTypes = DataTypes.EMPLOYEE
): ast.Expr => {
  return parse(
    match(timeSliderState)
      .with(
        { selectedGranularity: { value: Granularity.MONTH }, start: P.instanceOf(Date), end: P.instanceOf(Date) },
        (ts) => {
          if (range) {
            return `"${dataType}"."VERSION_ID" BETWEEN '${date(ts.start).format('YYYY-MM-DD')}' AND '${date(
              ts.end
            ).format('YYYY-MM-DD')}'`;
          } else {
            return `"${dataType}"."VERSION_ID" = '${date(ts.end).format('YYYY-MM-DD')}'`;
          }
        }
      )
      .with({ selectedGranularity: { value: Granularity.YEAR }, start: P.number, end: P.number }, (ts) => {
        if (range) {
          return `"${dataType}"."CALENDAR_YEAR" BETWEEN ${ts.start} AND ${ts.end}`;
        } else {
          return `"${dataType}"."CALENDAR_YEAR" = ${ts.end}`;
        }
      })
      .with({ selectedGranularity: { value: Granularity.FINYEAR }, start: P.number, end: P.number }, (ts) => {
        if (range) {
          return `"${dataType}"."FINANCIAL_YEAR" BETWEEN ${ts.start} AND ${ts.end}`;
        } else {
          return `"${dataType}"."FINANCIAL_YEAR" = ${ts.end}`;
        }
      })
      .with(
        {
          selectedGranularity: { value: Granularity.FINQUARTER },
          start: { year: P.number, quarterOfYear: P.number },
          end: { year: P.number, quarterOfYear: P.number },
        },
        (ts) => {
          if (range) {
            const quartersInRange = getQuartersInRange(ts.start, ts.end);
            // This is done purely for performance reason
            const yearConstraint = `"${dataType}"."FINANCIAL_YEAR" >= ${ts.start.year} AND "${dataType}"."FINANCIAL_YEAR" <= ${ts.end.year}`;
            return `(${quartersInRange
              .map((quarter) => {
                return `("${dataType}"."FINANCIAL_YEAR" = ${quarter.year} AND "${dataType}"."FINANCIAL_QUARTER" = ${quarter.quarterOfYear})`;
              })
              .join(' OR ')}) AND ${yearConstraint}`;
          } else {
            return `"${dataType}"."FINANCIAL_YEAR" = ${ts.end.year} AND "${dataType}"."FINANCIAL_QUARTER" = ${ts.end.quarterOfYear}`;
          }
        }
      )
      .otherwise(() => {
        throw new Error('Invalid timeSliderState');
      }),
    'expr'
  );
};

export const parseCustomSqlValue = (value: CustomSqlQueryValueBackendType): boolean | number | string | null => {
  if (!value) {
    return null;
  }
  return match(value)
    .with(
      { __typename: 'BigDecimalValue' },
      (valueWithType) => valueWithType.bigDecimal as number // TODO: Handle BigDecimal without loss of precision
    )
    .with({ __typename: 'BooleanValue' }, (valueWithType) => valueWithType.boolean)
    .with({ __typename: 'Confidential' }, () => CONFIDENTIAL_VALUE)
    .with({ __typename: 'DateValue' }, (valueWithType) => valueWithType.date)
    .with({ __typename: 'DoubleValue' }, (valueWithType) => valueWithType.double)
    .with({ __typename: 'IntValue' }, (valueWithType) => valueWithType.int)
    .with({ __typename: 'StringValue' }, (valueWithType) => valueWithType.string)
    .with({ __typename: 'TimestampValue' }, (valueWithType) => valueWithType.timestamp as string)
    .otherwise(() => {
      throw new Error(`Unknown value type: ${value}`);
    });
};

export const getHierarchyLength = (hierarchyItem: HierarchyItem[]): number => {
  return (
    hierarchyItem.filter((i) => i.isSelectable).length +
    sum(hierarchyItem.flatMap((h) => (h ? [getHierarchyLength(h.subItems ?? [])] : [0])))
  );
};

export class NonHierarchicalFilterDataManager implements FilterDataManager {
  constructor(
    readonly dimension: DataFieldWithDataType,
    readonly dataService: DataService,
    readonly timeSliderState: TimeSliderState,
    readonly preferences: DomainPreferences,
    readonly firstMonthOfYear: Months
  ) {}

  queryKey = [
    'get-datafield-values',
    this.timeSliderState.start,
    this.timeSliderState.end,
    this.timeSliderState.selectedGranularity,
    this.dimension,
  ];

  fetch = () => {
    return this.dataService.queryDataFieldValues([this.dimension], this.timeSliderState, this.firstMonthOfYear, true);
  };
  process = (data: CustomSqlQueryValueBackendType[][] | undefined): HierarchyItem[] => {
    const result =
      data?.flatMap((d) =>
        d.map((v) => {
          const value = parseCustomSqlValue(v)?.toString() ?? null;
          const item: HierarchyItem = {
            key: getFilterKey(this.dimension, [value]),
            name: value,
            values: [value],
            dimension: this.dimension,
            labelFn: (t, defaultValue) => {
              return nonHierarchicalFieldLabel(t, value, this.dimension, defaultValue);
            },
            isSelectable: true,
          };
          return item;
        })
      ) ?? [];
    return sortHierarchy(result, this.preferences, 1);
  };

  count = (hierarchyItem: HierarchyItem[]) => getHierarchyLength(hierarchyItem);
}

export class EmployeeFilterDataManager implements FilterDataManager {
  queryKey = [];
  fetch = () => Promise.resolve(undefined);
  process = () => [];
  count = () => 0;
}

export class HierarchicalFilterDataManager implements FilterDataManager {
  constructor(
    readonly dimension: DataFieldWithDataType,
    readonly dataService: DataService,
    readonly timeSliderState: TimeSliderState,
    readonly preferences: DomainPreferences,
    readonly firstMonthOfYear: Months
  ) {}

  queryKey = [
    'get-datafield-values',
    this.timeSliderState.start,
    this.timeSliderState.end,
    this.timeSliderState.selectedGranularity,
    this.dimension,
  ];

  fetch = () => {
    return this.dataService.queryDataFieldValues([this.dimension], this.timeSliderState, this.firstMonthOfYear, true);
  };

  process = (data: CustomSqlQueryValueBackendType[][] | undefined): HierarchyItem[] => {
    const entities: string[][] = data?.map((d) => d.map((v) => parseCustomSqlValue(v) as string)) ?? [];
    const hierarchy = getDataHierarchy(entities, this.dimension).flatMap((i) => (i === null ? [] : [i]));
    return sortHierarchy(hierarchy, this.preferences, 1);
  };

  count = (hierarchyItem: HierarchyItem[]) => getHierarchyLength(hierarchyItem);
}

export class EvaluationFilterDataManager implements FilterDataManager {
  constructor(
    readonly dataService: DataService,
    readonly performanceCycles: PerformanceCycle[],
    readonly domain: Domains,
    readonly useOldFilters?: boolean
  ) {}

  private evaluationCycleType = {
    dataType: DataTypes.EVALUATION,
    dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE,
  };
  private evaluationCycleDate = { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_AS_OF };
  private evaluationCycleName = {
    dataType: DataTypes.EVALUATION,
    dataField: EvaluationDataFields.EVALUATION_CYCLE_NAME,
  };
  private evaluationScore = { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_SCORE };

  // The order of the fields is important
  private fields = [this.evaluationCycleType, this.evaluationCycleDate, this.evaluationCycleName];

  queryKey = ['get-datafield-values', this.fields];

  fetch = async () => {
    return this.dataService.queryDataFieldValues(this.fields);
  };

  process = (data: CustomSqlQueryValueBackendType[][] | undefined) => {
    const dimension = { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE };
    const evaluationData =
      data?.map((d) => {
        const [type, date, name] = d.map(parseCustomSqlValue) as [string, string, string];
        return { type, date, name };
      }) ?? [];
    const sortedEvaluationData = orderBy(evaluationData, ['date', 'type'], ['desc', 'asc']);
    const filters = sortedEvaluationData.reduce<Record<string, HierarchyItem[]>>((acc, { type, date, name }) => {
      const cycleInfo = this.performanceCycles.find(
        (cycle: PerformanceCycle) => cycle.cycleType === type && cycle.cycleDate === date
      );
      if (!cycleInfo) {
        return acc;
      } else {
        const { evaluationValuesToScoreMap, scoreSortingOrder } = cycleInfo;
        const sortedEvaluationValues = sortBy(Object.keys(evaluationValuesToScoreMap), (item) => {
          return (scoreSortingOrder ?? []).indexOf(item);
        });
        const scoreFilters: HierarchyItem[] = sortedEvaluationValues.map((key) => {
          const score = evaluationValuesToScoreMap[key];
          return this.evaluationFilterItem(type, date, score, name, key, this.useOldFilters);
        });

        return { ...acc, [name]: [...(acc[name] ?? []), ...scoreFilters] };
      }
    }, {});

    const hierarchyItem = Object.entries(filters).map(([cycleName, cycleFilters]) => ({
      key: hashCode(`evaluation-cycle-${cycleName}`),
      labelFn: () => cycleName,
      values: [],
      dimension,
      name: cycleName,
      subItems: cycleFilters,
      isSelectable: false,
    }));
    return hierarchyItem;
  };

  private evaluationFilterItem = (
    type: string,
    date: string,
    score: number,
    name: string,
    key: string,
    useOldFilters?: boolean //TODO: this is temporary for movement db sql filters cannot be used
  ): HierarchyItem => {
    const dimension = { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE };
    const base: HierarchyItem = {
      key: hashCode(`${getKeyFromDataFieldWithDataType(dimension)}-${type}-${date}-${name}-${score}`),
      name: score.toString(),
      values: [`${type}-${date}-${name}-${score}`],
      dimension,
      labelFn: (_1, _2, full) => (full ? `${name}: ${key}` : key),
      isSelectable: true,
    };
    return useOldFilters
      ? {
          ...base,
          filterValues: [
            {
              dataType: DataTypes.EVALUATION,
              operation: Operations.EQUAL,
              property: EvaluationDataFields.EVALUATION_CYCLE_TYPE,
              values: [type],
            },
            {
              dataType: DataTypes.EVALUATION,
              operation: Operations.EQUAL,
              property: EvaluationDataFields.EVALUATION_AS_OF,
              values: [date],
            },
            {
              dataType: DataTypes.EVALUATION,
              operation: Operations.EQUAL,
              property: EvaluationDataFields.EVALUATION_SCORE,
              values: [score],
            },
          ],
        }
      : {
          ...base,
          sql: `(${fieldToSql(this.evaluationCycleType)} = '${type}' AND ${fieldToSql(
            this.evaluationCycleDate
          )} = '${date}' AND ${fieldToSql(this.evaluationScore)} = ${score})`,
          sqlOperator: 'OR',
        };
  };

  count = (hierarchyItem: HierarchyItem[]) => sum(hierarchyItem.map((i) => i.subItems?.length ?? 0));
}

export class HireYearFilterDataManager implements FilterDataManager {
  constructor(
    readonly dataService: DataService,
    readonly timeSliderState: TimeSliderState,
    readonly firstMonthOfYear: Months,
    readonly useOldFilters?: boolean //TODO: this is temporary for movement db sql filters cannot be used
  ) {}

  queryKey = [
    'get-datafield-values',
    this.timeSliderState.start,
    this.timeSliderState.end,
    this.timeSliderState.selectedGranularity,
    startDateField,
  ];

  fetch = () => {
    return this.dataService.queryDataFieldValues([startDateField], this.timeSliderState, this.firstMonthOfYear);
  };

  process = (data: CustomSqlQueryValueBackendType[][] | undefined): HierarchyItem[] => {
    const hireYears =
      data?.flatMap((dp) =>
        dp.map((v) => {
          const value = parseCustomSqlValue(v) as string;
          const d = moment.utc(value, 'YYYY-MM-DD', true);
          const year = d.clone().year();
          const startOfFinancialYear = date(fiscalYearStart(d.clone().valueOf(), this.firstMonthOfYear)).startOf(
            'month'
          );
          return {
            date: d.clone(),
            year: value === null ? null : year,
            financialYear: value === null ? null : startOfFinancialYear.year(),
            startOfCalendarYear: format(moment.utc().year(year).month(0).date(1).valueOf()),
            endOfCalendarYear: format(moment.utc().year(year).month(11).date(31).valueOf()),
            startOfFinancialYear: format(startOfFinancialYear.valueOf()),
            endOfFinancialYear: format(removeDay(addYear(startOfFinancialYear.valueOf(), 1), 1)),
          };
        })
      ) ?? [];
    const financialHireYear: HierarchyItem[] =
      this.firstMonthOfYear === Months.January
        ? []
        : [
            {
              key: hashCode('hire-year-financial-year'),
              name: 'Financial Year',
              values: [],
              isSelectable: false,
              dimension: startDateField,
              labelFn: (t) => t('common:filterTray.hireYearValues.financialYear'),
              subItems: orderBy(
                uniqBy(hireYears, (hy) => hy.financialYear),
                (hy) => hy.financialYear,
                'desc'
              ).map((hireYear) => {
                const year = hireYear.financialYear?.toString() ?? 'NA';
                return this.hireFinancialYearItem(year, hireYear, this.useOldFilters);
              }),
            },
          ];
    return [
      {
        key: hashCode('hire-year-calendar-year'),
        name: 'Calendar Year',
        values: [],
        isSelectable: false,
        dimension: startDateField,
        labelFn: (t) => t('common:filterTray.hireYearValues.calendarYear'),
        subItems: orderBy(
          uniqBy(hireYears, (hy) => hy.year),
          (hy) => hy.year,
          'desc'
        ).map((hireYear) => {
          const year = hireYear.year?.toString() ?? 'NA';
          return this.hireCalendarYearItem(year, hireYear, this.useOldFilters);
        }),
      },
      ...financialHireYear,
    ];
  };

  private hireCalendarYearItem = (
    year: string,
    hireYear: { startOfCalendarYear: string; endOfCalendarYear: string; year: number | null },
    useOldFilters?: boolean //TODO: this is temporary for movement db sql filters cannot be used
  ): HierarchyItem => {
    const base: HierarchyItem = {
      key: hashCode(`hire-calendar-year-${year}`),
      name: year,
      dimension: startDateField,
      values: [`calendar-year-${year}`],
      labelFn: (t, _, full) => {
        const year = hireYear.year ?? t(UNDEFINED_DISPLAY_KEY);
        return full
          ? t('common:filterTray.hireYearValues.calendarYearPill', { year: year.toString() })
          : year.toString();
      },
      isSelectable: true,
    };
    return useOldFilters
      ? {
          ...base,
          filterValues: [
            {
              dataType: DataTypes.EMPLOYEE,
              operation: Operations.GREATER_THAN_OR_EQUAL_TO,
              property: EmployeeDataFields.START_DATE,
              values: [hireYear.startOfCalendarYear],
            },
            {
              dataType: DataTypes.EMPLOYEE,
              operation: Operations.LESS_THAN_OR_EQUAL_TO,
              property: EmployeeDataFields.START_DATE,
              values: [hireYear.endOfCalendarYear],
            },
          ],
        }
      : {
          ...base,
          sql: `(${fieldToSql(startDateField)} >= '${hireYear.startOfCalendarYear}' AND ${fieldToSql(
            startDateField
          )} <= '${hireYear.endOfCalendarYear}')`,
          sqlOperator: 'OR',
        };
  };

  private hireFinancialYearItem = (
    year: string,
    hireYear: { startOfFinancialYear: string; endOfFinancialYear: string; financialYear: number | null },
    useOldFilters?: boolean //TODO: this is temporary for movement db sql filters cannot be used
  ): HierarchyItem => {
    const base: HierarchyItem = {
      key: hashCode(`hire-financial-year-${year}`),
      name: year,
      dimension: startDateField,
      labelFn: (t, _, full) => {
        const year = hireYear.financialYear ?? t(UNDEFINED_DISPLAY_KEY);
        return full
          ? t('common:filterTray.hireYearValues.financialYearPill', {
              year: year.toString(),
            })
          : year.toString();
      },
      values: [`financial-year-${year}`],
      isSelectable: true,
    };
    return useOldFilters
      ? {
          ...base,
          filterValues: [
            {
              dataType: DataTypes.EMPLOYEE,
              operation: Operations.GREATER_THAN_OR_EQUAL_TO,
              property: EmployeeDataFields.START_DATE,
              values: [hireYear.startOfFinancialYear],
            },
            {
              dataType: DataTypes.EMPLOYEE,
              operation: Operations.LESS_THAN_OR_EQUAL_TO,
              property: EmployeeDataFields.START_DATE,
              values: [hireYear.endOfFinancialYear],
            },
          ],
        }
      : {
          ...base,
          sql: `(${fieldToSql(startDateField)} >= '${hireYear.startOfFinancialYear}' AND ${fieldToSql(
            startDateField
          )} <= '${hireYear.endOfFinancialYear}')`,
          sqlOperator: 'OR',
        };
  };

  count = (hierarchyItem: HierarchyItem[]) => sum(hierarchyItem.map((i) => i.subItems?.length ?? 0));
}

export const employmentTemporalitySql = (temporalities: EMPLOYMENT_TEMPORALITY[]) => {
  return `${fieldToSql(employmentTemporalityField)} IN (${temporalities.map((t) => `'${t}'`).join(',')})`;
};

export const hierarchyItemOrd: Ord<HierarchyItem> = {
  compare: (a: HierarchyItem, b: HierarchyItem): Ordering => {
    if (a.name === null) {
      return Ordering.GT;
    } else if (b.name === null) {
      return Ordering.LT;
    } else {
      // Configuration for Natual ordering of strings
      // So [x1,x11,x12,x2,x3] => [x1,x2,x3,x11,x12]
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      return collator.compare(a.name, b.name);
    }
  },
};

export class EmployeeCohortFilterDataManager implements FilterDataManager {
  constructor(
    readonly dimension: DataFieldWithDataType,
    readonly cohortValues: CustomSqlQueryValueBackendType[],
    readonly preferences: DomainPreferences
  ) {}

  queryKey = ['get-employee-cohort-values', this.dimension];

  fetch = () => {
    return Promise.resolve([this.cohortValues]);
  };
  process = (data: CustomSqlQueryValueBackendType[][] | undefined): HierarchyItem[] => {
    // Copied this bit from NonHierarchicalFilterDataManager
    const result =
      data?.flatMap((d) =>
        d.map((v) => {
          const value = parseCustomSqlValue(v)?.toString() ?? null;
          const item: HierarchyItem = {
            key: getFilterKey(this.dimension, [value]),
            name: value,
            values: [value],
            dimension: this.dimension,
            labelFn: (t, defaultValue) => {
              return nonHierarchicalFieldLabel(t, value, this.dimension, defaultValue);
            },
            isSelectable: true,
          };
          return item;
        })
      ) ?? [];
    return sortHierarchy(result, this.preferences, 1);
  };

  count = (hierarchyItem: HierarchyItem[]) => getHierarchyLength(hierarchyItem);
}
