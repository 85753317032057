import { useSuspenseQuery } from '@tanstack/react-query';
import { match } from 'ts-pattern';
import { CohortMetricId, EmployeeCohortMetricId, MetricId, RegularMetricId } from '../../api/types-graphql';
import { Filter } from '../../common/components/filter/filterbar/types';
import { MetricTypes } from '../../common/types';
import { getFilterConditions } from '../../common/utils';
import { QueryMetricsQuerySuccess, VersionId } from '../types';
import { toRegularFrontendType } from '../utils';
import { MetricsService } from './service';
import { MetricDetails } from './types';

const queryKey = ['get-metric-details-map'];
export const useMetricDetailsMap = (metricService: MetricsService) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: () => {
      const tree = metricService.getMetricTree();
      return Object.fromEntries(
        tree.flatMap((m) => {
          return m.dimensions.map((d) => {
            return [d.id.value, d];
          });
        })
      ) as Record<CohortMetricId | RegularMetricId | EmployeeCohortMetricId, MetricDetails>;
    },
  });
};

export const useQueryMetricExample = (metricService: MetricsService, metricId: MetricId) => {
  return useSuspenseQuery({
    queryKey: ['query-metric-example', metricId],
    queryFn: async () => {
      return metricService.queryRegularMetrics({
        timeSelection: {
          calendarYearMonthly: { start: '2024-01-01', end: '2024-02-29' },
          calendarYearQuarterly: null,
          calendarYearYearly: null,
          financialYearQuarterly: null,
          financialYearYearly: null,
          singleValue: null,
          singleValueByCalendarMonths: null,
          singleValueByCalendarYears: null,
          singleValueByFinancialQuarters: null,
          singleValueByFinancialYears: null,
        },
        metrics: [
          {
            type: 'RegularMetricId',
            value: metricId as unknown as RegularMetricId,
          },
        ],
      });
    },
  });
};

export const useHeadcountAtDate = (metricService: MetricsService, endDate: VersionId, filters: Filter[]) => {
  return useSuspenseQuery({
    queryKey: ['headcount-at-date', endDate, filters],
    queryFn: async () => {
      const result = await metricService.queryRegularMetrics({
        timeSelection: {
          singleValueByCalendarMonths: { start: endDate, end: endDate },
          calendarYearMonthly: null,
          calendarYearQuarterly: null,
          calendarYearYearly: null,
          financialYearQuarterly: null,
          financialYearYearly: null,
          singleValue: null,
          singleValueByCalendarYears: null,
          singleValueByFinancialYears: null,
          singleValueByFinancialQuarters: null,
        },
        metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
        userFilters: getFilterConditions(filters, MetricTypes.REGULAR, false) ?? undefined,
      });
      return match(result.queryMetrics?.results[0])
        .with(
          { __typename: 'MetricResultSuccess' },
          (success) =>
            toRegularFrontendType(success as unknown as QueryMetricsQuerySuccess).segments[0].groupSegments[0].data
        )
        .with({ __typename: 'MetricResultFailure' }, (error) => {
          throw new Error(error.message);
        })
        .otherwise(() => {
          throw new Error('Unexpected results');
        });
    },
  });
};
