import React from 'react';
import { match } from 'ts-pattern';
import { useManualRefetchCountHandle } from '../../hooks';
import {
  ChartGridConfig,
  ComponentConfig,
  CustomChartComponentConfig,
  DashboardPageOptions,
  KpiComponentConfig,
  LineChartComponentConfig,
  OverTimeBarChartComponentConfig,
  PieChartComponentConfig,
  TableComponentConfig,
  TimePeriodBarChartComponentConfig,
} from '../dashboards/types';
import { Kpi } from '../kpi/Kpi';
import { TableViewOverTime } from '../tableview/TableViewOverTime';
import { ChartWrapper } from './ChartWrapper';
import { AreaChartOverTime } from './overtime/AreaChartOverTime';
import { BarChartOverTime } from './overtime/BarChartOverTime';
import { LineChartOverTime } from './overtime/LineChartOverTime';
import RadialTimePeriod from './radial/RadialTimePeriod';
import { ChartTimePeriod } from './timeperiod/ChartTimePeriod';

interface ChartProps {
  componentConfig: ComponentConfig;
  pageOptions: DashboardPageOptions;
}

export const ChartComponent = (props: ChartProps) => {
  const manualDataRefetchCountHandle = useManualRefetchCountHandle();
  const { componentConfig, pageOptions } = props;
  // TODO: if timeslider enabled, update the queries timeselection
  // Then, apply time benchmark
  return match(componentConfig)
    .with({ chartTypeConfig: { chartType: 'kpi' } }, (config: KpiComponentConfig) => (
      <ChartWrapper
        config={config}
        manualDataRefetchCountHandle={manualDataRefetchCountHandle}
        pageOptions={pageOptions}
      >
        {({ timeSliderState, filtersState, segmentationState }) => {
          return (
            <Kpi
              componentConfig={config}
              manualDataRefetchCountHandle={manualDataRefetchCountHandle}
              timeSliderState={timeSliderState}
              filtersState={filtersState}
              segmentationState={segmentationState}
            />
          );
        }}
      </ChartWrapper>
    ))
    .with({ chartTypeConfig: { chartType: 'table' } }, (config: TableComponentConfig) => (
      <ChartWrapper
        config={config}
        manualDataRefetchCountHandle={manualDataRefetchCountHandle}
        pageOptions={pageOptions}
      >
        {({ showTools, fullScreenHandle, timeSliderState, filtersState, segmentationState }) => {
          return (
            <TableViewOverTime
              componentConfig={config}
              manualDataRefetchCountHandle={manualDataRefetchCountHandle}
              timeSliderState={timeSliderState}
              filtersState={filtersState}
              segmentationState={segmentationState}
              showTools={showTools}
              fullScreenHandle={fullScreenHandle}
            />
          );
        }}
      </ChartWrapper>
    ))
    .with({ chartTypeConfig: { chartType: 'pie' } }, (config: PieChartComponentConfig) => (
      <ChartWrapper
        config={config}
        manualDataRefetchCountHandle={manualDataRefetchCountHandle}
        pageOptions={pageOptions}
      >
        {({ showTools, fullScreenHandle, timeSliderState, filtersState, segmentationState }) => {
          return (
            <RadialTimePeriod
              componentConfig={config}
              manualDataRefetchCountHandle={manualDataRefetchCountHandle}
              timeSliderState={timeSliderState}
              filtersState={filtersState}
              segmentationState={segmentationState}
              showTools={showTools}
              fullScreenHandle={fullScreenHandle}
            />
          );
        }}
      </ChartWrapper>
    ))
    .with(
      { chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' } },
      (config: TimePeriodBarChartComponentConfig) => (
        <ChartWrapper
          config={config}
          manualDataRefetchCountHandle={manualDataRefetchCountHandle}
          pageOptions={pageOptions}
        >
          {({ showTools, fullScreenHandle, timeSliderState, filtersState, segmentationState, displayGlobalState }) => {
            return (
              <ChartTimePeriod
                componentConfig={config}
                manualDataRefetchCountHandle={manualDataRefetchCountHandle}
                timeSliderState={timeSliderState}
                filtersState={filtersState}
                segmentationState={segmentationState}
                showTools={showTools}
                fullScreenHandle={fullScreenHandle}
                displayGlobalState={displayGlobalState}
              />
            );
          }}
        </ChartWrapper>
      )
    )
    .with(
      { chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' } },
      (config: OverTimeBarChartComponentConfig) => (
        <ChartWrapper
          config={config}
          manualDataRefetchCountHandle={manualDataRefetchCountHandle}
          pageOptions={pageOptions}
        >
          {({ showTools, fullScreenHandle, timeSliderState, filtersState, segmentationState, displayGlobalState }) => {
            return (
              <BarChartOverTime
                componentConfig={config}
                manualDataRefetchCountHandle={manualDataRefetchCountHandle}
                timeSliderState={timeSliderState}
                filtersState={filtersState}
                segmentationState={segmentationState}
                showTools={showTools}
                fullScreenHandle={fullScreenHandle}
                displayGlobalState={displayGlobalState}
              />
            );
          }}
        </ChartWrapper>
      )
    )
    .with({ chartTypeConfig: { chartType: 'line' } }, (config: LineChartComponentConfig) => (
      <ChartWrapper
        config={config}
        manualDataRefetchCountHandle={manualDataRefetchCountHandle}
        pageOptions={pageOptions}
      >
        {({ showTools, fullScreenHandle, timeSliderState, filtersState, segmentationState, displayGlobalState }) => {
          return (
            <LineChartOverTime
              componentConfig={config}
              manualDataRefetchCountHandle={manualDataRefetchCountHandle}
              timeSliderState={timeSliderState}
              filtersState={filtersState}
              segmentationState={segmentationState}
              showTools={showTools}
              fullScreenHandle={fullScreenHandle}
              displayGlobalState={displayGlobalState}
            />
          );
        }}
      </ChartWrapper>
    ))
    .with({ chartTypeConfig: { chartType: 'area' } }, (config: LineChartComponentConfig) => (
      <ChartWrapper
        config={config}
        manualDataRefetchCountHandle={manualDataRefetchCountHandle}
        pageOptions={pageOptions}
      >
        {({ showTools, fullScreenHandle, timeSliderState, filtersState, segmentationState, displayGlobalState }) => {
          return (
            <AreaChartOverTime
              componentConfig={config}
              manualDataRefetchCountHandle={manualDataRefetchCountHandle}
              timeSliderState={timeSliderState}
              filtersState={filtersState}
              segmentationState={segmentationState}
              showTools={showTools}
              fullScreenHandle={fullScreenHandle}
              displayGlobalState={displayGlobalState}
            />
          );
        }}
      </ChartWrapper>
    ))
    .with({ chartTypeConfig: { chartType: 'custom' } }, (config: CustomChartComponentConfig) => {
      const chartWrapperConfig: ChartGridConfig = {
        gridColSpan: config.gridColSpan,
        gridRowSpan: config.gridRowSpan,
        gridColStart: config.gridColStart,
        gridColEnd: config.gridColEnd,
        gridRowStart: config.gridRowStart,
        gridRowEnd: config.gridRowEnd,
      };
      return (
        <ChartWrapper
          config={chartWrapperConfig}
          manualDataRefetchCountHandle={manualDataRefetchCountHandle}
          pageOptions={pageOptions}
        >
          {(props) => {
            return config.customComponent(config)({ manualDataRefetchCountHandle, ...props });
          }}
        </ChartWrapper>
      );
    })
    .exhaustive();
};
