import React, { Suspense as ReactSuspense, useCallback, useState } from 'react';
import { Delayed } from './Delayed';
import { LoadingCircle } from './LoadingCircle';

const FakeLoader = () => {
  throw new Promise(() => null);
};

export const BetterSuspense = ({
  children,
  fallback = <LoadingCircle />,
  wait = 100,
  freez = 300,
}: {
  children: React.ReactElement;
  fallback?: React.ReactElement;
  wait?: number;
  freez?: number;
}) => {
  const [fakeLoading, setFakeLoading] = useState(false);
  const startFakeLoading = useCallback(() => {
    setFakeLoading(true);
    const timeoutId = setTimeout(() => {
      setFakeLoading(false);
    }, freez);
    return () => clearTimeout(timeoutId);
  }, [freez]);

  return (
    <ReactSuspense
      fallback={
        <Delayed delay={wait} onDelayEnd={startFakeLoading}>
          {fallback}
        </Delayed>
      }
    >
      {/* This is used to avoid the loader to show for a
            fraction of second causing a blip on the screen */}
      {fakeLoading && <FakeLoader />}
      {children}
    </ReactSuspense>
  );
};
