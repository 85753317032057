interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  messagingSenderId: string;
}

export interface Environment {
  name: Environments;
  hosts: string[];
  apiBase: string;
  mixpanelToken: string;
  panacloudFirebaseConfig: FirebaseConfig;
}

export enum Environments {
  PANACLOUD_QA = 'panacloud-qa',
  PANACLOUD_DEMO = 'panacloud-demo',
  PANACLOUD_JAPAN = 'panacloud-japan',
}

export const localGraphQlUrl = 'http://localhost:8080/graphql';
export const environmentConstants: Environment[] = [
  {
    name: Environments.PANACLOUD_DEMO,
    hosts: [
      'demo.panalyt.com',
      'panacloud-demo.panalyt.com',
      'temporary-testing-dot-ui-dot-panacloud-demo.appspot.com',
    ],
    apiBase: 'api-demo-panacloud.panalyt.com',
    mixpanelToken: '083e820927ae1f73caac8d4d508af4e7',
    panacloudFirebaseConfig: {
      apiKey: 'AIzaSyClY-rVyPohj80xNZB3rEABlC_M85c5UTo',
      authDomain: 'panacloud-demo.firebaseapp.com',
      databaseURL: 'https://panacloud-demo.firebaseio.com',
      projectId: 'panacloud-demo',
      messagingSenderId: '72749267226',
    },
  },
  {
    name: Environments.PANACLOUD_QA,
    hosts: [
      'localhost',
      '127.0.0.1',
      'qa.panalyt.com',
      'panacloud-qa.panalyt.com',
      'swan.panalyt.com',
      'panacloud-swan.panalyt.com',
      'valentinsaml.panalyt.com',
      'panacloud-valentinsaml.panalyt.com',
      'quickfix-qa.panalyt.com',
      'panacloud-quickfix-qa.panalyt.com',
      'temporary-testing-dot-ui-dot-panacloud-qa.appspot.com',
    ],
    apiBase: 'api-qa-panacloud.panalyt.com',
    mixpanelToken: '083e820927ae1f73caac8d4d508af4e7', //NOTE: we only send event for production environments
    panacloudFirebaseConfig: {
      apiKey: 'AIzaSyDP3HoNRx7AcHd56kM5RiGrJey1WdhqiLM',
      authDomain: 'panacloud-qa.firebaseapp.com',
      databaseURL: 'https://panacloud-qa.firebaseio.com',
      projectId: 'panacloud-qa',
      messagingSenderId: '788718841297',
    },
  },
  {
    name: Environments.PANACLOUD_JAPAN,
    hosts: [
      'app.panalyt.com',
      'japan.panalyt.com',
      'panacloud-japan.panalyt.com',
      'jt.panalyt.com',
      'panacloud-jt.panalyt.com',
      'jt2.panalyt.com',
      'crexta.panalyt.com',
      'panacloud-crexta.panalyt.com',
      'temporary-testing-dot-ui-dot-panacloud-japan.appspot.com',
    ],
    apiBase: 'api-japan-panacloud.panalyt.com',
    mixpanelToken: '93741733e5ab3cc78559072173805905',
    panacloudFirebaseConfig: {
      apiKey: 'AIzaSyDtkdLt77sKge95wYZW58og3P0TJFoM8SM',
      authDomain: 'panacloud-japan.firebaseapp.com',
      databaseURL: 'https://panacloud-japan.firebaseio.com',
      projectId: 'panacloud-japan',
      messagingSenderId: '580290219341',
    },
  },
];
