import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setAutoFreeze } from 'immer';
import { syncHistoryWithStore } from 'mobx-react-router';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Router } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { bootstrapApp } from './bootstrap';
import setChartjsDefaults from './common/charts/ChartjsDefaults';
import { DisableAccessIfMobile } from './common/components/shell/DisableAccessIfMobile';
import { environmentService } from './common/environment/environment-service';
import { rootStore } from './common/store/root-store';
import { defaultTheme } from './common/theme/default-theme';
import { muiTheme } from './common/theme/mui-theme';
import { routingStore } from './contexts';
import { ErrorBoundary } from './ErrorBoundary';
import { addPolyfills } from './polyfills';
import { RenderRoutes } from './RenderRoutes';
addPolyfills();
setAutoFreeze(false); // See https://immerjs.github.io/immer/freezing/
bootstrapApp();
setChartjsDefaults();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

const App = () => {
  const history = syncHistoryWithStore(rootStore.routeStore.browserHistory, routingStore);
  const clientPrimary = '#00a599';
  defaultTheme.color.clientPrimary = clientPrimary;

  return (
    <>
      <CssBaseline />
      <Router history={history}>
        <SnackbarProvider>
          <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={defaultTheme}>
              <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                  <DisableAccessIfMobile>
                    <RenderRoutes />
                  </DisableAccessIfMobile>
                  {environmentService.isLocalhost && (
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                  )}
                </QueryClientProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </MuiThemeProvider>
        </SnackbarProvider>
      </Router>
    </>
  );
};

export default Sentry.withProfiler(App);
