import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';
import { FullScreenToggle } from '../toggles/FullScreenToggle';

interface ToolsProps {
  fullScreenHandle: FullScreenHandle;
}

const StyledTools = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  right: 0.25rem;
  top: 0.25rem;
  margin-left: auto;
  margin-right: 0.25rem;
`;

export const Tools = (props: ToolsProps) => {
  const { fullScreenHandle } = props;
  return (
    <StyledTools>
      {fullScreenHandle.active ? null : <FullScreenToggle fullScreenHandle={fullScreenHandle} />}
    </StyledTools>
  );
};
