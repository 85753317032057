import { DomainPreferences } from '../../api/types';
import { RegularMetricId } from '../../api/types-graphql';
import { DashboardConfig } from '../../common/components/dashboards/types';
import { Languages } from '../../constants';
import { DataTypes, EmployeeDataFields, TimeSelectionType } from '../../types';
import {
  lastMonthEndOfMonth,
  lastYearFromLastMonthEndOfMonth,
  startOfYear,
  twoYearsAgoFromLastMonthEndOfMonth,
} from './utils';

export const ShipHealthcareDashboardChartConfig: (domainPreferences: DomainPreferences) => DashboardConfig = (
  domainPreferences: DomainPreferences
) => ({
  id: 'shiphealthcare_custom_db_1',
  title: {
    [Languages.EN]: 'Monthly Group Report',
    [Languages.JA]: '人材諸表®（月次報告）',
  },
  enabled: true,
  pages: [
    {
      pageId: '1',
      dashboardId: 'shiphealthcare_custom_db_1',
      pageNumber: 1,
      enabled: true,
      title: {
        [Languages.EN]: 'General Info',
        [Languages.JA]: '基本情報',
      },
      pageOptions: {
        displayOptions: {
          enabled: false,
        },
        timeSlider: {
          enabled: false,
        },
        filterTray: {
          enabled: true,
        },
        dataView: {
          enabled: false,
        },
        segmentationLevel1: {
          enabled: false,
        },
        segmentationLevel2: {
          enabled: false,
        },
        benchmark: {
          enabled: false,
        },
        forecast: {
          enabled: false,
        },
      },
      gridCols: 5,
      components: [
        {
          chartId: '1',
          title: {
            [Languages.EN]: 'Individuals',
            [Languages.JA]: '従業員',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間末時点の総数',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'individuals',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '2',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '男女比',
          },
          subtitle: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '（女：男）',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間末時点における累計',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            // TODO
          ],
        },
        {
          chartId: '3',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '年齢',
          },
          subtitle: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '（平均）',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間末時点の平均 ',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'age_avg',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '4',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '勤続年数',
          },
          subtitle: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '（平均）',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間末時点の平均 ',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'tenure_avg',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0023_2TenureAvg }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '5',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '管理職比率',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間末時点の比率',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'mngdef_perchc',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0014_2MngDefPercHc }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '6',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '入社者',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間における累計 ',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'joiners',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '7',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '離職者',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※対象期間における累計',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'leavers',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '8',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '離職率',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※直近12ヶ月で算出（計算式：選択した月から 直近12ヶ月間の月間離職率の合計）',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'attrition_ttm',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_3AttritionTtm }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastYearFromLastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '9',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '入社１年以内離職率',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※直近12ヶ月で算出（計算式：選択した月から 直近12ヶ月間の月間入社１年以内離職率の合計）',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1y_attrition',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0290_1_1yAttritionAttrition }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastYearFromLastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '10',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '育休取得率',
          },
          footer: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '※直近12ヶ月で算出（計算式：期間内に育休を 開始した人数÷同期間に子が誕生した人数）',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: 'parental_leave_utilization_perc',
              queries: [
                {
                  metrics: [
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0171ParentalLeaveTakenUtilizationPerc },
                  ],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: startOfYear(domainPreferences), end: lastMonthEndOfMonth }, // TODO: confirm
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '2',
      dashboardId: 'shiphealthcare_custom_db_1',
      pageNumber: 2,
      enabled: true,
      title: {
        [Languages.EN]: 'Demographics',
        [Languages.JA]: '人員構成',
      },
      pageOptions: {
        displayOptions: {
          enabled: true,
        },
        timeSlider: {
          enabled: false,
        },
        filterTray: {
          enabled: true,
        },
        dataView: {
          enabled: false,
        },
        segmentationLevel1: {
          enabled: false,
        },
        segmentationLevel2: {
          enabled: false,
        },
        benchmark: {
          enabled: false,
        },
        forecast: {
          enabled: false,
        },
      },
      gridCols: 3,
      gridRows: 2,
      components: [
        {
          chartId: '1',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '雇用形態別',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          gridColSpan: 1,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_4 },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: '2',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '年齢構成',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          gridColSpan: 2,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: '3',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '勤続年数グループ別',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          gridColSpan: 3,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '3',
      dashboardId: 'shiphealthcare_custom_db_1',
      pageNumber: 3,
      enabled: true,
      title: {
        [Languages.EN]: 'Joiners and Leavers',
        [Languages.JA]: '入社・離職',
      },
      pageOptions: {
        displayOptions: {
          enabled: true,
        },
        timeSlider: {
          enabled: false,
        },
        filterTray: {
          enabled: true,
        },
        dataView: {
          enabled: false,
        },
        segmentationLevel1: {
          enabled: false,
        },
        segmentationLevel2: {
          enabled: false,
        },
        benchmark: {
          enabled: false,
        },
        forecast: {
          enabled: false,
        },
      },
      gridCols: 8,
      gridRows: 2,
      components: [
        {
          chartId: '1',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '入社者数・離職者数の推移（直近12ヶ月）',
          },
          chartTypeConfig: { chartType: 'line' },
          gridColSpan: 5,
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearFromLastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
            },
          ],
        },
        {
          chartId: '2',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '年代別、入社者数と離職者数の比較',
          },
          subtitle: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '（対象期間累計、上部参照）',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          gridColSpan: 3,
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearFromLastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: '3',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '入社者数の前年同月比（直近12ヶ月）',
          },
          chartTypeConfig: { chartType: 'line' },
          gridColSpan: 4,
          queries: [
            {
              xAxisId: 0,
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearFromLastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
            },
            {
              isBenchmark: true,
              xAxisId: 1,
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: twoYearsAgoFromLastMonthEndOfMonth, end: lastYearFromLastMonthEndOfMonth },
              },
            },
          ],
        },
        {
          chartId: '4',
          title: {
            [Languages.EN]: '', // TODO
            [Languages.JA]: '離職者数の前年同月比（直近12ヶ月）',
          },
          chartTypeConfig: { chartType: 'line' },
          gridColSpan: 4,
          queries: [
            {
              xAxisId: 0,
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearFromLastMonthEndOfMonth, end: lastMonthEndOfMonth },
              },
            },
            {
              isBenchmark: true,
              xAxisId: 1,
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: twoYearsAgoFromLastMonthEndOfMonth, end: lastYearFromLastMonthEndOfMonth },
              },
            },
          ],
        },
      ],
    },
  ],
});
