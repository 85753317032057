import { axisClasses, barLabelClasses } from '@mui/x-charts';
import { darkGray, lightGray, primaryColor } from '../../../theme';

export const chartStyles = () => ({
  [`.${axisClasses.root}`]: {
    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
      stroke: lightGray,
      strokeWidth: 1,
    },
  },
  [`.${axisClasses.directionY}`]: {
    [`.${axisClasses.tickLabel}`]: {
      fill: primaryColor,
    },
  },
  [`.${axisClasses.directionX}`]: {
    [`.${axisClasses.tickLabel}`]: {
      fill: darkGray,
    },
  },
  [`.${barLabelClasses.root}`]: {
    fill: 'white',
  },
  '& .MuiBarLabel-root': {
    fontSize: '12px',
  },
});
