import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import { match, P } from 'ts-pattern';
import { Segment } from '../common/components/filter/filterbar/types';
import { MetricTypes } from '../common/types';
import { mapNormalFiltersToCohortFilters } from '../common/utils';
import { CONFIDENTIAL_DISPLAY_KEY, HierarchicalFields, Languages } from '../constants';
import {
  ApiMasterDataQueryFilterItem,
  CohortDataFields,
  CohortMetricIdType,
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  EmployeeCohortMetricIdType,
  EmployeeDataFields,
  HashCode,
  MetricIdTypeArrays,
  Operations,
  RegularMetricIdType,
  Segmentation,
  SegmentationByHierarchicalField,
  SegmentationByNonHierarchicalField,
  TimeSelection,
  TimeSelectionType,
} from '../types';
import { getDataFieldWithDataTypeFromKey, getKeyFromDataFieldWithDataType, hashCode } from '../utils';
import { date, format, formatFinQuarter, formatFinYear, formatMonth, formatYear } from '../utils-date';
import { MetricsService } from './metrics/service';
import { QueryCohortMetricsParams, QueryEmployeeCohortMetricsParams, QueryRegularMetricsParams } from './metrics/types';
import {
  ApiError,
  ApiMetricResults,
  ApiOverTimeTimeSegmentType,
  ApiTimeSegmentType,
  ApiValueDataType,
  CohortMetricResult,
  DataValue,
  EmployeeCohortMetricResult,
  MetricResult,
  QueryMetricsQuerySuccess,
  RegularMetricResult,
  SQLFilters,
  Timestamp,
} from './types';
import {
  ApiMasterDataType,
  CohortMetricId,
  EmployeeCohortMetricId,
  GroupByFieldInput,
  GroupbyHierarchicalFieldInput,
  GroupingInput,
  MetricId,
  QueryCohortMetricsQuery,
  QueryEmployeeCohortMetricsQuery,
  QueryMetricsQuery,
  RegularMetricId,
  SqlInputValueInput,
  TimeSelectionInput,
} from './types-graphql';

const fieldsOfIntValue: DataFieldWithDataType[] = [
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOINING_AGE },
];

const fieldsOfJsonValue: DataFieldWithDataType[] = [
  { dataType: DataTypes.COHORT, dataField: CohortDataFields.COHORT_VALUE },
];

const isIntValue = (dataFieldWithDataType: DataFieldWithDataType) => {
  return fieldsOfIntValue.deepCompareContains(dataFieldWithDataType);
};

const isJsonValue = (dataFieldWithDataType: DataFieldWithDataType) => {
  return fieldsOfJsonValue.deepCompareContains(dataFieldWithDataType);
};

export const singleValueFilter = <T>(v: T, valueKey: keyof SqlInputValueInput): SqlInputValueInput => {
  const filter: SqlInputValueInput = {
    bigDecimal: null,
    date: null,
    double: null,
    int: null,
    long: null,
    null: null,
    json: null,
    string: null,
  };
  filter[valueKey] = v;
  return filter;
};

export const singleNonNullValueFilter = <T>(v: T, dataFieldWithDataType: DataFieldWithDataType) => {
  if (isIntValue(dataFieldWithDataType)) {
    return singleValueFilter(Number(v), 'int');
  } else if (isJsonValue(dataFieldWithDataType)) {
    return singleValueFilter(v, 'json');
  }
  return singleValueFilter(v, 'string');
};

export const singleNullValueFilter = () => {
  return singleValueFilter(true, 'null');
};

export const toValue = (value: ApiValueDataType): DataValue => {
  return match(value)
    .with({ __typename: 'BigDecimalValue' }, (val) => val.bigDecimal as number)
    .with({ __typename: 'BooleanValue' }, (val) => val.boolean)
    .with({ __typename: 'DateValue' }, (val) => val.date)
    .with({ __typename: 'DoubleValue' }, (val) => val.double)
    .with({ __typename: 'IntValue' }, (val) => val.int)
    .with({ __typename: 'StringValue' }, (val) => val.string)
    .with({ __typename: 'Confidential' }, () => CONFIDENTIAL_DISPLAY_KEY)
    .with({ __typename: 'TimestampValue' }, (val) => val.timestamp as Timestamp)
    .with({ __typename: 'JsonValue' }, (val) => val.json)
    .with(null, (val) => val)
    .exhaustive();
};

export const toTimeSegmentRangeLabel = (
  start: ApiTimeSegmentType | undefined,
  end: ApiTimeSegmentType | undefined,
  locale: Languages
): string => {
  if (start && end) {
    return `${toTimeSegmentLabel(start, locale)}~${toTimeSegmentLabel(end, locale)}`;
  } else {
    throw new Error(`Time Segment is not valid start: ${start}, end: ${end}`);
  }
};

export const toTimeSegmentLabel = (segment: ApiTimeSegmentType, locale: Languages): string => {
  return match(segment)
    .with({ __typename: 'CalendarYearMonthlySegment' }, (seg) => formatMonth(date(seg.date).toDate(), locale))
    .with({ __typename: 'CalendarYearQuarterlySegment' }, (seg) => `${seg.quarter.year}-${seg.quarter.quarterOfYear}`)
    .with({ __typename: 'CalendarYearYearlySegment' }, (seg) => formatYear(seg.year, locale))
    .with({ __typename: 'FinancialYearQuarterlySegment' }, (seg) => formatFinQuarter(seg.quarter, locale))
    .with({ __typename: 'FinancialYearYearlySegment' }, (seg) => formatFinYear(seg.year, locale))
    .with({ __typename: 'SingleValueTimeSegment' }, (seg) => `${seg.start}/${seg.end}`)
    .with({ __typename: 'CalendarYearSingleValueByMonthsSegment' }, (seg) => `${seg.start}/${seg.end}`)
    .with({ __typename: 'CalendarYearSingleValueByYearsSegment' }, (seg) => seg.year.toString())
    .with(
      { __typename: 'FinancialYearSingleValueByQuartersSegment' },
      (seg) => `${seg.quarter.year}-${seg.quarter.quarterOfYear}`
    )
    .with({ __typename: 'FinancialYearSingleValueByYearsSegment' }, (seg) => seg.year.toString())
    .exhaustive();
};

const toCohortFrontendType = (success: QueryMetricsQuerySuccess): CohortMetricResult => {
  return {
    type: 'CohortMetricResult',
    metricId: success.metricId as CohortMetricId,
    ...toFrontendType(success),
  };
};

export const toRegularFrontendType = (success: QueryMetricsQuerySuccess): RegularMetricResult => {
  return {
    type: 'RegularMetricResult',
    metricId: success.metricId as RegularMetricId,
    ...toFrontendType(success),
  };
};

export const toEmployeeCohortFrontendType = (success: QueryMetricsQuerySuccess): EmployeeCohortMetricResult => {
  return {
    type: 'EmployeeCohortMetricResult',
    metricId: success.metricId as EmployeeCohortMetricId,
    ...toFrontendType(success),
  };
};

const toFrontendType = (success: QueryMetricsQuerySuccess): Omit<MetricResult, 'metricId' | 'type'> => {
  return {
    segments: success.segments.map((segment) => {
      return {
        groupSegments: segment.groupSegments.map((segments) => {
          return {
            data: toValue(segments.data),
            groupSegment: segments.groupSegment.map(
              (gs: { dataField: DataFields; dataType: DataTypes; value: ApiValueDataType }) => {
                return {
                  dataField: gs.dataField,
                  dataType: gs.dataType,
                  value: toValue(gs.value) as string,
                };
              }
            ),
          };
        }),
        timeSegment: segment.timeSegment,
      };
    }),
    meta: {
      metricSql: success.meta.metricSql,
    },
  };
};

const toHierarchicalSqlInputValueInput = (seg: SegmentationByHierarchicalField): GroupbyHierarchicalFieldInput => {
  return {
    dataField: seg.dataField.dataField,
    dataType: seg.dataField.dataType as unknown as ApiMasterDataType,
    filters:
      seg.filters?.map((v: any[]) =>
        v.map((h: any) => (h === null ? singleNullValueFilter() : singleNonNullValueFilter(h, seg.dataField)))
      ) ?? [], // TODO: empty array might not work
  };
};

const toNonHierarchicalSqlInputValueInput = (seg: SegmentationByNonHierarchicalField): GroupByFieldInput => {
  return {
    filters:
      seg.filters?.map((v: any) =>
        v === null ? singleNullValueFilter() : singleNonNullValueFilter(v, seg.dataField)
      ) ?? null,
    dataField: seg.dataField.dataField,
    dataType: seg.dataField.dataType as unknown as ApiMasterDataType,
  };
};

export const toGroupingInput = (segmentations: Segmentation[]): GroupingInput => {
  const groups = segmentations.map((segmentation) => {
    return match(segmentation)
      .with({ type: 'hierarchical' }, (seg) => ({
        byHierarchicalField: toHierarchicalSqlInputValueInput(seg),
        byField: null,
      }))
      .with({ type: 'non-hierarchical' }, (seg) => ({
        byHierarchicalField: null,
        byField: toNonHierarchicalSqlInputValueInput(seg),
      }))
      .exhaustive();
  });
  return { groups };
};

const toTimeSelectionInput = (timeSelection: TimeSelection): TimeSelectionInput => {
  const base = {
    calendarYearMonthly: null,
    calendarYearQuarterly: null,
    calendarYearYearly: null,
    financialYearQuarterly: null,
    financialYearYearly: null,
    singleValue: null,
    singleValueByCalendarMonths: null,
    singleValueByCalendarYears: null,
    singleValueByFinancialQuarters: null,
    singleValueByFinancialYears: null,
  };
  return match(timeSelection)
    .with({ type: TimeSelectionType.CalendarYearMonthly }, (t) => ({ ...base, calendarYearMonthly: t.input }))
    .with({ type: TimeSelectionType.CalendarYearQuarterly }, (t) => ({ ...base, calendarYearQuarterly: t.input }))
    .with({ type: TimeSelectionType.CalendarYearYearly }, (t) => ({ ...base, calendarYearYearly: t.input }))
    .with({ type: TimeSelectionType.FinancialYearQuarterly }, (t) => ({ ...base, financialYearQuarterly: t.input }))
    .with({ type: TimeSelectionType.FinancialYearYearly }, (t) => ({ ...base, financialYearYearly: t.input }))
    .with({ type: TimeSelectionType.CalendarYearSingleValueByMonths }, (t) => ({
      ...base,
      singleValue: { start: format(t.input.start.valueOf()), end: format(t.input.end.valueOf()) },
    })) // TODO: update once the backend is implemented
    .with({ type: TimeSelectionType.CalendarYearSingleValueByYears }, (t) => ({
      ...base,
      singleValueByCalendarYears: t.input,
    }))
    .with({ type: TimeSelectionType.FinancialYearSingleValueByQuarters }, (t) => ({
      ...base,
      singleValueByFinancialQuarters: t.input,
    }))
    .with({ type: TimeSelectionType.FinancialYearSingleValueByYears }, (t) => ({
      ...base,
      singleValueByFinancialYears: t.input,
    }))
    .otherwise(() => {
      throw new Error('TimeSelectionType not supported');
    });
};

type RegularMetricQueryService = (params: QueryRegularMetricsParams) => Promise<QueryMetricsQuery>;
type CohortMetricQueryService = (params: QueryCohortMetricsParams) => Promise<QueryCohortMetricsQuery>;
type EmployeeCohortMetricQueryService = (
  params: QueryEmployeeCohortMetricsParams
) => Promise<QueryEmployeeCohortMetricsQuery>;

type MetricQueryService = RegularMetricQueryService | CohortMetricQueryService | EmployeeCohortMetricQueryService;

type MetricResultOrError =
  | (RegularMetricResult | Error)
  | (CohortMetricResult | Error)
  | (EmployeeCohortMetricResult | Error);

// TODO: This is a temporary function to get the filter type from the metrics.
// We should remove this once we have metrics as an object with type and id array.
export const getMetricTypeFromMetrics = (metrics: MetricIdTypeArrays): MetricTypes => {
  return match(metrics[0])
    .with({ type: 'RegularMetricId' }, () => MetricTypes.REGULAR)
    .with({ type: 'CohortMetricId' }, () => MetricTypes.COHORT)
    .with({ type: 'EmployeeCohortMetricId' }, () => MetricTypes.EMPLOYEE_COHORT)
    .exhaustive();
};

// This is currently on the frontend but should be fetched from the metricTree as soon as the backend is ready to provide it and the concept is clarified
export const metricIdNeedsEmptyCohortFilter = (metricId: MetricId) => {
  const metricIdsWithEmptyCohortFilter: Set<CohortMetricId | EmployeeCohortMetricId> = new Set([
    CohortMetricId.Pm0417_2SimulatedHeadcountCount,
    CohortMetricId.Pm0418_2SimulatedLeaversCount,
    CohortMetricId.Pm0419_2SimulatedJoinersCount,
    CohortMetricId.Pm0420_2SimulatedRetireesCount,
    CohortMetricId.Pm0430_1CstmSatSurveyKhncAvgScore,
    CohortMetricId.Pm0430_2CstmSatSurveyKhncNumRespondents,
  ]);
  return metricIdsWithEmptyCohortFilter.has(metricId as unknown as CohortMetricId | EmployeeCohortMetricId);
};

export const toMetricResultOrError = async (
  metricService: MetricsService,
  metrics: MetricIdTypeArrays,
  timeSelection: TimeSelection,
  filters?: SQLFilters,
  segmentations?: Segmentation[]
): Promise<Array<MetricResultOrError>> => {
  const metricResultOrError = match(metrics[0])
    .with({ type: 'RegularMetricId' }, () => {
      return toRegularMetricResultOrError(
        metricService.queryRegularMetrics as RegularMetricQueryService,
        metrics as RegularMetricIdType[],
        timeSelection,
        filters,
        segmentations
      );
    })
    .with({ type: 'CohortMetricId' }, () => {
      return toCohortMetricResultOrError(
        metricService.queryCohortMetrics as CohortMetricQueryService,
        metrics as CohortMetricIdType[],
        timeSelection,
        filters,
        segmentations
      );
    })
    .with({ type: 'EmployeeCohortMetricId' }, () => {
      return toEmployeeCohortMetricResultOrError(
        metricService.queryEmployeeCohortMetrics as EmployeeCohortMetricQueryService,
        metrics as EmployeeCohortMetricIdType[],
        timeSelection,
        filters,
        segmentations
      );
    })
    .exhaustive();
  return metricResultOrError;
};

// TODO: Ideally there should be a toMetricResultOrError as well.
// Maybe we need {type: MetricIdType, metrics: MetricIdTypeArrays} before we do that
// to match with the correct function.
export const toRegularMetricResultOrError = async (
  metricQueryService: RegularMetricQueryService,
  metrics: RegularMetricIdType[],
  timeSelection: TimeSelection,
  filters?: SQLFilters,
  segmentations?: Segmentation[]
): Promise<Array<RegularMetricResult | Error>> => {
  const grouping: GroupingInput | undefined = segmentations ? toGroupingInput(segmentations) : undefined;
  const response: Promise<Array<RegularMetricResult | Error>> = metricQueryService({
    timeSelection: toTimeSelectionInput(timeSelection),
    metrics,
    userFilters: filters,
    grouping,
  }).then((queryResult: QueryMetricsQuery) => {
    const result = queryResult.queryMetrics as ApiMetricResults | null;
    return (
      result?.results?.map((res) => {
        return match(res)
          .with({ __typename: 'MetricResultFailure' }, (error) => {
            return new Error(error.message);
          })
          .with({ __typename: 'MetricResultSuccess' }, (success) => {
            return toRegularFrontendType(success);
          })
          .exhaustive();
      }) ?? []
    );
  });
  return response;
};

export const toCohortMetricResultOrError = async (
  metricQueryService: CohortMetricQueryService,
  metrics: CohortMetricIdType[],
  timeSelection: TimeSelection,
  filters?: SQLFilters,
  segmentations?: Segmentation[]
): Promise<Array<CohortMetricResult | Error>> => {
  const grouping: GroupingInput | undefined = segmentations ? toGroupingInput(segmentations) : undefined;
  const response: Promise<Array<CohortMetricResult | Error>> = metricQueryService({
    timeSelection: toTimeSelectionInput(timeSelection),
    metrics,
    userCohortFilters: filters,
    grouping,
  }).then((queryResult: QueryCohortMetricsQuery) => {
    const result = queryResult.queryCohortMetrics as ApiMetricResults | null;
    return (
      result?.results?.map((res) => {
        return match(res)
          .with({ __typename: 'MetricResultFailure' }, (error) => {
            return new Error(error.message);
          })
          .with({ __typename: 'MetricResultSuccess' }, (success) => {
            return toCohortFrontendType(success);
          })
          .exhaustive();
      }) ?? []
    );
  });
  return response;
};

export const toEmployeeCohortMetricResultOrError = async (
  metricQueryService: EmployeeCohortMetricQueryService,
  metrics: EmployeeCohortMetricIdType[],
  timeSelection: TimeSelection,
  filters?: SQLFilters,
  segmentations?: Segmentation[]
) => {
  const grouping: GroupingInput | undefined = segmentations ? toGroupingInput(segmentations) : undefined;
  const response: Promise<Array<EmployeeCohortMetricResult | Error>> = metricQueryService({
    timeSelection: toTimeSelectionInput(timeSelection),
    metrics,
    userCohortFilters: filters,
    grouping,
  }).then((queryResult: QueryEmployeeCohortMetricsQuery) => {
    const result = queryResult.queryEmployeeCohortMetrics as ApiMetricResults | null;
    return (
      result?.results?.map((res) => {
        return match(res)
          .with({ __typename: 'MetricResultFailure' }, (error) => {
            return new Error(error.message);
          })
          .with({ __typename: 'MetricResultSuccess' }, (success) => {
            return toEmployeeCohortFrontendType(success);
          })
          .exhaustive();
      }) ?? []
    );
  });
  return response;
};

export const combineFilters = (filters: ApiMasterDataQueryFilterItem[]) => {
  return filters.reduce<ApiMasterDataQueryFilterItem[]>((acc, filter) => {
    const existingItem = acc.find(
      (item) =>
        item.property === filter.property &&
        item.operation === filter.operation &&
        item.dataType === filter.dataType &&
        item.operation !== Operations.NOT_EQUAL &&
        filter.operation !== Operations.NOT_EQUAL &&
        item.dontCombine !== true &&
        filter.dontCombine !== true
    );

    if (existingItem) {
      const values = Array.from(new Set([existingItem.values, filter.values].flat()));
      return [
        ...acc.filter((f) => !isEqual(f, existingItem)),
        {
          ...existingItem,
          values,
        },
      ];
    } else {
      return [...acc, filter];
    }
  }, []);
};

export const isHierarchical = (filterProperty: DataFieldWithDataType): boolean => {
  return Array.from(HierarchicalFields).some((e) => isEqual(filterProperty, e));
};

const toSegmentationRegular = (segments: Segment[]): Segmentation[] | undefined => {
  if (segments.length === 0) {
    return undefined;
  }
  const regularSegmentations: Segmentation[] = Object.entries(
    groupBy(segments, (f) =>
      getKeyFromDataFieldWithDataType({ dataType: f.dataType, dataField: f.property as DataFields })
    )
  ).map(([key, f]) => {
    const dataFieldWithDataType = getDataFieldWithDataTypeFromKey(key);
    return isHierarchical(dataFieldWithDataType)
      ? {
          type: 'hierarchical',
          dataField: dataFieldWithDataType,
          filters: f.map((fv) => fv.values.flatMap((v) => v as string)),
        }
      : {
          type: 'non-hierarchical',
          dataField: dataFieldWithDataType,
          filters: f.map((fv) => fv.values[0] as unknown as DataValue),
        };
  });
  return regularSegmentations;
};

const toSegmentationCohort = (segments: Segment[]): Segmentation[] | undefined => {
  const cohortSegments = segments.map((s) => {
    const modifiedFilter = mapNormalFiltersToCohortFilters(s);
    return {
      ...modifiedFilter,
      // Since map returns cohort filter values as strings. This is how it is currently used for filters
      values: modifiedFilter.values.map((v) => JSON.parse(v as string)),
    };
  });
  const cohortSegmentations = toSegmentationRegular(cohortSegments);
  return cohortSegmentations;
};

export const toSegmentation = (filters: Segment[], metricType: MetricTypes): Segmentation[] | undefined => {
  return match(metricType)
    .with(MetricTypes.REGULAR, () => toSegmentationRegular(filters))
    .with(MetricTypes.COHORT, () => toSegmentationCohort(filters))
    .with(MetricTypes.EMPLOYEE_COHORT, () => toSegmentationCohort(filters))
    .exhaustive();
};

export const handleGQLErrors = async <T>(result: Promise<T>): Promise<T> => {
  return result.catch((error) => {
    // tslint:disable-next-line:no-console
    const errorReferences: string[] | null = error?.response?.errors?.map((e: any) => e.extensions?.errorReference);
    const apiError: ApiError = {
      name: error.name,
      message: `Couldn't fetch data. Error ref: ${errorReferences?.join(',') ?? 'unknown'}`,
      errorReferences,
    };
    return Promise.reject(apiError);
  });
};

export const compareApiOverTimeTimeSegmentType = (ts1: ApiOverTimeTimeSegmentType, ts2: ApiOverTimeTimeSegmentType) =>
  match([ts1, ts2])
    .with(
      [
        { __typename: 'CalendarYearMonthlySegment', date: P.string },
        { __typename: 'CalendarYearMonthlySegment', date: P.string },
      ],
      ([t1, t2]) => t1.date === t2.date
    )
    .with(
      [
        { __typename: 'CalendarYearYearlySegment', year: P.number },
        { __typename: 'CalendarYearYearlySegment', year: P.number },
      ],
      ([t1, t2]) => t1.year === t2.year
    )
    .with(
      [
        { __typename: 'CalendarYearQuarterlySegment', quarter: { quarterOfYear: P.number, year: P.number } },
        { __typename: 'CalendarYearQuarterlySegment', quarter: { quarterOfYear: P.number, year: P.number } },
      ],
      ([t1, t2]) => t1.quarter.year === t2.quarter.year && t1.quarter.quarterOfYear === t2.quarter.quarterOfYear
    )
    .with(
      [
        { __typename: 'FinancialYearQuarterlySegment', quarter: { quarterOfYear: P.number, year: P.number } },
        { __typename: 'FinancialYearQuarterlySegment', quarter: { quarterOfYear: P.number, year: P.number } },
      ],
      ([t1, t2]) => t1.quarter.year === t2.quarter.year && t1.quarter.quarterOfYear === t2.quarter.quarterOfYear
    )
    .with(
      [
        { __typename: 'FinancialYearYearlySegment', year: P.number },
        { __typename: 'FinancialYearYearlySegment', year: P.number },
      ],
      ([t1, t2]) => t1.year === t2.year
    )
    .otherwise(() => {
      throw new Error(`Invalid time segment type: ${ts1} and ${ts2}`);
    });

export const getFilterKey = (dimension: DataFieldWithDataType, values: any[]): HashCode => {
  return hashCode(`${getKeyFromDataFieldWithDataType(dimension)}-${values.join('-').replace(/\s+/g, '') ?? 'NA'}`);
};
