import { GraphQlRequestService } from '../../api/graphql-request-service';
import { Role } from '../../permissions/permissions';
import { Domains } from '../constants';
import { ApiValueDataType, SQLQuery } from './types';
import { DomainDependenciesQuery } from './types-graphql';
import { handleGQLErrors } from './utils';

export interface BackendService {
  fetchData: <T>(dataMapper: (data: ApiValueDataType[][]) => T[]) => (querySql: SQLQuery) => Promise<T[]>;
  fetchDataWhitelistedQuery: <T>(
    dataMapper: (data: ApiValueDataType[][]) => T[]
  ) => (querySql: SQLQuery) => Promise<T[]>;
  fetchDomainDependencies: () => Promise<DomainDependenciesQuery | null>;
}

// WARNING: Don't use this service directly. Instead, create a new service with a more descriptive name
//          such as EmployeeService and use this service as the underlying service to fetch the data
//          with the appropriate dataMapper to transform the raw data into the expected type (e.g. Employee)
export class GraphQLBackendService implements BackendService {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly domain: Domains,
    readonly executorRole: Role,
    readonly simulateRole: Role | null
  ) {}

  public fetchData =
    <T>(dataMapper: (data: ApiValueDataType[][]) => T) =>
    async (querySql: SQLQuery) => {
      const result = await handleGQLErrors(
        this.graphQlRequestService.graphQlSdk.executeCustomSqlQuery({
          domain: this.domain,
          querySql,
          selectedExecutorRole: this.executorRole.id,
          simulateRole: this.simulateRole?.id ?? null,
          disableNestLoop: null,
        })
      );
      return dataMapper(result.executeCustomSqlQuery?.data ?? []);
    };

  public fetchDataWhitelistedQuery =
    <T>(dataMapper: (data: ApiValueDataType[][]) => T) =>
    async (querySql: SQLQuery) => {
      const result = await handleGQLErrors(
        this.graphQlRequestService.graphQlSdk.executeWhitelistedSqlQuery({
          domain: this.domain,
          querySql,
          selectedExecutorRole: this.executorRole.id,
          simulateRole: this.simulateRole?.id ?? null,
        })
      );
      return dataMapper(result.executeWhitelistedSqlQuery?.data ?? []);
    };

  public fetchDomainDependencies = () => {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdkIgnoringErrors.domainDependencies({
        domain: this.domain,
        simulateRole: this.simulateRole?.id ?? null,
      })
    );
  };
}
