import moment from 'moment';
import { match, P } from 'ts-pattern';
import { Granularity, Months, TimeSliderGranularity } from '../../common/components/timeslider/types';
import { date, format, startForGranularity } from '../../utils-date';
import { TimeRangeType } from './types';

export const timeRangeTypeToString = (value: TimeRangeType): string => {
  return match(value)
    .with(P.number, (v) => v.toString())
    .with({ year: P.number, quarterOfYear: P.number }, (v) => `${v.year}-${v.quarterOfYear}`)
    .with(P.instanceOf(Date), (v) => format(v.valueOf()))
    .exhaustive();
};

export const stringToTimeRangeType = (value: string, granularity: TimeSliderGranularity): TimeRangeType => {
  return match(granularity)
    .with({ value: Granularity.MONTH }, () => new Date(value))
    .with({ value: P.union(Granularity.YEAR, Granularity.FINYEAR) }, () => Number(value))
    .with({ value: P.union(Granularity.FINQUARTER) }, () => {
      const [year, quarter] = value.split('-');
      return { year: Number(year), quarterOfYear: Number(quarter) };
    })
    .exhaustive();
};

export const startDateFromTimeRangeType = (
  value: TimeRangeType,
  granularity: TimeSliderGranularity,
  firstMonthOfYear: Months
): string => {
  return match({ granularity, value })
    .with({ granularity: { value: Granularity.MONTH }, value: P.instanceOf(Date) }, (v) =>
      format(startForGranularity(date(v.value).valueOf(), granularity, firstMonthOfYear))
    )
    .with({ granularity: { value: Granularity.YEAR }, value: P.number }, (v) =>
      format(startForGranularity(moment.utc().year(v.value).month(0).date(1).valueOf(), granularity, firstMonthOfYear))
    )
    .with({ granularity: { value: Granularity.FINYEAR }, value: P.number }, (v) =>
      format(startForGranularity(moment.utc().year(v.value).month(0).date(1).valueOf(), granularity, firstMonthOfYear))
    )
    .with(
      { granularity: { value: Granularity.FINQUARTER }, value: { year: P.number, quarterOfYear: P.number } },
      (v) => {
        const month = (v.value.quarterOfYear - 1) * 3;
        return format(
          startForGranularity(
            moment.utc().year(v.value.year).month(month).date(1).valueOf(),
            granularity,
            firstMonthOfYear
          )
        );
      }
    )
    .otherwise(() => {
      throw new Error(`Invalid time range type and granularity combination: ${value.toString()}, ${granularity.id}`);
    });
};
