import { AuthCredential, User, UserCredential } from 'firebase/auth';
import { action, computed, observable, runInAction } from 'mobx';
import i18n from '../../../i18n';
import { WithPrefix } from '../../../utility-types';
import { trackFirebaseLogin, trackFirebaseLogout } from '../helpers/trackers/userTracker';
import { trackAuthenticationError } from '../sentry/sentry';
import { StateType } from '../user/user-store';
import { AuthService } from './auth-service';
import { ResetPasswordError, VerifyPasswordResetCodeError } from './custom-auth-errors';

export type SamlProvider = WithPrefix<'saml'>;

export class AuthStore {
  @observable
  public isLoggedIn: boolean = false;

  @observable
  public pendingCredential: AuthCredential | null = null;

  @observable
  public email: string | null = null;

  @observable
  public loading: boolean = true;

  @observable
  public currentUser: User | undefined | null;

  @observable
  public message: string = '';

  @observable
  public state: StateType | null = null;

  public reset = action(() => {
    this.state = null;
    this.message = '';
  });

  public samlLoginTenantIds: Record<string, string> = {
    // todo remove valentinsaml once JT is integrated and the testing is over
    'jt.panalyt.com': 'panacloud-jt-ye3o9',
    'jt2.panalyt.com': 'panacloud-jt-ye3o9',
    'valentinsaml.panalyt.com': 'PanalytAzureSamlTest-s01xh',
  };

  public samlLoginProviderIds: Record<string, SamlProvider> = {
    'jt.panalyt.com': 'saml.panacloud-jt',
    'jt2.panalyt.com': 'saml.panacloud-jt',
    'valentinsaml.panalyt.com': 'saml.panalytazuresamltest',
  };

  private authService: AuthService;

  constructor(authService: AuthService) {
    authService.onAuthStateChanged(() => this.onAuthStateChanged());
    this.authService = authService;
  }

  @action
  public loginWithPassword = async (email: string, password: string) => {
    try {
      this.reset();
      const userCredential = await this.authService.loginWithPassword(email, password).catch((error) => {
        trackAuthenticationError(error, 'login-password', null, { email });
        throw error;
      });

      userCredential.user && trackFirebaseLogin(userCredential.user, { email });

      runInAction(() => (this.currentUser = userCredential.user));
      return {
        status: 'DONE',
        message: `Sign In Successful`,
      };
    } catch (error) {
      this.setStateAndMessage('ERROR', i18n.t('common:snackbarMessages.errorLogin', { message: error.message }));
      return {
        status: 'ERROR',
        message: `Sign In Failed. ${error.message}`,
      };
    }
  };

  @action
  public async logInUserWithGoogle() {
    try {
      this.reset();
      const userCredential = await this.authService.logInUserWithGoogle().catch((error) => {
        trackAuthenticationError(error, 'login-google-sso');
        throw error;
      });

      userCredential.user && trackFirebaseLogin(userCredential.user, { email: userCredential.user.email ?? 'UNKNOWN' });

      runInAction(() => (this.currentUser = userCredential.user));
    } catch (error) {
      this.setStateAndMessage(
        'ERROR',
        i18n.t('common:snackbarMessages.errorLoginWithGoogle', { message: error.message })
      );
    }
  }

  @action
  public async logInUserWithMicrosoft() {
    try {
      this.reset();
      const userCredential: UserCredential = await this.authService.logInUserWithMicrosoft().catch((error) => {
        trackAuthenticationError(error, 'login-microsoft-sso');
        throw error;
      });

      userCredential.user && trackFirebaseLogin(userCredential.user, { email: userCredential.user.email ?? 'UNKNOWN' });

      runInAction(() => (this.currentUser = userCredential.user));
    } catch (error) {
      this.setStateAndMessage(
        'ERROR',
        i18n.t('common:snackbarMessages.errorLoginWithMicrosoft', {
          message: error.message,
        })
      );
    }
  }

  @action
  public async logInUserWithSAML() {
    try {
      this.reset();
      const userCredential = await this.authService
        .logInUserWithSAML(this.samlLoginTenantIds, this.samlLoginProviderIds)
        .catch((error) => {
          trackAuthenticationError(error, 'login-SAML');
          throw error;
        });

      userCredential.user && trackFirebaseLogin(userCredential.user, { email: userCredential.user.email ?? 'UNKNOWN' });

      runInAction(() => {
        this.currentUser = userCredential.user;
      });
    } catch (error) {
      this.setStateAndMessage('ERROR', `SAML login failed: ${error.message}`); // TODO translation!
    }
  }

  @action
  public async logOutUser(reload = true) {
    this.currentUser && trackFirebaseLogout(this.currentUser);
    this.authService.logOutUser();
    reload && location.reload();
  }

  @action
  public async onPasswordReset(
    code: string,
    email: string,
    newPassword: string
  ): Promise<{ success: boolean } | ResetPasswordError> {
    try {
      await this.authService.confirmPasswordReset(code, newPassword);
      await this.loginWithPassword(email, newPassword);
      return { success: true };
    } catch (error) {
      trackAuthenticationError(error, 'password-reset', null, { email });
      return new ResetPasswordError(error.message);
    }
  }

  @action
  public async getEmailForCode(code: string): Promise<string | VerifyPasswordResetCodeError> {
    try {
      const email = await this.authService.verifyPasswordResetCode(code);
      return email;
    } catch (error) {
      return new VerifyPasswordResetCodeError(error);
    }
  }

  @action
  public async updatePassword(email: string, currentPassword: string, newPassword: string) {
    try {
      this.reset();
      const updatePasswordResult = await this.authService.updatePassword(email, currentPassword, newPassword);
      this.setStateAndMessage('DONE', i18n.t('common:snackbarMessages.passwordSuccessfullyUpdated'));
      return updatePasswordResult;
    } catch (error) {
      trackAuthenticationError(error, 'password-update', null, { email });
      this.setStateAndMessage(
        'ERROR',
        i18n.t('common:snackbarMessages.errorPasswordUpdate', { message: error.message })
      );
    }
  }

  // This is currently unused. Leaving it there in case we actually want to allow users to signup by themselves instead of being created by a CBP or admin
  public signUp = async (email: string, password: string) => {
    try {
      await this.authService.createUserWithEmailAndPassword(email, password);
      return {
        status: 'DONE',
        message: i18n.t('common:snackbarMessages.accountCreatedSuccessfully'),
      };
    } catch (error) {
      return {
        status: 'ERROR',
        message: i18n.t('common:snackbarMessages.signupFailedWithMessage', {
          message: error.message,
        }),
      };
    }
  };

  public sendVerificationEmail = async () => {
    try {
      await this.authService.sendVerificationEmail();
      return {
        status: 'DONE',
        message: i18n.t('common:snackbarMessages.emailSent'),
      };
    } catch (error) {
      trackAuthenticationError(error, 'send-verification-code', null, {
        email: this.authService.currentUser()?.email,
      });
      return {
        status: 'ERROR',
        message: i18n.t('common:snackbarMessages.signupFailedWithMessage', {
          message: error.message,
        }),
      };
    }
  };

  public verifyEmail = async (code: string) => {
    try {
      await this.authService.applyActionCode(code);
      this.setStateAndMessage('DONE', i18n.t('common:snackbarMessages.emailVerified'));
      this.onAuthStateChanged();
      return {
        status: 'DONE',
        message: i18n.t('common:snackbarMessages.emailVerified'),
      };
    } catch (error) {
      trackAuthenticationError(error, 'verify-verification-code', null, {
        email: this.authService.currentUser()?.email,
      });
      this.setStateAndMessage(
        'ERROR',
        i18n.t('common:snackbarMessages.errorVerifyingEmailWithMessage', {
          message: error.message,
        })
      );
      return {
        status: 'ERROR',
        message: i18n.t('common:snackbarMessages.errorVerifyingEmailWithMessage', {
          message: error.message,
        }),
      };
    }
  };

  @computed
  public get emailNeedsVerification(): boolean | null {
    if (!this.isLoggedIn) {
      return null;
    } else {
      const providerData = this.currentUser && this.currentUser.providerData;

      let skipEmailVerification = false;
      if (providerData) {
        if (providerData.length === 1) {
          const providerId = providerData[0].providerId;
          if (providerId === 'google.com' || providerId === 'microsoft.com') {
            console.debug(`SSO provider is ${providerId}. Skipping email-verification.`);
            skipEmailVerification = true;
          } else {
            console.debug(`SSO provider is ${providerId}. Not skipping email-verification.`);
          }
        } else {
          console.debug('Found none or multiple SSO providers. Not skipping email-verification.');
          // TODO: we might have to only log the necessary information instead of the entire object that might contain sensitive data
          console.debug(providerData);
        }
      }
      if ((this.currentUser && this.currentUser.emailVerified) || skipEmailVerification) {
        return false;
      } else {
        // This is an unusual case and shouldn't really happen anymore, so we log an error
        trackAuthenticationError(
          new Error('Email verification unexpectedly required'),
          'unexpected-email-verification-required',
          null,
          {
            email: this.currentUser?.email,
            providerData: this.currentUser?.providerData,
          }
        );
        return true;
      }
    }
  }

  @computed
  public get isSaml(): boolean {
    return Object.values(this.samlLoginTenantIds).includes(this.currentUser?.tenantId ?? '');
  }

  @action
  private setStateAndMessage(state: any, message: string) {
    this.state = state;
    this.message = message;
  }

  @action
  private onAuthStateChanged() {
    this.loading = false;
    this.isLoggedIn = this.authService.isLoggedIn();
    this.currentUser = this.authService.currentUser();
  }
}
