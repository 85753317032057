import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PercentageIcon } from '../../styles';
import { LocalOverTimeDisplayAction } from '../charts/overtime/types';
import { LocalTimePeriodDisplayAction } from '../charts/timeperiod/types';
import { StyledIconButton } from './shared-styles';

interface DisplayPercentageToggleProps {
  dispatcher: React.Dispatch<LocalOverTimeDisplayAction> | React.Dispatch<LocalTimePeriodDisplayAction>;
}

export const DisplayPercentageToggle = (props: DisplayPercentageToggleProps) => {
  const { dispatcher } = props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatcher({ type: 'toggle-percentage' });
  };

  return (
    <Tooltip title={t('common:commonValues.tooltips.togglePercentage')} placement="top">
      <StyledIconButton onClick={handleClick} size="small" aria-label="Toggle Percentage">
        <PercentageIcon />
      </StyledIconButton>
    </Tooltip>
  );
};
