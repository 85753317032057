import { action, autorun, computed, observable } from 'mobx';
import i18n from '../../../i18n';
import { Granularity } from '../date-manager/date-manager-constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { getEndOfMonth, getStartOfMonth } from '../filter/utils';
import { trackBenchmark } from '../helpers/trackers/sidebarTracker';
import { localStore } from '../local-store';
import { TimeSliderStore } from '../timeslider/timeslider-store';
import {
  Benchmark,
  BenchmarkCategory,
  BenchmarkTypes,
  ExternalBenchmarkTypes,
  InternalBenchmarkTypes,
  TimeBenchmarkTypes,
} from './types';

export const BENCHMARK = i18n.t('common:commonValues.misc.benchmark');
export const FORECAST = i18n.t('common:commonValues.misc.forecast');

export const getBenchmarkLabel = (label: string) => `${BENCHMARK} - ${label}`;
export const getForecastLabel = (label: string) => `${FORECAST} - ${label}`;

const benchmarkCategoryTranslations: Record<BenchmarkCategory, string> = {
  [BenchmarkCategory.INTERNAL]: i18n.t('common:benchmark.internal'),
  [BenchmarkCategory.EXTERNAL]: i18n.t('common:benchmark.external'),
  [BenchmarkCategory.TIME]: i18n.t('common:benchmark.time'),
};

export enum BenchmarkLocations {
  HEADER,
  SIDEBAR,
}
export const getBenchmarkTypesTranslations = (
  benchmarktype: BenchmarkTypes,
  benchmarkLocation: BenchmarkLocations,
  timeSliderStore: TimeSliderStore
): string => {
  const { specificPeriodBenchmarkStartDate, specificPeriodBenchmarkEndDate } = timeSliderStore.timeSliderConfig;
  const benchmarkTypesTranslations: Record<BenchmarkTypes, string> = {
    [InternalBenchmarkTypes.CompanySameFilters]: i18n.t('common:benchmark.companySameFilters'),
    [InternalBenchmarkTypes.CompanyTotal]: i18n.t('common:benchmark.companyTotal'),
    [InternalBenchmarkTypes.MyPop]: i18n.t('common:benchmark.myPop'),
    [ExternalBenchmarkTypes.PDU]: i18n.t('common:benchmark.pdu'),
    [ExternalBenchmarkTypes.PRDU]: i18n.t('common:benchmark.prdu'),
    [TimeBenchmarkTypes.YEAR]: i18n.t('common:benchmark.year'),
    [TimeBenchmarkTypes.FINQUARTER]: i18n.t('common:benchmark.finQuarter'),
    [TimeBenchmarkTypes.PRIOR_PERIOD]: i18n.t('common:benchmark.priorPeriod'),
    [TimeBenchmarkTypes.SPECIFIC_PERIOD]:
      benchmarkLocation === BenchmarkLocations.HEADER
        ? i18n.t('common:benchmark.specificPeriodRange', {
            specificPeriodStart: specificPeriodBenchmarkStartDate
              ? dateManagerService.formatApiDateToNewFormat(specificPeriodBenchmarkStartDate, Granularity.MONTH)
              : '',
            specificPeriodend: specificPeriodBenchmarkEndDate
              ? dateManagerService.formatApiDateToNewFormat(specificPeriodBenchmarkEndDate, Granularity.MONTH)
              : '',
          })
        : i18n.t('common:benchmark.specificPeriod'),
  };
  return benchmarkTypesTranslations[benchmarktype];
};

export const getBenchmarkDisplayLabel = (benchmark: Benchmark, timeSliderStore: TimeSliderStore) => {
  const { type, value } = benchmark;
  const typeLabel = benchmarkCategoryTranslations[type];
  const valueLabel = getBenchmarkTypesTranslations(value, BenchmarkLocations.SIDEBAR, timeSliderStore);
  return `${typeLabel}: ${valueLabel}`;
};

export class BenchmarkStore {
  // public prduId = 'ca20f5c53ddc42d7a26ead9438fa7753';

  public static isSameBenchmark = (benchmarkA: Benchmark | null, benchmarkB: Benchmark | null) => {
    if (benchmarkA && benchmarkB) {
      return benchmarkA.type === benchmarkB.type && benchmarkA.value === benchmarkB.value;
    }
    return false;
  };

  private timeSliderStore: TimeSliderStore;

  public constructor(timeSliderStore: TimeSliderStore) {
    this.timeSliderStore = timeSliderStore;

    let firstRun = true;
    autorun(() => {
      const storeData = JSON.stringify({ selectedBenchmark: this.selectedBenchmark });
      if (!firstRun) {
        localStore.set('benchmarkStore', storeData);
      }
      firstRun = false;
    });
  }

  public prduId = 'ca20f5c53ddc42d7a26ead9438fa7753';

  public internalBenchmarks: Benchmark[] = [
    {
      type: BenchmarkCategory.INTERNAL,
      value: InternalBenchmarkTypes.MyPop,
    },
    {
      type: BenchmarkCategory.INTERNAL,
      value: InternalBenchmarkTypes.CompanyTotal,
    },
    {
      type: BenchmarkCategory.INTERNAL,
      value: InternalBenchmarkTypes.CompanySameFilters,
    },
  ];

  @computed
  public get externalBenchmarks(): Benchmark[] {
    return [
      {
        type: BenchmarkCategory.EXTERNAL,
        value: ExternalBenchmarkTypes.PDU,
        isDisabled: () => true,
      },
      {
        type: BenchmarkCategory.EXTERNAL,
        value: ExternalBenchmarkTypes.PRDU,
        isDisabled: () => true,
      },
    ];
  }

  public timeBenchmarks: Benchmark[] = [
    {
      type: BenchmarkCategory.TIME,
      value: TimeBenchmarkTypes.YEAR,
    },
    // {
    //   type: BenchmarkCategory.TIME,
    //   value: TimeBenchmarkTypes.FINQUARTER,
    //   isDisabled: () => this.timeSliderStore.timeSliderConfig.granularity !== Granularity.FINQUARTER,
    // },
    // {
    //   type: BenchmarkCategory.TIME,
    //   value: TimeBenchmarkTypes.PRIOR_PERIOD,
    // },
    {
      type: BenchmarkCategory.TIME,
      value: TimeBenchmarkTypes.SPECIFIC_PERIOD,
    },
  ];

  public availableBenchmarks: Benchmark[] = [
    ...this.internalBenchmarks,
    ...this.externalBenchmarks,
    ...this.timeBenchmarks,
  ];

  @observable
  public selectedBenchmark: Benchmark | null = null;

  @computed
  public get isTimeBenchmark(): boolean {
    return this.selectedBenchmark?.type === BenchmarkCategory.TIME;
  }

  @action
  public toggleBenchmark = (benchmark: Benchmark) => {
    if (BenchmarkStore.isSameBenchmark(this.selectedBenchmark, benchmark)) {
      this.selectedBenchmark = null;
    } else {
      const selectedBenchmark = this.availableBenchmarks.find((b) => BenchmarkStore.isSameBenchmark(b, benchmark));
      if (selectedBenchmark) {
        this.selectedBenchmark = selectedBenchmark;
        trackBenchmark(benchmark);
      }
    }
  };

  @action
  public setSpecificPeriodBenchmarkStartDate = (date: string) => {
    const dateObj = dateManagerService.parseApiDate(date);
    this.timeSliderStore.setTimeSliderConfig({
      specificPeriodBenchmarkStartDate: getStartOfMonth(dateObj),
    });
  };

  @action
  public setSpecificPeriodBenchmarkEndDate = (date: string) => {
    const endDateObj = dateManagerService.parseApiDate(date);
    const startDate = this.timeSliderStore.timeSliderConfig.specificPeriodBenchmarkStartDate;
    if (endDateObj.isBefore(startDate)) return;
    this.timeSliderStore.setTimeSliderConfig({
      specificPeriodBenchmarkEndDate: getEndOfMonth(endDateObj),
    });
  };

  @action
  public resetBenchmark = () => {
    this.selectedBenchmark = null;
  };
}
