import { useReducer } from 'react';
import { match } from 'ts-pattern';
import { useDisplayGlobalContext } from '../../../../context/contexts';
import { DisplayState, GlobalRadialDisplayAction, RadialDisplayAction, RadialDisplayHandle } from './types';

export const useDisplay = (): RadialDisplayHandle => {
  const displayGlobalHandle = useDisplayGlobalContext();
  const [displayGlobalState] = displayGlobalHandle;

  const initialState = {
    enableLabels: true,
    showLabels: false,
    enableLegend: true,
    showLegend: false,
    showTableView: false,
    globalDisplayState: displayGlobalState,
  };
  return useReducer(reducer, initialState);
};

const reducer = (state: DisplayState, action: RadialDisplayAction | GlobalRadialDisplayAction): DisplayState => {
  return match(action)
    .with({ type: 'toggle-tableView' }, () => {
      return {
        ...state,
        enableLegend: state.showTableView,
        enableLabels: state.showTableView,
        showTableView: !state.showTableView,
      };
    })
    .with({ type: 'toggle-labels' }, () => {
      return {
        ...state,
        showLabels: !state.showLabels,
      };
    })
    .with({ type: 'toggle-legend' }, () => {
      return {
        ...state,
        showLegend: !state.showLegend,
      };
    })
    .with({ type: 'global-state-sync' }, (a) => {
      return {
        ...state,
        showLabels: a.globalDisplayState.showLabels,
        showLegend: a.globalDisplayState.showLegend,
        globalDisplayState: a.globalDisplayState,
      };
    })
    .exhaustive();
};
