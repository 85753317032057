export const unselectedFactboxColor = '#4CBFB6';
export const teal2 = 'rgb(38,166,154)';
export const lightTeal = '#26A69A';
export const darkTeal = '#18675f';
export const lightGray = '#e4e4e4';
export const lightestGray = '#f7f7f7';
export const mediumGray = '#BEBEBE';
export const darkGray = '#929292';
export const filterBarGrey = '#929292';
export const filterBarRed = 'red';
export const segmentationMainColor = '#30638E';
export const dropdownMenuItemHoverColor = 'rgba(38, 166, 154, 0.3)';

export const primaryColor = '#00a599';
export const benchmarkColor = 'rgba(133,202,91)';

export const filterColors = [
  '#FCA720',
  '#F6891F',
  '#E04F43',
  '#D72F5C',
  '#93294E',
  '#6A1874',
  '#1B237E',
  '#1B86E3',
  '#4EBBD4',
  '#6DBD5A',
  '#1B374D',
  'rgb(67,67,67)',
  'rgb(147,35,78)',
  'rgb(252,167,32)',
  'rgb(239,80,47)',
  'rgb(180,118,171)',
  'rgb(27,55,77)',
  'rgb(15,136,150)',
  'rgb(42,195,203)',
  'rgb(215,47,92)',
  '#6A1874',
];

export const chartColors = [
  'rgba(38,166,154,0.8)',
  'rgba(252, 208, 21, 0.8)',
  'rgba(224, 79, 67, 0.8)',
  'rgba(147, 35, 78, 0.8)',
  'rgba(106, 24, 116, 0.8)',
  'rgba(27, 35, 126, 0.8)',
  'rgba(78, 187, 212, 0.8)',
  'rgba(198, 237, 216, 0.8)',
  'rgba(133, 202, 91, 0.8)',
  'rgba(27, 113, 107, 0.8)',
  'rgba(18, 64, 62, 0.8)',
  'rgba(187, 118, 58, 0.8)',
  'rgba(226, 208, 163, 0.8)',
  'rgba(228, 172, 173, 0.8)',
  'rgba(112, 112, 112, 0.8)',
];

export const pbsSegmentationColors = ['#3D405B', '#00798C', '#3CBBB1', '#C9184A', '#C8553D', '#EDAE49', '#B1B9B8'];

export const chartColorsHex = [
  '#50b7ad',
  '#f6891f',
  '#fcd015',
  '#e04f43',
  '#93234e',
  '#6a1874',
  '#1b237e',
  '#4ebbd4',
  '#c6edd8',
  '#85ca5b',
  '#1b716b',
  '#12403e',
  '#bb763a',
  '#e2d0a3',
  '#e4acad',
  '#707070',
  '#25b1e4',
  '#533baf',
];
