import { DomainPreferences } from '../../api/types';
import { DashboardConfig } from '../../common/components/dashboards/types';
import { AlexDashboardChartConfig } from './alex_dashboard_config';
import { JTBDashboardChartConfig } from './jtb_dashboard_config';
import { ShaqDashboardChartConfig } from './shaq_dashboard_config';
import { ShipHealthcareDashboardChartConfig } from './shiphealthcare_dashboard_config';
import { ValentinDashboardChartConfig } from './valentin_dashboard_chart_config';

const defaultDashboardConfigs: DashboardConfig[] = [];
const jtDashboardConfigs: DashboardConfig[] = [];
const shipHealthcareDashboardConfigs: (domainPreferences: DomainPreferences) => DashboardConfig[] = (
  domainPreferences: DomainPreferences
) => [ShipHealthcareDashboardChartConfig(domainPreferences)];
const swanDashboardConfigs: DashboardConfig[] = [AlexDashboardChartConfig, ShaqDashboardChartConfig];
const allDashboardConfigs: (domainPreferences: DomainPreferences) => DashboardConfig[] = (
  domainPreferences: DomainPreferences
) => [
  AlexDashboardChartConfig,
  ShaqDashboardChartConfig,
  ValentinDashboardChartConfig,
  JTBDashboardChartConfig(domainPreferences),
];
const jtbDashboardConfigs: (domainPreferences: DomainPreferences) => DashboardConfig[] = (
  domainPreferences: DomainPreferences
) => [JTBDashboardChartConfig(domainPreferences)];

export const defaultDashboardConfigsJson = (domainPreferences: DomainPreferences) =>
  JSON.stringify(defaultDashboardConfigs);
export const jtDashboardConfigsJson = (domainPreferences: DomainPreferences) => JSON.stringify(jtDashboardConfigs);
export const shipHealthcareDashboardConfigsJson = (domainPreferences: DomainPreferences) =>
  JSON.stringify(shipHealthcareDashboardConfigs(domainPreferences));
export const jtbDashboardConfigsJson = (domainPreferences: DomainPreferences) =>
  JSON.stringify(jtbDashboardConfigs(domainPreferences));
export const swanDashboardConfigsJson = (domainPreferences: DomainPreferences) => JSON.stringify(swanDashboardConfigs);
export const allDashboardConfigsJson = (domainPreferences: DomainPreferences) =>
  JSON.stringify(allDashboardConfigs(domainPreferences));
