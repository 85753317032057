import { axisClasses, barLabelClasses } from '@mui/x-charts';
import { darkGray, lightGray, primaryColor } from '../../../theme';

type SeriesId = string;
interface ChartStylesOptions {
  seriesStyling: Record<SeriesId, { dottedLine: boolean; stroke?: string }>;
}

export const chartStyles = (options?: ChartStylesOptions) => ({
  [`.${axisClasses.root}`]: {
    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
      stroke: lightGray,
      strokeWidth: 1,
    },
  },
  [`.${axisClasses.directionY}`]: {
    [`.${axisClasses.tickLabel}`]: {
      fill: primaryColor,
    },
  },
  [`.${axisClasses.directionX}`]: {
    [`.${axisClasses.tickLabel}`]: {
      fill: darkGray,
    },
  },
  [`.${barLabelClasses.root}`]: {
    fill: 'white',
  },
  ...Object.entries(options?.seriesStyling ?? {}).reduce((acc, [seriesId, config]) => {
    return {
      ...acc,
      [`.MuiLineElement-series-${seriesId}`]: {
        stroke: config.stroke,
        strokeDasharray: config.dottedLine ? '4' : 'none',
      },
    };
  }, {}),
});
