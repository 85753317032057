import { Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { SQLFilters } from '../../../api/types';
import { FilterIcon } from '../../styles';
import { lightTeal } from '../../theme';

interface UserFilterTooltipProps {
  userFilters?: SQLFilters;
}

const StyledPopper = styled(Popper)({
  backgroundColor: lightTeal,
  padding: '8px',
  borderRadius: '5px',
  color: 'white',
  maxWidth: '500px',
  zIndex: 2147483647,
});

const Title = styled('h4')({
  fontSize: '1rem',
  color: 'white',
  margin: '0 0 4px 0',
});

const Divider = styled('hr')({
  margin: '0 0 4px 0',
  opacity: 0.3,
});

const Content = styled('p')({
  fontSize: '0.875rem',
  color: 'white',
  margin: 0,
  wordWrap: 'break-word',
});

export const UserFilterTooltip = (props: UserFilterTooltipProps) => {
  const { userFilters } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const checkFullscreen = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', checkFullscreen);
    return () => document.removeEventListener('fullscreenchange', checkFullscreen);
  }, []);

  if (!userFilters) {
    return null;
  }

  const handleHover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const popperContent = (
    <>
      <Title>Filters</Title>
      <Divider />
      <Content>{userFilters || 'No filters set'}</Content>
    </>
  );

  return (
    <span>
      <span
        className="inline-flex justify-end align-middle"
        onMouseEnter={handleHover}
        onMouseLeave={() => setOpen(false)}
      >
        <FilterIcon />
      </span>
      <StyledPopper
        placement="bottom-start"
        open={open}
        anchorEl={anchorEl}
        container={isFullscreen ? document.fullscreenElement : undefined}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window',
              altAxis: true,
              padding: 8,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {popperContent}
      </StyledPopper>
    </span>
  );
};
