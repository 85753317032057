import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarIcon, StackIcon } from '../../styles';
import { OverTimeDisplayHandle } from '../charts/overtime/types';
import { TimePeriodDisplayHandle } from '../charts/timeperiod/types';
import { StyledIconButton } from './shared-styles';

interface DisplayStackToggleProps {
  displayHandle: TimePeriodDisplayHandle | OverTimeDisplayHandle;
}

export const DisplayStackToggle = (props: DisplayStackToggleProps) => {
  const { displayHandle } = props;
  const { t } = useTranslation();
  const [state, dispatcher] = displayHandle;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatcher({ type: 'toggle-stack' });
  };

  return (
    <Tooltip title={t('common:commonValues.tooltips.toggleStack')} placement="top">
      <StyledIconButton onClick={handleClick} size="small" aria-label="Toggle Stack">
        {state.showStack ? <BarIcon /> : <StackIcon />}
      </StyledIconButton>
    </Tooltip>
  );
};
