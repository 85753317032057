import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';
import { defaultTheme } from '../../../../theme/default-theme';
import { replaceSpaces } from '../../../utils';
import { teal2 } from '../../theme';
import { TableData } from './types';

const StyledTableContainer = styled(TableContainer)<
  TableContainerProps & { $fullScreenActive: boolean; $chartHeight: string }
>`
  position: relative;
  top: 16px;
  height: calc(${(props) => props.$chartHeight} - 16px);
  max-height: calc(${(props) => props.$chartHeight} - 16px);
  overflow: auto;
`;

const StyledTable = styled(Table)<{ $fullScreenActive: boolean }>`
  height: 100%;
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${defaultTheme.color.clientPrimary};
`;

const StyledTableCell = styled(TableCell)`
  min-width: 100px;
`;

// TODO: Use MUI X DataTable here
export const TableView = ({
  tableData,
  fullScreenHandle,
  chartHeight,
}: {
  tableData: TableData;
  fullScreenHandle: FullScreenHandle;
  chartHeight: string;
}) => {
  return (
    <StyledTableContainer component={Paper} $fullScreenActive={fullScreenHandle.active} $chartHeight={chartHeight}>
      <StyledTable $fullScreenActive={fullScreenHandle.active} aria-label="simple table" stickyHeader>
        <StyledTableHead>
          <TableRow>
            {tableData.headers.map((h, i) => (
              <StyledTableCell
                key={`${h.key ?? replaceSpaces(h.value)}-${i}`}
                sx={{
                  backgroundColor: teal2,
                  color: 'white',
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                  textOverflow: 'unset',
                }}
              >
                {h.value}
              </StyledTableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {tableData.rows.map((row, i) => (
            <TableRow key={`${row.key ?? ''}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <StyledTableCell key={row.title ? replaceSpaces(row.title) : ''} sx={{ whiteSpace: 'nowrap' }}>
                {row.title}
              </StyledTableCell>
              {row.cells.map((cell) => (
                <StyledTableCell key={cell.key ?? replaceSpaces(cell.value)} sx={{ whiteSpace: 'nowrap' }}>
                  {cell.value}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};
