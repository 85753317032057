import { useCallback, useState } from 'react';
import { ManualDataRefetchCountHandle } from './types';

export const useManualRefetchCountHandle = (): ManualDataRefetchCountHandle => {
  const [manualDataRefetchCount, setManualDataRefetchCount] = useState(0);
  return {
    current: manualDataRefetchCount,
    increment: useCallback(() => setManualDataRefetchCount((prev) => prev + 1), []),
  };
};
