import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAliases } from '../../../api/alias/hooks';
import { SQLFilters } from '../../../api/types';
import { MetricId as GraphQLMetricId } from '../../../api/types-graphql';
import {
  useAliasServiceContext,
  useAuthorizationServiceContext,
  useGlobalLocaleContext,
  useMetricDetailsMapContext,
} from '../../../context/contexts';
import { MetricIdType, Segmentation, TimeSelection } from '../../../types';
import { MetricQueryTooltip } from '../modals/MetricQueryModal';
import { SingleValueTimePeriodTooltip } from '../modals/SingleValueTimePeriodModal';
import { UserFilterTooltip } from '../modals/UserFilterModal';
import { showTitle } from '../utils-display';

export const ChartTitle = (props: {
  metrics: MetricIdType[];
  metricsSql: (string | null)[];
  timeSelection: TimeSelection;
  title?: string;
  segmentations?: Segmentation[];
  userFilters?: SQLFilters;
}) => {
  const { t } = useTranslation();
  const { metrics, metricsSql, timeSelection, title, segmentations, userFilters } = props;

  const metricDetailsMap = useMetricDetailsMapContext();
  const aliasService = useAliasServiceContext();
  const { data: aliases } = useAliases(aliasService);
  const locale = useGlobalLocaleContext();
  const authorizationService = useAuthorizationServiceContext();
  const getAliasForMetric = aliasService.getAliasForMetricGroupId(aliases, locale.selected);
  const metricDetails = metrics.map((m) => metricDetailsMap[m.value as unknown as GraphQLMetricId]);

  const alias = metricDetails.map((md) => getAliasForMetric(md.metricGroupId));
  const canSeeChartDevOptions = authorizationService.canSeeChartDevOptions();
  return (
    <Typography>
      {showTitle(
        metrics,
        metricDetails.map((md) => md.metricGroupId),
        timeSelection,
        alias,
        t,
        title,
        segmentations
      )}
      {canSeeChartDevOptions && <MetricQueryTooltip metrics={metrics} metricsSql={metricsSql} />}
      {canSeeChartDevOptions && <UserFilterTooltip userFilters={userFilters} />}
      <SingleValueTimePeriodTooltip timeSelection={timeSelection} />
    </Typography>
  );
};
