import ChatIcon from '@mui/icons-material/Chat';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { observer } from 'mobx-react';
import React, { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SideBarLink } from '../../../pages/panalyt-superadmin/SideBar';
import { ReportBug } from '../../../pages/report-bug/ReportBug';
import { ROUTE_URLS } from '../../constants/constants';
import { rootStore } from '../../store/root-store';
import {
  useListFullViewDashboards,
  useListUserViewCustomDashboardConfigs,
  useListUserViewDashboards,
} from '../../v2/api/dashboards/hooks';
import { getCustomDashboardLink, getCustomDashboardPageLink } from '../../v2/common/components/dashboards/utils';
import { LoadingCircle } from '../../v2/common/components/LoadingCircle';
import { useGlobalLocaleContext } from '../../v2/context/contexts';
import { NavBarItemObj } from './NavBarItem';
import NavTraySection from './NavTraySection';

export const panalytSuperAdminConsoleItems: SideBarLink[] = [
  {
    id: 'companyList',
    name: 'common:navigation.companyList',
    link: '/panalyt-superadmin/company-list',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_company-list',
  },
  {
    id: 'manageDomainSettings',
    name: 'common:navigation.manageDomainSettings',
    link: '/panalyt-superadmin/manage-domain-settings',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_manage-domain-settings',
  },
  {
    id: 'manageDomainPreferences',
    name: 'common:navigation.manageDomainPreferences',
    link: '/panalyt-superadmin/manage-domain-preferences',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_manage-domain-preferences',
  },
  {
    id: 'companyDashboards',
    name: 'common:navigation.companyDashboards',
    link: '/panalyt-superadmin/company-dashboards',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_company-dashboards',
  },
  {
    id: 'domainSettings',
    name: 'common:navigation.domainSettings',
    link: '/panalyt-superadmin/manage-company/domain-preferences',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_domain-settings',
  },
  {
    id: 'manageMetrics',
    name: 'common:navigation.manageMetrics',
    link: '/panalyt-superadmin/manage-metrics',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_manage-metrics',
  },
  {
    id: 'multiDomainAuth',
    name: 'common:navigation.multiDomainAuth',
    link: '/panalyt-superadmin/multi-domain-auth',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_multi-domain-auth',
  },
  {
    id: 'backendApi',
    name: 'common:navigation.backendApi',
    link: '/panalyt-superadmin/backend-api',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_backend-api',
  },
  {
    id: 'batchActions',
    name: 'common:navigation.batchActions',
    link: '/panalyt-superadmin/batch-actions',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_batch-actions',
  },
  {
    id: 'chappiConversations',
    name: 'common:navigation.chappiConversations',
    link: '/panalyt-superadmin/chappi-conversations',
    avatar: <ChatIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_chappi-conversations',
  },
];

const StyledLink = styled(Link as any)`
  text-decoration: none;
`;
const myPanalytLink = '/my-panalyt';

const NavTrayContainer = styled.div`
  // overflow: auto;
`;
// TODO: disabling overflow here as it is somehow causing menu in NavTrayItem to not appear(can check with Dashboard list on nav tray).
//  Ideally there should be overflow here as well, so investigate later and fix.

interface NavigationTrayProps {
  toggleMenu: () => void;
}

interface AdminConsoleItem {
  id: string;
  name: string;
  link: string;
  className: string;
  isEnabled?: () => boolean;
}

// TODO: this might have to be cached for performance reasons
const adminConsoleItems = () => {
  const userPermissions: AdminConsoleItem = {
    id: 'permissions',
    name: 'common:navigation.permissions',
    link: ROUTE_URLS.PERMISSIONS,
    className: 'intercom_nt_permissions',
    isEnabled: () => rootStore.featureAccessService.canViewUserPermissions(),
  };
  const myAccount: AdminConsoleItem = {
    id: 'myAccount',
    name: 'common:navigation.myAccount',
    link: ROUTE_URLS.MY_ACCOUNT,
    className: 'intercom_nt_myaccount',
  };
  const alias: AdminConsoleItem = {
    id: 'alias',
    name: 'common:navigation.alias',
    link: ROUTE_URLS.ALIAS,
    className: 'intercom_nt_alias',
    isEnabled: () => rootStore.enabledFeaturesService.isAliasEnabled(),
  };
  const dataUpload: AdminConsoleItem = {
    id: 'dataUpload',
    name: 'common:navigation.dataUpload',
    link: ROUTE_URLS.DATA_UPLOAD,
    className: 'intercom_nt_dataUpload',
    isEnabled: () => rootStore.permissionsStore.canUploadData(),
  };

  return [userPermissions, myAccount, alias, dataUpload].filter((item) => (item.isEnabled ? item.isEnabled() : true));
};

const CustomDashboardsNavTraySection = ({ toggleMenu }: { toggleMenu: () => void }) => {
  const allowedCustomDashboards = useListUserViewCustomDashboardConfigs();
  const locale = useGlobalLocaleContext();
  const customDashboardSubItems: NavBarItemObj[] = allowedCustomDashboards.map((db) => {
    return {
      id: db.id,
      name: db.title?.[locale.selected],
      link: getCustomDashboardLink(db.id),
      subItems: db.pages
        .filter((p) => p.enabled)
        .map((p) => {
          return {
            id: p.pageId,
            name: p.title?.[locale.selected],
            link: getCustomDashboardPageLink(db.id, p.pageId),
          };
        }),
    };
  });

  return customDashboardSubItems.length > 0 ? (
    <NavTraySection
      toggleMenu={toggleMenu}
      name={'common:navigation.customDashboards'}
      subItems={customDashboardSubItems}
      color="#25b1e4"
      openByDefault={false}
      className="intercom_nt_db"
    />
  ) : null;
};

const NavigationTray = observer((props: NavigationTrayProps) => {
  const [reportBugOpen, setReportBugOpen] = useState(false);

  const handleReportBugClose = () => {
    setReportBugOpen(false);
  };

  const { toggleMenu } = props;
  const allowedDashboards = useListUserViewDashboards();
  const allDashboards = useListFullViewDashboards();
  const dashboardSubItems: NavBarItemObj[] = allowedDashboards.map((db) => ({
    ...db,
    name: db.nameKey,
    subItems: db.pages.map((p) => ({ ...p, name: p.nameKey })),
    className: 'intercom_nt_db-item',
  }));
  const showCustomDashboards = rootStore.enabledFeaturesService.isCustomDashboardsEnabled();
  return (
    <>
      <NavTrayContainer>
        <StyledLink to={myPanalytLink} onClick={toggleMenu}>
          <NavTraySection
            toggleMenu={toggleMenu}
            name="common:navigation.myPanalyt"
            color="rgb(38,166,154)"
            className="intercom_nt_mypanalyt"
          />
        </StyledLink>
        {showCustomDashboards && (
          <Suspense fallback={<LoadingCircle color="secondary" />}>
            <CustomDashboardsNavTraySection toggleMenu={toggleMenu} />
          </Suspense>
        )}
        <NavTraySection
          toggleMenu={toggleMenu}
          name={'common:navigation.dashboards'}
          subItems={dashboardSubItems}
          color="#FCA720"
          openByDefault={true}
          className="intercom_nt_db"
        />
        {rootStore.permissionsStore.canSeeAdminMenu() && (
          <NavTraySection
            toggleMenu={toggleMenu}
            name={'common:navigation.admin'}
            subItems={adminConsoleItems()}
            color="#93234E"
            className="intercom_nt_admin"
          />
        )}
        {rootStore.permissionsStore.canSeeSuperAdminMenu() && (
          <NavTraySection
            toggleMenu={toggleMenu}
            name={'common:navigation.superAdmin'}
            subItems={panalytSuperAdminConsoleItems.map(({ id, name, link }) => ({
              id,
              name,
              link,
            }))}
            color="#93234E"
            className="intercom_nt_admin"
          />
        )}
      </NavTrayContainer>
      <ReportBug open={reportBugOpen} onClose={handleReportBugClose} />
    </>
  );
});

export default NavigationTray;
