import { RegularMetricId } from '../../api/types-graphql';
import { DashboardConfig } from '../../common/components/dashboards/types';
import { Languages } from '../../constants';
import { DataTypes, EmployeeDataFields, TimeSelectionType } from '../../types';

export const ShaqDashboardChartConfig: DashboardConfig = {
  id: 'shaq',
  title: {
    [Languages.EN]: 'Shaq',
    [Languages.JA]: 'シャック',
  },
  enabled: true,
  pages: [
    {
      pageId: '1',
      dashboardId: 'shaq',
      pageNumber: 1,
      enabled: true,
      title: {
        [Languages.EN]: 'DEMAND',
        [Languages.JA]: '需要',
      },
      pageOptions: {
        dataView: {
          enabled: true,
        },
      },
      components: [
        {
          chartId: 'attrition-ttm',
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_3AttritionTtm }],
              timeSelection: { type: TimeSelectionType.FinancialYearYearly, input: { start: 2022, end: 2023 } },
            },
          ],
        },
        {
          chartId: '',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0010_1MngCalcCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0020_1FhcCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0023_1TenureMedian },
              ],
              timeSelection: {
                type: TimeSelectionType.FinancialYearQuarterly,
                input: { start: { quarterOfYear: 2, year: 2022 }, end: { quarterOfYear: 3, year: 2023 } },
              },
            },
          ],
        },
        {
          chartId: 'joiners',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: { type: TimeSelectionType.CalendarYearYearly, input: { start: 2020, end: 2023 } },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'individuals-count',
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: '2022-08-31', end: '2022-08-31' },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'new-joiners-managers-by-gender',
          title: {
            [Languages.EN]: 'New Joiners Managers by Gender',
            [Languages.JA]: '新規参加者マネージャーの性別',
          },
          chartTypeConfig: { chartType: 'area' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0014_1MngDefCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearQuarterly,
                input: { start: { quarterOfYear: 2, year: 2022 }, end: { quarterOfYear: 3, year: 2023 } },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
              filters: `EMPLOYEE.TENURE_GROUP = ('6ヶ月未満')`,
            },
          ],
        },
        {
          chartId: 'leavers-by-gender',
          title: {
            [Languages.EN]: 'Leavers by Gender',
            [Languages.JA]: '退職者の性別',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime', stack: false, relative: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2022-06-01', end: '2023-09-30' },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                  filters: ['Male', 'Female'],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
