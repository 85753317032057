import { DataFieldWithDataType } from '../../common-types';
import {
  ApplicationDataFields as ADF,
  DataTypes,
  DEFAULT_OPTION,
  Domains,
  EmployeeDataFields as EDF,
  EvaluationDataFields as EvDF,
  GLOBAL_SETTING,
  JobDataFields as JDF,
  PayrollDataFields as PDF,
  RecruiterJobsDataFields as RDF,
  SurveyDataFields,
} from './constants/constants';
import { Dashboards } from './v2/common/components/dashboards/types';

type DataViewByDashboardMap = Partial<
  Record<Dashboards | GLOBAL_SETTING, Partial<Record<Domains | DEFAULT_OPTION, Set<DataFieldWithDataType>>>>
>;

const CompanyDataViewHiddenColumnsSettings: DataViewByDashboardMap = {
  [GLOBAL_SETTING]: {
    [Domains.MIXI_COMPANY_WIDE]: new Set([{ dataType: DataTypes.EMPLOYEE, dataField: EDF.GENDER }]),
    [Domains.NECNSI]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.CUSTOM_FIELD_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.CUSTOM_FIELD_2_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.CUSTOM_FIELD_2_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.CUSTOM_FIELD_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.DISABILITY_SEVERITY },
    ]),
  },
};

export const isHiddenField = (
  field: DataFieldWithDataType,
  domain: Domains,
  location: Dashboards | GLOBAL_SETTING = GLOBAL_SETTING
) => CompanyDataViewHiddenColumnsSettings[location]?.[domain]?.deepCompareContains(field) ?? false;

export const getDataViewFieldsFromSettings = ({
  domain,
  location = GLOBAL_SETTING,
  defaultFields,
  nonNullFields,
  isJapaneseClient,
  isUsingEffectiveLeaverDate,
}: {
  domain: Domains;
  location: Dashboards | GLOBAL_SETTING;
  defaultFields: Set<DataFieldWithDataType>;
  nonNullFields: Set<DataFieldWithDataType>;
  isJapaneseClient: boolean;
  isUsingEffectiveLeaverDate: boolean;
}) => {
  const fields = Array.from(
    CompanyDataViewSettings[location]?.[domain] ?? CompanyDataViewSettings[location]?.[DEFAULT_OPTION] ?? defaultFields
  )
    .filter((f) => !isHiddenField(f, domain as Domains))
    .filter((f) => nonNullFields.deepCompareContains(f));

  const localFullNameField = { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCAL_FULL_NAME };
  if (
    isJapaneseClient &&
    nonNullFields.deepCompareContains(localFullNameField) &&
    !fields.deepCompareContains(localFullNameField)
  ) {
    fields.splice(2, 0, localFullNameField);
  }

  if (
    isUsingEffectiveLeaverDate &&
    new Set(fields).deepCompareContains({ dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE }) &&
    !new Set(fields).deepCompareContains({ dataType: DataTypes.EMPLOYEE, dataField: EDF.EFFECTIVE_LEAVER_DATE })
  ) {
    fields.push({ dataType: DataTypes.EMPLOYEE, dataField: EDF.EFFECTIVE_LEAVER_DATE });
  }
  return fields;
};

const CompanyDataViewSettings: DataViewByDashboardMap = {
  [Dashboards.PEOPLE_SNAPSHOT]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
  [Dashboards.ATTRITION]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
  [Dashboards.JOINERS_AND_LEAVERS]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
  [Dashboards.GENDER_DIVERSITY]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.GENDER },
    ]),
  },
  [Dashboards.MEDIAN_BASE]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.BASE_SALARY },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.BASE_SALARY_CURRENCY },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.GENDER },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.JOB_GRADE_LEVEL_1 },
    ]),
  },
  [Dashboards.EVALUATION]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      { dataType: DataTypes.EVALUATION, dataField: EvDF.EVALUATION_CYCLE_TYPE },
      { dataType: DataTypes.EVALUATION, dataField: EvDF.EVALUATION_AS_OF },
      { dataType: DataTypes.EVALUATION, dataField: EvDF.EVALUATION_SCORE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.JOB_GRADE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
  [Dashboards.PAYROLL]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.PAYROLL, dataField: PDF.EMPLOYEE_ID },
      { dataType: DataTypes.PAYROLL, dataField: PDF.ACTUAL_BASE_SALARY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.ACTUAL_BASE_SALARY_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.CONTRACTUAL_BASE_SALARY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.CONTRACTUAL_BASE_SALARY_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.NON_STATUTORY_PAYMENT_TOTAL },
      { dataType: DataTypes.PAYROLL, dataField: PDF.NON_STATUTORY_PAYMENT_TOTAL_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_ACTUAL_BASE_SALARY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_CONTRACTUAL_BASE_SALARY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_NON_STATUTORY_PAYMENT_TOTAL },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_STATUTORY_PAYMENT_TOTAL },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TOTAL_ALLOWANCE },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TOTAL_BONUS },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STATUTORY_PAYMENT_TOTAL },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STATUTORY_PAYMENT_TOTAL_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TRAVEL_AND_COMMUTING },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TRAVEL_AND_COMMUTING_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TRAVEL_AND_COMMUTING },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_OVERTIME },
      { dataType: DataTypes.PAYROLL, dataField: PDF.OVERTIME_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.OVERTIME },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_ALLOWANCE },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_ALLOWANCE_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_BONUS },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_BONUS_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TOTAL_BOARD_COMPENSATION },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_BOARD_COMPENSATION },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_BOARD_COMPENSATION_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TOTAL_EMPLOYEE_AWARD },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_EMPLOYEE_AWARD },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_EMPLOYEE_AWARD_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TOTAL_REIMBURSEMENT },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_REIMBURSEMENT },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_REIMBURSEMENT_CURRENCY },
      { dataType: DataTypes.PAYROLL, dataField: PDF.STANDARDIZED_TOTAL_PAYROLL_COST },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_PAYROLL_COST },
      { dataType: DataTypes.PAYROLL, dataField: PDF.TOTAL_PAYROLL_COST_CURRENCY },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.GENDER },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.JOB_GRADE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.JOB_GRADE_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.JOB_GRADE_LEVEL_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.JOB_TITLE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.MARITAL_STATUS },
    ]),
  },
  // TODO:
  // The following dashboards are part of DEV-1473 but require a bit more work
  // because they:
  //  - contain computed values
  //  - have fields that require multiple lines per employee
  //  - have fields from different tables (Depends on DEV-1176 to avoid making multiple queries)
  [Dashboards.RECRUITMENT_OVERVIEW]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.RECRUITERJOBS, dataField: RDF.RECRUITER_REAL_ID },
      { dataType: DataTypes.JOB, dataField: JDF.JOB_NAME_LEVEL_1 },
      { dataType: DataTypes.JOB, dataField: JDF.JOB_NAME_LEVEL_2 },
      { dataType: DataTypes.JOB, dataField: JDF.OPENED_AT },
      { dataType: DataTypes.JOB, dataField: JDF.CLOSED_AT },
      { dataType: DataTypes.JOB, dataField: JDF.OFFICE_LEVEL_1 },
      { dataType: DataTypes.JOB, dataField: JDF.OFFICE_LEVEL_2 },
      // TODO:
      // #Applicants
      // Headcount
      // #Hires
    ]),
  },
  [Dashboards.RECRUITMENT_FUNNEL]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.APPLICATION, dataField: ADF.CANDIDATE_ID },
      //ADF.CANDIDATE_NAME ?
      { dataType: DataTypes.JOB, dataField: JDF.JOB_NAME_LEVEL_1 },
      { dataType: DataTypes.JOB, dataField: JDF.JOB_NAME_LEVEL_2 },
      { dataType: DataTypes.APPLICATION, dataField: ADF.APPLIED_AT },
      { dataType: DataTypes.APPLICATION, dataField: ADF.SOURCE_LEVEL_1 },
      //ADF.STATUS ?
      { dataType: DataTypes.APPLICATION, dataField: ADF.STANDARDIZED_CURRENT_STAGE },
      // COMPUTED = DATE_SINCE_APPLIED
      // COMPUTED = TIME TO HIRE
      // COMPUTED = TIME TO REJECT
      { dataType: DataTypes.APPLICATION, dataField: ADF.REJECTED_AT },
      { dataType: DataTypes.APPLICATION, dataField: ADF.RESOLVED_AT }, // HIRED_AT ?
    ]),
  },

  [Dashboards.MOVEMENTS]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EFFECTIVE_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      // MOVED FROM
      // MOVED TO
    ]),
  },
  [Dashboards.PEOPLE_BALANCE_SHEET]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
  [Dashboards.WORK_HOURS_AND_OT]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCAL_FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
  [Dashboards.SURVEY]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TERM_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
      { dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.QUESTION },
      { dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.CYCLE },
      { dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.THEME_NAME },
      { dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.ANSWER },
      { dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.SCORE },
    ]),
  },
  [GLOBAL_SETTING]: {
    [DEFAULT_OPTION]: new Set([
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_2 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_3 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_4 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_5 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.FUNCTION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.LOCATION_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYMENT_TYPE_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
      { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
    ]),
  },
};
