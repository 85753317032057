import { match } from 'ts-pattern';
import { DataFieldWithDataType } from '../../../common-types';
import {
  MetricCategoryId,
  MetricCategoryTree,
  MetricGroupId,
  MetricId,
  MetricLifecycleStage,
  MetricTree,
} from '../graphql/generated/graphql-sdk';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { enumValues } from '../utilFunctions/pure-utils';
import { MetricCategoryDetails, MetricGroupDetails, MetricLifecycleStageEnum } from './types';

export class MetricService {
  private domainDependencyStore: DomainDependencyStore;

  private metricIdsSet = new Set(enumValues(MetricId));
  private metricGroupsSet = new Set(enumValues(MetricGroupId));
  private metricCategoriesSet = new Set(enumValues(MetricCategoryId));

  constructor(domainDependencyStore: DomainDependencyStore) {
    this.domainDependencyStore = domainDependencyStore;
  }

  private cleanupMetricTree = (metricTreeFromBackend: MetricTree): MetricTree => {
    // This function will remove any items that don't have ids in graphql-sdk.ts
    // This will happen if there is new data on the backend. Since frontend might not
    // support that fully(in translation, data validation in zod, etc), we just remove them from
    // the fetched list
    const cleanedMetricTree: MetricTree = {
      ...metricTreeFromBackend,
      categoryTrees: metricTreeFromBackend.categoryTrees
        .map((cTree) => {
          if (!this.metricCategoriesSet.has(cTree.metricCategoryId)) {
            return null;
          } else {
            return {
              ...cTree,
              groupTrees: cTree.groupTrees
                .map((gTree) => {
                  if (!this.metricGroupsSet.has(gTree.metricGroupId)) {
                    return null;
                  } else {
                    return {
                      ...gTree,
                      metricDefs: gTree.metricDefs
                        .map((mDef) => {
                          if (!this.metricIdsSet.has(mDef.id)) {
                            return null;
                          } else {
                            return mDef;
                          }
                        })
                        .filter(Boolean),
                    };
                  }
                })
                .filter(Boolean),
            };
          }
        })
        .filter(Boolean) as MetricCategoryTree[],
    };
    return cleanedMetricTree;
  };

  private convertBackendMetricLifecycleStageToFrontendMetricLifecycleStage = (
    backendStage: MetricLifecycleStage
  ): MetricLifecycleStageEnum | undefined => {
    return match(backendStage)
      .with({ __typename: 'MetricLifecycleStageActive' }, () => MetricLifecycleStageEnum.MetricLifecycleStageActive)
      .with(
        { __typename: 'MetricLifecycleStageDeprecated' },
        () => MetricLifecycleStageEnum.MetricLifecycleStageDeprecated
      )
      .with(
        { __typename: 'MetricLifecycleStageDevelopment' },
        () => MetricLifecycleStageEnum.MetricLifecycleStageDevelopment
      )
      .with({ __typename: 'MetricLifecycleStageDisabled' }, () => MetricLifecycleStageEnum.MetricLifecycleStageDisabled)
      .with({ __typename: undefined }, () => undefined)
      .exhaustive();
  };

  private convertMetricTreeToMetricDetails = (
    metricTree: MetricTree
  ): { groupDetails: MetricGroupDetails[]; categoryDetails: MetricCategoryDetails[] } => {
    const metricGroupDetails = metricTree.categoryTrees
      .flatMap((catTree) => {
        const { metricCategoryId, groupTrees, metricCategory } = catTree;
        const metricsWithDimensions: MetricGroupDetails[] = groupTrees.map((group) => {
          const { metricGroupId, metricDefs } = group;
          const metricDetails = metricDefs.map((def) => {
            return {
              id: def.id,
              metricGroupId: def.metricGroupId,
              formatType: def.formatType.id,
              underlyingFields: def.underlyingDataFields as unknown as DataFieldWithDataType[],
              metricType: def.metricType,
              defaultPosition: def.defaultPosition,
              segmentationPercAllowed: def.segmentationPercAllowed,
              lifecycleStage: this.convertBackendMetricLifecycleStageToFrontendMetricLifecycleStage(def.lifecycleStage),
            };
          });
          return {
            id: metricGroupId,
            defaultCategory: metricCategoryId,
            dimensions: metricDetails,
            defaultPosition: group.metricGroup.defaultPosition,
            defaultCategoryPosition: metricCategory.defaultPosition,
          };
        });
        return metricsWithDimensions;
      })
      .filter((g) => g.dimensions.length);
    const metricCategoryDetails = metricTree.categoryTrees.map((catTree) => {
      return {
        id: catTree.metricCategory.id,
        defaultPosition: catTree.metricCategory.defaultPosition,
      };
    });
    return { groupDetails: metricGroupDetails, categoryDetails: metricCategoryDetails };
  };

  public getMetricTree = (): { groupDetails: MetricGroupDetails[]; categoryDetails: MetricCategoryDetails[] } => {
    const metricTree = this.domainDependencyStore.getMetricTree();
    if (!metricTree) {
      throw new Error('null metric tree returned');
    }
    const cleanedMetricTree = this.cleanupMetricTree(metricTree);
    return this.convertMetricTreeToMetricDetails(cleanedMetricTree);
  };
}
