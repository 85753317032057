import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { environmentService } from './app/common/environment/environment-service';
import { localStore } from './app/common/local-store';
import * as commonEn from './translations/en/common.json';
import * as pagesEn from './translations/en/pages.json';
import * as commonEs from './translations/es-419/common.json';
import * as pagesEs from './translations/es-419/pages.json';
import * as commonJa from './translations/ja/common.json';
import * as pagesJa from './translations/ja/pages.json';

i18n.use(LanguageDetector).init({
  lng: environmentService.isCrexta && !localStore.get('i18nextLng') ? 'ja' : undefined,
  fallbackLng: 'en',
  resources: {
    en: {
      common: commonEn,
      pages: pagesEn,
    },
    ja: {
      common: commonJa,
      pages: pagesJa,
    },
    es: {
      common: commonEs,
      pages: pagesEs,
    },
  },
  debug: !environmentService.isTest && environmentService.isLocalhost,

  ns: ['common', 'pages'],
  defaultNS: 'common',

  keySeparator: '.',

  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  initImmediate: false,
  returnNull: false,
});

export default i18n;
