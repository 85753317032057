import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LabelsIcon } from '../../styles';
import { LocalOverTimeDisplayAction } from '../charts/overtime/types';
import { RadialDisplayAction } from '../charts/radial/types';
import { LocalTimePeriodDisplayAction } from '../charts/timeperiod/types';
import { StyledIconButton } from './shared-styles';

interface DisplayLabelsToggleProps {
  dispatcher:
    | React.Dispatch<LocalOverTimeDisplayAction>
    | React.Dispatch<LocalTimePeriodDisplayAction>
    | React.Dispatch<RadialDisplayAction>;
}

export const DisplayLabelsToggle = (props: DisplayLabelsToggleProps) => {
  const { dispatcher } = props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatcher({ type: 'toggle-labels' });
  };

  return (
    <Tooltip title={t('common:commonValues.tooltips.toggleLabels')} placement="top">
      <StyledIconButton onClick={handleClick} size="small" aria-label="Toggle Labels">
        <LabelsIcon />
      </StyledIconButton>
    </Tooltip>
  );
};
