import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';
import { DisplayLabelsToggle } from '../../toggles/DisplayLabelsToggle';
import { DisplayLegendToggle } from '../../toggles/DisplayLegendToggle';
import { DisplayPercentageToggle } from '../../toggles/DisplayPercentageToggle';
import { DisplayStackToggle } from '../../toggles/DisplayStackToggle';
import { FullScreenToggle } from '../../toggles/FullScreenToggle';
import { SortOrderToggle } from '../../toggles/SortOrderToggle';
import { TableViewToggle } from '../../toggles/TableViewToggle';
import { TimePeriodDisplayHandle } from './types';

interface TimePeriodToolsProps {
  fullScreenHandle: FullScreenHandle;
  displayHandle: TimePeriodDisplayHandle;
}

const StyledTools = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  right: 0.25rem;
  top: 0.25rem;
  margin-left: auto;
  margin-right: 0.25rem;
`;

export const Tools = (props: TimePeriodToolsProps) => {
  const { fullScreenHandle, displayHandle } = props;
  const [state, dispatcher] = displayHandle;

  return (
    <StyledTools>
      {state.enablePercentage ? <DisplayPercentageToggle dispatcher={dispatcher} /> : null}
      {state.enableStack ? <DisplayStackToggle displayHandle={displayHandle} /> : null}
      {state.enableLabels ? <DisplayLabelsToggle dispatcher={dispatcher} /> : null}
      {state.enableLegend ? <DisplayLegendToggle dispatcher={dispatcher} /> : null}
      {state.enableSortOrder ? <SortOrderToggle displayHandle={displayHandle} /> : null}
      {fullScreenHandle.active ? null : <FullScreenToggle fullScreenHandle={fullScreenHandle} />}
      <TableViewToggle displayHandle={displayHandle} />
    </StyledTools>
  );
};
