import { useCallback, useReducer } from 'react';
import { match } from 'ts-pattern';
import { Domains } from '../constants';
import {
  GlobalDisplayAction,
  GlobalDisplayHandle,
  GlobalDisplayReducer,
  GlobalDisplayState,
  GlobalDisplayStateWithPersistence,
  GlobalDisplayWithPersistenceHandle,
  GlobalResetHandle,
} from './types';

const initialState = () => ({
  enablePercentage: true,
  showPercentage: false,
  enableStack: true,
  showStack: false,
  enableGridLines: true,
  showGridLines: false,
  enableLabels: true,
  showLabels: false,
  enableLegend: true,
  showLegend: false,
});

export const useGlobalDisplayHandleWithPersistence = (domain: Domains): GlobalDisplayWithPersistenceHandle => {
  const persistenceKey = `${domain}-display-v1`;
  const stored: string | null = localStorage.getItem(persistenceKey);
  const storedItems: GlobalDisplayStateWithPersistence | null = stored
    ? (JSON.parse(stored) as unknown as GlobalDisplayStateWithPersistence)
    : null;
  const state = storedItems ?? { ...initialState(), persistenceKey };
  return useReducer(reducerWithPersistentWrapper(reducer), state);
};

export const useGlobalDisplayHandle = (): GlobalDisplayHandle => {
  return useReducer(reducer, initialState());
};

export const reducerWithPersistentWrapper = (
  underlyingReducer: GlobalDisplayReducer<GlobalDisplayState, GlobalDisplayAction>
): GlobalDisplayReducer<GlobalDisplayStateWithPersistence, GlobalDisplayAction> => {
  return (state: GlobalDisplayStateWithPersistence, action: GlobalDisplayAction): GlobalDisplayStateWithPersistence => {
    const newstate = underlyingReducer(state, action);
    // TODO: think whether we should store a smaller version of the state so that we can update keys without breaking the app for existing users
    localStorage.setItem(state.persistenceKey, JSON.stringify(newstate));
    return { ...newstate, persistenceKey: state.persistenceKey };
  };
};

const reducer = (state: GlobalDisplayState, action: GlobalDisplayAction): GlobalDisplayState => {
  return match(action.type)
    .with('toggle-percentage', () => ({
      ...state,
      showPercentage: !state.showPercentage,
      enableStack: state.showPercentage,
    }))
    .with('toggle-stack', () => ({
      ...state,
      showStack: !state.showStack,
      enablePercentage: state.showStack,
    }))
    .with('toggle-gridlines', () => ({
      ...state,
      showGridLines: !state.showGridLines,
    }))
    .with('toggle-labels', () => ({
      ...state,
      showLabels: !state.showLabels,
    }))
    .with('toggle-legend', () => ({
      ...state,
      showLegend: !state.showLegend,
    }))
    .with('reset', () => ({
      enablePercentage: true,
      showPercentage: false,
      enableStack: true,
      showStack: false,
      enableGridLines: true,
      showGridLines: false,
      enableLabels: true,
      showLabels: false,
      enableLegend: true,
      showLegend: false,
    }))
    .exhaustive();
};

export const useGlobalResetHandle = (resets: Array<() => void>): GlobalResetHandle => {
  const reset = useCallback(() => {
    resets.map((r) => r());
  }, [resets]);
  return { reset };
};
