import {
  AxisConfig,
  BarItem,
  BarLabelContext,
  BarSeriesType,
  ChartsXAxisProps,
  ChartsYAxisProps,
  LineSeriesType,
  ScaleName,
  StackOffsetType,
} from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/internals';
import { TFunction } from 'i18next';
import { match, P } from 'ts-pattern';
import { MetricDetails } from '../../../../api/metrics/types';
import {
  ApiOverTimeTimeSegmentType,
  DataValue,
  DomainPreferences,
  MetricResult,
  RegularMetricResult,
} from '../../../../api/types';
import { CohortMetricId, EmployeeCohortMetricId, MetricGroupId, RegularMetricId } from '../../../../api/types-graphql';
import { toTimeSegmentLabel, toTimeSegmentRangeLabel } from '../../../../api/utils';
import { ASCII_ARROW, Languages } from '../../../../constants';
import { Colors, DataFieldWithDataType, MetricIdType, Segmentation } from '../../../../types';
import { getSortIndices, sortByIndices, sortFieldValues, sortValuesAlphabetically } from '../../../../utils';
import { formatMetricValue, formatPercentageNumber } from '../../../../utils-display';
import { benchmarkColor, chartColorsHex } from '../../../theme';
import { showMetric } from '../../utils-display';
import { DisplayState as OverTimeDisplayState } from '../overtime/types';
import {
  getYAxisConfig,
  toNonSegmentedTableViewDataOverTime,
  toPartialNonSegmentedChartDataOverTime,
  toPartialSegmentedChartDataOverTime,
  toSegmentedTableViewDataOverTime,
} from '../overtime/utils';
import { DisplayState as TimePeriodDisplayState } from '../timeperiod/types';
import {
  toDoubleLevelSegmentedTableViewDataTimePeriod,
  toPartialDoubleLevelSegmentedChartDataTimePeriod,
  toPartialSingleLevelSegmentedChartDataTimePeriod,
  toSingleLevelSegmentedTableViewDataTimePeriod,
} from '../timeperiod/utils';
import {
  MuiOverTimeChartData,
  MuiTimePeriodChartData,
  NonSegmentedSeries,
  SegmentedOverTimeChartInput,
  SegmentedSeries,
} from './types';
import {
  removeNullsOrZeros,
  sortTimePeriodData,
  toDoubleLevelSegmentedTimePeriodChartInput,
  toNonSegmentedOverTimeChartInput,
  toSegmentedOverTimeChartInput,
  toSingleLevelSegmentedTimePeriodChartInput,
} from './utils';

export const toMuiChartsTimePeriodData = (
  resultOrErrors: Array<RegularMetricResult | Error>,
  segmentations: Segmentation[] | undefined,
  displayState: TimePeriodDisplayState,
  metricDetailsMap: Record<RegularMetricId | CohortMetricId | EmployeeCohortMetricId, MetricDetails>,
  formatLabel: (value: DataValue[], datafield: DataFieldWithDataType) => string,
  getAliasForMetric: (metricGroupId: MetricGroupId) => string | null,
  colors: Colors | undefined,
  domainPreferences: DomainPreferences,
  t: TFunction
): MuiTimePeriodChartData => {
  if (segmentations && segmentations.length > 1) {
    const chartInput = removeNullsOrZeros(
      toDoubleLevelSegmentedTimePeriodChartInput(
        resultOrErrors ?? [],
        toPartialDoubleLevelSegmentedChartDataTimePeriod,
        segmentations
      )
    );
    const totals = chartInput.series.reduce<number[]>((acc, s: SegmentedSeries) => {
      return s.values.map((v, j) => {
        return match(v)
          .with(P.union(P.number, P.nullish), (n) => (acc[j] ?? 0) + (n ?? 0))
          .otherwise(() => {
            throw new Error('Not implemented yet');
          });
      });
    }, []);

    const tableViewData = toDoubleLevelSegmentedTableViewDataTimePeriod(resultOrErrors ?? []);

    const rowTitleValues = tableViewData.rows.map((r) => r.title.value.join('=>'));
    const sortIndices = getSortIndices(
      rowTitleValues,
      (values) =>
        tableViewData.rows[0]?.title?.dataField
          ? sortFieldValues(values, tableViewData.rows[0]?.title?.dataField, domainPreferences)
          : sortValuesAlphabetically(values),
      tableViewData.rows.findIndex((r) => r.title.value[0] === null)
    );
    const sortedTableViewData = { ...tableViewData, rows: sortByIndices(tableViewData.rows, sortIndices) };

    const sortedSeries = sortFieldValues(
      chartInput.series.map((s, index) => ({ ...s, label: s.label.join(ASCII_ARROW), originalLabel: s.label, index })),
      segmentations?.[1]?.dataField,
      domainPreferences
    ).map((s) => ({ ...s, label: s.originalLabel }));

    const series: MakeOptional<BarSeriesType, 'type'>[] = sortedSeries.map((s: SegmentedSeries, i) => {
      const metricDetails = metricDetailsMap[s.metricId.value];
      const formatType = metricDetails?.formatType;
      const label = formatLabel(s.label, s.dataField);
      const values = displayState.showPercentage
        ? s.values.map((v, j) => {
            return match(v)
              .with(P.union(P.number, P.nullish), (n) => (n && totals[i] !== 0 ? (100 * n) / totals[j] : null))
              .otherwise(() => {
                throw new Error('Not implemented yet');
              });
          })
        : (s.values as number[]); // TODO: handle DataValue types
      return {
        id: i,
        label,
        color: colors?.[i] ?? chartColorsHex[i],
        data: values,
        valueFormatter: (value: number | null) =>
          displayState.showPercentage
            ? value
              ? formatPercentageNumber(value)
              : '0%'
            : formatMetricValue(value, formatType),
        yAxisId: formatType ?? undefined,
        showMark: false,
        stack: displayState.showStack || displayState.showPercentage ? 'total' : undefined,
        stackOffset: (displayState.showStack ? 'none' : displayState.showPercentage ? 'expand' : undefined) as
          | StackOffsetType
          | undefined,
      };
    });
    const yAxisConfig: AxisConfig<ScaleName, number | null, ChartsYAxisProps>[] = getYAxisConfig(series);

    const xAxisConfig: AxisConfig<ScaleName, string, ChartsXAxisProps> = {
      id: '1',
      scaleType: 'band',
      data: chartInput.xAxisData.map((d) => formatLabel(d.value, d.dataField)),
    };
    const barLabelConfig: undefined | ((item: BarItem, context: BarLabelContext) => string | null | undefined) =
      displayState.showLabels
        ? (item, context) => {
            if (context.bar.height < 10) {
              return null;
            }
            if (
              item.value &&
              ((context.bar.width < 35 && item.value > 1000) || (context.bar.width < 25 && item.value > 100))
            ) {
              return null;
            }
            const metric = chartInput.series[item.seriesId as number].metricId;
            const formatType = metricDetailsMap[metric.value]?.formatType;
            if (displayState.showPercentage) {
              return item.value ? formatPercentageNumber(item.value) : '';
            } else {
              return item.value !== 0 ? formatMetricValue(item.value, formatType) : '';
            }
          }
        : undefined;
    const metricSql = chartInput.series.map((s) => s.meta.metricSql);
    return sortTimePeriodData(
      {
        series,
        yAxisConfig,
        xAxisConfig,
        barLabelConfig,
        metricSql,
        tableViewData: sortedTableViewData,
      },
      displayState.selectedSortOrder,
      segmentations,
      domainPreferences,
      chartInput.xAxisData.flatMap((d) => d.value)
    );
  } else if (segmentations && segmentations.length > 0) {
    const chartInput = removeNullsOrZeros(
      toSingleLevelSegmentedTimePeriodChartInput(resultOrErrors ?? [], toPartialSingleLevelSegmentedChartDataTimePeriod)
    );
    const tableViewData = toSingleLevelSegmentedTableViewDataTimePeriod(resultOrErrors ?? []);

    const rowTitleValues = tableViewData.rows.map((r) => r.title.value.join('=>'));
    const sortIndices = getSortIndices(
      rowTitleValues,
      (values) =>
        tableViewData.rows[0]?.title?.dataField
          ? sortFieldValues(values, tableViewData.rows[0]?.title?.dataField, domainPreferences)
          : sortValuesAlphabetically(values),
      tableViewData.rows.findIndex((r) => r.title.value[0] === null)
    );
    const sortedTableViewData = { ...tableViewData, rows: sortByIndices(tableViewData.rows, sortIndices) };

    const totals = chartInput.series.reduce<number[]>((acc, s: SegmentedSeries) => {
      return s.values.map((v, j) => {
        return match(v)
          .with(P.union(P.number, P.nullish), (n) => (acc[j] ?? 0) + (n ?? 0))
          .otherwise(() => {
            throw new Error('Not implemented yet');
          });
      });
    }, []);

    const series = chartInput.series.map((s: SegmentedSeries, i) => {
      const metricDetails = metricDetailsMap[s.metricId.value];
      const formatType = metricDetails?.formatType;
      const alias = getAliasForMetric(metricDetails?.metricGroupId);
      const label = showMetric(s.metricId, metricDetails?.metricGroupId, alias, t);
      const values = displayState.showPercentage
        ? s.values.map((v, j) => {
            return match(v)
              .with(P.union(P.number, P.nullish), (n) => (n && totals[i] !== 0 ? (100 * n) / totals[j] : null))
              .otherwise(() => {
                throw new Error('Not implemented yet');
              });
          })
        : (s.values as number[]); // TODO: handle DataValue types
      return {
        id: i,
        label,
        color: colors?.[i] ?? chartColorsHex[i],
        data: values,
        valueFormatter: (value: number | null) =>
          displayState.showPercentage
            ? value
              ? formatPercentageNumber(value)
              : '0%'
            : formatMetricValue(value, formatType),
        yAxisId: formatType ?? undefined,
        showMark: false,
        stack: displayState.showStack || displayState.showPercentage ? 'total' : undefined,
        stackOffset: (displayState.showStack ? 'none' : displayState.showPercentage ? 'expand' : undefined) as
          | StackOffsetType
          | undefined,
      };
    });
    const yAxisConfig = getYAxisConfig(series);
    const xAxisConfig: AxisConfig<ScaleName, string, ChartsXAxisProps> = {
      id: '1',
      scaleType: 'band',
      data: chartInput.xAxisData.map((d) => formatLabel(d.value, d.dataField)),
    };
    const barLabelConfig: undefined | ((item: BarItem, context: BarLabelContext) => string | null | undefined) =
      displayState.showLabels
        ? (item, context) => {
            if (context.bar.height < 10) {
              return null;
            }
            if (
              item.value &&
              ((context.bar.width < 35 && item.value > 1000) || (context.bar.width < 25 && item.value > 100))
            ) {
              return null;
            }
            const metric = chartInput.series[item.seriesId as number].metricId;
            const formatType = metricDetailsMap[metric.value]?.formatType;
            if (displayState.showPercentage) {
              return item.value ? formatPercentageNumber(item.value) : '';
            } else {
              return item.value !== 0 ? formatMetricValue(item.value, formatType) : '';
            }
          }
        : undefined;
    const metricSql = chartInput.series.map((s) => s.meta.metricSql);
    return sortTimePeriodData(
      {
        series,
        yAxisConfig,
        xAxisConfig,
        barLabelConfig,
        metricSql,
        tableViewData: sortedTableViewData,
      },
      displayState.selectedSortOrder,
      segmentations,
      domainPreferences,
      chartInput.xAxisData.flatMap((d) => d.value)
    );
  } else {
    throw new Error('Segmentation is required');
  }
};

export const toMuiChartsOverTimeData = <T extends BarSeriesType | LineSeriesType>(
  resultOrErrors: Array<MetricResult | Error>,
  xAxisId: number,
  isBenchmark: boolean | undefined,
  segmentations: Segmentation[] | undefined,
  displayState: OverTimeDisplayState,
  metricDetailsMap: Record<RegularMetricId | CohortMetricId | EmployeeCohortMetricId, MetricDetails>,
  formatLabel: (value: (string | null)[], datafield: DataFieldWithDataType) => string,
  getAliasForMetric: (metricGroupId: MetricGroupId) => string | null,
  locale: Languages,
  colors: Colors | undefined,
  t: TFunction
): MuiOverTimeChartData<T> => {
  if (segmentations && segmentations.length > 0) {
    const chartInput: SegmentedOverTimeChartInput = toSegmentedOverTimeChartInput(
      resultOrErrors ?? [],
      toPartialSegmentedChartDataOverTime
    );
    const tableViewData = toSegmentedTableViewDataOverTime(resultOrErrors ?? []);

    const totals = chartInput.series.reduce<number[]>((acc, s: SegmentedSeries) => {
      return s.values.map((v, j) => {
        return match(v)
          .with(P.union(P.number, P.nullish), (n) => (acc[j] ?? 0) + (n ?? 0))
          .otherwise(() => {
            throw new Error('Not implemented yet');
          });
      });
    }, []);
    const series = chartInput.series.map((s: SegmentedSeries, i: number) => {
      const metricDetails = metricDetailsMap[s.metricId.value];
      const formatType = metricDetails?.formatType;
      const values = displayState.showPercentage
        ? s.values.map((v, j) => {
            return match(v)
              .with(P.union(P.number, P.nullish), (n) => (n && totals[j] !== 0 ? (100 * n) / totals[j] : 0))
              .otherwise(() => {
                throw new Error('Not implemented yet');
              });
          })
        : (s.values as number[]); // TODO: handle DataValue types
      const label = formatLabel(s.label, s.dataField);
      const index = xAxisId + i;
      return {
        id: `${s.metricId.value}-${index}${isBenchmark ? '-benchmark' : ''}`,
        color: isBenchmark ? benchmarkColor : colors?.[index] ?? chartColorsHex[index],
        showMark: displayState.showLabels,
        label: isBenchmark
          ? `${label}(${toTimeSegmentRangeLabel(chartInput.xAxisData.first(), chartInput.xAxisData.last(), locale)})`
          : label,
        data: values,
        valueFormatter: (value: number | null) =>
          displayState.showPercentage
            ? value
              ? formatPercentageNumber(value)
              : '0%'
            : formatMetricValue(value, formatType),
        yAxisId: formatType ?? undefined,
        metricId: s.metricId,
        stack: displayState.showStack || displayState.showPercentage ? 'total' : undefined,
        stackOffset: (displayState.showStack ? 'none' : displayState.showPercentage ? 'expand' : undefined) as
          | StackOffsetType
          | undefined,
      } as unknown as T & { metricId: MetricIdType };
    });
    const yAxisConfig = getYAxisConfig(series);
    const xAxisConfig: AxisConfig<ScaleName, ApiOverTimeTimeSegmentType, ChartsXAxisProps>[] = [
      {
        id: xAxisId.toString(),
        scaleType: 'point',
        data: chartInput.xAxisData,
        valueFormatter: (v) => toTimeSegmentLabel(v, locale),
      },
    ];
    const barLabelConfig: undefined | ((item: BarItem, context: BarLabelContext) => string | null | undefined) =
      displayState.showLabels
        ? (item, context) => {
            if (context.bar.height < 10) {
              return null;
            }
            if (
              item.value &&
              ((context.bar.width < 35 && item.value > 1000) || (context.bar.width < 25 && item.value > 100))
            ) {
              return null;
            }
            const metric = series.find((s) => s.id === item.seriesId)?.metricId;
            const formatType = metric ? metricDetailsMap[metric.value]?.formatType : undefined;
            if (displayState.showPercentage) {
              return item.value ? formatPercentageNumber(item.value) : '';
            } else {
              return item.value !== 0 ? formatMetricValue(item.value, formatType) : '';
            }
          }
        : undefined;
    return {
      series,
      tableViewData,
      yAxisConfig,
      xAxisConfig,
      metricSql: chartInput.series.map((s) => s.meta.metricSql),
      barLabelConfig,
    };
  } else {
    const chartInput = toNonSegmentedOverTimeChartInput(resultOrErrors ?? [], toPartialNonSegmentedChartDataOverTime);

    const tableViewData = toNonSegmentedTableViewDataOverTime(resultOrErrors ?? []);
    const series = chartInput.series.map((s: NonSegmentedSeries, i: number) => {
      const metricDetails = metricDetailsMap[s.metricId.value];
      const formatType = metricDetails?.formatType;
      const alias = getAliasForMetric(metricDetails?.metricGroupId);
      const label = showMetric(s.metricId, metricDetails?.metricGroupId, alias, t);
      const index = xAxisId + i;
      return {
        id: `${s.metricId.value}-${index}${isBenchmark ? '-benchmark' : ''}`,
        color: isBenchmark ? benchmarkColor : colors?.[index] ?? chartColorsHex[index],
        showMark: displayState.showLabels,
        label: isBenchmark
          ? `${label}(${toTimeSegmentRangeLabel(chartInput.xAxisData.first(), chartInput.xAxisData.last(), locale)})`
          : label,
        data: s.values,
        valueFormatter: (value: number | null) => formatMetricValue(value, formatType),
        yAxisId: (formatType as string) ?? undefined,
        xAxisId: '0', // TODO: fix this when the following issue is resolved: https://github.com/mui/mui-x/issues/17015
        metricId: s.metricId,
      } as unknown as T & { metricId: MetricIdType };
    });
    const barLabelConfig: undefined | ((item: BarItem) => string | null | undefined) = displayState.showLabels
      ? (item) => {
          const metric = series.find((s) => s.id === item.seriesId)?.metricId;
          const formatType = metric ? metricDetailsMap[metric.value]?.formatType : undefined;
          return item.value !== 0 ? formatMetricValue(item.value, formatType) : '';
        }
      : undefined;
    const yAxisConfig = getYAxisConfig(series);
    const xAxisConfig: AxisConfig<ScaleName, ApiOverTimeTimeSegmentType, ChartsXAxisProps>[] = [
      {
        id: xAxisId.toString(),
        scaleType: 'point',
        data: chartInput.xAxisData,
        valueFormatter: (v) => toTimeSegmentLabel(v, locale),
      },
    ];
    return {
      series,
      tableViewData,
      yAxisConfig,
      xAxisConfig,
      metricSql: chartInput.series.map((s) => s.meta.metricSql),
      barLabelConfig,
    };
  }
};
