import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LegendIcon } from '../../styles';
import { LocalOverTimeDisplayAction } from '../charts/overtime/types';
import { RadialDisplayAction } from '../charts/radial/types';
import { LocalTimePeriodDisplayAction } from '../charts/timeperiod/types';
import { StyledIconButton } from './shared-styles';

interface DisplayLegendToggleProps {
  dispatcher:
    | React.Dispatch<LocalOverTimeDisplayAction>
    | React.Dispatch<LocalTimePeriodDisplayAction>
    | React.Dispatch<RadialDisplayAction>;
}

export const DisplayLegendToggle = (props: DisplayLegendToggleProps) => {
  const { dispatcher } = props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatcher({ type: 'toggle-legend' });
  };

  return (
    <Tooltip title={t('common:commonValues.tooltips.toggleLegend')} placement="top">
      <StyledIconButton onClick={handleClick} size="small" aria-label="Toggle Legend">
        <LegendIcon />
      </StyledIconButton>
    </Tooltip>
  );
};
