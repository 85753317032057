import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { match, P } from 'ts-pattern';
import { GroupSegment, RegularMetricResult, Segment } from '../../../../api/types';
import { isHierarchical } from '../../../../api/utils';
import {
  CalendarYearSingleValueByMonthsTimeSelection,
  CalendarYearSingleValueByYearsTimeSelection,
  FinancialYearSingleValueByQuartersTimeSelection,
  FinancialYearSingleValueByYearsTimeSelection,
  RegularMetricIdType,
  Segmentation,
  TimeSelectionType,
} from '../../../../types';
import { getDataFieldWithDataTypeFromKey, getKeyFromDataFieldWithDataType } from '../../../../utils';
import { removeDuplicates } from '../../../utils';
import { toHierarchical } from '../../filter/utils';
import {
  SegmentedByDataFieldHeaderData,
  SegmentedTimePeriodTableData,
  SingleLevelSegmentedTableTimePeriodData,
  TableCellMetricData,
  TableRowTimePeriodData,
} from '../../tableview/types';
import {
  DoubleLevelSegmentedTimePeriodTableDataMonoid,
  SingleLevelSegmentedTimePeriodTableDataMonoid,
} from '../../tableview/utils';
import { Granularity, TimeSliderState } from '../../timeslider/types';
import {
  NonSegmentedSeries,
  NonSegmentedTimePeriodChartInput,
  PartialDoubleLevelSegmentedTimePeriodChartInput,
  SegmentedSeries,
  SingleLevelSegmentedTimePeriodChartInput,
} from '../mui-charts/types';
import { TimePeriodTimeSelection } from './types';

export const toPartialDoubleLevelSegmentedChartDataTimePeriod = (
  processedResult: PartialDoubleLevelSegmentedTimePeriodChartInput,
  result: RegularMetricResult,
  segmentations: Segmentation[]
): PartialDoubleLevelSegmentedTimePeriodChartInput => {
  const partialResult = result.segments.reduce<PartialDoubleLevelSegmentedTimePeriodChartInput>(
    (input, seg: Segment) => {
      return seg.groupSegments.reduce<PartialDoubleLevelSegmentedTimePeriodChartInput>((acc, groupSegment) => {
        const segmentsGroupedByHierarchicalField = groupBy(groupSegment.groupSegment, (gs) =>
          getKeyFromDataFieldWithDataType(toHierarchical({ dataType: gs.dataType, dataField: gs.dataField }))
        );
        const segmentsGroupedByField = Object.fromEntries(
          Object.entries(segmentsGroupedByHierarchicalField).map(([key, segments], i) =>
            segments.length > 1
              ? [key, segments]
              : [
                  segmentations[i].type === 'hierarchical'
                    ? key
                    : getKeyFromDataFieldWithDataType({
                        dataType: segments[0].dataType,
                        dataField: segments[0].dataField,
                      }),
                  segments,
                ]
          )
        );
        const xAxisGroupSegment: [string, GroupSegment[]] | undefined = Object.entries(segmentsGroupedByField).find(
          ([key]) => {
            const dataField = getDataFieldWithDataTypeFromKey(key);
            const firstSegmentation = segmentations[0];
            if (isHierarchical(dataField)) {
              return firstSegmentation.type === 'hierarchical' && isEqual(firstSegmentation.dataField, dataField);
            } else {
              return firstSegmentation.type === 'non-hierarchical' && isEqual(firstSegmentation.dataField, dataField);
            }
          }
        );
        const seriesGroupSegment: [string, GroupSegment[]] | undefined = Object.entries(segmentsGroupedByField).find(
          ([key]) => {
            const dataField = getDataFieldWithDataTypeFromKey(key);
            const firstSegmentation = segmentations[1];
            if (isHierarchical(dataField)) {
              return firstSegmentation.type === 'hierarchical' && isEqual(firstSegmentation.dataField, dataField);
            } else {
              return firstSegmentation.type === 'non-hierarchical' && isEqual(firstSegmentation.dataField, dataField);
            }
          }
        );
        if (xAxisGroupSegment && seriesGroupSegment) {
          const segment = xAxisGroupSegment[1].map((gs) => gs.value) as (string | null)[];
          const xAxisData = removeDuplicates([
            ...acc.xAxisData,
            {
              dataField: getDataFieldWithDataTypeFromKey(xAxisGroupSegment[0]),
              value: segment,
            },
          ]);
          const label = seriesGroupSegment[1].map((gs) => gs.value);
          const existingIndex = acc.series.findIndex((s) => isEqual(s.label, label));
          return {
            ...acc,
            xAxisData,
            series:
              existingIndex === -1
                ? [
                    ...acc.series,
                    {
                      label: label as (string | null)[],
                      metricId: { type: 'RegularMetricId', value: result.metricId },
                      dataField: getDataFieldWithDataTypeFromKey(seriesGroupSegment[0]),
                      values: [{ value: groupSegment.data, segment }],
                      meta: result.meta,
                    },
                  ]
                : acc.series.map((s, i) =>
                    i === existingIndex ? { ...s, values: [...s.values, { value: groupSegment.data, segment }] } : s
                  ),
          };
        } else {
          return acc;
        }
      }, input);
    },
    processedResult
  );
  return {
    ...partialResult,
    series: partialResult.series.map((s) => {
      const missingSegments = partialResult.xAxisData.filter((d) => {
        return !s.values.some((v) => isEqual(v.segment, d.value));
      });
      return {
        ...s,
        values: [...s.values, ...missingSegments.map((ts) => ({ segment: ts.value, value: null }))].sort((a, b) =>
          partialResult.xAxisData.findIndex((d) => isEqual(d.value, a.segment)) >
          partialResult.xAxisData.findIndex((d) => isEqual(d.value, b.segment))
            ? 1
            : -1
        ),
      };
    }),
  };
};

export const toPartialSingleLevelSegmentedChartDataTimePeriod = (
  processedResult: SingleLevelSegmentedTimePeriodChartInput,
  result: RegularMetricResult
): SingleLevelSegmentedTimePeriodChartInput => {
  return result.segments.reduce<SingleLevelSegmentedTimePeriodChartInput>((input, seg: Segment) => {
    return seg.groupSegments.reduce<SingleLevelSegmentedTimePeriodChartInput>((acc, groupSegment) => {
      const firstGroupSegment = groupSegment.groupSegment[0];
      if (!firstGroupSegment) {
        return acc;
      }
      const dataField = { dataType: firstGroupSegment.dataType, dataField: firstGroupSegment.dataField };
      const label = [result.metricId];
      const newValue = groupSegment.data;
      const existingIndex = acc.series.findIndex((s) => isEqual(s.dataField, dataField) && isEqual(s.label, label));

      const series: SegmentedSeries[] =
        existingIndex === -1
          ? [
              ...acc.series,
              {
                values: [newValue],
                label: label as (string | null)[],
                metricId: { type: 'RegularMetricId', value: result.metricId },
                meta: result.meta,
                dataField,
              },
            ]
          : acc.series.map((v, i) => (existingIndex === i ? { ...v, values: [...v.values, newValue] } : v));
      return {
        ...acc,
        xAxisData: uniqWith(
          [
            ...acc.xAxisData,
            { value: groupSegment.groupSegment.map((gs) => gs.value) as (string | null)[], dataField },
          ],
          (d1, d2) => isEqual(d1, d2)
        ),
        series,
      };
    }, input);
  }, processedResult);
};

export const toPartialNonSegmentedChartDataTimePeriod = (
  processedResult: NonSegmentedTimePeriodChartInput,
  result: RegularMetricResult
): NonSegmentedTimePeriodChartInput => {
  return result.segments.reduce<NonSegmentedTimePeriodChartInput>((input, seg: Segment) => {
    const firstGroupSegment = seg.groupSegments.first();
    if (!firstGroupSegment) {
      return input;
    }
    const series: NonSegmentedSeries = {
      label: [result.metricId],
      metricId: { type: 'RegularMetricId', value: result.metricId },
      values: [...(input.series?.values ?? []), firstGroupSegment?.data as number | null],
      meta: result.meta,
    };
    return {
      type: 'NonSegmentedSeries',
      series,
    };
  }, processedResult);
};

export const toSingleLevelSegmentedTableViewDataTimePeriod = (
  resultOrErrors: Array<RegularMetricResult | Error>
): SingleLevelSegmentedTableTimePeriodData<RegularMetricIdType> => {
  const tableDataMonoid = new SingleLevelSegmentedTimePeriodTableDataMonoid();
  return resultOrErrors.reduce<SingleLevelSegmentedTableTimePeriodData<RegularMetricIdType>>(
    (acc: SingleLevelSegmentedTableTimePeriodData<RegularMetricIdType>, resultOrError: RegularMetricResult | Error) => {
      return match(resultOrError)
        .with({ type: 'RegularMetricResult' }, (metricResult: RegularMetricResult) => {
          const result = metricResult.segments.reduce<SegmentedTimePeriodTableData<RegularMetricIdType>>(
            (tableData, segment) => {
              const rows: TableRowTimePeriodData<RegularMetricIdType>[] = segment.groupSegments.map((groupSegment) => {
                const segmentsGroupedByHierarchicalField = groupBy(groupSegment.groupSegment, (gs) =>
                  getKeyFromDataFieldWithDataType(toHierarchical({ dataType: gs.dataType, dataField: gs.dataField }))
                );
                const segmentsGroupedByField = Object.entries(segmentsGroupedByHierarchicalField).map(
                  ([key, segments]) =>
                    segments.length > 1
                      ? getDataFieldWithDataTypeFromKey(key)
                      : {
                          dataType: segments[0].dataType,
                          dataField: segments[0].dataField,
                        }
                );
                return {
                  title: {
                    value: groupSegment.groupSegment.map((gs) => gs.value),
                    dataField: segmentsGroupedByField[0],
                  },
                  cells: [
                    {
                      type: 'table-cell-metric',
                      value: groupSegment.data as number | null,
                      header: [metricResult.metricId],
                      metricId: { type: 'RegularMetricId', value: metricResult.metricId },
                    },
                  ],
                };
              });
              return {
                headers: tableData.headers,
                rows: [...tableData.rows, ...rows],
              };
            },
            {
              headers: [
                {
                  type: 'segmented-by-metric',
                  value: { type: 'RegularMetricId', value: metricResult.metricId },
                  metricId: { type: 'RegularMetricId', value: metricResult.metricId },
                },
              ],
              rows: [],
            }
          );
          return tableDataMonoid.combine(acc, result);
        })
        .with({ stack: P.string }, (error: Error) => {
          throw error;
        })
        .otherwise(() => {
          throw new Error('Unexpected result');
        });
    },
    { headers: [{ value: '', type: 'blank' }], rows: [] }
  );
};

export const toDoubleLevelSegmentedTableViewDataTimePeriod = (
  resultOrErrors: Array<RegularMetricResult | Error>
): SegmentedTimePeriodTableData<RegularMetricIdType> => {
  const tableDataMonoid = new DoubleLevelSegmentedTimePeriodTableDataMonoid();
  return resultOrErrors.reduce<SegmentedTimePeriodTableData<RegularMetricIdType>>(
    (acc: SegmentedTimePeriodTableData<RegularMetricIdType>, resultOrError: RegularMetricResult | Error) => {
      return match(resultOrError)
        .with({ type: 'RegularMetricResult' }, (metricResult: RegularMetricResult) => {
          const metricId: RegularMetricIdType = { type: 'RegularMetricId', value: metricResult.metricId };
          const result: SegmentedTimePeriodTableData<RegularMetricIdType> = metricResult.segments.reduce<
            SegmentedTimePeriodTableData<RegularMetricIdType>
          >(
            (tableData, segment) => {
              return segment.groupSegments.reduce<SegmentedTimePeriodTableData<RegularMetricIdType>>((acc, gs) => {
                const groupSegmentsGroupedByField: Record<string, GroupSegment[]> = groupBy(gs.groupSegment, (g) =>
                  getKeyFromDataFieldWithDataType(toHierarchical({ dataType: g.dataType, dataField: g.dataField }))
                );
                const hasAFieldForEachSegmentationLevel = Object.keys(groupSegmentsGroupedByField).length === 2;
                if (!hasAFieldForEachSegmentationLevel) {
                  return acc;
                }
                const segmentationLevel1Field = getDataFieldWithDataTypeFromKey(
                  Object.keys(groupSegmentsGroupedByField)[0]
                );
                const segmentationLevel2Field = getDataFieldWithDataTypeFromKey(
                  Object.keys(groupSegmentsGroupedByField)[1]
                );
                const headerDataField = segmentationLevel1Field;
                const headerGroupSegments = Object.values(groupSegmentsGroupedByField)[0];
                const titleDataField = segmentationLevel2Field;
                const rowsGroupSegments = Object.values(groupSegmentsGroupedByField)[1];
                const title = { value: rowsGroupSegments.map((g) => g.value), dataField: titleDataField };
                const headerValue = headerGroupSegments.map((g) => g.value);
                const header: SegmentedByDataFieldHeaderData<RegularMetricIdType> = {
                  type: 'segmented-by-datafield',
                  value: { value: headerValue, dataField: headerDataField },
                  metricId,
                };
                const existingIndex = acc.rows.findIndex((r) => isEqual(r.title, title));
                const newRow: TableRowTimePeriodData<RegularMetricIdType> = {
                  title,
                  cells: [
                    {
                      type: 'table-cell-metric',
                      value: gs.data as number | null,
                      header: headerValue,
                      metricId,
                    },
                  ],
                };
                return {
                  headers: uniqWith([...acc.headers, header], (h1, h2) => isEqual(h1.value, h2.value)),
                  rows:
                    existingIndex === -1
                      ? [...acc.rows, newRow]
                      : acc.rows.map((row, index) =>
                          index === existingIndex ? { ...row, cells: [...row.cells, ...newRow.cells] } : row
                        ),
                };
              }, tableData);
            },
            { headers: [], rows: [] }
          );

          // Fill in missing data with zero
          const rows = result.rows.map((row) => {
            const cells: TableCellMetricData<RegularMetricIdType>[] = result.headers.map((h) => {
              return match({ h, cells: row.cells })
                .with(
                  { h: { type: 'segmented-by-datafield' }, cells: P.array({ type: 'table-cell-metric' }) },
                  ({ h: header, cells }) => {
                    const exists: TableCellMetricData<RegularMetricIdType> | undefined = cells.find((c) =>
                      isEqual(c.header, header.value.value)
                    );
                    const cell: TableCellMetricData<RegularMetricIdType> = exists
                      ? {
                          type: 'table-cell-metric',
                          value: exists.value,
                          metricId: exists.metricId,
                          header: exists.header,
                        }
                      : {
                          type: 'table-cell-metric',
                          value: 0,
                          metricId: cells[0].metricId,
                          header: header.value.value,
                        };
                    return cell;
                  }
                )
                .otherwise(() => {
                  throw new Error('Unexpected header type');
                });
            });
            return {
              ...row,
              cells,
            };
          });

          return tableDataMonoid.combine(acc, { ...result, rows });
        })
        .with({ stack: P.string }, (error: Error) => {
          throw error;
        })
        .otherwise(() => {
          throw new Error('Unexpected result');
        });
    },
    { headers: [{ value: '', type: 'blank' }], rows: [] }
  );
};

export const timeSliderStateToTimePeriodTimeSelection = (timeSliderState: TimeSliderState): TimePeriodTimeSelection => {
  return match(timeSliderState)
    .with(
      { selectedGranularity: { value: Granularity.MONTH }, start: P.instanceOf(Date), end: P.instanceOf(Date) },
      (value) =>
        ({
          type: TimeSelectionType.CalendarYearSingleValueByMonths,
          input: {
            start: value.start,
            end: value.end,
          },
        } as CalendarYearSingleValueByMonthsTimeSelection)
    )
    .with(
      { selectedGranularity: { value: Granularity.YEAR }, start: P.number, end: P.number },
      (value) =>
        ({
          type: TimeSelectionType.CalendarYearSingleValueByYears,
          input: {
            start: value.start,
            end: value.end,
          },
        } as CalendarYearSingleValueByYearsTimeSelection)
    )
    .with(
      {
        selectedGranularity: { value: Granularity.FINQUARTER },
        start: { year: P.number, quarterOfYear: P.number },
        end: { year: P.number, quarterOfYear: P.number },
      },
      (value) =>
        ({
          type: TimeSelectionType.FinancialYearSingleValueByQuarters,
          input: {
            start: value.start,
            end: value.end,
          },
        } as FinancialYearSingleValueByQuartersTimeSelection)
    )
    .with(
      { selectedGranularity: { value: Granularity.FINYEAR }, start: P.number, end: P.number },
      (value) =>
        ({
          type: TimeSelectionType.FinancialYearSingleValueByYears,
          input: {
            start: value.start,
            end: value.end,
          },
        } as FinancialYearSingleValueByYearsTimeSelection)
    )
    .otherwise(() => {
      throw new Error('Unsupported timeslider state');
    });
};
