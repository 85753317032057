import isEqual from 'lodash.isequal';
import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { Domains, Languages } from '../../constants';
import { DataFieldWithDataType } from '../../types';
import { getDataFieldWithDataTypeFromKey } from '../../utils';
import { Role } from '../types';
import { Language, ListAliasesQuery, MetricCategoryId, MetricGroupId } from '../types-graphql';
import { Alias, AliasTypes } from './types';

export interface AliasService {
  getAliases: () => Promise<Alias[]>;
  setAlias: () => Promise<Alias[]>;
  getAliasForDataField: (
    aliases: Alias[],
    selectedLanguage: Languages
  ) => (dataField: DataFieldWithDataType) => string | null;
  getAliasForMetricCategoryId: (
    aliases: Alias[],
    selectedLanguage: Languages
  ) => (metricCategoryId: MetricCategoryId) => string | null;
  getAliasForMetricGroupId: (
    aliases: Alias[],
    selectedLanguage: Languages
  ) => (metricGroupId: MetricGroupId) => string | null;
}

const toFrontendLanguage = (language: Language) => {
  if (language === Language.En) {
    return Languages.EN;
  } else if (language === Language.Jp) {
    return Languages.JA;
  } else {
    throw new Error(`Unidentified Language found: ${language}`);
  }
};

export class BackendAliasService implements AliasService {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly domain: Domains,
    readonly simulateRole: Role | null
  ) {}

  public getAliases = async (): Promise<Alias[]> => {
    const result: ListAliasesQuery = await this.graphQlRequestService.graphQlSdk.listAliases({
      domain: this.domain,
      simulateRole: this.simulateRole?.id ?? null,
    });
    const modifiedResult =
      result.listAliases?.map((alias) => {
        return {
          ...alias,
          type: alias.type as AliasTypes,
          translations: alias.translations.map((t) => ({ ...t, language: toFrontendLanguage(t.language) })),
        };
      }) ?? [];
    return modifiedResult;
  };

  // TODO: add this function as well as a hook
  // that automatically updates the react-query cache
  // so that the ui refreshes automatically without page reload
  public setAlias = async (): Promise<Alias[]> => Promise.resolve([]);

  public getAliasForDataField =
    (aliases: Alias[], selectedLanguage: Languages) => (dataField: DataFieldWithDataType) => {
      const alias =
        aliases
          .filter((a) => a.type === AliasTypes.DATAFIELD)
          .map((a) => ({
            key: a.key,
            type: a.type,
            dataFieldWithDataType: getDataFieldWithDataTypeFromKey(a.key),
            translations: a.translations,
          }))
          .find((a) => isEqual(a.dataFieldWithDataType, dataField)) ?? null;
      return this.getAliasForLanguage(alias, selectedLanguage);
    };

  public getAliasForMetricCategoryId =
    (aliases: Alias[], selectedLanguage: Languages) => (metricCategoryId: MetricCategoryId) => {
      const alias =
        aliases
          .filter((a) => a.type === AliasTypes.METRIC_CATEGORY_ID)
          .map((a) => ({
            key: a.key,
            type: a.type,
            metricCategoryId: a.key as MetricCategoryId,
            translations: a.translations,
          }))
          .find((a) => a.metricCategoryId === metricCategoryId) ?? null;
      return this.getAliasForLanguage(alias, selectedLanguage);
    };

  public getAliasForMetricGroupId =
    (aliases: Alias[], selectedLanguage: Languages) => (metricGroupId: MetricGroupId) => {
      const alias =
        aliases
          .filter((a) => a.type === AliasTypes.METRIC_GROUP_ID)
          .map((a) => ({
            key: a.key,
            type: a.type,
            metricGroupId: a.key as MetricGroupId,
            translations: a.translations,
          }))
          .find((a) => a.metricGroupId === metricGroupId) ?? null;
      return this.getAliasForLanguage(alias, selectedLanguage);
    };

  private getAliasForLanguage = (alias: Alias | null, language: Languages) => {
    return alias?.translations?.find((t) => t.language === language)?.text || null;
  };
}
