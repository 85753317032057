import { DomainPreferences } from '../../api/types';
import { Months } from '../../common/components/timeslider/types';
import { addMonth, addYear, date, fiscalYearStart, format, monthEnd, now } from '../../utils-date';

// TODO: review this as we won't be able to store logic in json settings
// we should have two options: relative (e.g. last 3 months) or absolute timeframes (e.g. 2023 to 2024)
export const xYearsAgo = (x: number) => addYear(now(), -x);
export const lastYear = addYear(now(), -1);
export const lastYearEndOfMonth = format(monthEnd(lastYear));
export const nowEndOfMonth = format(monthEnd(now()));
export const lastMonth = addMonth(now(), -1);
export const lastMonthEndOfMonth = format(monthEnd(lastMonth));
export const lastYearFromLastMonth = addYear(lastMonth, -1);
export const lastYearFromLastMonthEndOfMonth = format(monthEnd(lastYearFromLastMonth));
export const twoYearsAgoFromLastMonth = addYear(lastMonth, -2);
export const twoYearsAgoFromLastMonthEndOfMonth = format(monthEnd(twoYearsAgoFromLastMonth));
export const startOfLastYear = format(date(lastYear).startOf('year').valueOf());

export const startOfYear = (domainPreferences: DomainPreferences, current = monthEnd(now())) => {
  const finMonth = domainPreferences.finYearStartMonth ?? Months.January;
  return fiscalYearStart(current, finMonth);
};
