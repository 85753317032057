import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartViewIcon, TableViewIcon } from '../../styles';
import { OverTimeDisplayHandle } from '../charts/overtime/types';
import { RadialDisplayHandle } from '../charts/radial/types';
import { TimePeriodDisplayHandle } from '../charts/timeperiod/types';
import { StyledIconButton } from './shared-styles';

interface TableViewToggleProps {
  displayHandle: TimePeriodDisplayHandle | OverTimeDisplayHandle | RadialDisplayHandle;
}

export const TableViewToggle = (props: TableViewToggleProps) => {
  const { displayHandle } = props;
  const [state, dispatcher] = displayHandle;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatcher({ type: 'toggle-tableView' });
  };

  return (
    <Tooltip
      title={
        state.showTableView
          ? t('common:commonValues.tooltips.toggleChartView')
          : t('common:commonValues.tooltips.toggleDataView')
      }
      placement="top"
    >
      <StyledIconButton
        onClick={handleClick}
        size="small"
        aria-label={state.showTableView ? 'Chart View' : 'Table View'}
      >
        {state.showTableView ? (
          <ChartViewIcon className="intercom_toggle_chart_view" />
        ) : (
          <TableViewIcon className="intercom_toggle_table_view" />
        )}
      </StyledIconButton>
    </Tooltip>
  );
};
