import React from 'react';
import i18n from '../../../i18n';
import { MetricFormatTypeId } from './api/types-graphql';
import {
  CONFIDENTIAL_DISPLAY_KEY,
  CONFIDENTIAL_VALUE,
  DATA_NOT_AVAILABLE_VALUE,
  DEFAULT_CURRENCY,
  UNDEFINED_DISPLAY_KEY,
} from './constants';
import { CURRENCY_FORMAT_TYPE, CurrencyBucket, FormatTypes, MetricFormatOptions } from './types';
import {
  currencySymbol,
  getFormattingPrecisionForNumber,
  isValidNumber,
  roundOffNumber,
  toFixedWithPrecision,
} from './utils';

// Ideally, we should use bigdecimal for money related variables to avoid losing precision and buffer overflows
export const formatCurrency = (
  value: string | number | undefined,
  currency: string = DEFAULT_CURRENCY,
  formatType: CURRENCY_FORMAT_TYPE = FormatTypes.CURRENCY_AGGREGATE
) => {
  if (value === CONFIDENTIAL_VALUE) {
    return i18n.t(CONFIDENTIAL_DISPLAY_KEY);
  }
  if (!value) {
    value = '0';
  }

  const valueAsNum = parseFloat(String(value).replace(/\s/g, ''));
  if (isNaN(valueAsNum)) {
    return UNDEFINED_DISPLAY_KEY;
  } else {
    if (currency === 'JPY') {
      const formattedValue = formatJapaneseCurrency(Math.abs(valueAsNum), formatType);
      return `${valueAsNum < 0 ? '-' : ''}${formattedValue}${currencySymbol[currency]}`;
    } else {
      const formattedValue = formatOtherCurrency(Math.abs(valueAsNum), formatType);
      return `${valueAsNum < 0 ? '-' : ''}${currencySymbol[currency]}${formattedValue}`;
    }
  }
};

const formatOtherCurrency = (value: number, formatType: CURRENCY_FORMAT_TYPE) => {
  const buckets: CurrencyBucket[] = [
    new CurrencyBucket(Math.pow(10, 12), 'T'),
    new CurrencyBucket(Math.pow(10, 9), 'B'),
    new CurrencyBucket(Math.pow(10, 6), 'M'),
  ];
  return formatCurrencyByBuckets(value, buckets, formatType);
};

const formatCurrencyByBuckets = (value: number, buckets: CurrencyBucket[], formatType: CURRENCY_FORMAT_TYPE) => {
  const bucketToApply = buckets.find((b) => value >= b.value);
  const numPart = bucketToApply ? value / bucketToApply.value : value;
  const formattedNumPart = formatNumber(numPart, formatType);
  const bucketSymbol = bucketToApply ? bucketToApply.delimiter : '';
  return `${formattedNumPart}${bucketSymbol}`;
};

export const commaSeparate = (num: number | string) =>
  num !== undefined ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '';
const formatToMillion = (value: string | number) => {
  const roundedToMillionValue = `${toFixedWithPrecision(Number(value) / 1000000, 1)}M`;
  return roundedToMillionValue;
};

export const formatNumber = (val: number | string, formatType: FormatTypes): string => {
  if (val === CONFIDENTIAL_VALUE) {
    return i18n.t(CONFIDENTIAL_DISPLAY_KEY);
  }
  if (isValidNumber(val)) {
    const n = Number(val);
    if (formatType === FormatTypes.EXACT_NUMBER) {
      return commaSeparate(n);
    } else {
      if (val === 0) {
        return '0';
      } else {
        const roundedNum = roundOffNumber(val, formatType);
        // After rounding, we might get the same number of digits as the original number
        // or different(for eg: 99.98 would give 100. Therefore, now we need to fetch the precision
        // again for the new number and format it with the correct number of decimal points)
        const precision = getFormattingPrecisionForNumber(roundedNum, formatType);
        const roundedNumWithCorrectDecimals = precision !== null ? toFixedWithPrecision(n, precision) : String(n);
        return n >= 1000000
          ? formatToMillion(roundedNumWithCorrectDecimals)
          : commaSeparate(roundedNumWithCorrectDecimals);
      }
    }
  }
  return String(val);
};

const formatJapaneseCurrency = (value: number, formatType: CURRENCY_FORMAT_TYPE) => {
  const buckets: CurrencyBucket[] = [
    new CurrencyBucket(Math.pow(10, 16), '京'),
    new CurrencyBucket(Math.pow(10, 12), '兆'),
    new CurrencyBucket(Math.pow(10, 8), '億'),
    new CurrencyBucket(Math.pow(10, 4), '万'),
  ];
  return formatCurrencyByBuckets(value, buckets, formatType);
};
export const formatPercentageNumber = (num: number | string) => {
  return `${formatNumber(num, FormatTypes.PERCENTAGE)}%`;
};

export const convertNumDaysToYearAndMonth = (days: number) => {
  const yearInDays = 365.25; // accounting for leap years
  const monthInDays = 30.44; // assuming a month has an average of 30.44 days

  const years = Math.floor(days / yearInDays);
  const months = Math.floor((days % yearInDays) / monthInDays);
  return `${years}${i18n.t('common:commonValues.misc.yearsShort')}  ${toFixedWithPrecision(months, 0)}${i18n.t(
    'common:commonValues.misc.monthsShort'
  )}`;
};

const metricFormatTypeToFormatterMap: Record<
  MetricFormatTypeId,
  (val: number | string, options?: MetricFormatOptions) => string
> = {
  [MetricFormatTypeId.Pmft001Salary]: (val, options) => {
    return formatCurrency(val, options?.currency ?? DEFAULT_CURRENCY);
  },
  [MetricFormatTypeId.Pmft002Percentage]: (val) => {
    return formatPercentageNumber(val);
  },
  [MetricFormatTypeId.Pmft004DaysToYearMonth]: (val) => {
    return convertNumDaysToYearAndMonth(Number(val));
  },
  [MetricFormatTypeId.Pmft005Count]: (val) => {
    return formatNumber(val, FormatTypes.COUNT);
  },
  [MetricFormatTypeId.Pmft006Number]: (val) => {
    return formatNumber(val, FormatTypes.NUMBER);
  },
  [MetricFormatTypeId.Pmft007SensitiveNumber]: (val) => {
    return formatNumber(val, FormatTypes.SENSITIVE_NUMBER);
  },
  [MetricFormatTypeId.Pmft008Multiple]: (val) => {
    return `${formatNumber(val, FormatTypes.NUMBER)}x`;
  },
  [MetricFormatTypeId.Pmft009Leaves]: (val) => {
    return formatNumber(val, FormatTypes.LEAVES_TAKEN);
  },
  [MetricFormatTypeId.Pmft010Hours]: (val) => {
    return formatNumber(val, FormatTypes.HOURS);
  },
  [MetricFormatTypeId.Pmft011Fte]: (val) => {
    return formatNumber(val, FormatTypes.FTE);
  },
  [MetricFormatTypeId.Pmft012FloatingNumber1]: (val) => {
    return formatNumber(val, FormatTypes.FLOATING_NUMBER_1);
  },
  [MetricFormatTypeId.Pmft013FloatingNumber2]: (val) => {
    return formatNumber(val, FormatTypes.FLOATING_NUMBER_2);
  },
  [MetricFormatTypeId.Pmft014FloatingPercentage1]: (val) => {
    return `${formatNumber(val, FormatTypes.FLOATING_NUMBER_1)}%`;
  },
  [MetricFormatTypeId.Pmft015FloatingPercentage2]: (val) => {
    return `${formatNumber(val, FormatTypes.FLOATING_NUMBER_2)}%`;
  },
};

export const formatMetricValue = (
  val: number | string | null,
  formatType: MetricFormatTypeId = MetricFormatTypeId.Pmft005Count,
  options?: MetricFormatOptions
): string => {
  if (val === null) {
    return DATA_NOT_AVAILABLE_VALUE;
  }
  return metricFormatTypeToFormatterMap[formatType](val, options);
};

export type ClickEvent<ElementType = HTMLInputElement> = React.MouseEvent<ElementType>;
