import { Card, Grid, styled, Typography } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { defaultTheme } from '../../../../theme/default-theme';
import { toRegularMetricResultOrError } from '../../../api/utils';
import { Languages, UNDEFINED_DISPLAY_KEY } from '../../../constants';
import { useGlobalLocaleContext, useMetricDetailsMapContext, useMetricServiceContext } from '../../../context/contexts';
import { TimeSelection } from '../../../types';
import { formatMetricValue } from '../../../utils-display';
import { ManualDataRefetchCountHandle, MetricTypes } from '../../types';
import { getFilterConditions } from '../../utils';
import { BetterSuspense } from '../BetterSuspense';
import { ChartComponentProps } from '../charts/chart-types';
import { SegmentedSeries } from '../charts/mui-charts/types';
import {
  toNonSegmentedTimePeriodChartInput,
  toSingleLevelSegmentedTimePeriodChartInput,
} from '../charts/mui-charts/utils';
import {
  timeSliderStateToTimePeriodTimeSelection,
  toPartialNonSegmentedChartDataTimePeriod,
  toPartialSingleLevelSegmentedChartDataTimePeriod,
} from '../charts/timeperiod/utils';
import { KpiComponentConfig, KpiComponentSectionConfig, KpiQueryConfig } from '../dashboards/types';
import { Filter, Segment } from '../filter/filterbar/types';
import { ItemsState } from '../filter/types';
import { formatHierarchicalLabel } from '../filter/utils-display';
import { LoadingCircle } from '../LoadingCircle';
import { TimeSliderState } from '../timeslider/types';

const StyledCard = styled(Card)`
  box-shadow: none;
  padding-bottom: 0rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${defaultTheme.color.clientPrimary}; /* equivalent to bg-panalyt-teal */
`;

const Title = styled(Typography)<{ $selectedLanguage: Languages }>`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: ${(props) => (props.$selectedLanguage === Languages.EN ? '1rem' : '1.8rem')};
  font-weight: normal;
  margin-bottom: 0rem;
`;

const Subtitle = styled(Typography)<{ $selectedLanguage: Languages }>`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: ${(props) => (props.$selectedLanguage === Languages.EN ? '1rem' : '1.3rem')};
  font-weight: normal;
  margin-bottom: 0.5rem;
`;

const Footer = styled(Typography)<{ $selectedLanguage: Languages }>`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: ${(props) => (props.$selectedLanguage === Languages.EN ? '0.8rem' : '1rem')};
  font-weight: normal;
  margin-bottom: 0.5rem;
`;

const KpiSectionTitle = styled(Typography)`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: 0rem;
`;

const KpiSectionSubtitle = styled(Typography)`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: 0.8rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
`;

const BASE_SECTION_FONT_SIZE = 3.3; // rem
const SECTION_FONT_SIZE_REDUCTION_FACTOR = 0.2; //rem

const getSectionFontSize = (sectionCount: number) =>
  BASE_SECTION_FONT_SIZE - BASE_SECTION_FONT_SIZE * SECTION_FONT_SIZE_REDUCTION_FACTOR * sectionCount;

const ChartText = styled(Typography)<{ fontSize?: string }>`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: ${(props) => props.fontSize ?? `${BASE_SECTION_FONT_SIZE}rem`}; /* equivalent to text-2xl */
  font-weight: normal;
`;

type KpiProps = ChartComponentProps<
  KpiComponentConfig,
  'manualDataRefetchCountHandle' | 'timeSliderState' | 'filtersState' | 'segmentationState'
>;

export const Kpi = (props: KpiProps) => {
  const { componentConfig, ...rest } = props;
  const { sections, title, subtitle, footer, align = 'column', ...restComponentConfig } = componentConfig;
  const locale = useGlobalLocaleContext();
  return (
    <StyledCard sx={{ padding: '0.5rem' }}>
      <Grid
        item
        container
        direction="column"
        justifyContent={'center'}
        alignItems="flex-start"
        sx={{ height: '100%', width: '100%' }}
        wrap="nowrap"
      >
        <Grid item container justifyContent={'left'}>
          {title ? <Title $selectedLanguage={locale.selected}>{title[locale.selected]}</Title> : null}
        </Grid>
        <Grid item container justifyContent={'left'}>
          {subtitle ? <Subtitle $selectedLanguage={locale.selected}>{subtitle[locale.selected]}</Subtitle> : null}
        </Grid>
        <Grid
          item
          container
          direction={align}
          wrap="nowrap"
          justifyContent={align === 'row' ? 'center' : 'flex-start'}
          sx={{ flexGrow: 1 }}
        >
          {sections.map((section) => {
            return (
              <Grid item container key={section.key} wrap="nowrap" sx={{ flexGrow: 1 }} alignItems={'center'}>
                <KpiSection
                  section={section}
                  componentConfig={restComponentConfig}
                  styleOptions={{ fontSize: `${getSectionFontSize(sections.length - 1)}rem` }}
                  {...rest}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item container justifyContent={'left'}>
          {footer ? <Footer $selectedLanguage={locale.selected}>{footer[locale.selected]}</Footer> : null}
        </Grid>
      </Grid>
    </StyledCard>
  );
};

interface KpiSectionProps {
  section: KpiComponentSectionConfig;
  componentConfig: Omit<KpiComponentConfig, 'sections' | 'title' | 'subtitle'>;
  manualDataRefetchCountHandle: ManualDataRefetchCountHandle;
  timeSliderState: TimeSliderState | null;
  filtersState: ItemsState<Filter>;
  segmentationState: ItemsState<Segment>;
  styleOptions?: { fontSize?: string };
}

export const KpiSection = ({
  section,
  manualDataRefetchCountHandle,
  timeSliderState,
  filtersState,
  styleOptions,
}: KpiSectionProps) => {
  const { title, subtitle } = section;
  const locale = useGlobalLocaleContext();

  return (
    <Grid item container direction={'column'}>
      <Grid item>{title ? <KpiSectionTitle>{title[locale.selected]}</KpiSectionTitle> : null}</Grid>
      <Grid item>{subtitle ? <KpiSectionSubtitle>{subtitle[locale.selected]}</KpiSectionSubtitle> : null}</Grid>
      <Grid item container wrap="nowrap" justifyContent={'center'} sx={{ flexGrow: 1 }}>
        {section.queries.map((query, i) => {
          return (
            <div key={`kpi-${section.key}-${i}`}>
              <BetterSuspense fallback={<LoadingCircle color="secondary" />}>
                <KpiSectionValue
                  query={query}
                  manualDataRefetchCountHandle={manualDataRefetchCountHandle}
                  timeSliderState={timeSliderState}
                  filtersState={filtersState}
                  styleOptions={styleOptions}
                />
              </BetterSuspense>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

interface KpiSectionValueProps {
  query: KpiQueryConfig;
  manualDataRefetchCountHandle: ManualDataRefetchCountHandle;
  timeSliderState: TimeSliderState | null;
  filtersState: ItemsState<Filter>;
  styleOptions?: { fontSize?: string };
}

export const KpiSectionValue = ({
  query,
  manualDataRefetchCountHandle,
  timeSliderState,
  filtersState,
  styleOptions,
}: KpiSectionValueProps) => {
  const { t } = useTranslation();
  const metricDetailsMap = useMetricDetailsMapContext();
  const metricService = useMetricServiceContext();
  const { metrics, timeSelection, filters, segmentations } = query;

  const finalTimeSelection: TimeSelection = timeSliderState
    ? timeSliderStateToTimePeriodTimeSelection(timeSliderState)
    : timeSelection;
  const allFilters =
    [getFilterConditions(filtersState.items, MetricTypes.REGULAR, false), filters]
      .flatMap((f) => (f ? [f] : []))
      .join(' AND ') || undefined;
  const queryKey = useDebounce(
    JSON.stringify([
      'kpi',
      metrics,
      finalTimeSelection,
      allFilters,
      segmentations,
      manualDataRefetchCountHandle.current,
    ]),
    500
  );
  const { data } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: () =>
      toRegularMetricResultOrError(
        metricService.queryRegularMetrics,
        metrics,
        finalTimeSelection,
        allFilters,
        segmentations
      ),
  });
  if (segmentations && segmentations.length > 0) {
    const chartInput = toSingleLevelSegmentedTimePeriodChartInput(
      data ?? [],
      toPartialSingleLevelSegmentedChartDataTimePeriod
    );
    return (
      <Grid item container>
        {chartInput.series.map((s: SegmentedSeries) => {
          const metricDetails = metricDetailsMap[s.metricId.value];
          return (
            <ChartText key={s.metricId.value}>
              <Grid item container direction={'column'} sx={{ fontSize: '1.2rem' }}>
                {s.values.map((v, j) => {
                  const d = chartInput.xAxisData[j];
                  const label = formatHierarchicalLabel(d.value, d.dataField, t(UNDEFINED_DISPLAY_KEY), t);
                  return (
                    <Grid key={v as string} item>
                      {`${label}: ${
                        formatMetricValue(v as string, metricDetails.formatType) ?? t(UNDEFINED_DISPLAY_KEY)
                      }`}
                    </Grid>
                  );
                })}
              </Grid>
            </ChartText>
          );
        })}
      </Grid>
    );
  } else {
    const chartInput = toNonSegmentedTimePeriodChartInput(data ?? [], toPartialNonSegmentedChartDataTimePeriod);
    const series = chartInput.series;
    if (series) {
      const metricDetails = metricDetailsMap[series.metricId.value];
      return (
        <Grid item container>
          <ChartText fontSize={styleOptions?.fontSize}>
            {formatMetricValue(series.values[0] as string, metricDetails.formatType) ?? t(UNDEFINED_DISPLAY_KEY)}
          </ChartText>
        </Grid>
      );
    } else {
      return null; // TODO: add proper handling of missing data
    }
  }
};
