import i18n from '../../../i18n';
import { CustomError } from '../v2/common/errors';

const errorCodesToMessage: Record<string, string> = {
  'auth/invalid-action-code': i18n.t('common:errors.authErrorCodes.invalidActionCode'),
};

export class VerifyPasswordResetCodeError extends CustomError {
  constructor(error: Error) {
    const name = 'VerifyPasswordResetCodeError';
    const message = `${i18n.t('common:snackbarMessages.errorPasswordResetCode', {
      message: `${errorCodesToMessage[error.message] ?? error.message}`,
    })}`;
    super(name, message);
  }
}

export class ResetPasswordError extends CustomError {
  constructor(message: string) {
    const name = 'PasswordDoesNotMeetRequirementsError';
    super(name, message);
  }
}
