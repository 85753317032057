import { isEqual } from 'lodash';
import { ApiOverTimeTimeSegmentType } from '../../../api/types';
import { MetricIdType, Monoid, RegularMetricIdType } from '../../../types';
import { removeDuplicates } from '../../utils';
import {
  BlankHeaderValue,
  NonSegmentedTableOverTimeData,
  SegmentedOverTimeTableData,
  SegmentedTimePeriodTableData,
  TableCellEmptyData,
  TableCellTimeData,
} from './types';

export class NonSegmentedOverTimeTableDataMonoid implements Monoid<NonSegmentedTableOverTimeData<MetricIdType>> {
  public empty = { headers: [], rows: [] };
  public combine = (
    acc: NonSegmentedTableOverTimeData<MetricIdType>,
    result: NonSegmentedTableOverTimeData<MetricIdType>
  ): NonSegmentedTableOverTimeData<MetricIdType> => {
    const uniqueRows = removeDuplicates([...acc.rows, ...result.rows].map((r) => r.title));
    const rows = uniqueRows.map((rowTitle) => {
      const rowInAcc = acc.rows.find((r) => r.title === rowTitle);
      const rowInResult = result.rows.find((r) => r.title === rowTitle);
      return {
        title: rowTitle,
        cells: [...(rowInAcc?.cells ?? []), ...(rowInResult?.cells ?? [])],
      };
    });
    return { ...acc, headers: removeDuplicates([...acc.headers, ...result.headers]), rows };
  };
}

export class NonSegmentedOverTimeTableDataColumnWiseMonoid<T extends MetricIdType>
  implements Monoid<NonSegmentedTableOverTimeData<T>>
{
  get empty(): NonSegmentedTableOverTimeData<T> {
    return { headers: [], rows: [] };
  }

  combine(a: NonSegmentedTableOverTimeData<T>, b: NonSegmentedTableOverTimeData<T>): NonSegmentedTableOverTimeData<T> {
    if (a.headers.length === 0 && a.rows.length === 0) {
      return b;
    }

    if (b.headers.length === 0 && b.rows.length === 0) {
      return a;
    }
    // Create blank headers for empty header arrays, including title column
    const blankHeader: BlankHeaderValue = { type: 'blank', value: '' };
    const blankCell: TableCellEmptyData = { type: 'table-cell-empty', value: null };
    const cellFromTitle = (title: ApiOverTimeTimeSegmentType): TableCellTimeData => ({
      value: title,
      type: 'table-cell-time',
      header: [],
    });
    const aHeaders = a.headers.length === 0 ? Array(a.rows[0]?.cells.length + 1 || 0).fill(blankHeader) : a.headers;
    const bHeaders = b.headers.length === 0 ? Array(b.rows[0]?.cells.length || 0).fill(blankHeader) : b.headers;

    // Combine headers side by side
    const headers = [...aHeaders, blankHeader, ...bHeaders];

    const rows = (
      a.rows.length === 0 && b.rows.length === 0 ? [] : a.rows.length > b.rows.length ? a.rows : b.rows
    ).map((_, index) => {
      const aRow = a.rows[index];
      const bRow = b.rows[index];
      if (!aRow || !bRow) {
        throw new Error('Both inputs should have defined rows of the same length');
      }

      const aCells = aHeaders.slice(1).map((_, index) => aRow.cells[index] ?? blankCell);
      const bCells = bHeaders.map((_, index) => bRow.cells[index] ?? blankCell);

      return {
        title: aRow.title,
        cells: [...aCells, cellFromTitle(bRow.title), ...bCells],
      };
    });

    return {
      headers,
      rows,
    };
  }
}

export class SingleLevelSegmentedTimePeriodTableDataMonoid
  implements Monoid<SegmentedTimePeriodTableData<RegularMetricIdType>>
{
  public empty = { headers: [], rows: [] };
  public combine = (
    acc: SegmentedTimePeriodTableData<RegularMetricIdType>,
    result: SegmentedTimePeriodTableData<RegularMetricIdType>
  ): SegmentedTimePeriodTableData<RegularMetricIdType> => {
    const rows = result.rows.map((newRow) => {
      const existingRow = acc.rows.find(
        (r) =>
          // Compare the actual values and dataField properties
          isEqual(r.title.value, newRow.title.value) && isEqual(r.title.dataField, newRow.title.dataField)
      );

      if (existingRow) {
        return {
          title: existingRow.title,
          cells: [...existingRow.cells, ...newRow.cells],
        };
      } else {
        return newRow;
      }
    });

    // Combine existing rows that weren't matched with new rows
    const unmatchedAccRows = acc.rows.filter(
      (accRow) =>
        !result.rows.some(
          (newRow) =>
            isEqual(accRow.title.value, newRow.title.value) && isEqual(accRow.title.dataField, newRow.title.dataField)
        )
    );

    return {
      headers: [...acc.headers, ...result.headers],
      rows: [...unmatchedAccRows, ...rows],
    };
  };
}

export class SingleLevelSegmentedOverTimeTableDataMonoid
  implements Monoid<SegmentedOverTimeTableData<RegularMetricIdType>>
{
  public empty = { headers: [], rows: [] };
  public combine = (
    acc: SegmentedOverTimeTableData<RegularMetricIdType>,
    result: SegmentedOverTimeTableData<RegularMetricIdType>
  ): SegmentedOverTimeTableData<RegularMetricIdType> => {
    // TODO: This doesn't seem correct. Final Number of rows could
    // be higher than individual rows, but not under this logic
    const rows = result.rows.map((newRow) => {
      const existingRow = acc.rows.find((r) => r.title === newRow.title);
      if (existingRow) {
        return {
          title: existingRow.title,
          cells: [...existingRow.cells, ...newRow.cells],
        };
      } else {
        return newRow;
      }
    });
    return { ...acc, headers: [...acc.headers, ...result.headers], rows };
  };
}

export class DoubleLevelSegmentedTimePeriodTableDataMonoid
  implements Monoid<SegmentedTimePeriodTableData<RegularMetricIdType>>
{
  public empty = { headers: [], rows: [] };
  public combine = (
    acc: SegmentedTimePeriodTableData<RegularMetricIdType>,
    result: SegmentedTimePeriodTableData<RegularMetricIdType>
  ): SegmentedTimePeriodTableData<RegularMetricIdType> => {
    return { ...acc, headers: [...acc.headers, ...result.headers], rows: [...acc.rows, ...result.rows] };
  };
}

export class DoubleLevelSegmentedOverTimeTableDataMonoid
  implements Monoid<SegmentedOverTimeTableData<RegularMetricIdType>>
{
  public empty = { headers: [], rows: [] };
  public combine = (
    acc: SegmentedOverTimeTableData<RegularMetricIdType>,
    result: SegmentedOverTimeTableData<RegularMetricIdType>
  ): SegmentedOverTimeTableData<RegularMetricIdType> => {
    return { ...acc, headers: [...acc.headers, ...result.headers], rows: [...acc.rows, ...result.rows] };
  };
}
