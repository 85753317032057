import { useReducer } from 'react';
import { match } from 'ts-pattern';
import { GlobalDisplayState } from '../../../../context/types';
import { MetricIdType, Segmentation } from '../../../../types';
import { SortTypes } from '../../../types';
import { ChartTypeConfig } from '../../dashboards/types';
import {
  DisplayState,
  GlobalTimePeriodDisplayAction,
  LocalTimePeriodDisplayAction,
  SortOrderTimePeriodDisplayAction,
  TimePeriodDisplayHandle,
  ToolsAction,
} from './types';

// Stacked Bar chart (e.g. multiple metrics or 2 levels of segmentation)
const isStackedBarChart = (state: DisplayState) => {
  return match(state.chartTypeConfig)
    .with({ chartType: 'bar' }, () => state.metrics.length > 1 || state.segmentations?.length === 2)
    .otherwise(() => false);
};

export const useDisplay = (
  chartTypeConfig: ChartTypeConfig,
  segmentations: Segmentation[] | undefined,
  metrics: MetricIdType[],
  displayGlobalState: GlobalDisplayState
): TimePeriodDisplayHandle => {
  const initialState: DisplayState = {
    enableLabels: true,
    showLabels: false,
    enablePercentage: false,
    showPercentage: false,
    enableStack: false,
    showStack: false,
    showTableView: false,
    enableLegend: true,
    showLegend: false,
    enableSortOrder: true,
    selectedSortOrder: SortTypes.ASC,
    segmentations,
    metrics,
    chartTypeConfig,
    globalDisplayState: displayGlobalState,
  };

  return useReducer(reducer, initialState);
};

export const reducer = (
  state: DisplayState,
  action: GlobalTimePeriodDisplayAction | LocalTimePeriodDisplayAction | SortOrderTimePeriodDisplayAction | ToolsAction
): DisplayState => {
  const stackedBarChart = isStackedBarChart(state);
  return match(action)
    .with({ type: 'toggle-tools' }, (a: ToolsAction) => {
      const stackedBarChart = isStackedBarChart({ ...state, segmentations: a.segmentations });
      const enablePercentage = stackedBarChart && state.globalDisplayState.enablePercentage;
      const showPercentage = stackedBarChart && state.globalDisplayState.showPercentage;

      return {
        ...state,
        enablePercentage,
        showPercentage,
        enableStack: stackedBarChart && state.globalDisplayState.enableStack && !showPercentage,
        segmentations: a.segmentations,
      };
    })
    .with({ type: 'toggle-labels' }, () => {
      return {
        ...state,
        showLabels: !state.showLabels,
      };
    })
    .with({ type: 'toggle-percentage' }, () => {
      const showPercentage = stackedBarChart && !state.showPercentage;
      return {
        ...state,
        showPercentage,
        enableStack: !showPercentage,
      };
    })
    .with({ type: 'toggle-stack' }, () => {
      const showStack = stackedBarChart && !state.showStack;
      return {
        ...state,
        showStack,
        enablePercentage: !showStack,
      };
    })
    .with({ type: 'toggle-legend' }, () => {
      return {
        ...state,
        showLegend: !state.showLegend,
      };
    })
    .with({ type: 'sort-order' }, (a: SortOrderTimePeriodDisplayAction) => {
      return {
        ...state,
        selectedSortOrder: a.selectedSortOrder,
      };
    })
    .with({ type: 'toggle-tableView' }, () => {
      const enablePercentage = stackedBarChart && state.showTableView && (state.prevState?.enablePercentage ?? false);
      const enableStack = stackedBarChart && state.showTableView && (state.prevState?.enableStack ?? false);
      const enableLabels = state.showTableView;
      const enableLegend = state.showTableView;
      const enableSortOrder = state.showTableView;

      return {
        ...state,
        showTableView: !state.showTableView,
        enableSortOrder,
        enablePercentage,
        enableStack,
        enableLabels,
        enableLegend,
        prevState: state,
      };
    })
    .with({ type: 'global-state-sync' }, (a) => {
      const showPercentage = stackedBarChart && a.globalDisplayState.showPercentage;
      const showStack = stackedBarChart && a.globalDisplayState.showStack && !showPercentage;
      const enablePercentage = stackedBarChart && a.globalDisplayState.enablePercentage;
      const enableStack = stackedBarChart && a.globalDisplayState.enableStack && !showPercentage;

      return {
        ...state,
        showLabels: a.globalDisplayState.showLabels,
        showPercentage,
        showStack,
        showLegend: a.globalDisplayState.showLegend,
        enablePercentage,
        enableStack,
        globalDisplayState: a.globalDisplayState,
      };
    })
    .exhaustive();
};
