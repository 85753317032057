import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import React from 'react';
import { ManualDataRefetchCountHandle } from '../../types';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

export interface ErrorData {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
}

interface ChartDataFetchErrorProps {
  manualDataRefetchCountHandle: ManualDataRefetchCountHandle;
  errorData?: ErrorData;
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem; /* sm:max-w-lg */
`;

const InnerContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  height: 13rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const CenteredText = styled.div`
  text-align: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  margin-left: auto;
  margin-right: auto;
  height: 1.75rem;
  width: 1.75rem;
  color: var(--tremor-content-subtle);

  .dark & {
    color: var(--dark-tremor-content-subtle);
  }
`;

const ErrorMessage = styled.p`
  margin-top: 0.5rem;
  font-size: var(--tremor-default); /* Custom font size */
  font-weight: 500; /* font-medium */
  color: var(--tremor-content-strong);

  .dark & {
    color: var(--dark-tremor-content-strong);
  }
`;

const AdditionalMessage = styled.div`
  font-size: var(--tremor-default);
  color: var(--tremor-content);

  .dark & {
    color: var(--dark-tremor-content);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChartDataFetchError = (props: ChartDataFetchErrorProps) => {
  const { manualDataRefetchCountHandle, errorData } = props;
  return (
    <Container>
      <InnerContainer>
        <CenteredText>
          <StyledErrorIcon aria-hidden={true} />
          <ErrorMessage>{errorData?.error?.message ?? `Couldn't fetch data`}</ErrorMessage>
          <AdditionalMessage>
            Please wait for a while and try again
            <ButtonContainer>
              <IconButton
                aria-label="refresh"
                onClick={() => {
                  errorData?.resetError();
                  manualDataRefetchCountHandle.increment();
                }}
              >
                <RefreshIcon /> Refresh data
              </IconButton>
            </ButtonContainer>
          </AdditionalMessage>
        </CenteredText>
      </InnerContainer>
    </Container>
  );
};
