import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { ManualDataRefetchCountHandle } from '../../types';
import { ChartDataFetchError, ErrorData } from './ChartDataFetchError';

interface ChartErrorBoundaryProps {
  children: React.ReactNode;
  manualDataRefetchCountHandle: ManualDataRefetchCountHandle;
}

export const ChartErrorBoundary = (props: ChartErrorBoundaryProps) => {
  const { manualDataRefetchCountHandle, children } = props;
  return (
    <ErrorBoundary
      fallback={(errorData) => (
        <ChartDataFetchError
          manualDataRefetchCountHandle={manualDataRefetchCountHandle}
          errorData={errorData as ErrorData}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};
