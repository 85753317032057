import { RegularMetricId } from '../../api/types-graphql';
import { DashboardConfig } from '../../common/components/dashboards/types';
import { Languages } from '../../constants';
import { ApplicationDataFields, DataTypes, EmployeeDataFields, TimeSelectionType } from '../../types';
import { date, format, monthEnd, now } from '../../utils-date';
import { lastYear, lastYearEndOfMonth, nowEndOfMonth, xYearsAgo } from './utils';

const pageOptions = {
  displayOptions: {
    enabled: true,
  },
  timeSlider: {
    enabled: false,
  },
  filterTray: {
    enabled: true,
  },
  dataView: {
    enabled: false,
  },
  segmentationLevel1: {
    enabled: true,
  },
  segmentationLevel2: {
    enabled: false,
  },
  benchmark: {
    enabled: false,
  },
  forecast: {
    enabled: false,
  },
};

const colors = ['#9DF7E5', '#F4E4BA', '#E5A9A9', '#D66BA0', '#AF4D98'];

export const AlexDashboardChartConfig: DashboardConfig = {
  id: 'themis',
  title: {
    [Languages.EN]: 'Themis Project',
    [Languages.JA]: 'テミスプロジェクト',
  },
  enabled: true,
  pages: [
    {
      pageId: '1',
      dashboardId: 'themis',
      pageNumber: 1,
      enabled: true,
      title: {
        [Languages.EN]: 'DEMAND',
        [Languages.JA]: '需要',
      },
      pageOptions,
      components: [
        {
          chartId: 'attrition-ttm',
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          colors,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_3AttritionTtm }],
              timeSelection: { type: TimeSelectionType.FinancialYearYearly, input: { start: 2022, end: 2023 } },
            },
          ],
        },
        {
          chartId: '',
          chartTypeConfig: { chartType: 'line' },
          colors,
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0010_1MngCalcCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0020_1FhcCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0023_1TenureMedian },
              ],
              timeSelection: {
                type: TimeSelectionType.FinancialYearQuarterly,
                input: { start: { quarterOfYear: 2, year: 2022 }, end: { quarterOfYear: 3, year: 2023 } },
              },
            },
          ],
        },
        {
          chartId: 'joiners',
          chartTypeConfig: { chartType: 'line' },
          colors,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: { type: TimeSelectionType.CalendarYearYearly, input: { start: 2020, end: 2023 } },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'individuals-count',
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          colors,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: '2022-08-31', end: '2022-08-31' },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'new-joiners-managers-by-gender',
          title: {
            [Languages.EN]: 'New Joiners Managers by Gender ',
            [Languages.JA]: '新規参加者のマネージャーの性別',
          },
          chartTypeConfig: { chartType: 'area' },
          colors,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0014_1MngDefCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearQuarterly,
                input: { start: { quarterOfYear: 2, year: 2022 }, end: { quarterOfYear: 3, year: 2023 } },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
              filters: `EMPLOYEE.TENURE_GROUP = ('6ヶ月未満')`,
            },
          ],
        },
        {
          chartId: 'leavers-by-gender',
          title: {
            [Languages.EN]: 'Leavers by Gender ',
            [Languages.JA]: '退職者の性別',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime', stack: false, relative: true },
          colors,
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2022-06-01', end: '2023-09-30' },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                  filters: ['Male', 'Female'],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '2',
      dashboardId: 'themis',
      pageNumber: 2,
      enabled: true,
      title: {
        [Languages.EN]: 'SUPPLY',
        [Languages.JA]: '供給',
      },
      pageOptions,
      components: [
        {
          chartId: 'female-managers',
          title: {
            [Languages.EN]: 'Female Managers',
            [Languages.JA]: '女性マネージャー',
          },
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0016_1FMngDefCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0016_2FMngDefPercHc },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: format(lastYear), end: format(now()) },
              },
            },
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0015_1NewMngDefCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: format(xYearsAgo(2)), end: format(monthEnd(lastYear)) },
              },
            },
          ],
        },
        {
          chartId: 'new-managers-by-gender',
          title: {
            [Languages.EN]: 'New Managers by Gender',
            [Languages.JA]: '新しいマネージャーの性別',
          },
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0015_1NewMngDefCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: date(now()).year() - 5, end: date(now()).year() },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: 'female-managers-by-department',
          title: {
            [Languages.EN]: 'Female Managers by Department',
            [Languages.JA]: '部門別女性マネージャー',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0016_1FMngDefCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'management-promotion-pct-hc',
          title: {
            [Languages.EN]: 'Management Promotion % of HC',
            [Languages.JA]: '管理職昇進率',
          },
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0196ManagementPromotionPercOfHeadcount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: date(now()).year() - 5, end: date(now()).year() },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: 'applications-by-gender',
          title: {
            [Languages.EN]: 'Applications by Gender',
            [Languages.JA]: '応募者の性別',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0252_2ApplicationsCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearYearly,
                input: { start: date(now()).year() - 2, end: date(now()).year() },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.CUSTOM_FILTER_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'applications-by-source',
          title: {
            [Languages.EN]: 'Applications by Source',
            [Languages.JA]: '応募者のソース',
          },
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime', stack: false, relative: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0252_2ApplicationsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: 2019, end: 2021 },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.SOURCE_LEVEL_1 },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '3',
      dashboardId: 'themis',
      pageNumber: 3,
      enabled: true,
      title: {
        [Languages.EN]: 'OFFER',
        [Languages.JA]: 'オファー',
      },
      pageOptions,
      gridCols: 4,
      gridRows: 1,
      components: [
        {
          chartId: 'parental-leave-utilization-by-gender',
          title: {
            [Languages.EN]: 'Parental leave utilization by Gender',
            [Languages.JA]: '性別別の育児休暇利用率',
          },
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'pie', variant: 'donut' },
          query: {
            metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0171ParentalLeaveTakenUtilizationPerc }],
            timeSelection: {
              type: TimeSelectionType.CalendarYearSingleValueByMonths,
              input: { start: '2021-01-01', end: '2021-12-31' },
            },
            segmentations: [
              {
                type: 'non-hierarchical',
                dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
              },
            ],
          },
        },
        {
          chartId: 'individuals-by-department-by-age-group',
          title: {
            [Languages.EN]: 'Individuals by Department and by Age Group',
            [Languages.JA]: '部門別年齢層別の従業員数',
          },
          gridColSpan: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod', stack: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '4',
      dashboardId: 'themis',
      pageNumber: 4,
      enabled: true,
      title: {
        [Languages.EN]: 'Themis 4',
        [Languages.JA]: 'テミス4',
      },
      pageOptions,
      gridCols: 4,
      components: [
        {
          chartId: 'individuals-count',
          title: {
            [Languages.EN]: 'Individuals Count',
            [Languages.JA]: '従業員数',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count',
          title: {
            [Languages.EN]: 'Joiners & Leavers Count',
            [Languages.JA]: '入社者と退職者数',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
                  ],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'individuals-count-2',
          title: {
            [Languages.EN]: 'Individuals Count',
            [Languages.JA]: '従業員数',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-2',
          title: {
            [Languages.EN]: 'Joiners & Leavers Count',
            [Languages.JA]: '入社者と退職者数',
          },
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
                  ],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'individuals-by-department-by-age-group',
          title: {
            [Languages.EN]: 'Individuals by Department and by Age Group',
            [Languages.JA]: '部門別年齢層別の従業員数',
          },
          gridColSpan: 4,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod', stack: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-over-time',
          title: {
            [Languages.EN]: 'Joiners & Leavers Over Time',
            [Languages.JA]: '入社者と退職者の推移',
          },
          gridColSpan: 4,
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
            },
          ],
        },
      ],
    },
    {
      pageId: '5',
      dashboardId: 'themis',
      pageNumber: 5,
      enabled: true,
      title: {
        [Languages.EN]: 'Joiners & Leavers',
        [Languages.JA]: '入社者と退職者',
      },
      pageOptions,
      gridCols: 4,
      components: [
        {
          chartId: 'joiners-and-leavers-count-over-time',
          title: {
            [Languages.EN]: 'Joiners & Leavers Over Time',
            [Languages.JA]: '入社者と退職者の推移',
          },
          gridColSpan: 4,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-org',
          title: {
            [Languages.EN]: 'Organization',
            [Languages.JA]: '組織',
          },
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-location',
          title: {
            [Languages.EN]: 'Location',
            [Languages.JA]: '場所',
          },
          gridColSpan: 2,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-tenure',
          title: {
            [Languages.EN]: 'Tenure',
            [Languages.JA]: '在職期間',
          },
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-gender',
          title: {
            [Languages.EN]: 'Gender',
            [Languages.JA]: '性別',
          },
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-job-grade',
          title: {
            [Languages.EN]: 'Job Grade',
            [Languages.JA]: '職級',
          },
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-employment-type',
          title: {
            [Languages.EN]: 'Employment Type',
            [Languages.JA]: '雇用形態',
          },
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-age-group',
          title: {
            [Languages.EN]: 'Age Group',
            [Languages.JA]: '年齢層',
          },
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '6',
      dashboardId: 'themis',
      pageNumber: 6,
      enabled: true,
      title: {
        [Languages.EN]: 'People Snapshot: Headcount',
        [Languages.JA]: '供給',
      },
      pageOptions,
      gridCols: 3,
      components: [
        {
          chartId: 'people-snapshot-headcount-over-time',
          title: {
            [Languages.EN]: 'Headcount Over Time',
            [Languages.JA]: '従業員数の推移',
          },
          gridColSpan: 3,
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-headcount-by-employment-type',
          title: {
            [Languages.EN]: 'Employment Type',
            [Languages.JA]: '雇用形態',
          },
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-headcount-factbox',
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount },
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount },
                  ],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-headcount-by-year',
          title: {
            [Languages.EN]: 'Headcount by Year',
            [Languages.JA]: '年別従業員数',
          },
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: date(now()).year() - 14, end: date(now()).year() },
              },
            },
          ],
        },
      ],
    },
    {
      pageId: '7',
      dashboardId: 'themis',
      pageNumber: 7,
      enabled: true,
      title: {
        [Languages.EN]: 'People Snapshot: Demographics(Headcount)',
        [Languages.JA]: '人口統計(従業員数)',
      },
      pageOptions,
      gridCols: 6,
      gridRows: 4,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: {
            [Languages.EN]: 'Organization',
            [Languages.JA]: '組織',
          },
          gridColStart: 1,
          gridColEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-location',
          title: {
            [Languages.EN]: 'Location',
            [Languages.JA]: '場所',
          },
          gridColStart: 3,
          gridColEnd: 6,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-tenure',
          title: {
            [Languages.EN]: 'Tenure',
            [Languages.JA]: '在職期間',
          },
          gridColStart: 1,
          gridColEnd: 2,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-gender',
          title: {
            [Languages.EN]: 'Gender',
            [Languages.JA]: '性別',
          },
          gridColStart: 2,
          gridColEnd: 3,
          gridRowStart: 3,
          gridRowEnd: 5,
          colors: ['#7245b2', '#25b1e4'],
          chartTypeConfig: { chartType: 'pie' },
          query: {
            metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
            timeSelection: {
              type: TimeSelectionType.CalendarYearSingleValueByMonths,
              input: { start: nowEndOfMonth, end: nowEndOfMonth },
            },
            segmentations: [
              {
                type: 'non-hierarchical',
                dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
              },
            ],
          },
        },
        {
          chartId: 'people-snapshot-demographic-by-job-grade',
          title: {
            [Languages.EN]: 'Job Grade',
            [Languages.JA]: '職級',
          },
          gridColStart: 3,
          gridColEnd: 4,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-employment-type',
          title: {
            [Languages.EN]: 'Employment Type',
            [Languages.JA]: '雇用形態',
          },
          gridColStart: 4,
          gridColEnd: 5,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-age-group',
          title: {
            [Languages.EN]: 'Age Group',
            [Languages.JA]: '年齢層',
          },
          gridColStart: 5,
          gridColEnd: 6,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-hc',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 1,
          gridRowEnd: 2,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-tenure',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 2,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0023_2TenureAvg }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-gender-ratio',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 3,
          gridRowEnd: 4,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                  segmentations: [
                    {
                      type: 'non-hierarchical',
                      dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-age',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 4,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '8',
      dashboardId: 'themis',
      pageNumber: 8,
      enabled: true,
      title: {
        [Languages.EN]: 'People Snapshot with Time slider',
        [Languages.JA]: '時間スライダー付きの人口統計',
      },
      pageOptions: { ...pageOptions, timeSlider: { enabled: true } },
      gridCols: 6,
      gridRows: 4,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: {
            [Languages.EN]: 'Organization',
            [Languages.JA]: '組織',
          },
          gridColStart: 1,
          gridColEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-location',
          title: {
            [Languages.EN]: 'Location',
            [Languages.JA]: '場所',
          },
          gridColStart: 3,
          gridColEnd: 6,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-tenure',
          title: {
            [Languages.EN]: 'Tenure',
            [Languages.JA]: '在職期間',
          },
          gridColStart: 1,
          gridColEnd: 2,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-gender',
          title: {
            [Languages.EN]: 'Gender',
            [Languages.JA]: '性別',
          },
          gridColStart: 2,
          gridColEnd: 3,
          gridRowStart: 3,
          gridRowEnd: 5,
          colors: ['#7245b2', '#25b1e4'],
          chartTypeConfig: { chartType: 'pie' },
          query: {
            metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
            timeSelection: {
              type: TimeSelectionType.CalendarYearSingleValueByMonths,
              input: { start: nowEndOfMonth, end: nowEndOfMonth },
            },
            segmentations: [
              {
                type: 'non-hierarchical',
                dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
              },
            ],
          },
        },
        {
          chartId: 'people-snapshot-demographic-by-job-grade',
          title: {
            [Languages.EN]: 'Job Grade',
            [Languages.JA]: '職級',
          },
          gridColStart: 3,
          gridColEnd: 4,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-employment-type',
          title: {
            [Languages.EN]: 'Employment Type',
            [Languages.JA]: '雇用形態',
          },
          gridColStart: 4,
          gridColEnd: 5,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-age-group',
          title: {
            [Languages.EN]: 'Age Group',
            [Languages.JA]: '年齢層',
          },
          gridColStart: 5,
          gridColEnd: 6,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-hc',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 1,
          gridRowEnd: 2,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-tenure',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 2,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0023_2TenureAvg }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-gender-ratio',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 3,
          gridRowEnd: 4,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                  segmentations: [
                    {
                      type: 'non-hierarchical',
                      dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-age',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 4,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              key: '1',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: nowEndOfMonth, end: nowEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '9',
      dashboardId: 'themis',
      pageNumber: 9,
      enabled: true,
      title: {
        [Languages.EN]: 'G3 Page',
        [Languages.JA]: 'G3 ページ',
      },
      pageOptions: {
        dataView: {
          enabled: true,
        },
        timeSlider: { enabled: false },
      },
      gridCols: 6,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: {
            [Languages.EN]: 'Individual Count',
            [Languages.JA]: '従業員数',
          },
          gridColSpan: 2,
          gridRowSpan: 2,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearYearly,
                input: { start: 2023, end: 2024 },
              },
            },
          ],
        },
        {
          chartId: 'joiners-this-month',
          title: {
            [Languages.EN]: 'Joiners & Leavers',
            [Languages.JA]: '入社者と退職者',
          },
          gridColSpan: 2,
          gridRowSpan: 2,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2024-06-30', end: '2024-06-30' },
              },
            },
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2024-06-30', end: '2024-06-30' },
              },
              filters: `EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = ('自己都合')`,
            },
          ],
        },
      ],
    },
  ],
};
