import { Grid, useTheme } from '@mui/material';
import React, { Suspense, useState } from 'react';
import { FullScreenHandle, useFullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';
import {
  useDisplayGlobalContext,
  useGlobalFilterContext,
  useGlobalSegmentationLevel1Context,
  useGlobalTimeSliderContext,
} from '../../../context/contexts';
import { GlobalDisplayState } from '../../../context/types';
import { FullHeightFullScreen } from '../../styles';
import { ManualDataRefetchCountHandle } from '../../types';
import { ChartGridConfig, DashboardPageOptions } from '../dashboards/types';
import { Filter, Segment } from '../filter/filterbar/types';
import { ItemsState } from '../filter/types';
import { TimeSliderState } from '../timeslider/types';
import { ChartErrorBoundary } from './ChartErrorBoundary';
import { Skeleton } from './Skeleton';

interface ChartWrapperProps {
  children: (props: {
    showTools: boolean;
    fullScreenHandle: FullScreenHandle;
    timeSliderState: TimeSliderState | null;
    filtersState: ItemsState<Filter>;
    segmentationState: ItemsState<Segment>;
    displayGlobalState: GlobalDisplayState;
  }) => React.ReactNode;
  manualDataRefetchCountHandle: ManualDataRefetchCountHandle;
  config: ChartGridConfig;
  pageOptions: DashboardPageOptions;
}

const StyledChartWrapper = styled('div')`
  position: relative;
  padding: 0.25rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ChartWrapper = (props: ChartWrapperProps) => {
  const { children, manualDataRefetchCountHandle, config, pageOptions } = props;
  const { filterTray, timeSlider, segmentationLevel1 } = pageOptions;
  const fullScreenHandle = useFullScreenHandle();
  const timeSliderHandle = useGlobalTimeSliderContext();
  const timeSliderEnabled = timeSlider?.enabled ?? true;
  const filterTrayEnabled = filterTray?.enabled ?? true;
  const segmentationLevel1Enabled = segmentationLevel1?.enabled ?? true;
  const [timeSliderState] = timeSliderHandle;
  const finalTimeSliderState = timeSliderEnabled ? timeSliderState : null;
  const globalFilterHandle = useGlobalFilterContext();
  const [filtersState] = globalFilterHandle;
  const finalFiltersState = filterTrayEnabled ? filtersState : { ...filtersState, items: [] };
  const globalSegmentationHandle = useGlobalSegmentationLevel1Context();
  const [segmentationState] = globalSegmentationHandle;
  const finalSegmentationState = segmentationLevel1Enabled ? segmentationState : { ...segmentationState, items: [] };
  const displayGlobalHandle = useDisplayGlobalContext();
  const [displayGlobalState] = displayGlobalHandle;
  const [showTools, setShowTools] = useState(false);
  const theme = useTheme();
  // if start or end present, don't use span
  const gridColumn = config.gridColStart || config.gridColEnd ? undefined : `span ${config.gridColSpan ?? 1}`;
  const gridRow = config.gridRowStart || config.gridRowEnd ? undefined : `span ${config.gridRowSpan ?? 1}`;
  return (
    <ChartErrorBoundary manualDataRefetchCountHandle={manualDataRefetchCountHandle}>
      <Suspense fallback={<Skeleton />}>
        <Grid
          item
          sx={{
            position: 'relative', // Equivalent to relative
            gridColumnStart: config.gridColStart ?? 'auto',
            gridColumnEnd: config.gridColEnd ?? 'auto',
            gridRowStart: config.gridRowStart ?? 'auto',
            gridRowEnd: config.gridRowEnd ?? 'auto',
            gridColumn,
            gridRow,
            height: '100%',
            [theme.breakpoints.down('lg')]: {
              height: '40vh',
              gridColumn: 'span 1',
              gridRow: 'span 1',
              gridColumnStart: 'auto',
              gridColumnEnd: 'auto',
              gridRowStart: 'auto',
              gridRowEnd: 'auto',
            },
          }}
        >
          <FullHeightFullScreen handle={fullScreenHandle} $fullScreenActive={fullScreenHandle.active}>
            <StyledChartWrapper onMouseOver={() => setShowTools(true)} onMouseOut={() => setShowTools(false)}>
              {children({
                showTools,
                fullScreenHandle,
                timeSliderState: finalTimeSliderState,
                filtersState: finalFiltersState,
                segmentationState: finalSegmentationState,
                displayGlobalState,
              })}
            </StyledChartWrapper>
          </FullHeightFullScreen>
        </Grid>
      </Suspense>
    </ChartErrorBoundary>
  );
};
