import { Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { MetricIdType } from '../../../types';
import { StyledInfoIcon } from '../../styles';
import { lightTeal } from '../../theme';

interface MetricQueryTooltipProps {
  metrics: MetricIdType[];
  metricsSql: (string | null)[];
}

const StyledPopper = styled(Popper)({
  backgroundColor: lightTeal,
  padding: '8px',
  borderRadius: '5px',
  color: 'white',
  maxWidth: '600px',
  maxHeight: '600px',
  overflow: 'auto',
  zIndex: 2147483647,
});

const Title = styled('h4')({
  fontSize: '1rem',
  color: 'white',
  margin: '0 0 4px 0',
});

const Divider = styled('hr')({
  margin: '0 0 4px 0',
  opacity: 0.3,
});

const MetricIds = styled('h6')({
  fontSize: '0.875rem',
  color: 'white',
  margin: '4px 0',
});

const SqlLabel = styled('p')({
  fontSize: '0.875rem',
  color: 'white',
  margin: '4px 0',
  '& strong': {
    fontStyle: 'italic',
  },
});

const SqlContent = styled('p')({
  fontSize: '0.875rem',
  color: 'white',
  margin: '4px 0',
});

export const MetricQueryTooltip = (props: MetricQueryTooltipProps) => {
  const { metrics, metricsSql } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const checkFullscreen = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', checkFullscreen);
    return () => document.removeEventListener('fullscreenchange', checkFullscreen);
  }, []);

  const handleHover = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (freeze) return;
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClick = () => {
    setFreeze(!freeze);
  };

  const popperContent = (
    <>
      <Title>Query Metadata</Title>
      <Divider />
      <MetricIds>{`Metric IDs: ${metrics.map((m) => m.value).join(', ')}`}</MetricIds>
      <SqlLabel>
        <strong>SQL</strong>
      </SqlLabel>
      <SqlContent>
        {metricsSql.length
          ? metricsSql.map((m, i) => (
              <div key={i}>
                {m}
                <br />
              </div>
            ))
          : 'No metric SQL available'}
      </SqlContent>
    </>
  );

  return (
    <>
      <span
        className="inline-flex justify-end align-middle"
        onMouseEnter={handleHover}
        onMouseLeave={() => (freeze ? null : setOpen(false))}
        onClick={handleClick}
      >
        <StyledInfoIcon />
      </span>
      <StyledPopper
        placement="bottom-start"
        open={open}
        anchorEl={anchorEl}
        container={isFullscreen ? document.fullscreenElement : undefined}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window',
              altAxis: true,
              padding: 8,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {popperContent}
      </StyledPopper>
    </>
  );
};
