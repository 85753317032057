import {
  AxisConfig,
  BarSeriesType,
  ChartsXAxisProps,
  ChartsYAxisProps,
  LineSeriesType,
  ScaleName,
} from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/internals';
import isEqual from 'lodash.isequal';
import uniqBy from 'lodash.uniqby';
import { match, P } from 'ts-pattern';
import { timeRangeTypeToString } from '../../../../api/timeranges/utils';
import {
  ApiOverTimeTimeSegmentType,
  CohortMetricResult,
  EmployeeCohortMetricResult,
  MetricResult,
  RegularMetricResult,
  Segment,
} from '../../../../api/types';
import { compareApiOverTimeTimeSegmentType } from '../../../../api/utils';
import {
  CohortMetricIdType,
  EmployeeCohortMetricIdType,
  MetricIdType,
  Monoid,
  OverTimeTimeSelection,
  RegularMetricIdType,
  SingleValueTimeSelection,
  TimeSelectionType,
} from '../../../../types';
import { date } from '../../../../utils-date';
import { benchmarkColor } from '../../../theme';
import { removeDuplicates } from '../../../utils';
import { LineChartQueryConfig } from '../../dashboards/types';
import {
  NonSegmentedTableOverTimeData,
  SegmentedByDataFieldHeaderData,
  SegmentedOverTimeTableData,
  TableCellMetricData,
  TableRowOverTimeData,
} from '../../tableview/types';
import { Granularity, TimeSliderState } from '../../timeslider/types';
import {
  MuiOverTimeChartData,
  PartialNonSegmentedOverTimeChartInput,
  PartialNonSegmentedSeries,
  PartialSegmentedOverTimeChartInput,
  PartialSegmentedSeries,
} from '../mui-charts/types';
import { chartStyles } from './styles';

export const toPartialNonSegmentedChartDataOverTimeRegular = (
  processedResult: PartialNonSegmentedOverTimeChartInput,
  result: RegularMetricResult
): PartialNonSegmentedOverTimeChartInput => {
  const response = result.segments.reduce<PartialNonSegmentedOverTimeChartInput>(
    (input: PartialNonSegmentedOverTimeChartInput, segment: Segment) => {
      const timeSegment = segment.timeSegment as ApiOverTimeTimeSegmentType;
      const existingTimeSegmentIndex = input.xAxisData.findIndex((el) =>
        compareApiOverTimeTimeSegmentType(el, timeSegment)
      );

      const label = [result.metricId];
      const existingSeriesIndex = input.series.findIndex((s) => isEqual(s.label, label));
      const newValue = segment.groupSegments[0].data as number;

      const series: PartialNonSegmentedSeries = {
        label,
        metricId: { type: 'RegularMetricId', value: result.metricId },
        values: [{ timeSegment, value: newValue }],
        meta: result.meta,
      };

      return {
        ...input,
        xAxisData: existingTimeSegmentIndex === -1 ? [...input.xAxisData, timeSegment] : input.xAxisData,
        series:
          existingSeriesIndex === -1
            ? [...input.series, series]
            : input.series.map((s, i) =>
                existingSeriesIndex === i ? { ...s, values: [...s.values, { timeSegment, value: newValue }] } : s
              ),
      };
    },
    processedResult
  );
  return response;
};

export const toPartialNonSegmentedChartDataOverTimeCohort = (
  processedResult: PartialNonSegmentedOverTimeChartInput,
  result: CohortMetricResult
): PartialNonSegmentedOverTimeChartInput => {
  const response = result.segments.reduce<PartialNonSegmentedOverTimeChartInput>(
    (input: PartialNonSegmentedOverTimeChartInput, segment: Segment) => {
      const timeSegment = segment.timeSegment as ApiOverTimeTimeSegmentType;
      const existingTimeSegmentIndex = input.xAxisData.findIndex((el) =>
        compareApiOverTimeTimeSegmentType(el, timeSegment)
      );

      const label = [result.metricId];
      const existingSeriesIndex = input.series.findIndex((s) => isEqual(s.label, label));
      const newValue = segment.groupSegments[0].data as number;

      const series: PartialNonSegmentedSeries = {
        label,
        metricId: { type: 'CohortMetricId', value: result.metricId },
        values: [{ timeSegment, value: newValue }],
        meta: result.meta,
      };

      return {
        ...input,
        xAxisData: existingTimeSegmentIndex === -1 ? [...input.xAxisData, timeSegment] : input.xAxisData,
        series:
          existingSeriesIndex === -1
            ? [...input.series, series]
            : input.series.map((s, i) =>
                existingSeriesIndex === i ? { ...s, values: [...s.values, { timeSegment, value: newValue }] } : s
              ),
      };
    },
    processedResult
  );
  return response;
};

const convertEmployeeCohortJsonResponseDataToSeriesValues = (
  result: EmployeeCohortMetricResult
): { timeSegment: ApiOverTimeTimeSegmentType; value: number | null }[] => {
  // Note: This logic is based on how headcount simulation works. Might need to change
  // or be refactored once we have more employeeCohortMetrics.
  // Doing segments.last below since I care about the latest version only. Ideally only one
  // segment should exist as only one version should be specified in the query.
  const overtimeEmpCohortData = (result.segments.last()?.groupSegments[0].data ?? {}) as unknown as Record<
    string,
    number
  >;
  const overtimeEmpCohortDataEntries = Object.entries(overtimeEmpCohortData).filter(([year, val]) => val !== null);
  const seriesVals = overtimeEmpCohortDataEntries.map(([year, val]) => {
    const timeSegment: ApiOverTimeTimeSegmentType = { __typename: 'FinancialYearYearlySegment', year: Number(year) };
    return { timeSegment, value: val };
  });
  return seriesVals;
};

export const toPartialNonSegmentedChartDataOverTimeEmployeeCohort = (
  processedResult: PartialNonSegmentedOverTimeChartInput,
  result: EmployeeCohortMetricResult
): PartialNonSegmentedOverTimeChartInput => {
  // I don't understand the use of processedResult here properly.
  // Might change how this is working.
  // fix type here
  const label = [result.metricId];
  const seriesVals = convertEmployeeCohortJsonResponseDataToSeriesValues(result);
  const series = [
    {
      label,
      metricId: { type: 'EmployeeCohortMetricId', value: result.metricId } as EmployeeCohortMetricIdType,
      meta: result.meta,
      values: seriesVals,
    },
  ];
  const xAxisData: ApiOverTimeTimeSegmentType[] = seriesVals.map((s) => {
    return s.timeSegment;
  });
  return {
    type: 'NonSegmentedSeries',
    series,
    xAxisData,
  };
};

export const toPartialNonSegmentedChartDataOverTime = (
  processedResult: PartialNonSegmentedOverTimeChartInput,
  result: MetricResult
): PartialNonSegmentedOverTimeChartInput => {
  return match(result)
    .with({ type: 'RegularMetricResult' }, (res) => toPartialNonSegmentedChartDataOverTimeRegular(processedResult, res))
    .with({ type: 'EmployeeCohortMetricResult' }, (res) => {
      return toPartialNonSegmentedChartDataOverTimeEmployeeCohort(processedResult, res);
    })
    .with({ type: 'CohortMetricResult' }, (res) => {
      return toPartialNonSegmentedChartDataOverTimeCohort(processedResult, res);
    })
    .otherwise(() => {
      throw new Error(`Unsupported metric result type ${result.type}`);
    });
};

export const toPartialSegmentedChartDataOverTimeRegular = (
  processedResult: PartialSegmentedOverTimeChartInput,
  result: RegularMetricResult
): PartialSegmentedOverTimeChartInput => {
  return result.segments.reduce<PartialSegmentedOverTimeChartInput>(
    (input: PartialSegmentedOverTimeChartInput, segment: Segment) => {
      const timeSegment = segment.timeSegment as ApiOverTimeTimeSegmentType;
      const series: PartialSegmentedSeries[] = segment.groupSegments.reduce<PartialSegmentedSeries[]>((acc, gs) => {
        const firstGroupSegment = gs.groupSegment[0];
        if (!firstGroupSegment) {
          return acc;
        }
        const dataField = { dataType: firstGroupSegment.dataType, dataField: firstGroupSegment.dataField };
        const label = gs.groupSegment.map((gss) => gss.value);
        const newValue = gs.data;
        const existingIndex = acc.findIndex((s) => isEqual(s.label, label));
        return existingIndex === -1
          ? [
              ...acc,
              {
                values: [{ timeSegment, value: newValue }],
                label: label as (string | null)[],
                metricId: { type: 'RegularMetricId', value: result.metricId },
                meta: result.meta,
                dataField,
              },
            ]
          : acc.map((v, i) =>
              existingIndex === i ? { ...v, values: [...v.values, { timeSegment, value: newValue }] } : v
            );
      }, input.series);

      return {
        ...input,
        xAxisData: [...input.xAxisData, timeSegment],
        series,
      };
    },
    processedResult
  );
};

export const toPartialSegmentedChartDataOverTimeCohort = (
  processedResult: PartialSegmentedOverTimeChartInput,
  result: CohortMetricResult
): PartialSegmentedOverTimeChartInput => {
  return result.segments.reduce<PartialSegmentedOverTimeChartInput>(
    (input: PartialSegmentedOverTimeChartInput, segment: Segment) => {
      const timeSegment = segment.timeSegment as ApiOverTimeTimeSegmentType;
      const series: PartialSegmentedSeries[] = segment.groupSegments.reduce<PartialSegmentedSeries[]>((acc, gs) => {
        const firstGroupSegment = gs.groupSegment[0];
        if (!firstGroupSegment) {
          return acc;
        }
        const dataField = { dataType: firstGroupSegment.dataType, dataField: firstGroupSegment.dataField };
        const label = gs.groupSegment.map((gss) => Object.values(gss.value as Record<string, string>)[0]);
        const newValue = gs.data as number | null;
        const existingIndex = acc.findIndex((s) => isEqual(s.label, label));
        return existingIndex === -1
          ? [
              ...acc,
              {
                values: [{ timeSegment, value: newValue }],
                label,
                metricId: { type: 'CohortMetricId', value: result.metricId },
                meta: result.meta,
                dataField,
              },
            ]
          : acc.map((v, i) =>
              existingIndex === i ? { ...v, values: [...v.values, { timeSegment, value: newValue }] } : v
            );
      }, input.series);

      return {
        ...input,
        xAxisData: [...input.xAxisData, timeSegment],
        series,
      };
    },
    processedResult
  );
};

export const toPartialSegmentedChartDataOverTime = (
  processedResult: PartialSegmentedOverTimeChartInput,
  result: MetricResult
): PartialSegmentedOverTimeChartInput => {
  return match(result)
    .with({ type: 'RegularMetricResult' }, (res) => toPartialSegmentedChartDataOverTimeRegular(processedResult, res))
    .with({ type: 'CohortMetricResult' }, (res) => toPartialSegmentedChartDataOverTimeCohort(processedResult, res))
    .otherwise(() => {
      throw new Error('Unsupported result type');
    });
};

export const toSegmentedTableViewDataOverTime = (
  resultOrErrors: Array<MetricResult | Error>
): SegmentedOverTimeTableData<MetricIdType> => {
  return resultOrErrors.reduce<SegmentedOverTimeTableData<MetricIdType>>(
    (acc: SegmentedOverTimeTableData<MetricIdType>, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          const result: SegmentedOverTimeTableData<RegularMetricIdType> = res.segments.reduce<
            SegmentedOverTimeTableData<RegularMetricIdType>
          >(
            (tableData, segment) => {
              const headerAndCells = segment.groupSegments.flatMap((groupSegment) => {
                const firstSegment = groupSegment.groupSegment[0];
                if (!firstSegment) {
                  return [];
                }
                const dataField = { dataType: firstSegment.dataType, dataField: firstSegment.dataField };
                const header: SegmentedByDataFieldHeaderData<RegularMetricIdType> = {
                  type: 'segmented-by-datafield',
                  value: { value: groupSegment.groupSegment.map((gs) => gs.value), dataField },
                  metricId: { type: 'RegularMetricId', value: res.metricId },
                };
                const tableCellData: TableCellMetricData<RegularMetricIdType> = {
                  type: 'table-cell-metric',
                  value: groupSegment.data as number | null,
                  metricId: { type: 'RegularMetricId', value: res.metricId },
                  header: header.value.value,
                };
                return [{ header, cell: tableCellData }];
              });
              const row: TableRowOverTimeData<RegularMetricIdType> = {
                title: segment.timeSegment as ApiOverTimeTimeSegmentType,
                cells: headerAndCells.map((h) => h.cell),
              };
              const existingHeaders = tableData.headers.map((h) => h.value);
              const newHeaders: SegmentedByDataFieldHeaderData<RegularMetricIdType>[] = headerAndCells
                .filter((h) => !existingHeaders.find((eh) => isEqual(eh, h.header.value)))
                .map((h) => h.header);
              return {
                headers: [...tableData.headers, ...newHeaders],
                rows: [...tableData.rows, row],
              };
            },
            { headers: [], rows: [] } as SegmentedOverTimeTableData<RegularMetricIdType>
          );
          return { ...acc, headers: [...acc.headers, ...result.headers], rows: [...acc.rows, ...result.rows] };
        })
        .with({ type: 'CohortMetricResult' }, (res: CohortMetricResult) => {
          // Temporary
          const result: SegmentedOverTimeTableData<CohortMetricIdType> = {
            headers: [],
            rows: [],
          };
          return result;
        })
        .with({ type: 'EmployeeCohortMetricResult' }, (res: EmployeeCohortMetricResult) => {
          throw new Error('Unsupported result type');
        })
        .otherwise(() => {
          throw new Error('Unsupported result type');
        });
    },
    { headers: [{ type: 'blank', value: '' }], rows: [] }
  );
};

export const toNonSegmentedTableViewDataOverTime = (
  resultOrErrors: Array<MetricResult | Error>
): NonSegmentedTableOverTimeData<MetricIdType> => {
  return resultOrErrors.reduce<NonSegmentedTableOverTimeData<MetricIdType>>(
    (acc: NonSegmentedTableOverTimeData<MetricIdType>, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          const result: NonSegmentedTableOverTimeData<RegularMetricIdType> = {
            headers: [
              {
                type: 'non-segmented',
                value: { type: 'RegularMetricId', value: res.metricId },
                metricId: { type: 'RegularMetricId', value: res.metricId },
              },
            ],
            rows: res.segments.map((segment) => {
              const cells: TableCellMetricData<RegularMetricIdType>[] = segment.groupSegments.map((groupSegment) => {
                return {
                  type: 'table-cell-metric',
                  value: groupSegment.data as number | null,
                  header: [res.metricId],
                  metricId: { type: 'RegularMetricId', value: res.metricId },
                };
              });
              return { title: segment.timeSegment as ApiOverTimeTimeSegmentType, cells };
            }),
          };
          const rows = result.rows.map((newRow) => {
            const existingRow = acc.rows.find((r) => isEqual(r.title, newRow.title));
            if (existingRow) {
              return {
                title: existingRow.title,
                cells: [...existingRow.cells, ...newRow.cells],
              };
            } else {
              return newRow;
            }
          });
          return { ...acc, headers: [...acc.headers, ...result.headers], rows };
        })
        .with({ type: 'CohortMetricResult' }, (res: CohortMetricResult) => {
          const result: NonSegmentedTableOverTimeData<CohortMetricIdType> = {
            headers: [
              {
                type: 'non-segmented',
                value: { type: 'CohortMetricId', value: res.metricId },
                metricId: { type: 'CohortMetricId', value: res.metricId },
              },
            ],
            rows: res.segments.map((segment) => {
              const cells: TableCellMetricData<CohortMetricIdType>[] = segment.groupSegments.map((groupSegment) => {
                return {
                  type: 'table-cell-metric',
                  value: groupSegment.data as number | null,
                  header: [res.metricId],
                  metricId: { type: 'CohortMetricId', value: res.metricId },
                };
              });
              return { title: segment.timeSegment as ApiOverTimeTimeSegmentType, cells };
            }),
          };
          const rows = result.rows.map((newRow) => {
            const existingRow = acc.rows.find((r) => isEqual(r.title, newRow.title));
            if (existingRow) {
              return {
                title: existingRow.title,
                cells: [...existingRow.cells, ...newRow.cells],
              };
            } else {
              return newRow;
            }
          });
          return { ...acc, headers: [...acc.headers, ...result.headers], rows };
        })
        .with({ type: 'EmployeeCohortMetricResult' }, (res: EmployeeCohortMetricResult) => {
          const seriesVals = convertEmployeeCohortJsonResponseDataToSeriesValues(res);
          const result: NonSegmentedTableOverTimeData<EmployeeCohortMetricIdType> = {
            headers: [
              {
                type: 'non-segmented',
                value: { type: 'EmployeeCohortMetricId', value: res.metricId },
                metricId: { type: 'EmployeeCohortMetricId', value: res.metricId },
              },
            ],
            rows: seriesVals.map(({ timeSegment, value }) => {
              const cells: TableCellMetricData<EmployeeCohortMetricIdType>[] = [
                {
                  type: 'table-cell-metric',
                  value,
                  header: [res.metricId],
                  metricId: { type: 'EmployeeCohortMetricId', value: res.metricId },
                },
              ];
              return { title: timeSegment, cells };
            }),
          };
          const rows = result.rows.map((newRow) => {
            const existingRow = acc.rows.find((r) => isEqual(r.title, newRow.title));
            if (existingRow) {
              return {
                title: existingRow.title,
                cells: [...existingRow.cells, ...newRow.cells],
              };
            } else {
              return newRow;
            }
          });
          return { ...acc, headers: [...acc.headers, ...result.headers], rows };
        })
        .otherwise(() => {
          throw new Error('Unsupported result type');
        });
    },
    { headers: [{ type: 'blank', value: '' }], rows: [] }
  );
};

export const timeSliderStateToSingleValueTimeSelection = (
  timeSliderState: TimeSliderState
): SingleValueTimeSelection => {
  return match(timeSliderState)
    .with(
      { selectedGranularity: { value: Granularity.MONTH }, start: P.instanceOf(Date), end: P.instanceOf(Date) },
      (): SingleValueTimeSelection => ({
        type: TimeSelectionType.CalendarYearSingleValueByMonths,
        input: {
          start: timeRangeTypeToString(timeSliderState.start),
          end: timeRangeTypeToString(timeSliderState.end),
        },
      })
    )
    .with(
      { selectedGranularity: { value: Granularity.YEAR }, start: P.number, end: P.number },
      (v): SingleValueTimeSelection => ({
        type: TimeSelectionType.CalendarYearSingleValueByYears,
        input: {
          start: v.start,
          end: v.end,
        },
      })
    )
    .with(
      {
        selectedGranularity: { value: Granularity.FINQUARTER },
        start: { year: P.number, quarter: P.number },
        end: { year: P.number, quarter: P.number },
      },
      (v): SingleValueTimeSelection => ({
        type: TimeSelectionType.FinancialYearSingleValueByQuarters,
        input: {
          start: {
            quarterOfYear: v.start.quarter,
            year: v.start.year,
          },
          end: {
            quarterOfYear: v.end.quarter,
            year: v.end.year,
          },
        },
      })
    )
    .with(
      { selectedGranularity: { value: Granularity.FINYEAR }, start: P.number, end: P.number },
      (v): SingleValueTimeSelection => ({
        type: TimeSelectionType.FinancialYearSingleValueByYears,
        input: {
          start: v.start,
          end: v.end,
        },
      })
    )
    .otherwise(() => {
      throw new Error('Unsupported granularity');
    });
};

export const timeSliderStateToOverTimeTimeSelection = (timeSliderState: TimeSliderState): OverTimeTimeSelection => {
  return match(timeSliderState)
    .with(
      { selectedGranularity: { value: Granularity.MONTH }, start: P.instanceOf(Date), end: P.instanceOf(Date) },
      (): OverTimeTimeSelection => ({
        type: TimeSelectionType.CalendarYearMonthly,
        input: {
          start: timeRangeTypeToString(timeSliderState.start),
          end: timeRangeTypeToString(timeSliderState.end),
        },
      })
    )
    .with(
      { selectedGranularity: { value: Granularity.YEAR }, start: P.number, end: P.number },
      (v): OverTimeTimeSelection => ({
        type: TimeSelectionType.CalendarYearYearly,
        input: {
          start: v.start,
          end: v.end,
        },
      })
    )
    .with(
      {
        selectedGranularity: { value: Granularity.FINQUARTER },
        start: { year: P.number, quarter: P.number },
        end: { year: P.number, quarter: P.number },
      },
      (v): OverTimeTimeSelection => ({
        type: TimeSelectionType.FinancialYearQuarterly,
        input: {
          start: {
            quarterOfYear: v.start.quarter,
            year: v.start.year,
          },
          end: {
            quarterOfYear: v.end.quarter,
            year: v.end.year,
          },
        },
      })
    )
    .with(
      { selectedGranularity: { value: Granularity.FINYEAR }, start: P.number, end: P.number },
      (v): OverTimeTimeSelection => ({
        type: TimeSelectionType.FinancialYearYearly,
        input: {
          start: v.start,
          end: v.end,
        },
      })
    )
    .otherwise(() => {
      throw new Error('Unsupported granularity');
    });
};

type ApiTimeSegmentTypeTuple = [ApiOverTimeTimeSegmentType, ApiOverTimeTimeSegmentType];

export const timeSegmentOrder = (ts1: ApiOverTimeTimeSegmentType, ts2: ApiOverTimeTimeSegmentType): number => {
  const tuple: ApiTimeSegmentTypeTuple = [ts1, ts2];
  return match(tuple)
    .with(
      [{ __typename: 'CalendarYearMonthlySegment' }, { __typename: 'CalendarYearMonthlySegment' }],
      ([cyms1, cyms2]) => {
        const cymsd1 = date(cyms1.date);
        const cymsd2 = date(cyms2.date);
        if (cymsd1.isBefore(cymsd2)) {
          return -1;
        } else if (cymsd1.isAfter(cymsd2)) {
          return 1;
        } else {
          return 0;
        }
      }
    )
    .with(
      [{ __typename: 'CalendarYearQuarterlySegment' }, { __typename: 'CalendarYearQuarterlySegment' }],
      ([cyqs1, cyqs2]) => {
        if (cyqs1.quarter.year < cyqs2.quarter.year) {
          return -1;
        } else if (cyqs1.quarter.year > cyqs2.quarter.year) {
          return 1;
        } else if (cyqs1.quarter.quarterOfYear < cyqs2.quarter.quarterOfYear) {
          return -1;
        } else if (cyqs1.quarter.quarterOfYear > cyqs2.quarter.quarterOfYear) {
          return 1;
        } else {
          return 0;
        }
      }
    )
    .with(
      [{ __typename: 'CalendarYearYearlySegment' }, { __typename: 'CalendarYearYearlySegment' }],
      ([cyys1, cyys2]) => {
        if (cyys1.year < cyys2.year) {
          return -1;
        } else if (cyys1.year > cyys2.year) {
          return 1;
        } else {
          return 0;
        }
      }
    )
    .with(
      [{ __typename: 'FinancialYearQuarterlySegment' }, { __typename: 'FinancialYearQuarterlySegment' }],
      ([fyqs1, fyqs2]) => {
        if (fyqs1.quarter.year < fyqs2.quarter.year) {
          return -1;
        } else if (fyqs1.quarter.year > fyqs2.quarter.year) {
          return 1;
        } else if (fyqs1.quarter.quarterOfYear < fyqs2.quarter.quarterOfYear) {
          return -1;
        } else if (fyqs1.quarter.quarterOfYear > fyqs2.quarter.quarterOfYear) {
          return 1;
        } else {
          return 0;
        }
      }
    )
    .with(
      [{ __typename: 'FinancialYearYearlySegment' }, { __typename: 'FinancialYearYearlySegment' }],
      ([fyys1, fyys2]) => {
        if (fyys1.year < fyys2.year) {
          return -1;
        } else if (fyys1.year > fyys2.year) {
          return 1;
        } else {
          return 0;
        }
      }
    )
    .otherwise(() => {
      return 0;
    });
};

export const getYAxisConfig = <T extends number | null>(
  series: MakeOptional<BarSeriesType | LineSeriesType, 'type'>[]
): AxisConfig<ScaleName, T, ChartsYAxisProps>[] => {
  return uniqBy(series, (s) => s.yAxisId).map((s, i) => {
    return {
      id: s.yAxisId ?? 'default',
      position: i % 2 === 0 ? 'left' : 'right',
      min: s.data?.some((d) => (d ? d < 0 : false)) ? undefined : 0, // If there is any negative number in the series, use the default min based on the data. Otherwise, use zero.
    };
  });
};

export const getSeriesStyles = (
  queries: LineChartQueryConfig[],
  series: MakeOptional<LineSeriesType | BarSeriesType, 'type'>[]
) =>
  chartStyles({
    seriesStyling: queries.reduce((acc, q, i) => {
      const seriesId = series[i].id;
      if (seriesId) {
        acc[seriesId] = {
          // TODO: improve handling of benchmark through types
          dottedLine: q.isBenchmark ?? false,
          stroke: q.isBenchmark ? benchmarkColor : undefined,
        };
      }
      return acc;
    }, {} as Record<string, { dottedLine: boolean; stroke: string | undefined }>),
  });

type AxisConfigType = AxisConfig<ScaleName, ApiOverTimeTimeSegmentType, ChartsXAxisProps>;
// Is this the right place for the monoids
// TODO: Table View needs to be combined here. Also other stuff might need to be changed
// once we have decided exactly how different series will need to be combined for different chart configurations
// and with segment etc as well.
// So the actual logic doesn't need to be reviewed too closely.
export class MuiOverTimeChartDataMonoid<T extends LineSeriesType | BarSeriesType>
  implements Monoid<MuiOverTimeChartData<T>>
{
  // @Alex: Why do we need empty?
  public empty = {} as MuiOverTimeChartData<T>;
  public combine = (acc: MuiOverTimeChartData<T>, result: MuiOverTimeChartData<T>): MuiOverTimeChartData<T> => {
    if (Object.keys(acc).length === 0) {
      return result;
    }
    const {
      series: accSeries,
      tableViewData: accTableViewData,
      barLabelConfig: accBarLabelConfig,
      metricSql: accMetricSql,
      xAxisConfig: accXAxisConfig,
      yAxisConfig: accYAxisConfig,
    } = acc;
    const {
      series: resultSeries,
      tableViewData: resultTableViewData,
      barLabelConfig: resultBarLabelConfig,
      metricSql: resultMetricSql,
      xAxisConfig: resultXAxisConfig,
      yAxisConfig: resultYAxisConfig,
    } = result;

    // making sure all xAxisConfig data values typename is the same
    const validate = () => {
      // ensure one and only one xAxis
      const validXAxisConfig =
        accXAxisConfig && accXAxisConfig.length === 1 && resultXAxisConfig && resultXAxisConfig.length === 1;
      if (!validXAxisConfig) {
        throw new Error('only one xAxis config allowed');
      }
    };
    const getKeyFromApiTimeSegmentType = (timeSegment: ApiOverTimeTimeSegmentType) => {
      // Is there a better way?
      return JSON.stringify(timeSegment);
    };

    const getApiSegmentTypeFromKey = (key: string): ApiOverTimeTimeSegmentType => {
      // Is there a better way?
      return JSON.parse(key);
    };

    // Ideally I wanna use dataset which should make it easier to combine results

    const getDataMapFromXAxisConfigAndSeriesData = (xAxisConfig: AxisConfigType, series: MakeOptional<T, 'type'>[]) => {
      // assuming only one xAxisConfig now
      const dataMap = Object.fromEntries(
        (xAxisConfig.data ?? []).map((d, i) => {
          const dataKey = getKeyFromApiTimeSegmentType(d);
          const seriesIdToDataMap: Record<string, number | null> = {};
          series.forEach((s) => {
            const id = s.id as string;
            const val = (s.data ?? [])[i];
            seriesIdToDataMap[id] = val;
          });
          return [dataKey, seriesIdToDataMap];
        })
      );
      return dataMap;
    };

    const getCombinedDataMap = (
      dataMap1: Record<string, Record<string, number | null>>,
      dataMap2: Record<string, Record<string, number | null>>
    ): Record<string, Record<string, number | null>> => {
      const combinedKeys = [...Object.keys(dataMap1), ...Object.keys(dataMap2)];
      const combinedDataMap = Object.fromEntries(
        combinedKeys.map((k) => {
          return [k, { ...dataMap1[k], ...dataMap2[k] }];
        })
      );
      return combinedDataMap;
    };

    const getXAxisConfigAndSeriesDataFromDataMap = (dataMap: Record<string, Record<string, number | null>>) => {
      const xAxisConfigTimeSegments = Object.keys(dataMap).map((d) => getApiSegmentTypeFromKey(d));
      const sortedTimeSegments = xAxisConfigTimeSegments.sort((s1, s2) => timeSegmentOrder(s1, s2));
      const newXAxisConfig: AxisConfigType = {
        // not really combining id and scale type,
        // so I am assuming that these would be the same for both axes
        id: resultXAxisConfig?.[0].id as string,
        scaleType: resultXAxisConfig?.[0].scaleType,
        valueFormatter: resultXAxisConfig?.[0].valueFormatter,
        data: sortedTimeSegments,
      };
      const newSeriesData = sortedTimeSegments.map((timeSegment) => {
        const key = getKeyFromApiTimeSegmentType(timeSegment);
        return dataMap[key];
      });
      // New Series Data looks like [{series1: 4, series2: 5}, {series1: 6, series2: 7}]
      return { newXAxisConfig, newSeriesData };
    };

    validate();

    const accDataMap = getDataMapFromXAxisConfigAndSeriesData(accXAxisConfig?.[0] as AxisConfigType, accSeries);
    const resultDataMap = getDataMapFromXAxisConfigAndSeriesData(
      resultXAxisConfig?.[0] as AxisConfigType,
      resultSeries
    );
    const combinedDataMap = getCombinedDataMap(accDataMap, resultDataMap);

    const { newXAxisConfig, newSeriesData } = getXAxisConfigAndSeriesDataFromDataMap(combinedDataMap);
    const joinedSeries = [...accSeries, ...resultSeries];
    const joinedSeriesWithUpdatedData = joinedSeries.map((s) => {
      return {
        ...s,
        data: newSeriesData.map((d) => d[s.id as string] ?? null),
      };
    });

    const combinedYAxisConfig = removeDuplicates([...(accYAxisConfig ?? []), ...(resultYAxisConfig ?? [])]);
    const combined: MuiOverTimeChartData<T> = {
      series: joinedSeriesWithUpdatedData,
      tableViewData: resultTableViewData,
      barLabelConfig: resultBarLabelConfig,
      metricSql: resultMetricSql,
      xAxisConfig: [newXAxisConfig],
      yAxisConfig: combinedYAxisConfig,
    };

    return combined;
  };
}
