import { DataFieldWithDataType, toDataFieldWithDataType } from '../../../common-types';
import { Dashboards } from '../v2/common/components/dashboards/types';

export const CONFIDENTIAL_VALUE = 'CONFIDENTIAL';

export const CONFIDENTIAL_DISPLAY_KEY = 'common:commonValues.confidential';

export enum ROUTE_URLS {
  // Admin routes
  ALIAS = '/admin/alias',
  DATA_UPLOAD = '/admin/data-upload',
  EDIT_ROLE_PERMISSIONS = '/admin/edit-role-permissions',
  MY_ACCOUNT = '/admin/my-account',
  PERMISSIONS = '/admin/permissions',
  VIEW_ROLE_PERMISSIONS = '/admin/view-role-permissions',

  // Dashboard routes
  ATTRITION = '/dashboard/attrition',
  DIVERSITY_GENDER = '/dashboard/diversity-gender',
  EVALUATION = '/dashboard/evaluation',
  JOINERS_AND_LEAVERS = '/dashboard/joiners-leavers',
  MEDIAN_BASE = '/dashboard/median-base',
  MOVEMENT = '/dashboard/movement',
  PAYROLL = '/dashboard/payroll',
  PEOPLE_BALANCE_SHEET = '/dashboard/people-balance-sheet',
  PEOPLE_SNAPSHOT = '/dashboard/people-snapshot',
  RECRUITMENT = '/dashboard/recruitment',
  RECRUITMENT_BALANCE_SHEET = '/dashboard/recruitment-balance-sheet',
  SURVEY = '/dashboard/survey',
  WORK_HOURS_AND_OT = '/dashboard/work-hours-and-ot',
  HEADCOUNT_SIMULATION = '/dashboard/headcount-simulation',

  // Panalyt superadmin routes
  BACKEND_API = '/panalyt-superadmin/backend-api',
  BATCH_ACTIONS = '/panalyt-superadmin/batch-actions',
  CHAPPI_CONVERSATIONS = '/panalyt-superadmin/chappi-conversations',
  COMPANY_DASHBOARDS = '/panalyt-superadmin/company-dashboards',
  COMPANY_LIST = '/panalyt-superadmin/company-list',
  DOMAIN_PREFERENCES = '/panalyt-superadmin/manage-company/domain-preferences',
  MANAGE_DOMAIN_PREFERENCES = '/panalyt-superadmin/manage-domain-preferences',
  MANAGE_DOMAIN_SETTINGS = '/panalyt-superadmin/manage-domain-settings',
  MANAGE_METRICS = '/panalyt-superadmin/manage-metrics',
  MULTI_DOMAIN_AUTH = '/panalyt-superadmin/multi-domain-auth',

  // Other routes
  FORGOT_PASSWORD = '/forgotpassword',
  LOGIN = '/login',
  LOGIN_ERROR = '/login-error',
  MY_PANALYT = '/my-panalyt',
  NO_ACCOUNT_ERROR = '/no-account-error',
  RESET = '/reset',
  SETUP_ERROR = '/setup-error',
  WRONG_AUTH_METHOD_ERROR = '/wrong-auth-method-error',
  SOMETHING_WENT_WRONG = '/something-went-wrong',
  VERIFY_EMAIL = '/verify-email',
}

export enum AxisTypes {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum Operations {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  COUNT = 'COUNT',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  AVG = 'AVG',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  MAX = 'MAX',
  MIN = 'MIN',
  MEDIAN = 'MEDIAN',
  SUM = 'SUM',
  LIKE = 'LIKE',
}

export type ComparisonOperations =
  | Operations.EQUAL
  | Operations.GREATER_THAN
  | Operations.GREATER_THAN_OR_EQUAL_TO
  | Operations.LESS_THAN
  | Operations.LESS_THAN_OR_EQUAL_TO;

export enum EmploymentTemporality {
  PRESENT = 'PRESENT',
  PAST = 'PAST',
  FUTURE = 'FUTURE',
}

export enum Languages {
  JA = 'ja',
  JP = 'jp', // For backward compatibility with users having the old value in local storage
  EN = 'en',
  ES = 'es',
}

export const RecruitmentDashboards = [
  Dashboards.RECRUITMENT_FUNNEL,
  Dashboards.RECRUITMENT_OVERVIEW,
  Dashboards.RECRUITMENT_BALANCE_SHEET,
];
const EngagementDashboards = [Dashboards.SURVEY];
export const isRecruitmentDashboard = (dashboard?: Dashboards) =>
  dashboard && RecruitmentDashboards.includes(dashboard);
export const isEngagementDashboard = (dashboard?: Dashboards) => dashboard && EngagementDashboards.includes(dashboard);

export enum Charts {
  BY_LOCATION = 'BY_LOCATION',
  BY_FUNCTION = 'BY_FUNCTION',
  BY_POSITION = 'BY_POSITION',
  BY_EMPLOYMENT_TYPE = 'BY_EMPLOYMENT_TYPE',
  BY_JOB_GRADE = 'BY_JOB_GRADE',
  BY_ORGANIZATION = 'BY_ORGANIZATION',
  BY_TENURE = 'BY_TENURE_GROUP',
  BY_LOCAL_OR_NON_LOCAL = 'BY_LOCAL_OR_NON_LOCAL',
  BY_NATIONALITY = 'BY_NATIONALITY_HIERARCHICAL',
  BY_AGE = 'BY_AGE',
  BY_GENDER = 'BY_GENDER',
  OVER_TIME = 'OVER_TIME',
  JOB_GRADE_OVER_TIME = 'JOB_GRADE_OVER_TIME',
  BY_JOB_TITLE = 'BY_JOB_TITLE',
  BY_CUSTOM_FIELD_1 = 'BY_CUSTOM_FIELD_1',
  BY_CUSTOM_FIELD_2 = 'BY_CUSTOM_FIELD_2',
  BY_CUSTOM_FIELD_3 = 'BY_CUSTOM_FIELD_3',
  BY_CUSTOM_FIELD_4 = 'BY_CUSTOM_FIELD_4',
  BY_CUSTOM_FIELD_5 = 'BY_CUSTOM_FIELD_5',
  BY_CUSTOM_FILTER_2 = 'BY_CUSTOM_FILTER_2',
  BY_CUSTOM_FILTER_3 = 'BY_CUSTOM_FILTER_3',
  BY_CUSTOM_FILTER_4 = 'BY_CUSTOM_FILTER_4',
  BY_SOURCE = 'BY_SOURCE',
  BY_RECRUITMENT_CATEGORY_HIERARCHY = 'BY_RECRUITMENT_CATEGORY_HIERARCHY',
}

export enum Factboxes {
  PEOPLE_SNAPSHOT_KPI = 'PEOPLE_SNAPSHOT_KPI',
  MEDIAN_BASE_KPI = 'MEDIAN_BASE_KPI',
  TOTAL_COMP_KPI = 'TOTAL_COMP_KPI',
}

export enum Selectors {
  NO_MOVEMENT_CRITERIA_SELECTOR = 'NO_MOVEMENT_CRITERIA_SELECTOR',
}

export enum ChartTypes {
  BAR = 'bar',
  DOUGHNUT = 'doughnut',
  HORIZONTALBAR = 'horizontalBar',
  LINE = 'line',
}

export enum ChartOrientations {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum OrgMetricsDropdown {
  HEADCOUNT = 'headcount',
  REGULAR = 'regularEmpTypePercentage',
  CONTRACT = 'contractEmpTypePercentage',
  SPAN_OF_CONTROL = 'spanOfControl',
}

export enum ChartStrategy {
  GROUPED = 'grouped',
  BASIC = 'basic',
}

export enum ChartPositions {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export type DEFAULT_OPTION = 'default';
export const DEFAULT_OPTION = 'default' as const;

export type GLOBAL_SETTING = 'global_setting';
export const GLOBAL_SETTING = 'global_setting';

export enum PartnerDomains {
  CREXTA = 'crexta',
}

export enum Domains {
  AU_FINANCIAL_HOLDINGS = 'au_financial_holdings',
  BRIDGESTONE = 'bridgestone',
  CISNE = 'cisne',
  TD_HOLDINGS = 'td_holdings',
  CREATURES = 'creatures',
  IVS2023 = 'ivs2023',
  JT = 'jt',
  JTB = 'jtb',
  J_OILMILLS = 'j_oilmills',
  KHNEOCHEM = 'khneochem',
  LOOOP = 'looop',
  MIXI_COMPANY_WIDE = 'mixi_company_wide',
  MSAD = 'irric',
  NECNSI = 'necnsi',
  NIKKI = 'nikki',
  NSSOL = 'nssol',
  PATAGONIA = 'patagonia',
  PERSOLAPAC = 'persolapac',
  SHIRATORI = 'shiratori',
  SHIZUOKAGAS = 'shizuokagas',
  SWAN = 'swan',
  SWAN_ASIA = 'swan.asia',
  SHIP_HEALTHCARE = 'ship_healthcare',
}

export enum DataTypes {
  EMPLOYEE = 'EMPLOYEE',
  APPLICATION = 'APPLICATION',
  JOB = 'JOB',
  OFFER = 'OFFER',
  RECRUITERJOBS = 'RECRUITERJOBS',
  QUESTIONANSWER = 'QUESTIONANSWER',
  TIMEANDATTENDANCEMONTHLY = 'TIMEANDATTENDANCEMONTHLY',
  PAYROLL = 'PAYROLL',
  EVALUATION = 'EVALUATION',
  JOINERS_VIEW = 'JOINERS_VIEW',
  FINANCIALMETRICS = 'FINANCIALMETRICS',
  INTERVIEW = 'INTERVIEW',
  MANAGERENPS = 'MANAGERENPS',
  COHORT = 'COHORT',
}

export enum CohortDataFields {
  COHORT_VALUE = 'COHORT_VALUE',
}

export enum FinancialDataFields {
  FINANCIALMETRICSID = 'FINANCIALMETRICSID',
  VERSION_ID = 'VERSION_ID',
  REVENUE = 'REVENUE',
  GROSS_PROFIT = 'GROSS_PROFIT',
  OPERATING_PROFIT = 'OPERATING_PROFIT',
  NET_INCOME = 'NET_INCOME',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES = 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES',
  TRAINING_COST = 'TRAINING_COST',
  ORGANIZATION_LEVEL_1 = 'ORGANIZATION_LEVEL_1',
  FUNCTION_LEVEL_1 = 'FUNCTION_LEVEL_1',
  GRANULARITY = 'GRANULARITY',
  COST_CENTER = 'COST_CENTER',
  LABOUR_COST = 'LABOUR_COST',
}

export enum EvaluationDataFields {
  EVALUATION_ID = 'EVALUATION_ID',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  EVALUATION_SCORE = 'EVALUATION_SCORE',
  EVALUATION_CYCLE_TYPE = 'EVALUATION_CYCLE_TYPE',
  EVALUATION_CYCLE_NAME = 'EVALUATION_CYCLE_NAME',
  EVALUATION_CYCLE_VIZ_TYPE = 'EVALUATION_CYCLE_VIZ_TYPE',
  EVALUATION_AS_OF = 'EVALUATION_AS_OF',
  VERSION_ID = 'VERSION_ID',
}

export enum ManagerEnpsDataFields {
  ENPS_SCORE = 'ENPS_SCORE',
  PND_VALUE = 'PND_VALUE',
}

export enum EmployeeDataFields {
  PERMISSIONS = 'PERMISSIONS',
  SUPERIOR_IDS = 'SUPERIOR_IDS',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  EFFECTIVE_MONTH = 'EFFECTIVE_MONTH', // This field doesn't actually exist on the employee table
  START_DATE = 'START_DATE',
  TERM_DATE = 'TERM_DATE',
  START_DATE_NORMALIZED = 'START_DATE_NORMALIZED',
  START_DATE_NORMALIZED_YEAR_MONTH = 'START_DATE_NORMALIZED_YEAR_MONTH',
  TERM_DATE_NORMALIZED = 'TERM_DATE_NORMALIZED',
  TERM_DATE_NORMALIZED_YEAR_MONTH = 'TERM_DATE_NORMALIZED_YEAR_MONTH',
  EFFECTIVE_LEAVER_DATE = 'EFFECTIVE_LEAVER_DATE',
  EFFECTIVE_LEAVER_DATE_NORMALIZED = 'EFFECTIVE_LEAVER_DATE_NORMALIZED',
  DATE_OF_BIRTH_YEAR_MONTH = 'DATE_OF_BIRTH_YEAR_MONTH',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  CALENDAR_QUARTER = 'CALENDAR_QUARTER',
  FINANCIAL_YEAR = 'FINANCIAL_YEAR',
  FINANCIAL_QUARTER = 'FINANCIAL_QUARTER',
  EMPLOYMENT_TEMPORALITY = 'EMPLOYMENT_TEMPORALITY',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  MEMBER_ID = 'MEMBER_ID',
  FULL_NAME = 'FULL_NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  LOCAL_FULL_NAME = 'LOCAL_FULL_NAME',
  PREFERRED_NAME = 'PREFERRED_NAME',
  COMPANY_EMAIL = 'COMPANY_EMAIL',
  GENDER = 'GENDER',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  MANAGER_ID = 'MANAGER_ID',
  MANAGER_EMAIL = 'MANAGER_EMAIL',
  NATIONALITY_HIERARCHICAL = 'NATIONALITY_HIERARCHICAL',
  NATIONALITY_HIERARCHICAL_LEVEL_1 = 'NATIONALITY_HIERARCHICAL_LEVEL_1',
  NATIONALITY_HIERARCHICAL_LEVEL_2 = 'NATIONALITY_HIERARCHICAL_LEVEL_2',
  NATIONALITY_HIERARCHICAL_LEVEL_3 = 'NATIONALITY_HIERARCHICAL_LEVEL_3',
  NATIONALITY_HIERARCHICAL_LEVEL_4 = 'NATIONALITY_HIERARCHICAL_LEVEL_4',
  NATIONALITY_HIERARCHICAL_LEVEL_5 = 'NATIONALITY_HIERARCHICAL_LEVEL_5',
  NATIONALITY_HIERARCHICAL_LEVEL_6 = 'NATIONALITY_HIERARCHICAL_LEVEL_6',
  NATIONALITY_HIERARCHICAL_LEVEL_7 = 'NATIONALITY_HIERARCHICAL_LEVEL_7',
  NATIONALITY_HIERARCHICAL_LEVEL_8 = 'NATIONALITY_HIERARCHICAL_LEVEL_8',
  NATIONALITY_HIERARCHICAL_LEVEL_9 = 'NATIONALITY_HIERARCHICAL_LEVEL_9',
  NATIONALITY_HIERARCHICAL_LEVEL_10 = 'NATIONALITY_HIERARCHICAL_LEVEL_10',
  PERFORMANCE_CYCLE = 'PERFORMANCE_CYCLE',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_LEVEL_1 = 'ORGANIZATION_LEVEL_1',
  ORGANIZATION_LEVEL_2 = 'ORGANIZATION_LEVEL_2',
  ORGANIZATION_LEVEL_3 = 'ORGANIZATION_LEVEL_3',
  ORGANIZATION_LEVEL_4 = 'ORGANIZATION_LEVEL_4',
  ORGANIZATION_LEVEL_5 = 'ORGANIZATION_LEVEL_5',
  ORGANIZATION_LEVEL_6 = 'ORGANIZATION_LEVEL_6',
  ORGANIZATION_LEVEL_7 = 'ORGANIZATION_LEVEL_7',
  ORGANIZATION_LEVEL_8 = 'ORGANIZATION_LEVEL_8',
  ORGANIZATION_LEVEL_9 = 'ORGANIZATION_LEVEL_9',
  ORGANIZATION_LEVEL_10 = 'ORGANIZATION_LEVEL_10',
  PRE_MERGER_ORGANIZATION = 'PRE_MERGER_ORGANIZATION',
  PRE_MERGER_ORGANIZATION_LEVEL_1 = 'PRE_MERGER_ORGANIZATION_LEVEL_1',
  PRE_MERGER_ORGANIZATION_LEVEL_2 = 'PRE_MERGER_ORGANIZATION_LEVEL_2',
  PRE_MERGER_ORGANIZATION_LEVEL_3 = 'PRE_MERGER_ORGANIZATION_LEVEL_3',
  PRE_MERGER_ORGANIZATION_LEVEL_4 = 'PRE_MERGER_ORGANIZATION_LEVEL_4',
  PRE_MERGER_ORGANIZATION_LEVEL_5 = 'PRE_MERGER_ORGANIZATION_LEVEL_5',
  PRE_MERGER_ORGANIZATION_LEVEL_6 = 'PRE_MERGER_ORGANIZATION_LEVEL_6',
  PRE_MERGER_ORGANIZATION_LEVEL_7 = 'PRE_MERGER_ORGANIZATION_LEVEL_7',
  PRE_MERGER_ORGANIZATION_LEVEL_8 = 'PRE_MERGER_ORGANIZATION_LEVEL_8',
  PRE_MERGER_ORGANIZATION_LEVEL_9 = 'PRE_MERGER_ORGANIZATION_LEVEL_9',
  PRE_MERGER_ORGANIZATION_LEVEL_10 = 'PRE_MERGER_ORGANIZATION_LEVEL_10',
  LOCATION = 'LOCATION',
  LOCATION_LEVEL_1 = 'LOCATION_LEVEL_1',
  LOCATION_LEVEL_2 = 'LOCATION_LEVEL_2',
  LOCATION_LEVEL_3 = 'LOCATION_LEVEL_3',
  LOCATION_LEVEL_4 = 'LOCATION_LEVEL_4',
  LOCATION_LEVEL_5 = 'LOCATION_LEVEL_5',
  LOCATION_LEVEL_6 = 'LOCATION_LEVEL_6',
  LOCATION_LEVEL_7 = 'LOCATION_LEVEL_7',
  LOCATION_LEVEL_8 = 'LOCATION_LEVEL_8',
  LOCATION_LEVEL_9 = 'LOCATION_LEVEL_9',
  LOCATION_LEVEL_10 = 'LOCATION_LEVEL_10',
  FUNCTION = 'FUNCTION',
  FUNCTION_LEVEL_1 = 'FUNCTION_LEVEL_1',
  FUNCTION_LEVEL_2 = 'FUNCTION_LEVEL_2',
  FUNCTION_LEVEL_3 = 'FUNCTION_LEVEL_3',
  FUNCTION_LEVEL_4 = 'FUNCTION_LEVEL_4',
  FUNCTION_LEVEL_5 = 'FUNCTION_LEVEL_5',
  FUNCTION_LEVEL_6 = 'FUNCTION_LEVEL_6',
  FUNCTION_LEVEL_7 = 'FUNCTION_LEVEL_7',
  FUNCTION_LEVEL_8 = 'FUNCTION_LEVEL_8',
  FUNCTION_LEVEL_9 = 'FUNCTION_LEVEL_9',
  FUNCTION_LEVEL_10 = 'FUNCTION_LEVEL_10',
  EMPLOYING_ENTITY = 'EMPLOYING_ENTITY',
  REGIONAL_OR_LOCAL = 'REGIONAL_OR_LOCAL',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  EMPLOYMENT_TYPE_LEVEL_1 = 'EMPLOYMENT_TYPE_LEVEL_1',
  EMPLOYMENT_TYPE_LEVEL_2 = 'EMPLOYMENT_TYPE_LEVEL_2',
  EMPLOYMENT_TYPE_LEVEL_3 = 'EMPLOYMENT_TYPE_LEVEL_3',
  EMPLOYMENT_TYPE_LEVEL_4 = 'EMPLOYMENT_TYPE_LEVEL_4',
  EMPLOYMENT_TYPE_LEVEL_5 = 'EMPLOYMENT_TYPE_LEVEL_5',
  EMPLOYMENT_TYPE_LEVEL_6 = 'EMPLOYMENT_TYPE_LEVEL_6',
  EMPLOYMENT_TYPE_LEVEL_7 = 'EMPLOYMENT_TYPE_LEVEL_7',
  EMPLOYMENT_TYPE_LEVEL_8 = 'EMPLOYMENT_TYPE_LEVEL_8',
  EMPLOYMENT_TYPE_LEVEL_9 = 'EMPLOYMENT_TYPE_LEVEL_9',
  EMPLOYMENT_TYPE_LEVEL_10 = 'EMPLOYMENT_TYPE_LEVEL_10',
  INTERNAL_JOB_GRADE = 'INTERNAL_JOB_GRADE',
  JOB_GRADE = 'JOB_GRADE',
  JOB_GRADE_LEVEL_1 = 'JOB_GRADE_LEVEL_1',
  JOB_GRADE_LEVEL_2 = 'JOB_GRADE_LEVEL_2',
  JOB_GRADE_LEVEL_3 = 'JOB_GRADE_LEVEL_3',
  JOB_GRADE_LEVEL_4 = 'JOB_GRADE_LEVEL_4',
  JOB_GRADE_LEVEL_5 = 'JOB_GRADE_LEVEL_5',
  JOB_GRADE_LEVEL_6 = 'JOB_GRADE_LEVEL_6',
  JOB_GRADE_LEVEL_7 = 'JOB_GRADE_LEVEL_7',
  JOB_GRADE_LEVEL_8 = 'JOB_GRADE_LEVEL_8',
  JOB_GRADE_LEVEL_9 = 'JOB_GRADE_LEVEL_9',
  JOB_GRADE_LEVEL_10 = 'JOB_GRADE_LEVEL_10',
  INTERNAL_JOB_TITLE = 'INTERNAL_JOB_TITLE',
  JOB_TITLE = 'JOB_TITLE',
  RECRUITMENT_CATEGORY = 'RECRUITMENT_CATEGORY',
  RECRUITMENT_CATEGORY_HIERARCHICAL = 'RECRUITMENT_CATEGORY_HIERARCHICAL',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_1 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_1',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_2 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_2',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_3 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_3',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_4 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_4',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_5 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_5',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_6 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_6',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_7 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_7',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_8 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_8',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_9 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_9',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_10 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_10',
  WORK_CONSIDERATION = 'WORK_CONSIDERATION',
  SOCIAL_TYPE = 'SOCIAL_TYPE',
  SUPERIOR_ID = 'SUPERIOR_ID',
  CAREER_LADDER = 'CAREER_LADDER',
  ONBOARDING_SURVEY_SCORE = 'ONBOARDING_SURVEY_SCORE',
  ONBOARDING_SURVEY_SCORE_GROUP = 'ONBOARDING_SURVEY_SCORE_GROUP',
  PERFORMANCE_REGULAR_CYCLE = 'PERFORMANCE_REGULAR_CYCLE',
  PERFORMANCE_BONUS_CYCLE = 'PERFORMANCE_BONUS_CYCLE',
  MANAGER_FEEDBACK = 'MANAGER_FEEDBACK',
  TOTAL_AVERAGE_SCORE = 'TOTAL_AVERAGE_SCORE',
  TOTAL_AVERAGE_SCORE_QUARTILE = 'TOTAL_AVERAGE_SCORE_QUARTILE',
  COMMUNICATION_SKILL_SCORE = 'COMMUNICATION_SKILL_SCORE',
  COMMUNICATION_SKILL_SCORE_QUARTILE = 'COMMUNICATION_SKILL_SCORE_QUARTILE',
  GENERAL_COGNITIVE_ABILITY_SCORE = 'GENERAL_COGNITIVE_ABILITY_SCORE',
  GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE = 'GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE',
  ROLE_RELATED_KNOWLEDGE_SCORE = 'ROLE_RELATED_KNOWLEDGE_SCORE',
  ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE = 'ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE',
  REGRET_ATTRITION = 'REGRET_ATTRITION',
  VOLUNTARY_OR_INVOLUNTARY_ATTRITION = 'VOLUNTARY_OR_INVOLUNTARY_ATTRITION',
  PERFORMANCE_RATING = 'PERFORMANCE_RATING',
  LOCAL_OR_NON_LOCAL = 'LOCAL_OR_NON_LOCAL',
  BASE_SALARY_CURRENCY = 'BASE_SALARY_CURRENCY',
  BASE_SALARY = 'BASE_SALARY',
  BASE_SALARY_FREQUENCY = 'BASE_SALARY_FREQUENCY',
  BASE_SALARY_PER_YEAR = 'BASE_SALARY_PER_YEAR',
  REVENUE_SALARY = 'REVENUE_SALARY',
  REVENUE_SALARY_CURRENCY = 'REVENUE_SALARY_CURRENCY',
  LOCAL_REVENUE_SALARY = 'LOCAL_REVENUE_SALARY',
  ICR = 'ICR',
  ANNUAL_CASH_BONUS_1_CURRENCY = 'ANNUAL_CASH_BONUS_1_CURRENCY',
  ANNUAL_CASH_BONUS_1 = 'ANNUAL_CASH_BONUS_1',
  AGE_GROUP = 'AGE_GROUP',
  TENURE_GROUP = 'TENURE_GROUP',
  VERSION_ID = 'VERSION_ID',
  LOCAL_FIRST_NAME = 'LOCAL_FIRST_NAME',
  LOCAL_LAST_NAME = 'LOCAL_LAST_NAME',
  AGE = 'AGE',
  MARITAL_STATUS = 'MARITAL_STATUS',
  COMPANY_COUNTRY = 'COMPANY_COUNTRY',
  STANDARDIZED_BASE_SALARY = 'STANDARDIZED_BASE_SALARY',
  STANDARDIZED_BASE_SALARY_PER_YEAR = 'STANDARDIZED_BASE_SALARY_PER_YEAR',
  LOCAL_SALARY_CURRENCY = 'LOCAL_SALARY_CURRENCY',
  LOCAL_SALARY = 'LOCAL_SALARY',
  COST_CENTER = 'COST_CENTER',
  DAYS_IN_COMPANY = 'DAYS_IN_COMPANY',
  POSITION = 'POSITION',
  POSITION_LEVEL_1 = 'POSITION_LEVEL_1',
  POSITION_LEVEL_2 = 'POSITION_LEVEL_2',
  POSITION_LEVEL_3 = 'POSITION_LEVEL_3',
  POSITION_LEVEL_4 = 'POSITION_LEVEL_4',
  POSITION_LEVEL_5 = 'POSITION_LEVEL_5',
  POSITION_LEVEL_6 = 'POSITION_LEVEL_6',
  POSITION_LEVEL_7 = 'POSITION_LEVEL_7',
  POSITION_LEVEL_8 = 'POSITION_LEVEL_8',
  POSITION_LEVEL_9 = 'POSITION_LEVEL_9',
  POSITION_LEVEL_10 = 'POSITION_LEVEL_10',
  LOCAL_FIRST_NAME_PRONUNCIATION = 'LOCAL_FIRST_NAME_PRONUNCIATION',
  LOCAL_LAST_NAME_PRONUNCIATION = 'LOCAL_LAST_NAME_PRONUNCIATION',
  LOCAL_FULL_NAME_PRONUNCIATION = 'LOCAL_FULL_NAME_PRONUNCIATION',
  LOCAL_FIRST_NAME_PRONUNCIATION_2 = 'LOCAL_FIRST_NAME_PRONUNCIATION_2',
  LOCAL_LAST_NAME_PRONUNCIATION_2 = 'LOCAL_LAST_NAME_PRONUNCIATION_2',
  LOCAL_FULL_NAME_PRONUNCIATION_2 = 'LOCAL_FULL_NAME_PRONUNCIATION_2',
  CUSTOM_FILTER_1 = 'CUSTOM_FILTER_1',
  CUSTOM_FILTER_2 = 'CUSTOM_FILTER_2',
  CUSTOM_FILTER_3 = 'CUSTOM_FILTER_3',
  CUSTOM_FILTER_4 = 'CUSTOM_FILTER_4',
  CUSTOM_FILTER_5 = 'CUSTOM_FILTER_5',
  CUSTOM_FILTER_6 = 'CUSTOM_FILTER_6',
  CUSTOM_FILTER_7 = 'CUSTOM_FILTER_7',
  CUSTOM_FILTER_8 = 'CUSTOM_FILTER_8',
  CUSTOM_FILTER_9 = 'CUSTOM_FILTER_9',
  CUSTOM_FILTER_10 = 'CUSTOM_FILTER_10',
  CUSTOM_FILTER_11 = 'CUSTOM_FILTER_11',
  CUSTOM_FILTER_12 = 'CUSTOM_FILTER_12',
  CUSTOM_FILTER_13 = 'CUSTOM_FILTER_13',
  CUSTOM_FILTER_14 = 'CUSTOM_FILTER_14',
  CUSTOM_FILTER_15 = 'CUSTOM_FILTER_15',
  CUSTOM_FILTER_16 = 'CUSTOM_FILTER_16',
  CUSTOM_FILTER_17 = 'CUSTOM_FILTER_17',
  CUSTOM_FILTER_18 = 'CUSTOM_FILTER_18',
  CUSTOM_FILTER_19 = 'CUSTOM_FILTER_19',
  CUSTOM_FILTER_20 = 'CUSTOM_FILTER_20',
  JOINING_AGE = 'JOINING_AGE',
  JOINING_AGE_GROUP = 'JOINING_AGE_GROUP',
  ATTRITION_SCORE = 'ATTRITION_SCORE',
  CAREER_CHANGE_INTENTION = 'CAREER_CHANGE_INTENTION',
  INTERNAL_TRANSFER_INTENTION = 'INTERNAL_TRANSFER_INTENTION',
  PULSE_SURVEY_CYCLE_1 = 'PULSE_SURVEY_CYCLE_1',
  PULSE_SURVEY_CYCLE_2 = 'PULSE_SURVEY_CYCLE_2',
  PULSE_SURVEY_CYCLE_3 = 'PULSE_SURVEY_CYCLE_3',
  PULSE_SURVEY_SCORE_1 = 'PULSE_SURVEY_SCORE_1',
  PULSE_SURVEY_SCORE_2 = 'PULSE_SURVEY_SCORE_2',
  CLIENT_EFFECTIVE_DATE = 'CLIENT_EFFECTIVE_DATE',
  MANAGER_OR_IC = 'MANAGER_OR_IC',
  CAREER_COURSE = 'CAREER_COURSE',
  TOTAL_COMPENSATION_CURRENCY = 'TOTAL_COMPENSATION_CURRENCY',
  TOTAL_COMPENSATION = 'TOTAL_COMPENSATION',
  PND_VALUE = 'PND_VALUE',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FIELD_1_LEVEL_1 = 'CUSTOM_FIELD_1_LEVEL_1',
  CUSTOM_FIELD_1_LEVEL_2 = 'CUSTOM_FIELD_1_LEVEL_2',
  CUSTOM_FIELD_1_LEVEL_3 = 'CUSTOM_FIELD_1_LEVEL_3',
  CUSTOM_FIELD_1_LEVEL_4 = 'CUSTOM_FIELD_1_LEVEL_4',
  CUSTOM_FIELD_1_LEVEL_5 = 'CUSTOM_FIELD_1_LEVEL_5',
  CUSTOM_FIELD_1_LEVEL_6 = 'CUSTOM_FIELD_1_LEVEL_6',
  CUSTOM_FIELD_1_LEVEL_7 = 'CUSTOM_FIELD_1_LEVEL_7',
  CUSTOM_FIELD_1_LEVEL_8 = 'CUSTOM_FIELD_1_LEVEL_8',
  CUSTOM_FIELD_1_LEVEL_9 = 'CUSTOM_FIELD_1_LEVEL_9',
  CUSTOM_FIELD_1_LEVEL_10 = 'CUSTOM_FIELD_1_LEVEL_10',
  CUSTOM_FIELD_2 = 'CUSTOM_FIELD_2',
  CUSTOM_FIELD_2_LEVEL_1 = 'CUSTOM_FIELD_2_LEVEL_1',
  CUSTOM_FIELD_2_LEVEL_2 = 'CUSTOM_FIELD_2_LEVEL_2',
  CUSTOM_FIELD_2_LEVEL_3 = 'CUSTOM_FIELD_2_LEVEL_3',
  CUSTOM_FIELD_2_LEVEL_4 = 'CUSTOM_FIELD_2_LEVEL_4',
  CUSTOM_FIELD_2_LEVEL_5 = 'CUSTOM_FIELD_2_LEVEL_5',
  CUSTOM_FIELD_2_LEVEL_6 = 'CUSTOM_FIELD_2_LEVEL_6',
  CUSTOM_FIELD_2_LEVEL_7 = 'CUSTOM_FIELD_2_LEVEL_7',
  CUSTOM_FIELD_2_LEVEL_8 = 'CUSTOM_FIELD_2_LEVEL_8',
  CUSTOM_FIELD_2_LEVEL_9 = 'CUSTOM_FIELD_2_LEVEL_9',
  CUSTOM_FIELD_2_LEVEL_10 = 'CUSTOM_FIELD_2_LEVEL_10',
  CUSTOM_FIELD_3 = 'CUSTOM_FIELD_3',
  CUSTOM_FIELD_3_LEVEL_1 = 'CUSTOM_FIELD_3_LEVEL_1',
  CUSTOM_FIELD_3_LEVEL_2 = 'CUSTOM_FIELD_3_LEVEL_2',
  CUSTOM_FIELD_3_LEVEL_3 = 'CUSTOM_FIELD_3_LEVEL_3',
  CUSTOM_FIELD_3_LEVEL_4 = 'CUSTOM_FIELD_3_LEVEL_4',
  CUSTOM_FIELD_3_LEVEL_5 = 'CUSTOM_FIELD_3_LEVEL_5',
  CUSTOM_FIELD_3_LEVEL_6 = 'CUSTOM_FIELD_3_LEVEL_6',
  CUSTOM_FIELD_3_LEVEL_7 = 'CUSTOM_FIELD_3_LEVEL_7',
  CUSTOM_FIELD_3_LEVEL_8 = 'CUSTOM_FIELD_3_LEVEL_8',
  CUSTOM_FIELD_3_LEVEL_9 = 'CUSTOM_FIELD_3_LEVEL_9',
  CUSTOM_FIELD_3_LEVEL_10 = 'CUSTOM_FIELD_3_LEVEL_10',
  CUSTOM_FIELD_4 = 'CUSTOM_FIELD_4',
  CUSTOM_FIELD_4_LEVEL_1 = 'CUSTOM_FIELD_4_LEVEL_1',
  CUSTOM_FIELD_4_LEVEL_2 = 'CUSTOM_FIELD_4_LEVEL_2',
  CUSTOM_FIELD_4_LEVEL_3 = 'CUSTOM_FIELD_4_LEVEL_3',
  CUSTOM_FIELD_4_LEVEL_4 = 'CUSTOM_FIELD_4_LEVEL_4',
  CUSTOM_FIELD_4_LEVEL_5 = 'CUSTOM_FIELD_4_LEVEL_5',
  CUSTOM_FIELD_4_LEVEL_6 = 'CUSTOM_FIELD_4_LEVEL_6',
  CUSTOM_FIELD_4_LEVEL_7 = 'CUSTOM_FIELD_4_LEVEL_7',
  CUSTOM_FIELD_4_LEVEL_8 = 'CUSTOM_FIELD_4_LEVEL_8',
  CUSTOM_FIELD_4_LEVEL_9 = 'CUSTOM_FIELD_4_LEVEL_9',
  CUSTOM_FIELD_4_LEVEL_10 = 'CUSTOM_FIELD_4_LEVEL_10',
  CUSTOM_FIELD_5 = 'CUSTOM_FIELD_5',
  CUSTOM_FIELD_5_LEVEL_1 = 'CUSTOM_FIELD_5_LEVEL_1',
  CUSTOM_FIELD_5_LEVEL_2 = 'CUSTOM_FIELD_5_LEVEL_2',
  CUSTOM_FIELD_5_LEVEL_3 = 'CUSTOM_FIELD_5_LEVEL_3',
  CUSTOM_FIELD_5_LEVEL_4 = 'CUSTOM_FIELD_5_LEVEL_4',
  CUSTOM_FIELD_5_LEVEL_5 = 'CUSTOM_FIELD_5_LEVEL_5',
  CUSTOM_FIELD_5_LEVEL_6 = 'CUSTOM_FIELD_5_LEVEL_6',
  CUSTOM_FIELD_5_LEVEL_7 = 'CUSTOM_FIELD_5_LEVEL_7',
  CUSTOM_FIELD_5_LEVEL_8 = 'CUSTOM_FIELD_5_LEVEL_8',
  CUSTOM_FIELD_5_LEVEL_9 = 'CUSTOM_FIELD_5_LEVEL_9',
  CUSTOM_FIELD_5_LEVEL_10 = 'CUSTOM_FIELD_5_LEVEL_10',
  CUSTOM_FIELD_6 = 'CUSTOM_FIELD_6',
  CUSTOM_FIELD_6_LEVEL_1 = 'CUSTOM_FIELD_6_LEVEL_1',
  CUSTOM_FIELD_6_LEVEL_2 = 'CUSTOM_FIELD_6_LEVEL_2',
  CUSTOM_FIELD_6_LEVEL_3 = 'CUSTOM_FIELD_6_LEVEL_3',
  CUSTOM_FIELD_6_LEVEL_4 = 'CUSTOM_FIELD_6_LEVEL_4',
  CUSTOM_FIELD_6_LEVEL_5 = 'CUSTOM_FIELD_6_LEVEL_5',
  CUSTOM_FIELD_6_LEVEL_6 = 'CUSTOM_FIELD_6_LEVEL_6',
  CUSTOM_FIELD_6_LEVEL_7 = 'CUSTOM_FIELD_6_LEVEL_7',
  CUSTOM_FIELD_6_LEVEL_8 = 'CUSTOM_FIELD_6_LEVEL_8',
  CUSTOM_FIELD_6_LEVEL_9 = 'CUSTOM_FIELD_6_LEVEL_9',
  CUSTOM_FIELD_6_LEVEL_10 = 'CUSTOM_FIELD_6_LEVEL_10',
  CUSTOM_FIELD_7 = 'CUSTOM_FIELD_7',
  CUSTOM_FIELD_7_LEVEL_1 = 'CUSTOM_FIELD_7_LEVEL_1',
  CUSTOM_FIELD_7_LEVEL_2 = 'CUSTOM_FIELD_7_LEVEL_2',
  CUSTOM_FIELD_7_LEVEL_3 = 'CUSTOM_FIELD_7_LEVEL_3',
  CUSTOM_FIELD_7_LEVEL_4 = 'CUSTOM_FIELD_7_LEVEL_4',
  CUSTOM_FIELD_7_LEVEL_5 = 'CUSTOM_FIELD_7_LEVEL_5',
  CUSTOM_FIELD_7_LEVEL_6 = 'CUSTOM_FIELD_7_LEVEL_6',
  CUSTOM_FIELD_7_LEVEL_7 = 'CUSTOM_FIELD_7_LEVEL_7',
  CUSTOM_FIELD_7_LEVEL_8 = 'CUSTOM_FIELD_7_LEVEL_8',
  CUSTOM_FIELD_7_LEVEL_9 = 'CUSTOM_FIELD_7_LEVEL_9',
  CUSTOM_FIELD_7_LEVEL_10 = 'CUSTOM_FIELD_7_LEVEL_10',
  CUSTOM_FIELD_8 = 'CUSTOM_FIELD_8',
  CUSTOM_FIELD_8_LEVEL_1 = 'CUSTOM_FIELD_8_LEVEL_1',
  CUSTOM_FIELD_8_LEVEL_2 = 'CUSTOM_FIELD_8_LEVEL_2',
  CUSTOM_FIELD_8_LEVEL_3 = 'CUSTOM_FIELD_8_LEVEL_3',
  CUSTOM_FIELD_8_LEVEL_4 = 'CUSTOM_FIELD_8_LEVEL_4',
  CUSTOM_FIELD_8_LEVEL_5 = 'CUSTOM_FIELD_8_LEVEL_5',
  CUSTOM_FIELD_8_LEVEL_6 = 'CUSTOM_FIELD_8_LEVEL_6',
  CUSTOM_FIELD_8_LEVEL_7 = 'CUSTOM_FIELD_8_LEVEL_7',
  CUSTOM_FIELD_8_LEVEL_8 = 'CUSTOM_FIELD_8_LEVEL_8',
  CUSTOM_FIELD_8_LEVEL_9 = 'CUSTOM_FIELD_8_LEVEL_9',
  CUSTOM_FIELD_8_LEVEL_10 = 'CUSTOM_FIELD_8_LEVEL_10',
  CUSTOM_FIELD_9 = 'CUSTOM_FIELD_9',
  CUSTOM_FIELD_9_LEVEL_1 = 'CUSTOM_FIELD_9_LEVEL_1',
  CUSTOM_FIELD_9_LEVEL_2 = 'CUSTOM_FIELD_9_LEVEL_2',
  CUSTOM_FIELD_9_LEVEL_3 = 'CUSTOM_FIELD_9_LEVEL_3',
  CUSTOM_FIELD_9_LEVEL_4 = 'CUSTOM_FIELD_9_LEVEL_4',
  CUSTOM_FIELD_9_LEVEL_5 = 'CUSTOM_FIELD_9_LEVEL_5',
  CUSTOM_FIELD_9_LEVEL_6 = 'CUSTOM_FIELD_9_LEVEL_6',
  CUSTOM_FIELD_9_LEVEL_7 = 'CUSTOM_FIELD_9_LEVEL_7',
  CUSTOM_FIELD_9_LEVEL_8 = 'CUSTOM_FIELD_9_LEVEL_8',
  CUSTOM_FIELD_9_LEVEL_9 = 'CUSTOM_FIELD_9_LEVEL_9',
  CUSTOM_FIELD_9_LEVEL_10 = 'CUSTOM_FIELD_9_LEVEL_10',
  CUSTOM_FIELD_10 = 'CUSTOM_FIELD_10',
  CUSTOM_FIELD_10_LEVEL_1 = 'CUSTOM_FIELD_10_LEVEL_1',
  CUSTOM_FIELD_10_LEVEL_2 = 'CUSTOM_FIELD_10_LEVEL_2',
  CUSTOM_FIELD_10_LEVEL_3 = 'CUSTOM_FIELD_10_LEVEL_3',
  CUSTOM_FIELD_10_LEVEL_4 = 'CUSTOM_FIELD_10_LEVEL_4',
  CUSTOM_FIELD_10_LEVEL_5 = 'CUSTOM_FIELD_10_LEVEL_5',
  CUSTOM_FIELD_10_LEVEL_6 = 'CUSTOM_FIELD_10_LEVEL_6',
  CUSTOM_FIELD_10_LEVEL_7 = 'CUSTOM_FIELD_10_LEVEL_7',
  CUSTOM_FIELD_10_LEVEL_8 = 'CUSTOM_FIELD_10_LEVEL_8',
  CUSTOM_FIELD_10_LEVEL_9 = 'CUSTOM_FIELD_10_LEVEL_9',
  CUSTOM_FIELD_10_LEVEL_10 = 'CUSTOM_FIELD_10_LEVEL_10',
  SPAN_OF_CONTROL = 'SPAN_OF_CONTROL',
  DEPTH_OF_CONTROL = 'DEPTH_OF_CONTROL',
  DEFINED_MANAGER = 'DEFINED_MANAGER',
  BASE_BOARD_COMPENSATION = 'BASE_BOARD_COMPENSATION',
  VARIABLE_BOARD_COMPENSATION = 'VARIABLE_BOARD_COMPENSATION',
  DEPARTMENT = 'DEPARTMENT',
  EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH = 'EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH',
  FTE_VALUE = 'FTE_VALUE',
  move_from = 'move_from', //Not really an employee field but the backend returns it with EMPLOYEE datatype
  move_to = 'move_to', //Not really an employee field but the backend returns it with EMPLOYEE datatype
  TENURE_IN_JOBGRADE_LEVEL_1 = 'TENURE_IN_JOBGRADE_LEVEL_1',
  TENURE_IN_JOBGRADE_LEVEL_2 = 'TENURE_IN_JOBGRADE_LEVEL_2',
  TENURE_IN_PREVIOUS_JOBGRADE_LEVEL_1 = 'TENURE_IN_PREVIOUS_JOBGRADE_LEVEL_1',
  TENURE_AS_MANAGER = 'TENURE_AS_MANAGER',
  TENURE_IN_ORG_LEVEL_1 = 'TENURE_IN_ORG_LEVEL_1',
  TENURE_IN_ORG_LEVEL_2 = 'TENURE_IN_ORG_LEVEL_2',
  STATUTORY_ELIGIBILITY_FOR_PARENTAL_LEAVE = 'STATUTORY_ELIGIBILITY_FOR_PARENTAL_LEAVE',
  DISABILITY_SEVERITY = 'DISABILITY_SEVERITY',
  MANAGEMENT_POOL = 'MANAGEMENT_POOL',
  YOUNGEST_CHILD_BIRTHDATE = 'YOUNGEST_CHILD_BIRTHDATE',
  LATEST_PARENTAL_LEAVE_STARTDATE = 'LATEST_PARENTAL_LEAVE_STARTDATE',
  CUSTOM_START_DATE = 'CUSTOM_START_DATE',
  CUSTOM_DAYS_IN_COMPANY = 'CUSTOM_DAYS_IN_COMPANY',
  CUSTOM_TENURE_GROUP = 'CUSTOM_TENURE_GROUP',
  START_CALENDAR_YEAR = 'START_CALENDAR_YEAR',
  START_FINANCIAL_YEAR = 'START_FINANCIAL_YEAR',
  EMPLOYMENT_REGULARITY = 'EMPLOYMENT_REGULARITY',
  DEFINED_MANAGER_2 = 'DEFINED_MANAGER_2',
  NUM_DAYS_SICK_LEAVE_TAKEN = 'NUM_DAYS_SICK_LEAVE_TAKEN',
}

export type LeaverDateField = EmployeeDataFields.TERM_DATE | EmployeeDataFields.EFFECTIVE_LEAVER_DATE;

export type LeaverDateNormalizedField =
  | { dataType: DataTypes.EMPLOYEE; dataField: EmployeeDataFields.TERM_DATE_NORMALIZED }
  | { dataType: DataTypes.EMPLOYEE; dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED };

export enum TimeAndAttendanceMonthlyDataFields {
  VERSION_ID = 'VERSION_ID',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  NUM_DAYS_WORKED = 'NUM_DAYS_WORKED',
  NUM_DAYS_REMOTE = 'NUM_DAYS_REMOTE',
  NUM_WORKING_DAYS = 'NUM_WORKING_DAYS',
  NUM_LEAVES = 'NUM_LEAVES',
  NUM_HOURS_WORKED = 'NUM_HOURS_WORKED',
  NUM_OVERTIME_HOURS = 'NUM_OVERTIME_HOURS',
  NUM_DAYS_IN_OFFICE = 'NUM_DAYS_IN_OFFICE',
  PAID_LEAVES_BALANCE = 'PAID_LEAVES_BALANCE',
  NUM_PAID_LEAVES_GRANTED = 'NUM_PAID_LEAVES_GRANTED',
  NUM_PAID_LEAVES_TAKEN = 'NUM_PAID_LEAVES_TAKEN',
  NUM_ANNUAL_PAID_LEAVES_TAKEN = 'NUM_ANNUAL_PAID_LEAVES_TAKEN',
  NUM_HOURS_WORKING_DAY_OVERTIME = 'NUM_HOURS_WORKING_DAY_OVERTIME',
  NUM_HOURS_WORKED_ON_LEGAL_HOLIDAY = 'NUM_HOURS_WORKED_ON_LEGAL_HOLIDAY',
  NUM_HOURS_WORKED_ON_HOLIDAY = 'NUM_HOURS_WORKED_ON_HOLIDAY',
  NUM_DAYS_ABSENTED = 'NUM_DAYS_ABSENTED',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FIELD_2 = 'CUSTOM_FIELD_2',
  CUSTOM_FIELD_3 = 'CUSTOM_FIELD_3',
  CUSTOM_FIELD_4 = 'CUSTOM_FIELD_4',
  CUSTOM_FIELD_5 = 'CUSTOM_FIELD_5',
  NUM_WORKING_HOURS = 'NUM_WORKING_HOURS',
  NUM_DAILY_WORKING_HOURS = 'NUM_DAILY_WORKING_HOURS',
  NUM_PARENTAL_LEAVES_TAKEN = 'NUM_PARENTAL_LEAVES_TAKEN',
  LATEST_PARENTAL_LEAVE_STARTDATE = 'LATEST_PARENTAL_LEAVE_STARTDATE',
  NON_STATUTORY_OVERTIME = 'NON_STATUTORY_OVERTIME',
}

export enum ComputedEmployeeFields { // which are not a field in the table but can be derived using multiple queries
  MANAGER_NAME = 'MANAGER_NAME',
  DATE = 'DATE',
  TRAIN = 'TRAIN',
  none = 'none',
  MY_POPULATION = 'MY_POPULATION',
  FILTERED_MY_POPULATION = 'FILTERED_MY_POPULATION',
}

export enum JobDataFields {
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  VERSION_ID = 'VERSION_ID',
  JOB_AGE = 'JOB_AGE',
  JOB_ID = 'JOB_ID',
  JOB_NAME = 'JOB_NAME',
  JOB_NAME_LEVEL_1 = 'JOB_NAME_LEVEL_1',
  JOB_NAME_LEVEL_2 = 'JOB_NAME_LEVEL_2',
  JOB_NAME_LEVEL_3 = 'JOB_NAME_LEVEL_3',
  JOB_NAME_LEVEL_4 = 'JOB_NAME_LEVEL_4',
  JOB_NAME_LEVEL_5 = 'JOB_NAME_LEVEL_5',
  JOB_NAME_LEVEL_6 = 'JOB_NAME_LEVEL_6',
  JOB_NAME_LEVEL_7 = 'JOB_NAME_LEVEL_7',
  JOB_NAME_LEVEL_8 = 'JOB_NAME_LEVEL_8',
  JOB_NAME_LEVEL_9 = 'JOB_NAME_LEVEL_9',
  JOB_NAME_LEVEL_10 = 'JOB_NAME_LEVEL_10',
  JOB_GRADE = 'JOB_GRADE',
  JOB_GRADE_LEVEL_1 = 'JOB_GRADE_LEVEL_1',
  JOB_GRADE_LEVEL_2 = 'JOB_GRADE_LEVEL_2',
  JOB_GRADE_LEVEL_3 = 'JOB_GRADE_LEVEL_3',
  JOB_GRADE_LEVEL_4 = 'JOB_GRADE_LEVEL_4',
  JOB_GRADE_LEVEL_5 = 'JOB_GRADE_LEVEL_5',
  JOB_GRADE_LEVEL_6 = 'JOB_GRADE_LEVEL_6',
  JOB_GRADE_LEVEL_7 = 'JOB_GRADE_LEVEL_7',
  JOB_GRADE_LEVEL_8 = 'JOB_GRADE_LEVEL_8',
  JOB_GRADE_LEVEL_9 = 'JOB_GRADE_LEVEL_9',
  JOB_GRADE_LEVEL_10 = 'JOB_GRADE_LEVEL_10',
  OPENED_AT = 'OPENED_AT',
  CLOSED_AT = 'CLOSED_AT',
  OFFICE = 'OFFICE',
  OFFICE_LEVEL_1 = 'OFFICE_LEVEL_1',
  OFFICE_LEVEL_2 = 'OFFICE_LEVEL_2',
  OFFICE_LEVEL_3 = 'OFFICE_LEVEL_3',
  OFFICE_LEVEL_4 = 'OFFICE_LEVEL_4',
  OFFICE_LEVEL_5 = 'OFFICE_LEVEL_5',
  OFFICE_LEVEL_6 = 'OFFICE_LEVEL_6',
  OFFICE_LEVEL_7 = 'OFFICE_LEVEL_7',
  OFFICE_LEVEL_8 = 'OFFICE_LEVEL_8',
  OFFICE_LEVEL_9 = 'OFFICE_LEVEL_9',
  OFFICE_LEVEL_10 = 'OFFICE_LEVEL_10',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_LEVEL_1 = 'ORGANIZATION_LEVEL_1',
  ORGANIZATION_LEVEL_2 = 'ORGANIZATION_LEVEL_2',
  ORGANIZATION_LEVEL_3 = 'ORGANIZATION_LEVEL_3',
  ORGANIZATION_LEVEL_4 = 'ORGANIZATION_LEVEL_4',
  ORGANIZATION_LEVEL_5 = 'ORGANIZATION_LEVEL_5',
  ORGANIZATION_LEVEL_6 = 'ORGANIZATION_LEVEL_6',
  ORGANIZATION_LEVEL_7 = 'ORGANIZATION_LEVEL_7',
  ORGANIZATION_LEVEL_8 = 'ORGANIZATION_LEVEL_8',
  ORGANIZATION_LEVEL_9 = 'ORGANIZATION_LEVEL_9',
  ORGANIZATION_LEVEL_10 = 'ORGANIZATION_LEVEL_10',
  LOCATION = 'LOCATION',
  LOCATION_LEVEL_1 = 'LOCATION_LEVEL_1',
  LOCATION_LEVEL_2 = 'LOCATION_LEVEL_2',
  LOCATION_LEVEL_3 = 'LOCATION_LEVEL_3',
  LOCATION_LEVEL_4 = 'LOCATION_LEVEL_4',
  LOCATION_LEVEL_5 = 'LOCATION_LEVEL_5',
  LOCATION_LEVEL_6 = 'LOCATION_LEVEL_6',
  LOCATION_LEVEL_7 = 'LOCATION_LEVEL_7',
  LOCATION_LEVEL_8 = 'LOCATION_LEVEL_8',
  LOCATION_LEVEL_9 = 'LOCATION_LEVEL_9',
  LOCATION_LEVEL_10 = 'LOCATION_LEVEL_10',
  FUNCTION = 'FUNCTION',
  FUNCTION_LEVEL_1 = 'FUNCTION_LEVEL_1',
  FUNCTION_LEVEL_2 = 'FUNCTION_LEVEL_2',
  FUNCTION_LEVEL_3 = 'FUNCTION_LEVEL_3',
  FUNCTION_LEVEL_4 = 'FUNCTION_LEVEL_4',
  FUNCTION_LEVEL_5 = 'FUNCTION_LEVEL_5',
  FUNCTION_LEVEL_6 = 'FUNCTION_LEVEL_6',
  FUNCTION_LEVEL_7 = 'FUNCTION_LEVEL_7',
  FUNCTION_LEVEL_8 = 'FUNCTION_LEVEL_8',
  FUNCTION_LEVEL_9 = 'FUNCTION_LEVEL_9',
  FUNCTION_LEVEL_10 = 'FUNCTION_LEVEL_10',
  RECRUITMENT_CATEGORY = 'RECRUITMENT_CATEGORY',
  STATUS = 'STATUS',
  RECRUITMENT_CATEGORY_HIERARCHICAL = 'RECRUITMENT_CATEGORY_HIERARCHICAL',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FIELD_2 = 'CUSTOM_FIELD_2',
}

export enum ApplicationDataFields {
  APPLICATION_ID = 'APPLICATION_ID',
  STANDARDIZED_CURRENT_STAGE = 'STANDARDIZED_CURRENT_STAGE',
  STANDARDIZED_CURRENT_STAGE_OUTCOME = 'STANDARDIZED_CURRENT_STAGE_OUTCOME',
  RESOLVED_AT = 'RESOLVED_AT',
  REJECTED_AT = 'REJECTED_AT',
  START_MONTH = 'START_MONTH',
  START_YEAR = 'START_YEAR',
  APPLIED_AT = 'APPLIED_AT',
  CANDIDATE_ID = 'CANDIDATE_ID',
  CANDIDATE_EMAIL_ADDRESS = 'CANDIDATE_EMAIL_ADDRESS',
  CANDIDATE_FIRST_NAME = 'CANDIDATE_FIRST_NAME',
  CANDIDATE_LAST_NAME = 'CANDIDATE_LAST_NAME',
  SOURCE = 'SOURCE',
  SOURCE_LEVEL_1 = 'SOURCE_LEVEL_1',
  SOURCE_LEVEL_2 = 'SOURCE_LEVEL_2',
  SOURCE_LEVEL_3 = 'SOURCE_LEVEL_3',
  SOURCE_LEVEL_4 = 'SOURCE_LEVEL_4',
  SOURCE_LEVEL_5 = 'SOURCE_LEVEL_5',
  SOURCE_LEVEL_6 = 'SOURCE_LEVEL_6',
  SOURCE_LEVEL_7 = 'SOURCE_LEVEL_7',
  SOURCE_LEVEL_8 = 'SOURCE_LEVEL_8',
  SOURCE_LEVEL_9 = 'SOURCE_LEVEL_9',
  SOURCE_LEVEL_10 = 'SOURCE_LEVEL_10',
  SOURCE_TYPE = 'SOURCE_TYPE',
  CURRENT_STAGE = 'CURRENT_STAGE',
  APPLICATION_AGE = 'APPLICATION_AGE',
  CURRENT_JOB_TITLE = 'CURRENT_JOB_TITLE',
  REJECTION_REASON = 'REJECTION_REASON',
  REJECTION_REASON_LEVEL_1 = 'REJECTION_REASON_LEVEL_1',
  REJECTION_REASON_LEVEL_2 = 'REJECTION_REASON_LEVEL_2',
  APPLICATION_CURRENT_STAGE = 'APPLICATION_CURRENT_STAGE',
  APPLICATION_CURRENT_STAGE_LEVEL_1 = 'APPLICATION_CURRENT_STAGE_LEVEL_1',
  APPLICATION_CURRENT_STAGE_LEVEL_2 = 'APPLICATION_CURRENT_STAGE_LEVEL_2',
  APPLICATION_CURRENT_STAGE_LEVEL_3 = 'APPLICATION_CURRENT_STAGE_LEVEL_3',
  APPLICATION_CURRENT_STAGE_OUTCOME = 'APPLICATION_CURRENT_STAGE_OUTCOME',
  APPLICATION_CURRENT_STAGE_LEVEL1_OUTCOME = 'APPLICATION_CURRENT_STAGE_LEVEL1_OUTCOME',
  APPLICATION_CURRENT_STAGE_LEVEL2_OUTCOME = 'APPLICATION_CURRENT_STAGE_LEVEL2_OUTCOME',
  APPLICATION_CURRENT_STAGE_LEVEL3_OUTCOME = 'APPLICATION_CURRENT_STAGE_LEVEL3_OUTCOME',
  VERSION_ID = 'VERSION_ID',
  JOB_ID = 'JOB_ID',
  STATUS = 'STATUS',
  OFFICE = 'OFFICE',
  LOCATION = 'LOCATION',
  ORGANIZATION = 'ORGANIZATION',
  JOB_NAME = 'JOB_NAME',
  SOURCE_ID = 'SOURCE_ID',
  SOURCE_NAME = 'SOURCE_NAME',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  DEGREE_TYPE = 'DEGREE_TYPE',
  DEGREE_MAJOR = 'DEGREE_MAJOR',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FILTER_1 = 'CUSTOM_FILTER_1',
  CUSTOM_FILTER_2 = 'CUSTOM_FILTER_2',
  CUSTOM_FILTER_3 = 'CUSTOM_FILTER_3',
  CUSTOM_FILTER_4 = 'CUSTOM_FILTER_4',
  CUSTOM_FILTER_5 = 'CUSTOM_FILTER_5',
}

export enum OfferDataFields {
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  VERSION_ID = 'VERSION_ID',
  STATUS = 'STATUS',
  STARTS_AT = 'STARTS_AT',
  APPLICATION_ID = 'APPLICATION_ID',
  OFFER_ID = 'OFFER_ID',
  JOB_ID = 'JOB_ID',
  CREATED_AT = 'CREATED_AT',
  RESOLVED_AT = 'RESOLVED_AT',
}

export enum PayrollDataFields {
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  CONTRACTUAL_BASE_SALARY_CURRENCY = 'CONTRACTUAL_BASE_SALARY_CURRENCY',
  CONTRACTUAL_BASE_SALARY = 'CONTRACTUAL_BASE_SALARY',
  STANDARDIZED_CONTRACTUAL_BASE_SALARY = 'STANDARDIZED_CONTRACTUAL_BASE_SALARY',
  CONTRACTUAL_SALARY_FREQUENCY = 'CONTRACTUAL_SALARY_FREQUENCY',
  COMPUTED_ACTUAL_BASE_SALARY_CURRENCY = 'COMPUTED_ACTUAL_BASE_SALARY_CURRENCY',
  COMPUTED_ACTUAL_BASE_SALARY = 'COMPUTED_ACTUAL_BASE_SALARY',
  STANDARDIZED_COMPUTED_ACTUAL_BASE_SALARY = 'STANDARDIZED_COMPUTED_ACTUAL_BASE_SALARY',
  ACTUAL_BASE_SALARY_CURRENCY = 'ACTUAL_BASE_SALARY_CURRENCY',
  ACTUAL_BASE_SALARY = 'ACTUAL_BASE_SALARY',
  STANDARDIZED_ACTUAL_BASE_SALARY = 'STANDARDIZED_ACTUAL_BASE_SALARY',
  RECEIVED_BASE_SALARY_CURRENCY = 'RECEIVED_BASE_SALARY_CURRENCY',
  RECEIVED_BASE_SALARY = 'RECEIVED_BASE_SALARY',
  STANDARDIZED_RECEIVED_BASE_SALARY = 'STANDARDIZED_RECEIVED_BASE_SALARY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY',
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20 = 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20',
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20 = 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20',
  EMPLOYEE_STATUTORY_PAYMENT_TOTAL_CURRENCY = 'EMPLOYEE_STATUTORY_PAYMENT_TOTAL_CURRENCY',
  EMPLOYEE_STATUTORY_PAYMENT_TOTAL = 'EMPLOYEE_STATUTORY_PAYMENT_TOTAL',
  STANDARDIZED_EMPLOYEE_STATUTORY_PAYMENT_TOTAL = 'STANDARDIZED_EMPLOYEE_STATUTORY_PAYMENT_TOTAL',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY',
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20 = 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20',
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20 = 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20',
  EMPLOYER_STATUTORY_PAYMENT_TOTAL_CURRENCY = 'EMPLOYER_STATUTORY_PAYMENT_TOTAL_CURRENCY',
  EMPLOYER_STATUTORY_PAYMENT_TOTAL = 'EMPLOYER_STATUTORY_PAYMENT_TOTAL',
  STANDARDIZED_EMPLOYER_STATUTORY_PAYMENT_TOTAL = 'STANDARDIZED_EMPLOYER_STATUTORY_PAYMENT_TOTAL',
  STATUTORY_PAYMENT_TOTAL_CURRENCY = 'STATUTORY_PAYMENT_TOTAL_CURRENCY',
  STATUTORY_PAYMENT_TOTAL = 'STATUTORY_PAYMENT_TOTAL',
  STANDARDIZED_STATUTORY_PAYMENT_TOTAL = 'STANDARDIZED_STATUTORY_PAYMENT_TOTAL',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY',
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20 = 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20',
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20 = 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20',
  EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY = 'EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY',
  EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL = 'EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL',
  STANDARDIZED_EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL = 'STANDARDIZED_EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY',
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20 = 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20',
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20 = 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20',
  EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY = 'EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY',
  EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL = 'EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL',
  STANDARDIZED_EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL = 'STANDARDIZED_EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL',
  NON_STATUTORY_PAYMENT_TOTAL_CURRENCY = 'NON_STATUTORY_PAYMENT_TOTAL_CURRENCY',
  NON_STATUTORY_PAYMENT_TOTAL = 'NON_STATUTORY_PAYMENT_TOTAL',
  STANDARDIZED_NON_STATUTORY_PAYMENT_TOTAL = 'STANDARDIZED_NON_STATUTORY_PAYMENT_TOTAL',
  CUSTOM_BONUS_1_CURRENCY = 'CUSTOM_BONUS_1_CURRENCY',
  CUSTOM_BONUS_1 = 'CUSTOM_BONUS_1',
  STANDARDIZED_CUSTOM_BONUS_1 = 'STANDARDIZED_CUSTOM_BONUS_1',
  CUSTOM_BONUS_2_CURRENCY = 'CUSTOM_BONUS_2_CURRENCY',
  CUSTOM_BONUS_2 = 'CUSTOM_BONUS_2',
  STANDARDIZED_CUSTOM_BONUS_2 = 'STANDARDIZED_CUSTOM_BONUS_2',
  CUSTOM_BONUS_3_CURRENCY = 'CUSTOM_BONUS_3_CURRENCY',
  CUSTOM_BONUS_3 = 'CUSTOM_BONUS_3',
  STANDARDIZED_CUSTOM_BONUS_3 = 'STANDARDIZED_CUSTOM_BONUS_3',
  CUSTOM_BONUS_4_CURRENCY = 'CUSTOM_BONUS_4_CURRENCY',
  CUSTOM_BONUS_4 = 'CUSTOM_BONUS_4',
  STANDARDIZED_CUSTOM_BONUS_4 = 'STANDARDIZED_CUSTOM_BONUS_4',
  CUSTOM_BONUS_5_CURRENCY = 'CUSTOM_BONUS_5_CURRENCY',
  CUSTOM_BONUS_5 = 'CUSTOM_BONUS_5',
  STANDARDIZED_CUSTOM_BONUS_5 = 'STANDARDIZED_CUSTOM_BONUS_5',
  CUSTOM_BONUS_6_CURRENCY = 'CUSTOM_BONUS_6_CURRENCY',
  CUSTOM_BONUS_6 = 'CUSTOM_BONUS_6',
  STANDARDIZED_CUSTOM_BONUS_6 = 'STANDARDIZED_CUSTOM_BONUS_6',
  CUSTOM_BONUS_7_CURRENCY = 'CUSTOM_BONUS_7_CURRENCY',
  CUSTOM_BONUS_7 = 'CUSTOM_BONUS_7',
  STANDARDIZED_CUSTOM_BONUS_7 = 'STANDARDIZED_CUSTOM_BONUS_7',
  CUSTOM_BONUS_8_CURRENCY = 'CUSTOM_BONUS_8_CURRENCY',
  CUSTOM_BONUS_8 = 'CUSTOM_BONUS_8',
  STANDARDIZED_CUSTOM_BONUS_8 = 'STANDARDIZED_CUSTOM_BONUS_8',
  CUSTOM_BONUS_9_CURRENCY = 'CUSTOM_BONUS_9_CURRENCY',
  CUSTOM_BONUS_9 = 'CUSTOM_BONUS_9',
  STANDARDIZED_CUSTOM_BONUS_9 = 'STANDARDIZED_CUSTOM_BONUS_9',
  CUSTOM_BONUS_10_CURRENCY = 'CUSTOM_BONUS_10_CURRENCY',
  CUSTOM_BONUS_10 = 'CUSTOM_BONUS_10',
  STANDARDIZED_CUSTOM_BONUS_10 = 'STANDARDIZED_CUSTOM_BONUS_10',
  CUSTOM_BONUS_11_CURRENCY = 'CUSTOM_BONUS_11_CURRENCY',
  CUSTOM_BONUS_11 = 'CUSTOM_BONUS_11',
  STANDARDIZED_CUSTOM_BONUS_11 = 'STANDARDIZED_CUSTOM_BONUS_11',
  CUSTOM_BONUS_12_CURRENCY = 'CUSTOM_BONUS_12_CURRENCY',
  CUSTOM_BONUS_12 = 'CUSTOM_BONUS_12',
  STANDARDIZED_CUSTOM_BONUS_12 = 'STANDARDIZED_CUSTOM_BONUS_12',
  CUSTOM_BONUS_13_CURRENCY = 'CUSTOM_BONUS_13_CURRENCY',
  CUSTOM_BONUS_13 = 'CUSTOM_BONUS_13',
  STANDARDIZED_CUSTOM_BONUS_13 = 'STANDARDIZED_CUSTOM_BONUS_13',
  CUSTOM_BONUS_14_CURRENCY = 'CUSTOM_BONUS_14_CURRENCY',
  CUSTOM_BONUS_14 = 'CUSTOM_BONUS_14',
  STANDARDIZED_CUSTOM_BONUS_14 = 'STANDARDIZED_CUSTOM_BONUS_14',
  CUSTOM_BONUS_15_CURRENCY = 'CUSTOM_BONUS_15_CURRENCY',
  CUSTOM_BONUS_15 = 'CUSTOM_BONUS_15',
  STANDARDIZED_CUSTOM_BONUS_15 = 'STANDARDIZED_CUSTOM_BONUS_15',
  CUSTOM_BONUS_16_CURRENCY = 'CUSTOM_BONUS_16_CURRENCY',
  CUSTOM_BONUS_16 = 'CUSTOM_BONUS_16',
  STANDARDIZED_CUSTOM_BONUS_16 = 'STANDARDIZED_CUSTOM_BONUS_16',
  CUSTOM_BONUS_17_CURRENCY = 'CUSTOM_BONUS_17_CURRENCY',
  CUSTOM_BONUS_17 = 'CUSTOM_BONUS_17',
  STANDARDIZED_CUSTOM_BONUS_17 = 'STANDARDIZED_CUSTOM_BONUS_17',
  CUSTOM_BONUS_18_CURRENCY = 'CUSTOM_BONUS_18_CURRENCY',
  CUSTOM_BONUS_18 = 'CUSTOM_BONUS_18',
  STANDARDIZED_CUSTOM_BONUS_18 = 'STANDARDIZED_CUSTOM_BONUS_18',
  CUSTOM_BONUS_19_CURRENCY = 'CUSTOM_BONUS_19_CURRENCY',
  CUSTOM_BONUS_19 = 'CUSTOM_BONUS_19',
  STANDARDIZED_CUSTOM_BONUS_19 = 'STANDARDIZED_CUSTOM_BONUS_19',
  CUSTOM_BONUS_20_CURRENCY = 'CUSTOM_BONUS_20_CURRENCY',
  CUSTOM_BONUS_20 = 'CUSTOM_BONUS_20',
  STANDARDIZED_CUSTOM_BONUS_20 = 'STANDARDIZED_CUSTOM_BONUS_20',
  TOTAL_BONUS_CURRENCY = 'TOTAL_BONUS_CURRENCY',
  TOTAL_BONUS = 'TOTAL_BONUS',
  STANDARDIZED_TOTAL_BONUS = 'STANDARDIZED_TOTAL_BONUS',
  CUSTOM_ALLOWANCE_1_CURRENCY = 'CUSTOM_ALLOWANCE_1_CURRENCY',
  CUSTOM_ALLOWANCE_1 = 'CUSTOM_ALLOWANCE_1',
  STANDARDIZED_CUSTOM_ALLOWANCE_1 = 'STANDARDIZED_CUSTOM_ALLOWANCE_1',
  CUSTOM_ALLOWANCE_2_CURRENCY = 'CUSTOM_ALLOWANCE_2_CURRENCY',
  CUSTOM_ALLOWANCE_2 = 'CUSTOM_ALLOWANCE_2',
  STANDARDIZED_CUSTOM_ALLOWANCE_2 = 'STANDARDIZED_CUSTOM_ALLOWANCE_2',
  CUSTOM_ALLOWANCE_3_CURRENCY = 'CUSTOM_ALLOWANCE_3_CURRENCY',
  CUSTOM_ALLOWANCE_3 = 'CUSTOM_ALLOWANCE_3',
  STANDARDIZED_CUSTOM_ALLOWANCE_3 = 'STANDARDIZED_CUSTOM_ALLOWANCE_3',
  CUSTOM_ALLOWANCE_4_CURRENCY = 'CUSTOM_ALLOWANCE_4_CURRENCY',
  CUSTOM_ALLOWANCE_4 = 'CUSTOM_ALLOWANCE_4',
  STANDARDIZED_CUSTOM_ALLOWANCE_4 = 'STANDARDIZED_CUSTOM_ALLOWANCE_4',
  CUSTOM_ALLOWANCE_5_CURRENCY = 'CUSTOM_ALLOWANCE_5_CURRENCY',
  CUSTOM_ALLOWANCE_5 = 'CUSTOM_ALLOWANCE_5',
  STANDARDIZED_CUSTOM_ALLOWANCE_5 = 'STANDARDIZED_CUSTOM_ALLOWANCE_5',
  CUSTOM_ALLOWANCE_6_CURRENCY = 'CUSTOM_ALLOWANCE_6_CURRENCY',
  CUSTOM_ALLOWANCE_6 = 'CUSTOM_ALLOWANCE_6',
  STANDARDIZED_CUSTOM_ALLOWANCE_6 = 'STANDARDIZED_CUSTOM_ALLOWANCE_6',
  CUSTOM_ALLOWANCE_7_CURRENCY = 'CUSTOM_ALLOWANCE_7_CURRENCY',
  CUSTOM_ALLOWANCE_7 = 'CUSTOM_ALLOWANCE_7',
  STANDARDIZED_CUSTOM_ALLOWANCE_7 = 'STANDARDIZED_CUSTOM_ALLOWANCE_7',
  CUSTOM_ALLOWANCE_8_CURRENCY = 'CUSTOM_ALLOWANCE_8_CURRENCY',
  CUSTOM_ALLOWANCE_8 = 'CUSTOM_ALLOWANCE_8',
  STANDARDIZED_CUSTOM_ALLOWANCE_8 = 'STANDARDIZED_CUSTOM_ALLOWANCE_8',
  CUSTOM_ALLOWANCE_9_CURRENCY = 'CUSTOM_ALLOWANCE_9_CURRENCY',
  CUSTOM_ALLOWANCE_9 = 'CUSTOM_ALLOWANCE_9',
  STANDARDIZED_CUSTOM_ALLOWANCE_9 = 'STANDARDIZED_CUSTOM_ALLOWANCE_9',
  CUSTOM_ALLOWANCE_10_CURRENCY = 'CUSTOM_ALLOWANCE_10_CURRENCY',
  CUSTOM_ALLOWANCE_10 = 'CUSTOM_ALLOWANCE_10',
  STANDARDIZED_CUSTOM_ALLOWANCE_10 = 'STANDARDIZED_CUSTOM_ALLOWANCE_10',
  CUSTOM_ALLOWANCE_11_CURRENCY = 'CUSTOM_ALLOWANCE_11_CURRENCY',
  CUSTOM_ALLOWANCE_11 = 'CUSTOM_ALLOWANCE_11',
  STANDARDIZED_CUSTOM_ALLOWANCE_11 = 'STANDARDIZED_CUSTOM_ALLOWANCE_11',
  CUSTOM_ALLOWANCE_12_CURRENCY = 'CUSTOM_ALLOWANCE_12_CURRENCY',
  CUSTOM_ALLOWANCE_12 = 'CUSTOM_ALLOWANCE_12',
  STANDARDIZED_CUSTOM_ALLOWANCE_12 = 'STANDARDIZED_CUSTOM_ALLOWANCE_12',
  CUSTOM_ALLOWANCE_13_CURRENCY = 'CUSTOM_ALLOWANCE_13_CURRENCY',
  CUSTOM_ALLOWANCE_13 = 'CUSTOM_ALLOWANCE_13',
  STANDARDIZED_CUSTOM_ALLOWANCE_13 = 'STANDARDIZED_CUSTOM_ALLOWANCE_13',
  CUSTOM_ALLOWANCE_14_CURRENCY = 'CUSTOM_ALLOWANCE_14_CURRENCY',
  CUSTOM_ALLOWANCE_14 = 'CUSTOM_ALLOWANCE_14',
  STANDARDIZED_CUSTOM_ALLOWANCE_14 = 'STANDARDIZED_CUSTOM_ALLOWANCE_14',
  CUSTOM_ALLOWANCE_15_CURRENCY = 'CUSTOM_ALLOWANCE_15_CURRENCY',
  CUSTOM_ALLOWANCE_15 = 'CUSTOM_ALLOWANCE_15',
  STANDARDIZED_CUSTOM_ALLOWANCE_15 = 'STANDARDIZED_CUSTOM_ALLOWANCE_15',
  CUSTOM_ALLOWANCE_16_CURRENCY = 'CUSTOM_ALLOWANCE_16_CURRENCY',
  CUSTOM_ALLOWANCE_16 = 'CUSTOM_ALLOWANCE_16',
  STANDARDIZED_CUSTOM_ALLOWANCE_16 = 'STANDARDIZED_CUSTOM_ALLOWANCE_16',
  CUSTOM_ALLOWANCE_17_CURRENCY = 'CUSTOM_ALLOWANCE_17_CURRENCY',
  CUSTOM_ALLOWANCE_17 = 'CUSTOM_ALLOWANCE_17',
  STANDARDIZED_CUSTOM_ALLOWANCE_17 = 'STANDARDIZED_CUSTOM_ALLOWANCE_17',
  CUSTOM_ALLOWANCE_18_CURRENCY = 'CUSTOM_ALLOWANCE_18_CURRENCY',
  CUSTOM_ALLOWANCE_18 = 'CUSTOM_ALLOWANCE_18',
  STANDARDIZED_CUSTOM_ALLOWANCE_18 = 'STANDARDIZED_CUSTOM_ALLOWANCE_18',
  CUSTOM_ALLOWANCE_19_CURRENCY = 'CUSTOM_ALLOWANCE_19_CURRENCY',
  CUSTOM_ALLOWANCE_19 = 'CUSTOM_ALLOWANCE_19',
  STANDARDIZED_CUSTOM_ALLOWANCE_19 = 'STANDARDIZED_CUSTOM_ALLOWANCE_19',
  CUSTOM_ALLOWANCE_20_CURRENCY = 'CUSTOM_ALLOWANCE_20_CURRENCY',
  CUSTOM_ALLOWANCE_20 = 'CUSTOM_ALLOWANCE_20',
  STANDARDIZED_CUSTOM_ALLOWANCE_20 = 'STANDARDIZED_CUSTOM_ALLOWANCE_20',
  TOTAL_ALLOWANCE_CURRENCY = 'TOTAL_ALLOWANCE_CURRENCY',
  TOTAL_ALLOWANCE = 'TOTAL_ALLOWANCE',
  STANDARDIZED_TOTAL_ALLOWANCE = 'STANDARDIZED_TOTAL_ALLOWANCE',
  CUSTOM_PAYROLL_COST_1_CURRENCY = 'CUSTOM_PAYROLL_COST_1_CURRENCY',
  CUSTOM_PAYROLL_COST_1 = 'CUSTOM_PAYROLL_COST_1',
  STANDARDIZED_CUSTOM_PAYROLL_COST_1 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_1',
  CUSTOM_PAYROLL_COST_2_CURRENCY = 'CUSTOM_PAYROLL_COST_2_CURRENCY',
  CUSTOM_PAYROLL_COST_2 = 'CUSTOM_PAYROLL_COST_2',
  STANDARDIZED_CUSTOM_PAYROLL_COST_2 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_2',
  CUSTOM_PAYROLL_COST_3_CURRENCY = 'CUSTOM_PAYROLL_COST_3_CURRENCY',
  CUSTOM_PAYROLL_COST_3 = 'CUSTOM_PAYROLL_COST_3',
  STANDARDIZED_CUSTOM_PAYROLL_COST_3 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_3',
  CUSTOM_PAYROLL_COST_4_CURRENCY = 'CUSTOM_PAYROLL_COST_4_CURRENCY',
  CUSTOM_PAYROLL_COST_4 = 'CUSTOM_PAYROLL_COST_4',
  STANDARDIZED_CUSTOM_PAYROLL_COST_4 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_4',
  CUSTOM_PAYROLL_COST_5_CURRENCY = 'CUSTOM_PAYROLL_COST_5_CURRENCY',
  CUSTOM_PAYROLL_COST_5 = 'CUSTOM_PAYROLL_COST_5',
  STANDARDIZED_CUSTOM_PAYROLL_COST_5 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_5',
  CUSTOM_PAYROLL_COST_6_CURRENCY = 'CUSTOM_PAYROLL_COST_6_CURRENCY',
  CUSTOM_PAYROLL_COST_6 = 'CUSTOM_PAYROLL_COST_6',
  STANDARDIZED_CUSTOM_PAYROLL_COST_6 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_6',
  CUSTOM_PAYROLL_COST_7_CURRENCY = 'CUSTOM_PAYROLL_COST_7_CURRENCY',
  CUSTOM_PAYROLL_COST_7 = 'CUSTOM_PAYROLL_COST_7',
  STANDARDIZED_CUSTOM_PAYROLL_COST_7 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_7',
  CUSTOM_PAYROLL_COST_8_CURRENCY = 'CUSTOM_PAYROLL_COST_8_CURRENCY',
  CUSTOM_PAYROLL_COST_8 = 'CUSTOM_PAYROLL_COST_8',
  STANDARDIZED_CUSTOM_PAYROLL_COST_8 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_8',
  CUSTOM_PAYROLL_COST_9_CURRENCY = 'CUSTOM_PAYROLL_COST_9_CURRENCY',
  CUSTOM_PAYROLL_COST_9 = 'CUSTOM_PAYROLL_COST_9',
  STANDARDIZED_CUSTOM_PAYROLL_COST_9 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_9',
  CUSTOM_PAYROLL_COST_10_CURRENCY = 'CUSTOM_PAYROLL_COST_10_CURRENCY',
  CUSTOM_PAYROLL_COST_10 = 'CUSTOM_PAYROLL_COST_10',
  CUSTOM_PAYROLL_COST_11_CURRENCY = 'CUSTOM_PAYROLL_COST_11_CURRENCY',
  CUSTOM_PAYROLL_COST_11 = 'CUSTOM_PAYROLL_COST_11',
  CUSTOM_PAYROLL_COST_12_CURRENCY = 'CUSTOM_PAYROLL_COST_12_CURRENCY',
  CUSTOM_PAYROLL_COST_12 = 'CUSTOM_PAYROLL_COST_12',
  CUSTOM_PAYROLL_COST_13_CURRENCY = 'CUSTOM_PAYROLL_COST_13_CURRENCY',
  CUSTOM_PAYROLL_COST_13 = 'CUSTOM_PAYROLL_COST_13',
  CUSTOM_PAYROLL_COST_14_CURRENCY = 'CUSTOM_PAYROLL_COST_14_CURRENCY',
  CUSTOM_PAYROLL_COST_14 = 'CUSTOM_PAYROLL_COST_14',
  CUSTOM_PAYROLL_COST_15_CURRENCY = 'CUSTOM_PAYROLL_COST_15_CURRENCY',
  CUSTOM_PAYROLL_COST_15 = 'CUSTOM_PAYROLL_COST_15',
  CUSTOM_PAYROLL_COST_16_CURRENCY = 'CUSTOM_PAYROLL_COST_16_CURRENCY',
  CUSTOM_PAYROLL_COST_16 = 'CUSTOM_PAYROLL_COST_16',
  CUSTOM_PAYROLL_COST_17_CURRENCY = 'CUSTOM_PAYROLL_COST_17_CURRENCY',
  CUSTOM_PAYROLL_COST_17 = 'CUSTOM_PAYROLL_COST_17',
  CUSTOM_PAYROLL_COST_18_CURRENCY = 'CUSTOM_PAYROLL_COST_18_CURRENCY',
  CUSTOM_PAYROLL_COST_18 = 'CUSTOM_PAYROLL_COST_18',
  CUSTOM_PAYROLL_COST_19_CURRENCY = 'CUSTOM_PAYROLL_COST_19_CURRENCY',
  CUSTOM_PAYROLL_COST_19 = 'CUSTOM_PAYROLL_COST_19',
  CUSTOM_PAYROLL_COST_20_CURRENCY = 'CUSTOM_PAYROLL_COST_20_CURRENCY',
  CUSTOM_PAYROLL_COST_20 = 'CUSTOM_PAYROLL_COST_20',
  STANDARDIZED_CUSTOM_PAYROLL_COST_10 = 'STANDARDIZED_CUSTOM_PAYROLL_COST_10',
  TOTAL_PAYROLL_COST_CURRENCY = 'TOTAL_PAYROLL_COST_CURRENCY',
  TOTAL_PAYROLL_COST = 'TOTAL_PAYROLL_COST',
  STANDARDIZED_TOTAL_PAYROLL_COST = 'STANDARDIZED_TOTAL_PAYROLL_COST',
  CUSTOM_EMPLOYER_CONTRIBUTION_1_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_1_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_1 = 'CUSTOM_EMPLOYER_CONTRIBUTION_1',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_1 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_1',
  CUSTOM_EMPLOYER_CONTRIBUTION_2_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_2_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_2 = 'CUSTOM_EMPLOYER_CONTRIBUTION_2',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_2 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_2',
  CUSTOM_EMPLOYER_CONTRIBUTION_3_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_3_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_3 = 'CUSTOM_EMPLOYER_CONTRIBUTION_3',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_3 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_3',
  CUSTOM_EMPLOYER_CONTRIBUTION_4_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_4_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_4 = 'CUSTOM_EMPLOYER_CONTRIBUTION_4',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_4 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_4',
  CUSTOM_EMPLOYER_CONTRIBUTION_5_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_5_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_5 = 'CUSTOM_EMPLOYER_CONTRIBUTION_5',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_5 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_5',
  CUSTOM_EMPLOYER_CONTRIBUTION_6_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_6_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_6 = 'CUSTOM_EMPLOYER_CONTRIBUTION_6',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_6 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_6',
  CUSTOM_EMPLOYER_CONTRIBUTION_7_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_7_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_7 = 'CUSTOM_EMPLOYER_CONTRIBUTION_7',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_7 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_7',
  CUSTOM_EMPLOYER_CONTRIBUTION_8_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_8_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_8 = 'CUSTOM_EMPLOYER_CONTRIBUTION_8',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_8 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_8',
  CUSTOM_EMPLOYER_CONTRIBUTION_9_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_9_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_9 = 'CUSTOM_EMPLOYER_CONTRIBUTION_9',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_9 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_9',
  CUSTOM_EMPLOYER_CONTRIBUTION_10_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_10_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_10 = 'CUSTOM_EMPLOYER_CONTRIBUTION_10',
  CUSTOM_EMPLOYER_CONTRIBUTION_11_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_11_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_12_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_12_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_13_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_13_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_14_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_14_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_15_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_15_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_16_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_16_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_17_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_17_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_18_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_18_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_19_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_19_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_20_CURRENCY = 'CUSTOM_EMPLOYER_CONTRIBUTION_20_CURRENCY',
  CUSTOM_EMPLOYER_CONTRIBUTION_11 = 'CUSTOM_EMPLOYER_CONTRIBUTION_11',
  CUSTOM_EMPLOYER_CONTRIBUTION_12 = 'CUSTOM_EMPLOYER_CONTRIBUTION_12',
  CUSTOM_EMPLOYER_CONTRIBUTION_13 = 'CUSTOM_EMPLOYER_CONTRIBUTION_13',
  CUSTOM_EMPLOYER_CONTRIBUTION_14 = 'CUSTOM_EMPLOYER_CONTRIBUTION_14',
  CUSTOM_EMPLOYER_CONTRIBUTION_15 = 'CUSTOM_EMPLOYER_CONTRIBUTION_15',
  CUSTOM_EMPLOYER_CONTRIBUTION_16 = 'CUSTOM_EMPLOYER_CONTRIBUTION_16',
  CUSTOM_EMPLOYER_CONTRIBUTION_17 = 'CUSTOM_EMPLOYER_CONTRIBUTION_17',
  CUSTOM_EMPLOYER_CONTRIBUTION_18 = 'CUSTOM_EMPLOYER_CONTRIBUTION_18',
  CUSTOM_EMPLOYER_CONTRIBUTION_19 = 'CUSTOM_EMPLOYER_CONTRIBUTION_19',
  CUSTOM_EMPLOYER_CONTRIBUTION_20 = 'CUSTOM_EMPLOYER_CONTRIBUTION_20',
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_10 = 'STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_10',
  TOTAL_BOARD_COMPENSATION = 'TOTAL_BOARD_COMPENSATION',
  TOTAL_BOARD_COMPENSATION_CURRENCY = 'TOTAL_BOARD_COMPENSATION_CURRENCY',
  STANDARDIZED_TOTAL_BOARD_COMPENSATION = 'STANDARDIZED_TOTAL_BOARD_COMPENSATION',
  BOARD_COMPENSATION_1 = 'BOARD_COMPENSATION_1',
  BOARD_COMPENSATION_2 = 'BOARD_COMPENSATION_2',
  BOARD_COMPENSATION_3 = 'BOARD_COMPENSATION_3',
  BOARD_COMPENSATION_4 = 'BOARD_COMPENSATION_4',
  BOARD_COMPENSATION_5 = 'BOARD_COMPENSATION_5',
  BOARD_COMPENSATION_6 = 'BOARD_COMPENSATION_6',
  BOARD_COMPENSATION_7 = 'BOARD_COMPENSATION_7',
  BOARD_COMPENSATION_8 = 'BOARD_COMPENSATION_8',
  BOARD_COMPENSATION_9 = 'BOARD_COMPENSATION_9',
  BOARD_COMPENSATION_10 = 'BOARD_COMPENSATION_10',
  BOARD_COMPENSATION_11 = 'BOARD_COMPENSATION_11',
  BOARD_COMPENSATION_12 = 'BOARD_COMPENSATION_12',
  BOARD_COMPENSATION_13 = 'BOARD_COMPENSATION_13',
  BOARD_COMPENSATION_14 = 'BOARD_COMPENSATION_14',
  BOARD_COMPENSATION_15 = 'BOARD_COMPENSATION_15',
  BOARD_COMPENSATION_16 = 'BOARD_COMPENSATION_16',
  BOARD_COMPENSATION_17 = 'BOARD_COMPENSATION_17',
  BOARD_COMPENSATION_18 = 'BOARD_COMPENSATION_18',
  BOARD_COMPENSATION_19 = 'BOARD_COMPENSATION_19',
  BOARD_COMPENSATION_20 = 'BOARD_COMPENSATION_20',
  BOARD_COMPENSATION_1_CURRENCY = 'BOARD_COMPENSATION_1_CURRENCY',
  BOARD_COMPENSATION_2_CURRENCY = 'BOARD_COMPENSATION_2_CURRENCY',
  BOARD_COMPENSATION_3_CURRENCY = 'BOARD_COMPENSATION_3_CURRENCY',
  BOARD_COMPENSATION_4_CURRENCY = 'BOARD_COMPENSATION_4_CURRENCY',
  BOARD_COMPENSATION_5_CURRENCY = 'BOARD_COMPENSATION_5_CURRENCY',
  BOARD_COMPENSATION_6_CURRENCY = 'BOARD_COMPENSATION_6_CURRENCY',
  BOARD_COMPENSATION_7_CURRENCY = 'BOARD_COMPENSATION_7_CURRENCY',
  BOARD_COMPENSATION_8_CURRENCY = 'BOARD_COMPENSATION_8_CURRENCY',
  BOARD_COMPENSATION_9_CURRENCY = 'BOARD_COMPENSATION_9_CURRENCY',
  BOARD_COMPENSATION_10_CURRENCY = 'BOARD_COMPENSATION_10_CURRENCY',
  BOARD_COMPENSATION_11_CURRENCY = 'BOARD_COMPENSATION_11_CURRENCY',
  BOARD_COMPENSATION_12_CURRENCY = 'BOARD_COMPENSATION_12_CURRENCY',
  BOARD_COMPENSATION_13_CURRENCY = 'BOARD_COMPENSATION_13_CURRENCY',
  BOARD_COMPENSATION_14_CURRENCY = 'BOARD_COMPENSATION_14_CURRENCY',
  BOARD_COMPENSATION_15_CURRENCY = 'BOARD_COMPENSATION_15_CURRENCY',
  BOARD_COMPENSATION_16_CURRENCY = 'BOARD_COMPENSATION_16_CURRENCY',
  BOARD_COMPENSATION_17_CURRENCY = 'BOARD_COMPENSATION_17_CURRENCY',
  BOARD_COMPENSATION_18_CURRENCY = 'BOARD_COMPENSATION_18_CURRENCY',
  BOARD_COMPENSATION_19_CURRENCY = 'BOARD_COMPENSATION_19_CURRENCY',
  BOARD_COMPENSATION_20_CURRENCY = 'BOARD_COMPENSATION_20_CURRENCY',
  STANDARDIZED_BOARD_COMPENSATION_1 = 'STANDARDIZED_BOARD_COMPENSATION_1',
  STANDARDIZED_BOARD_COMPENSATION_2 = 'STANDARDIZED_BOARD_COMPENSATION_2',
  STANDARDIZED_BOARD_COMPENSATION_3 = 'STANDARDIZED_BOARD_COMPENSATION_3',
  STANDARDIZED_BOARD_COMPENSATION_4 = 'STANDARDIZED_BOARD_COMPENSATION_4',
  STANDARDIZED_BOARD_COMPENSATION_5 = 'STANDARDIZED_BOARD_COMPENSATION_5',
  STANDARDIZED_BOARD_COMPENSATION_6 = 'STANDARDIZED_BOARD_COMPENSATION_6',
  STANDARDIZED_BOARD_COMPENSATION_7 = 'STANDARDIZED_BOARD_COMPENSATION_7',
  STANDARDIZED_BOARD_COMPENSATION_8 = 'STANDARDIZED_BOARD_COMPENSATION_8',
  STANDARDIZED_BOARD_COMPENSATION_9 = 'STANDARDIZED_BOARD_COMPENSATION_9',
  STANDARDIZED_BOARD_COMPENSATION_10 = 'STANDARDIZED_BOARD_COMPENSATION_10',
  TOTAL_EMPLOYEE_AWARD = 'TOTAL_EMPLOYEE_AWARD',
  TOTAL_EMPLOYEE_AWARD_CURRENCY = 'TOTAL_EMPLOYEE_AWARD_CURRENCY',
  STANDARDIZED_TOTAL_EMPLOYEE_AWARD = 'STANDARDIZED_TOTAL_EMPLOYEE_AWARD',
  EMPLOYEE_AWARD_1 = 'EMPLOYEE_AWARD_1',
  EMPLOYEE_AWARD_2 = 'EMPLOYEE_AWARD_2',
  EMPLOYEE_AWARD_3 = 'EMPLOYEE_AWARD_3',
  EMPLOYEE_AWARD_4 = 'EMPLOYEE_AWARD_4',
  EMPLOYEE_AWARD_5 = 'EMPLOYEE_AWARD_5',
  EMPLOYEE_AWARD_6 = 'EMPLOYEE_AWARD_6',
  EMPLOYEE_AWARD_7 = 'EMPLOYEE_AWARD_7',
  EMPLOYEE_AWARD_8 = 'EMPLOYEE_AWARD_8',
  EMPLOYEE_AWARD_9 = 'EMPLOYEE_AWARD_9',
  EMPLOYEE_AWARD_10 = 'EMPLOYEE_AWARD_10',
  EMPLOYEE_AWARD_11 = 'EMPLOYEE_AWARD_11',
  EMPLOYEE_AWARD_12 = 'EMPLOYEE_AWARD_12',
  EMPLOYEE_AWARD_13 = 'EMPLOYEE_AWARD_13',
  EMPLOYEE_AWARD_14 = 'EMPLOYEE_AWARD_14',
  EMPLOYEE_AWARD_15 = 'EMPLOYEE_AWARD_15',
  EMPLOYEE_AWARD_16 = 'EMPLOYEE_AWARD_16',
  EMPLOYEE_AWARD_17 = 'EMPLOYEE_AWARD_17',
  EMPLOYEE_AWARD_18 = 'EMPLOYEE_AWARD_18',
  EMPLOYEE_AWARD_19 = 'EMPLOYEE_AWARD_19',
  EMPLOYEE_AWARD_20 = 'EMPLOYEE_AWARD_20',
  EMPLOYEE_AWARD_1_CURRENCY = 'EMPLOYEE_AWARD_1_CURRENCY',
  EMPLOYEE_AWARD_2_CURRENCY = 'EMPLOYEE_AWARD_2_CURRENCY',
  EMPLOYEE_AWARD_3_CURRENCY = 'EMPLOYEE_AWARD_3_CURRENCY',
  EMPLOYEE_AWARD_4_CURRENCY = 'EMPLOYEE_AWARD_4_CURRENCY',
  EMPLOYEE_AWARD_5_CURRENCY = 'EMPLOYEE_AWARD_5_CURRENCY',
  EMPLOYEE_AWARD_6_CURRENCY = 'EMPLOYEE_AWARD_6_CURRENCY',
  EMPLOYEE_AWARD_7_CURRENCY = 'EMPLOYEE_AWARD_7_CURRENCY',
  EMPLOYEE_AWARD_8_CURRENCY = 'EMPLOYEE_AWARD_8_CURRENCY',
  EMPLOYEE_AWARD_9_CURRENCY = 'EMPLOYEE_AWARD_9_CURRENCY',
  EMPLOYEE_AWARD_10_CURRENCY = 'EMPLOYEE_AWARD_10_CURRENCY',
  EMPLOYEE_AWARD_11_CURRENCY = 'EMPLOYEE_AWARD_11_CURRENCY',
  EMPLOYEE_AWARD_12_CURRENCY = 'EMPLOYEE_AWARD_12_CURRENCY',
  EMPLOYEE_AWARD_13_CURRENCY = 'EMPLOYEE_AWARD_13_CURRENCY',
  EMPLOYEE_AWARD_14_CURRENCY = 'EMPLOYEE_AWARD_14_CURRENCY',
  EMPLOYEE_AWARD_15_CURRENCY = 'EMPLOYEE_AWARD_15_CURRENCY',
  EMPLOYEE_AWARD_16_CURRENCY = 'EMPLOYEE_AWARD_16_CURRENCY',
  EMPLOYEE_AWARD_17_CURRENCY = 'EMPLOYEE_AWARD_17_CURRENCY',
  EMPLOYEE_AWARD_18_CURRENCY = 'EMPLOYEE_AWARD_18_CURRENCY',
  EMPLOYEE_AWARD_19_CURRENCY = 'EMPLOYEE_AWARD_19_CURRENCY',
  EMPLOYEE_AWARD_20_CURRENCY = 'EMPLOYEE_AWARD_20_CURRENCY',
  STANDARDIZED_EMPLOYEE_AWARD_1 = 'STANDARDIZED_EMPLOYEE_AWARD_1',
  STANDARDIZED_EMPLOYEE_AWARD_2 = 'STANDARDIZED_EMPLOYEE_AWARD_2',
  STANDARDIZED_EMPLOYEE_AWARD_3 = 'STANDARDIZED_EMPLOYEE_AWARD_3',
  STANDARDIZED_EMPLOYEE_AWARD_4 = 'STANDARDIZED_EMPLOYEE_AWARD_4',
  STANDARDIZED_EMPLOYEE_AWARD_5 = 'STANDARDIZED_EMPLOYEE_AWARD_5',
  STANDARDIZED_EMPLOYEE_AWARD_6 = 'STANDARDIZED_EMPLOYEE_AWARD_6',
  STANDARDIZED_EMPLOYEE_AWARD_7 = 'STANDARDIZED_EMPLOYEE_AWARD_7',
  STANDARDIZED_EMPLOYEE_AWARD_8 = 'STANDARDIZED_EMPLOYEE_AWARD_8',
  STANDARDIZED_EMPLOYEE_AWARD_9 = 'STANDARDIZED_EMPLOYEE_AWARD_9',
  STANDARDIZED_EMPLOYEE_AWARD_10 = 'STANDARDIZED_EMPLOYEE_AWARD_10',
  TOTAL_REIMBURSEMENT = 'TOTAL_REIMBURSEMENT',
  TOTAL_REIMBURSEMENT_CURRENCY = 'TOTAL_REIMBURSEMENT_CURRENCY',
  STANDARDIZED_TOTAL_REIMBURSEMENT = 'STANDARDIZED_TOTAL_REIMBURSEMENT',
  REIMBURSEMENT_1 = 'REIMBURSEMENT_1',
  REIMBURSEMENT_2 = 'REIMBURSEMENT_2',
  REIMBURSEMENT_3 = 'REIMBURSEMENT_3',
  REIMBURSEMENT_4 = 'REIMBURSEMENT_4',
  REIMBURSEMENT_5 = 'REIMBURSEMENT_5',
  REIMBURSEMENT_6 = 'REIMBURSEMENT_6',
  REIMBURSEMENT_7 = 'REIMBURSEMENT_7',
  REIMBURSEMENT_8 = 'REIMBURSEMENT_8',
  REIMBURSEMENT_9 = 'REIMBURSEMENT_9',
  REIMBURSEMENT_10 = 'REIMBURSEMENT_10',
  REIMBURSEMENT_11 = 'REIMBURSEMENT_11',
  REIMBURSEMENT_12 = 'REIMBURSEMENT_12',
  REIMBURSEMENT_13 = 'REIMBURSEMENT_13',
  REIMBURSEMENT_14 = 'REIMBURSEMENT_14',
  REIMBURSEMENT_15 = 'REIMBURSEMENT_15',
  REIMBURSEMENT_16 = 'REIMBURSEMENT_16',
  REIMBURSEMENT_17 = 'REIMBURSEMENT_17',
  REIMBURSEMENT_18 = 'REIMBURSEMENT_18',
  REIMBURSEMENT_19 = 'REIMBURSEMENT_19',
  REIMBURSEMENT_20 = 'REIMBURSEMENT_20',
  REIMBURSEMENT_1_CURRENCY = 'REIMBURSEMENT_1_CURRENCY',
  REIMBURSEMENT_2_CURRENCY = 'REIMBURSEMENT_2_CURRENCY',
  REIMBURSEMENT_3_CURRENCY = 'REIMBURSEMENT_3_CURRENCY',
  REIMBURSEMENT_4_CURRENCY = 'REIMBURSEMENT_4_CURRENCY',
  REIMBURSEMENT_5_CURRENCY = 'REIMBURSEMENT_5_CURRENCY',
  REIMBURSEMENT_6_CURRENCY = 'REIMBURSEMENT_6_CURRENCY',
  REIMBURSEMENT_7_CURRENCY = 'REIMBURSEMENT_7_CURRENCY',
  REIMBURSEMENT_8_CURRENCY = 'REIMBURSEMENT_8_CURRENCY',
  REIMBURSEMENT_9_CURRENCY = 'REIMBURSEMENT_9_CURRENCY',
  REIMBURSEMENT_10_CURRENCY = 'REIMBURSEMENT_10_CURRENCY',
  REIMBURSEMENT_11_CURRENCY = 'REIMBURSEMENT_11_CURRENCY',
  REIMBURSEMENT_12_CURRENCY = 'REIMBURSEMENT_12_CURRENCY',
  REIMBURSEMENT_13_CURRENCY = 'REIMBURSEMENT_13_CURRENCY',
  REIMBURSEMENT_14_CURRENCY = 'REIMBURSEMENT_14_CURRENCY',
  REIMBURSEMENT_15_CURRENCY = 'REIMBURSEMENT_15_CURRENCY',
  REIMBURSEMENT_16_CURRENCY = 'REIMBURSEMENT_16_CURRENCY',
  REIMBURSEMENT_17_CURRENCY = 'REIMBURSEMENT_17_CURRENCY',
  REIMBURSEMENT_18_CURRENCY = 'REIMBURSEMENT_18_CURRENCY',
  REIMBURSEMENT_19_CURRENCY = 'REIMBURSEMENT_19_CURRENCY',
  REIMBURSEMENT_20_CURRENCY = 'REIMBURSEMENT_20_CURRENCY',
  STANDARDIZED_REIMBURSEMENT_1 = 'STANDARDIZED_REIMBURSEMENT_1',
  STANDARDIZED_REIMBURSEMENT_2 = 'STANDARDIZED_REIMBURSEMENT_2',
  STANDARDIZED_REIMBURSEMENT_3 = 'STANDARDIZED_REIMBURSEMENT_3',
  STANDARDIZED_REIMBURSEMENT_4 = 'STANDARDIZED_REIMBURSEMENT_4',
  STANDARDIZED_REIMBURSEMENT_5 = 'STANDARDIZED_REIMBURSEMENT_5',
  STANDARDIZED_REIMBURSEMENT_6 = 'STANDARDIZED_REIMBURSEMENT_6',
  STANDARDIZED_REIMBURSEMENT_7 = 'STANDARDIZED_REIMBURSEMENT_7',
  STANDARDIZED_REIMBURSEMENT_8 = 'STANDARDIZED_REIMBURSEMENT_8',
  STANDARDIZED_REIMBURSEMENT_9 = 'STANDARDIZED_REIMBURSEMENT_9',
  STANDARDIZED_REIMBURSEMENT_10 = 'STANDARDIZED_REIMBURSEMENT_10',
  OVERTIME = 'OVERTIME',
  OVERTIME_CURRENCY = 'OVERTIME_CURRENCY',
  STANDARDIZED_OVERTIME = 'STANDARDIZED_OVERTIME',
  TRAVEL_AND_COMMUTING = 'TRAVEL_AND_COMMUTING',
  TRAVEL_AND_COMMUTING_CURRENCY = 'TRAVEL_AND_COMMUTING_CURRENCY',
  TOTAL_CASH_COMPENSATION = 'TOTAL_CASH_COMPENSATION',
  STANDARDIZED_TRAVEL_AND_COMMUTING = 'STANDARDIZED_TRAVEL_AND_COMMUTING',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  VERSION_ID = 'VERSION_ID',
}

export enum SurveyDataFields {
  QUESTION_ID = 'QUESTION_ID',
  QUESTION_ANSWER_ID = 'QUESTION_ANSWER_ID',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  VERSION_ID = 'VERSION_ID',
  CYCLE = 'CYCLE',
  QUESTION = 'QUESTION',
  QUESTION_TYPE = 'QUESTION_TYPE',
  ANSWER = 'ANSWER',
  ANSWER_TYPE = 'ANSWER_TYPE',
  SCORE = 'SCORE',
  CYCLE_START_DATE = 'CYCLE_START_DATE',
  CYCLE_END_DATE = 'CYCLE_END_DATE',
  SENTIMENT = 'SENTIMENT',
  THEME_NAME = 'THEME_NAME',
  MANAGER_SCORE = 'MANAGER_SCORE',
}

export enum RecruiterJobsDataFields {
  RECRUITER_NAME = 'RECRUITER_NAME',
  RECRUITER_REAL_ID = 'RECRUITER_REAL_ID',
  VERSION_ID = 'VERSION_ID',
  RECRUITER_TEMPORALITY = 'RECRUITER_TEMPORALITY',
  JOB_ID = 'JOB_ID',
  RECRUITER_ID = 'RECRUITER_ID',
  RESPONSIBLE = 'RESPONSIBLE',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
}

export enum InterviewDataFields {
  INTERVIEW_ID = 'INTERVIEW_ID',
  APPLICATION_ID = 'APPLICATION_ID',
  INTERVIEWER_ID = 'INTERVIEWER_ID',
  INTERVIEWER_NAME = 'INTERVIEWER_NAME',
  SCORE = 'SCORE',
  DATE = 'DATE',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  //JOB_ID = 'JOB_ID',
  VERSION_ID = 'VERSION_ID',
}

export const JoinersViewDataFields = { ...EmployeeDataFields };
export type JoinersViewDataFields = typeof JoinersViewDataFields[keyof typeof JoinersViewDataFields];

export type DataFields =
  | ApplicationDataFields
  | EmployeeDataFields
  | EvaluationDataFields
  | JobDataFields
  | ManagerEnpsDataFields
  | PayrollDataFields
  | RecruiterJobsDataFields
  | SurveyDataFields
  | TimeAndAttendanceMonthlyDataFields
  | FinancialDataFields
  | OfferDataFields
  | InterviewDataFields
  | ComputedEmployeeFields
  | CohortDataFields;

const getDataFieldsWithDataType = (dataFields: Partial<Record<DataFields, string>>, dataType: DataTypes) => {
  return Object.values(dataFields).map((d) => {
    // enumValues import doesn't work due to some cicular dependencies
    return toDataFieldWithDataType(dataType, d as DataFields);
  });
};

export const getDataFieldsForDataType = (dataType: DataTypes): DataFieldWithDataType[] => {
  switch (dataType) {
    case DataTypes.EMPLOYEE:
      return getDataFieldsWithDataType(EmployeeDataFields, DataTypes.EMPLOYEE);
    case DataTypes.JOINERS_VIEW:
      return getDataFieldsWithDataType(EmployeeDataFields, DataTypes.JOINERS_VIEW);
    case DataTypes.APPLICATION:
      return getDataFieldsWithDataType(ApplicationDataFields, DataTypes.APPLICATION);
    case DataTypes.EVALUATION:
      return getDataFieldsWithDataType(EvaluationDataFields, DataTypes.EVALUATION);
    case DataTypes.JOB:
      return getDataFieldsWithDataType(JobDataFields, DataTypes.JOB);
    case DataTypes.PAYROLL:
      return getDataFieldsWithDataType(PayrollDataFields, DataTypes.PAYROLL);
    case DataTypes.RECRUITERJOBS:
      return getDataFieldsWithDataType(RecruiterJobsDataFields, DataTypes.RECRUITERJOBS);
    case DataTypes.TIMEANDATTENDANCEMONTHLY:
      return getDataFieldsWithDataType(TimeAndAttendanceMonthlyDataFields, DataTypes.TIMEANDATTENDANCEMONTHLY);
    case DataTypes.FINANCIALMETRICS:
      return getDataFieldsWithDataType(FinancialDataFields, DataTypes.FINANCIALMETRICS);
    case DataTypes.OFFER:
      return getDataFieldsWithDataType(OfferDataFields, DataTypes.OFFER);
    case DataTypes.QUESTIONANSWER:
      // any because QuestionAnswer only has Employee Id which conflicts with some other fields
      return getDataFieldsWithDataType(SurveyDataFields as any, DataTypes.QUESTIONANSWER);
    case DataTypes.MANAGERENPS:
      return getDataFieldsWithDataType(ManagerEnpsDataFields, DataTypes.MANAGERENPS);
    default:
      throw new Error(`Invalid dataType ${dataType}`);
  }
};

export type ManagerDataField = EmployeeDataFields.MANAGER_OR_IC | EmployeeDataFields.DEFINED_MANAGER;

const payrollSalaryFields = [
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.CONTRACTUAL_BASE_SALARY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_CONTRACTUAL_BASE_SALARY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.ACTUAL_BASE_SALARY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_ACTUAL_BASE_SALARY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STATUTORY_PAYMENT_TOTAL },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_STATUTORY_PAYMENT_TOTAL },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.NON_STATUTORY_PAYMENT_TOTAL },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_NON_STATUTORY_PAYMENT_TOTAL },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_BONUS },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TOTAL_BONUS },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_ALLOWANCE },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TOTAL_ALLOWANCE },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_PAYROLL_COST },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_BOARD_COMPENSATION },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_EMPLOYEE_AWARD },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_REIMBURSEMENT },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TOTAL_PAYROLL_COST },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TOTAL_BOARD_COMPENSATION },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TOTAL_EMPLOYEE_AWARD },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TOTAL_REIMBURSEMENT },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_CASH_COMPENSATION },
];

export const salaryFields = [
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REVENUE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_REVENUE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ANNUAL_CASH_BONUS_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.STANDARDIZED_BASE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY_PER_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.STANDARDIZED_BASE_SALARY_PER_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TOTAL_COMPENSATION },
  ...payrollSalaryFields,
];

export const payrollFields = [
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.ACTUAL_BASE_SALARY_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.CONTRACTUAL_BASE_SALARY_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.NON_STATUTORY_PAYMENT_TOTAL_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STATUTORY_PAYMENT_TOTAL_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_TRAVEL_AND_COMMUTING },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TRAVEL_AND_COMMUTING_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TRAVEL_AND_COMMUTING },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.STANDARDIZED_OVERTIME },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.OVERTIME_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.OVERTIME },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_ALLOWANCE_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_BONUS_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_BOARD_COMPENSATION_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_EMPLOYEE_AWARD_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_REIMBURSEMENT_CURRENCY },
  { dataType: DataTypes.PAYROLL, dataField: PayrollDataFields.TOTAL_PAYROLL_COST_CURRENCY },
  ...payrollSalaryFields,
];

export const hrisCompFields = [
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY_CURRENCY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REVENUE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REVENUE_SALARY_CURRENCY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_REVENUE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ANNUAL_CASH_BONUS_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_SALARY_CURRENCY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TOTAL_COMPENSATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.STANDARDIZED_BASE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY_PER_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.STANDARDIZED_BASE_SALARY_PER_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TOTAL_COMPENSATION_CURRENCY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ICR },
];

export enum StandardizedEmpTypes {
  FTE = 'FTE',
  Regular = 'Regular',
  Contract = 'Contract',
  NonRegular = 'nonRegular',
}

export enum Versioning {
  NONE = 'NONE',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export interface DataTypesAttributes {
  versioning: Versioning;
  /**
   * @deprecated This is a column that we join on when filters or measures are specified for different tables than the base table and those tables are not joined explicitly.
   * Please don't use this! Instead, if you find yourself repeatingly writing explicit joins between two datatypes, then write a helper function to add the join.
   * However, in the future it might make sense to indicate, if a table has one or multiple employee-id columns.
   */
  defaultIdColumn: DataFields;
}

export const allowedEmployeeChartDimensionFields = [
  EmployeeDataFields.GENDER,
  EmployeeDataFields.NATIONALITY_HIERARCHICAL,
  EmployeeDataFields.ORGANIZATION,
  EmployeeDataFields.PRE_MERGER_ORGANIZATION,
  EmployeeDataFields.LOCATION,
  EmployeeDataFields.FUNCTION,
  EmployeeDataFields.EMPLOYING_ENTITY,
  EmployeeDataFields.REGIONAL_OR_LOCAL,
  EmployeeDataFields.EMPLOYMENT_TYPE,
  EmployeeDataFields.INTERNAL_JOB_GRADE,
  EmployeeDataFields.JOB_GRADE,
  EmployeeDataFields.INTERNAL_JOB_TITLE,
  EmployeeDataFields.JOB_TITLE,
  EmployeeDataFields.RECRUITMENT_CATEGORY,
  EmployeeDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL,
  EmployeeDataFields.WORK_CONSIDERATION,
  EmployeeDataFields.SOCIAL_TYPE,
  EmployeeDataFields.CAREER_LADDER,
  EmployeeDataFields.LOCAL_OR_NON_LOCAL,
  EmployeeDataFields.AGE_GROUP,
  EmployeeDataFields.TENURE_GROUP,
  EmployeeDataFields.MARITAL_STATUS,
  EmployeeDataFields.COMPANY_COUNTRY,
  EmployeeDataFields.COST_CENTER,
  EmployeeDataFields.POSITION,
  EmployeeDataFields.CUSTOM_FILTER_1,
  EmployeeDataFields.CUSTOM_FILTER_2,
  EmployeeDataFields.CUSTOM_FILTER_3,
  EmployeeDataFields.CUSTOM_FILTER_4,
  EmployeeDataFields.CUSTOM_FILTER_5,
  EmployeeDataFields.CUSTOM_FILTER_6,
  EmployeeDataFields.CUSTOM_FILTER_7,
  EmployeeDataFields.CUSTOM_FILTER_8,
  EmployeeDataFields.CUSTOM_FILTER_9,
  EmployeeDataFields.CUSTOM_FILTER_10,
  EmployeeDataFields.CUSTOM_FILTER_11,
  EmployeeDataFields.CUSTOM_FILTER_12,
  EmployeeDataFields.CUSTOM_FILTER_13,
  EmployeeDataFields.CUSTOM_FILTER_14,
  EmployeeDataFields.CUSTOM_FILTER_15,
  EmployeeDataFields.CUSTOM_FILTER_16,
  EmployeeDataFields.CUSTOM_FILTER_17,
  EmployeeDataFields.CUSTOM_FILTER_18,
  EmployeeDataFields.CUSTOM_FILTER_19,
  EmployeeDataFields.CUSTOM_FILTER_20,
  EmployeeDataFields.JOINING_AGE_GROUP,
  EmployeeDataFields.MANAGER_OR_IC,
  EmployeeDataFields.CUSTOM_FIELD_1,
  EmployeeDataFields.CUSTOM_FIELD_2,
  EmployeeDataFields.CUSTOM_FIELD_3,
  EmployeeDataFields.CUSTOM_FIELD_4,
  EmployeeDataFields.CUSTOM_FIELD_5,
  EmployeeDataFields.CUSTOM_FIELD_6,
  EmployeeDataFields.CUSTOM_FIELD_7,
  EmployeeDataFields.CUSTOM_FIELD_8,
  EmployeeDataFields.CUSTOM_FIELD_9,
  EmployeeDataFields.CUSTOM_FIELD_10,
  EmployeeDataFields.DEPARTMENT,
  // commented out instead of deleting so it's easy to edit
  // and also so we know which ones we have intentionally disabled
  // EmployeeDataFields.EFFECTIVE_DATE,
  // EmployeeDataFields.EFFECTIVE_MONTH,
  // EmployeeDataFields.START_DATE,
  // EmployeeDataFields.TERM_DATE,
  // EmployeeDataFields.EFFECTIVE_LEAVER_DATE,
  // EmployeeDataFields.EMPLOYEE_ID,
  // EmployeeDataFields.MEMBER_ID,
  // EmployeeDataFields.FULL_NAME,
  // EmployeeDataFields.FIRST_NAME,
  // EmployeeDataFields.LAST_NAME,
  // EmployeeDataFields.LOCAL_FULL_NAME,
  // EmployeeDataFields.PREFERRED_NAME,
  // EmployeeDataFields.COMPANY_EMAIL,
  // EmployeeDataFields.DATE_OF_BIRTH,
  // EmployeeDataFields.MANAGER_ID,
  // EmployeeDataFields.MANAGER_EMAIL,
  // EmployeeDataFields.PERFORMANCE_CYCLE,
  // EmployeeDataFields.ONBOARDING_SURVEY_SCORE,
  // EmployeeDataFields.ONBOARDING_SURVEY_SCORE_GROUP,
  // EmployeeDataFields.PERFORMANCE_REGULAR_CYCLE,
  // EmployeeDataFields.PERFORMANCE_BONUS_CYCLE,
  // EmployeeDataFields.MANAGER_FEEDBACK,
  // EmployeeDataFields.TOTAL_AVERAGE_SCORE,
  // EmployeeDataFields.TOTAL_AVERAGE_SCORE_QUARTILE,
  // EmployeeDataFields.COMMUNICATION_SKILL_SCORE,
  // EmployeeDataFields.COMMUNICATION_SKILL_SCORE_QUARTILE,
  // EmployeeDataFields.GENERAL_COGNITIVE_ABILITY_SCORE,
  // EmployeeDataFields.GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE,
  // EmployeeDataFields.ROLE_RELATED_KNOWLEDGE_SCORE,
  // EmployeeDataFields.ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE,
  // EmployeeDataFields.REGRET_ATTRITION,
  // EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION,
  // EmployeeDataFields.PERFORMANCE_RATING,
  // EmployeeDataFields.BASE_SALARY_CURRENCY,
  // EmployeeDataFields.BASE_SALARY,
  // EmployeeDataFields.BASE_SALARY_FREQUENCY,
  // EmployeeDataFields.BASE_SALARY_PER_YEAR,
  // EmployeeDataFields.REVENUE_SALARY,
  // EmployeeDataFields.REVENUE_SALARY_CURRENCY,
  // EmployeeDataFields.LOCAL_REVENUE_SALARY,
  // EmployeeDataFields.ICR,
  // EmployeeDataFields.ANNUAL_CASH_BONUS_1_CURRENCY,
  // EmployeeDataFields.ANNUAL_CASH_BONUS_1,
  // EmployeeDataFields.LOCAL_FIRST_NAME,
  // EmployeeDataFields.LOCAL_LAST_NAME,
  // EmployeeDataFields.AGE,
  // EmployeeDataFields.STANDARDIZED_BASE_SALARY,
  // EmployeeDataFields.STANDARDIZED_BASE_SALARY_PER_YEAR,
  // EmployeeDataFields.LOCAL_SALARY_CURRENCY,
  // EmployeeDataFields.LOCAL_SALARY,
  // EmployeeDataFields.DAYS_IN_COMPANY,
  // EmployeeDataFields.LOCAL_FIRST_NAME_PRONUNCIATION,
  // EmployeeDataFields.LOCAL_LAST_NAME_PRONUNCIATION,
  // EmployeeDataFields.LOCAL_FULL_NAME_PRONUNCIATION,
  // EmployeeDataFields.LOCAL_FIRST_NAME_PRONUNCIATION_2,
  // EmployeeDataFields.LOCAL_LAST_NAME_PRONUNCIATION_2,
  // EmployeeDataFields.LOCAL_FULL_NAME_PRONUNCIATION_2,
  // EmployeeDataFields.JOINING_AGE,
  // EmployeeDataFields.ATTRITION_SCORE,
  // EmployeeDataFields.CAREER_CHANGE_INTENTION,
  // EmployeeDataFields.INTERNAL_TRANSFER_INTENTION,
  // EmployeeDataFields.PULSE_SURVEY_CYCLE_1,
  // EmployeeDataFields.PULSE_SURVEY_CYCLE_2,
  // EmployeeDataFields.PULSE_SURVEY_CYCLE_3,
  // EmployeeDataFields.PULSE_SURVEY_SCORE_1,
  // EmployeeDataFields.PULSE_SURVEY_SCORE_2,
  // EmployeeDataFields.CLIENT_EFFECTIVE_DATE,
  // EmployeeDataFields.CAREER_COURSE,
  // EmployeeDataFields.TOTAL_COMPENSATION_CURRENCY,
  // EmployeeDataFields.TOTAL_COMPENSATION,
  // EmployeeDataFields.PND_VALUE,
  // EmployeeDataFields.SPAN_OF_CONTROL,
  // EmployeeDataFields.DEPTH_OF_CONTROL,
  // EmployeeDataFields.DEFINED_MANAGER,
  // EmployeeDataFields.BASE_BOARD_COMPENSATION,
  // EmployeeDataFields.VARIABLE_BOARD_COMPENSATION,
  // EmployeeDataFields.FTE_VALUE,
  // EmployeeDataFields.move_from,
  // EmployeeDataFields.move_to,
  // EmployeeDataFields.TENURE_AS_MANAGER,
  // EmployeeDataFields.STATUTORY_ELIGIBILITY_FOR_PARENTAL_LEAVE,
  // EmployeeDataFields.DISABILITY_SEVERITY,
  // EmployeeDataFields.MANAGEMENT_POOL,
  // EmployeeDataFields.YOUNGEST_CHILD_BIRTHDATE,
  // EmployeeDataFields.LATEST_PARENTAL_LEAVE_STARTDATE
].map((f) => toDataFieldWithDataType(DataTypes.EMPLOYEE, f));

export const allowedJobChartDimensionFields = [
  JobDataFields.JOB_NAME,
  JobDataFields.JOB_GRADE,
  JobDataFields.OFFICE,
  JobDataFields.ORGANIZATION,
  JobDataFields.LOCATION,
  JobDataFields.FUNCTION,
  JobDataFields.RECRUITMENT_CATEGORY,
  JobDataFields.STATUS,
  JobDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL,
  JobDataFields.CUSTOM_FIELD_1,
  JobDataFields.CUSTOM_FIELD_2,
].map((f) => toDataFieldWithDataType(DataTypes.JOB, f));

export const allowedApplicationChartDimensionFields = [
  ApplicationDataFields.SOURCE,
  ApplicationDataFields.SOURCE_TYPE,
  ApplicationDataFields.CURRENT_JOB_TITLE,
  ApplicationDataFields.REJECTION_REASON,
  ApplicationDataFields.APPLICATION_CURRENT_STAGE,
  ApplicationDataFields.STATUS,
  ApplicationDataFields.OFFICE,
  ApplicationDataFields.LOCATION,
  ApplicationDataFields.ORGANIZATION,
  ApplicationDataFields.JOB_NAME,
  ApplicationDataFields.SOURCE_ID,
  ApplicationDataFields.SOURCE_NAME,
  ApplicationDataFields.DEGREE_TYPE,
  ApplicationDataFields.DEGREE_MAJOR,
  ApplicationDataFields.CUSTOM_FIELD_1,
  ApplicationDataFields.CUSTOM_FILTER_1,
  ApplicationDataFields.CUSTOM_FILTER_2,
  ApplicationDataFields.CUSTOM_FILTER_3,
  ApplicationDataFields.CUSTOM_FILTER_4,
  ApplicationDataFields.CUSTOM_FILTER_5,
].map((f) => toDataFieldWithDataType(DataTypes.JOB, f));

export const allowedChartDimensionFields = [
  ...allowedEmployeeChartDimensionFields,
  ...allowedJobChartDimensionFields,
  ...allowedApplicationChartDimensionFields,
];
