import BarChartIcon from '@mui/icons-material/BarChart';
import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InfoIcon from '@mui/icons-material/Info';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { FullScreen } from 'react-full-screen';
import styled, { css } from 'styled-components';
import chartViewIconUrl from '../../../../assets/image/dataContainerIcons/chartView.png';
import fullScreenIconUrl from '../../../../assets/image/dataContainerIcons/fullScreen.png';
import labelIconUrl from '../../../../assets/image/dataContainerIcons/label.png';
import percentageIconUrl from '../../../../assets/image/dataContainerIcons/percentage.png';
import sortIconUrl from '../../../../assets/image/dataContainerIcons/sort.png';
import tableViewIconUrl from '../../../../assets/image/dataContainerIcons/tableView.png';
import { lightestGray, mediumGray, primaryColor, teal2 } from './theme';

const StyledIcon = css`
  color: ${mediumGray};
  font-size: 1.4em;
  cursor: pointer;
  &:hover {
    color: ${teal2};
  }
`;

const ChartIconButtonCss = css`
  margin-left: 5px;
  text-align: center;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: right;
  height: 16px;
  width: 16px;
`;

export const ChartIconButton = styled('img')`
  ${ChartIconButtonCss}
  background-color: ${primaryColor};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  ${StyledIcon}
`;

export const FilterIcon = styled(FilterAltIcon)`
  ${StyledIcon}
`;

export const StackIcon = styled(StackedBarChartIcon)`
  ${ChartIconButtonCss}
  background-color: ${teal2};
  font-size: 1.4em;
  cursor: pointer;
  color: white;
`;

export const BarIcon = styled(BarChartIcon)`
  ${ChartIconButtonCss}
  background-color: ${teal2};
  font-size: 1.4em;
  cursor: pointer;
  color: white;
`;

export const LegendIcon = styled(LegendToggleIcon)`
  ${ChartIconButtonCss}
  background-color: ${teal2};
  font-size: 1.4em;
  cursor: pointer;
  color: white;
`;

export const DateRangeIcon = styled(DateRangeSharpIcon)`
  ${StyledIcon}
`;

export const SortIcon = styled(ChartIconButton).attrs({ src: sortIconUrl })`
  ${StyledIcon}
`;

export const LabelsIcon = styled(ChartIconButton).attrs({ src: labelIconUrl })`
  ${StyledIcon}
`;

export const PercentageIcon = styled(ChartIconButton).attrs({ src: percentageIconUrl })`
  ${StyledIcon}
`;

export const FullScreenIcon = styled(ChartIconButton).attrs({ src: fullScreenIconUrl })`
  ${StyledIcon}
`;

export const TableViewIcon = styled(ChartIconButton).attrs({ src: tableViewIconUrl })`
  ${StyledIcon}
`;

export const ChartViewIcon = styled(ChartIconButton).attrs({ src: chartViewIconUrl })`
  ${StyledIcon}
`;

export const FullHeightFullScreen = styled(FullScreen)<{ $fullScreenActive: boolean }>`
  background-color: ${(props) => (props.$fullScreenActive ? lightestGray : 'inherit')};
  height: 100%;
`;

export const CHART_TITLE_HEIGHT = '16px';
export const CHART_CONTENT_HEIGHT = `calc(100% - ${CHART_TITLE_HEIGHT})`;
