import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframe for the pulse animation
const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

// Styled container
const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem; /* sm:max-w-lg */
  animation: ${pulse} 2s infinite;
`;

// Common styles for the loading bars
const LoadingBar = styled.div<{ $minWidth: string; width?: string }>`
  height: 1rem; /* h-4 */
  margin: 0.25rem 0; /* my-1 */
  background-color: #e5e7eb; /* bg-gray-200 */
  border-radius: 9999px; /* rounded-full */
  min-width: ${(props) => props.$minWidth || '11rem'}; /* min-w-* */
  width: ${(props) => props.width || 'auto'}; /* Width if specified */

  .dark & {
    background-color: #3f3f46; /* dark:bg-neutral-700 */
  }
`;

export const Skeleton = () => {
  return (
    <Container>
      <LoadingBar $minWidth="11rem" width="11rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
      <LoadingBar $minWidth="24rem" />
    </Container>
  );
};
