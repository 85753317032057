import { once } from 'lodash';
import { createContext, useContext } from 'react';
import { AliasService } from '../api/alias/service';
import { AuthorizationService } from '../api/authorization/service';
import { DomainViewDashboardService } from '../api/dashboards/domain-view-dashboard-service';
import { FullViewDashboardService } from '../api/dashboards/full-view-dashboard-service';
import { UserViewDashboardService } from '../api/dashboards/user-view-dashboard-service';
import { DataService } from '../api/data/service';
import { EmployeeService } from '../api/employee/service';
import { MetricsService } from '../api/metrics/service';
import { MetricDetails } from '../api/metrics/types';
import { BackendService } from '../api/service';
import { LatestDomainPreferences, Role, RoleId, VersionId } from '../api/types';
import { CohortMetricId, EmployeeCohortMetricId, RegularMetricId } from '../api/types-graphql';
import { UserRolePreferencesService } from '../api/userrolepreferences/service';
import { FilterTypes } from '../common/components/filter/filterbar/types';
import { FilterSection } from '../common/components/filter/types';
import { TimeSliderHandle } from '../common/components/timeslider/types';
import { Domains, Languages } from '../constants';
import { DataFieldWithDataType, DataTypes } from '../types';
import { GlobalDisplayHandle, GlobalFilterHandle, GlobalResetHandle, GlobalSegmentHandle } from './types';

export const ExecutorRoleContext = createContext<Role | null>(null);

export const useExecutorRoleContext = () => {
  const context = useContext(ExecutorRoleContext);
  if (!context) {
    throw new Error('useExecutorRoleContext must be used within a ExecutorRoleContextProvider');
  }
  return context;
};

export const LatestDomainPreferencesContext = createContext<LatestDomainPreferences | null>(null);

export const useLatestDomainPreferencesContext = () => {
  const context = useContext(LatestDomainPreferencesContext);
  if (!context) {
    throw new Error('useLatestDomainPreferencesContext must be used within a LatestDomainPreferencesContext');
  }
  return context;
};

export const EffectiveRoleContext = createContext<RoleId | null>(null);

export const useEffectiveRoleContext = () => {
  const context = useContext(EffectiveRoleContext);
  if (!context) {
    throw new Error('useEffectiveRoleContext must be used within a EffectiveRoleContextProvider');
  }
  return context;
};

export const LatestVersionsContext = createContext<Partial<Record<DataTypes, VersionId>> | null>(null);

export const useLatestVersionsContext = () => {
  const context = useContext(LatestVersionsContext);
  if (!context) {
    throw new Error('useLatestVersionsContext must be used within a LatestVersionsContextProvider');
  }
  return context;
};

export const PermittedFiltersContext = createContext<DataFieldWithDataType[] | null>(null);

export const usePermittedFiltersContext = () => {
  const context = useContext(PermittedFiltersContext);
  if (!context) {
    throw new Error('usePermittedFiltersContext must be used within a PermittedFiltersContextProvider');
  }
  return context;
};

export const DomainContext = createContext<Domains | null>(null);

export const useGlobalDomainContext = () => {
  const context = useContext(DomainContext);
  if (!context) {
    throw new Error('useGlobalDomainContext must be used within a DomainContextProvider');
  }
  return context;
};

export const FullViewDashboardServiceContext = createContext<FullViewDashboardService | null>(null);

export const useFullViewDashboardServiceContext = () => {
  const context = useContext(FullViewDashboardServiceContext);
  if (!context) {
    throw new Error('useFullViewDashboardServiceContext must be used within a FullViewDashboardServiceContextProvider');
  }
  return context;
};

// @Alex, wondering if we can simplify this boilerplate
export const DomainViewDashboardServiceContext = createContext<DomainViewDashboardService | null>(null);

export const useDomainViewDashboardServiceContext = () => {
  const context = useContext(DomainViewDashboardServiceContext);
  if (!context) {
    throw new Error(
      'useDomainViewDashboardServiceContext must be used within a DomainViewDashboardServiceContextProvider'
    );
  }
  return context;
};

export const UserViewDashboardServiceContext = createContext<UserViewDashboardService | null>(null);

export const useUserViewDashboardServiceContext = () => {
  const context = useContext(UserViewDashboardServiceContext);
  if (!context) {
    throw new Error('useUserViewDashboardServiceContext must be used within a UserViewDashboardServiceContextProvider');
  }
  return context;
};

export const LocaleContext = createContext<{ selected: Languages } | null>(null);

export const useGlobalLocaleContext = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useGlobalLocaleContext must be used within a LocaleContextProvider');
  }
  return context;
};

export const ResetContext = createContext<GlobalResetHandle | null>(null);

export const useGlobalResetContext = () => {
  const context = useContext(ResetContext);
  if (!context) {
    throw new Error('useGlobalResetContext must be used within a ResetContextProvider');
  }
  return context;
};

export const FilterContext = createContext<GlobalFilterHandle | null>(null);

export const useGlobalFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useGlobalFilterContext must be used within a FilterContextProvider');
  }
  return context;
};

export const FilterRecruitmentContext = createContext<GlobalFilterHandle | null>(null);

export const useGlobalFilterRecruitmentContext = () => {
  const context = useContext(FilterRecruitmentContext);
  if (!context) {
    throw new Error('useGlobalFilterRecruitmentContext must be used within a FilterRecruitmentContextProvider');
  }
  return context;
};

export const FilterMovementContext = createContext<GlobalFilterHandle | null>(null);

export const useGlobalFilterMovementContext = () => {
  const context = useContext(FilterMovementContext);
  if (!context) {
    throw new Error('useGlobalFilterMovementContext must be used within a FilterMovementContextProvider');
  }
  return context;
};

export const TimeSliderContext = createContext<TimeSliderHandle | null>(null);

export const useGlobalTimeSliderContext = () => {
  const context = useContext(TimeSliderContext);
  if (!context) {
    throw new Error('useGlobalTimeSliderContext must be used within a TimeSliderContextProvider');
  }
  return context;
};

export const MetricDetailsMapContext = createContext<Record<
  RegularMetricId | CohortMetricId | EmployeeCohortMetricId,
  MetricDetails
> | null>(null);

export const useMetricDetailsMapContext = () => {
  const context = useContext(MetricDetailsMapContext);
  if (!context) {
    throw new Error('useMetricDetailsMapContext must be used within a MetricDetailsMapProvider');
  }
  return context;
};

export const SegmentationLevel1Context = createContext<GlobalSegmentHandle | null>(null);

export const useGlobalSegmentationLevel1Context = () => {
  const context = useContext(SegmentationLevel1Context);
  if (!context) {
    throw new Error('useGlobalSegmentationLevel1Context must be used within a SegmentationLevel1ContextProvider');
  }
  return context;
};

export const SegmentationLevel2Context = createContext<GlobalSegmentHandle | null>(null);

export const useGlobalSegmentationLevel2Context = () => {
  const context = useContext(SegmentationLevel2Context);
  if (!context) {
    throw new Error('useGlobalSegmentationLevel2Context must be used within a SegmentationLevel2ContextProvider');
  }
  return context;
};

export const SegmentationLevel1RecruitmentContext = createContext<GlobalSegmentHandle | null>(null);

export const useGlobalSegmentationLevel1RecruitmentContext = () => {
  const context = useContext(SegmentationLevel1RecruitmentContext);
  if (!context) {
    throw new Error(
      'useGlobalSegmentationLevel1RecruitmentContext must be used within a SegmentationLevel1RecruitmentContextProvider'
    );
  }
  return context;
};

export const SegmentationLevel2RecruitmentContext = createContext<GlobalSegmentHandle | null>(null);

export const useGlobalSegmentationLevel2RecruitmentContext = () => {
  const context = useContext(SegmentationLevel2RecruitmentContext);
  if (!context) {
    throw new Error(
      'useGlobalSegmentationLevel2RecruitmentContext must be used within a SegmentationLevel2RecruitmentContextProvider'
    );
  }
  return context;
};

export const DisplayContext = createContext<GlobalDisplayHandle | null>(null);

export const useDisplayGlobalContext = () => {
  const context = useContext(DisplayContext);
  if (!context) {
    throw new Error('useDisplayContext must be used within a DisplayContextProvider');
  }
  return context;
};

export const UserRolePreferencesServiceContext = once(<T extends FilterTypes>() =>
  createContext<UserRolePreferencesService<T> | null>(null)
);

export const useUserRolePreferencesServiceContext = <T extends FilterTypes>() => {
  const context = useContext(UserRolePreferencesServiceContext<T>());
  if (!context) {
    throw new Error('useUserRolePreferencesService must be used within a UserRolePreferencesServiceContextProvider');
  }
  return context;
};

export const AliasServiceContext = createContext<AliasService | null>(null);

export const useAliasServiceContext = () => {
  const context = useContext(AliasServiceContext);
  if (!context) {
    throw new Error('useAliasService must be used within a AliasServiceContextProvider');
  }
  return context;
};

export const MetricServiceContext = createContext<MetricsService | null>(null);

export const useMetricServiceContext = () => {
  const context = useContext(MetricServiceContext);
  if (!context) {
    throw new Error('useMetricQueryServiceContext must be used within a MetricServiceContext');
  }
  return context;
};

export const AuthorizationServiceContext = createContext<AuthorizationService | null>(null);

export const useAuthorizationServiceContext = () => {
  const context = useContext(AuthorizationServiceContext);
  if (!context) {
    throw new Error('useAuthorizationServiceContext must be used within a AuthorizationContextProvider');
  }
  return context;
};

export const BackendServiceContext = createContext<BackendService | null>(null);

export const useBackendServiceContext = () => {
  const context = useContext(BackendServiceContext);
  if (!context) {
    throw new Error('useBackendServiceContext must be used within a BackendContextProvider');
  }
  return context;
};

export const EmployeeServiceContext = createContext<EmployeeService | null>(null);

export const useEmployeeServiceContext = () => {
  const context = useContext(EmployeeServiceContext);
  if (!context) {
    throw new Error('useEmployeeServiceContext must be used within a EmployeeContextProvider');
  }
  return context;
};

export const DataServiceContext = createContext<DataService | null>(null);

export const useDataServiceContext = () => {
  const context = useContext(DataServiceContext);
  if (!context) {
    throw new Error('useDataServiceContext must be used within a DataContextProvider');
  }
  return context;
};

export const FilterSectionsContext = createContext<FilterSection[] | null>(null);

export const useFilterSectionsContext = () => {
  const context = useContext(FilterSectionsContext);
  if (!context) {
    throw new Error('useFilterSectionsContext must be used within a FilterSectionsContextProvider');
  }
  return context;
};

export const SegmentationSectionsContext = createContext<FilterSection[] | null>(null);

export const useSegmentationSectionsContext = () => {
  const context = useContext(SegmentationSectionsContext);
  if (!context) {
    throw new Error('useSegmentationSectionsContext must be used within a SegmentationSectionsContextProvider');
  }
  return context;
};
