import { Grid, styled, Typography } from '@mui/material';
import { LineSeriesType } from '@mui/x-charts';
import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { UNDEFINED_DISPLAY_KEY } from '../../../constants';
import { DataFieldWithDataType } from '../../../types';
import { CHART_CONTENT_HEIGHT, CHART_TITLE_HEIGHT } from '../../styles';
import { ManualDataRefetchCountHandle } from '../../types';
import { toMuiChartsOverTimeData } from '../charts/mui-charts/utils-display';
import { useMetricQueries } from '../charts/overtime/hooks';
import { TableComponentConfig } from '../dashboards/types';
import { Filter, Segment } from '../filter/filterbar/types';
import { ItemsState } from '../filter/types';
import { formatHierarchicalLabel } from '../filter/utils-display';
import { TimeSliderState } from '../timeslider/types';
import { TableViewMetrics } from './TableViewMetrics';
import { Tools } from './Tools';

const CategoryText = styled(Typography)`
  color: #ffffff; /* equivalent to text-tremor-content-inverted */
  font-size: 0.875rem; /* equivalent to text-sm */
  font-weight: normal;
  margin-bottom: 0.5rem;
`;

interface TableViewOverTimeProps {
  componentConfig: TableComponentConfig;
  manualDataRefetchCountHandle: ManualDataRefetchCountHandle;
  timeSliderState: TimeSliderState | null;
  filtersState: ItemsState<Filter>;
  segmentationState: ItemsState<Segment>;
  showTools: boolean;
  fullScreenHandle: FullScreenHandle;
}

export const TableViewOverTime = (props: TableViewOverTimeProps) => {
  const {
    componentConfig,
    manualDataRefetchCountHandle,
    timeSliderState,
    filtersState,
    segmentationState,
    showTools,
    fullScreenHandle,
  } = props;
  const { title, queries, chartTypeConfig } = componentConfig;
  const { t } = useTranslation();
  const formatLabel = (value: (string | null)[], dataField: DataFieldWithDataType) =>
    formatHierarchicalLabel(value, dataField, t(UNDEFINED_DISPLAY_KEY), t);
  const fakeGlobalState = {
    enablePercentage: false,
    showPercentage: false,
    enableStack: false,
    showStack: false,
    enableGridLines: false,
    showGridLines: false,
    enableLabels: false,
    showLabels: false,
    enableLegend: false,
    showLegend: false,
  };

  const {
    data: { tableViewData },
  } = useMetricQueries<LineSeriesType>(
    queries,
    timeSliderState,
    filtersState,
    segmentationState,
    manualDataRefetchCountHandle.current,
    chartTypeConfig,
    fakeGlobalState,
    formatLabel,
    undefined,
    t,
    toMuiChartsOverTimeData<LineSeriesType>
  );
  return (
    <Grid item container direction="column" sx={{ height: '100%' }}>
      <Grid item container sx={{ height: CHART_TITLE_HEIGHT }}>
        {showTools ? <Tools fullScreenHandle={fullScreenHandle} /> : null}
      </Grid>
      <Grid item container sx={{ height: CHART_CONTENT_HEIGHT }}>
        <TableViewMetrics
          tableData={tableViewData}
          fullScreenHandle={fullScreenHandle}
          chartHeight={CHART_CONTENT_HEIGHT}
        />
      </Grid>
    </Grid>
  );
};
