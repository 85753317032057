import {
  ApplicationCurrentStateValues,
  ApplicationStatusValues,
  EmploymentRegularityValues,
  GenderValues,
  HireStatusValues,
  JobStatusValues,
  LocalOrNonLocalValues,
  ManagerOrIcValues,
  MartialStatusValues,
  RegionalOrLocalValues,
  RegretNonRegretValues,
  VoluntaryInvoluntaryValues,
} from './types';

export const hireStatusValuesTranslationMap: Record<HireStatusValues, string> = {
  [HireStatusValues.Accepted]: 'common:filterTray.hireStatusValues.accepted',
  [HireStatusValues.ACCEPTED]: 'common:filterTray.hireStatusValues.accepted',
  [HireStatusValues.Rejected]: 'common:filterTray.hireStatusValues.rejected',
  [HireStatusValues.Rejcted]: 'common:filterTray.hireStatusValues.rejected',
  [HireStatusValues.UNRESOLVED]: 'common:filterTray.hireStatusValues.unresolved',
  [HireStatusValues.DEPRECATED]: 'common:filterTray.hireStatusValues.deprecated',
};

export const jobStatusValuesTranslationMap: Record<JobStatusValues, string> = {
  [JobStatusValues.OPEN]: 'common:filterTray.jobStatusValues.open',
  [JobStatusValues.CLOSED]: 'common:filterTray.jobStatusValues.closed',
  [JobStatusValues.DRAFT]: 'common:filterTray.jobStatusValues.draft',
  [JobStatusValues.ARCHIVED]: 'common:filterTray.jobStatusValues.archived',
};

export const applicationStatusValuesTranslationMap: Record<ApplicationStatusValues, string> = {
  [ApplicationStatusValues.Active]: 'common:filterTray.applicationStatusValues.active',
  [ApplicationStatusValues.Rejected]: 'common:filterTray.applicationStatusValues.rejected',
  [ApplicationStatusValues.Hired]: 'common:filterTray.applicationStatusValues.hired',
};

export const applicationCurrentStageValuesTranslationMap: Record<ApplicationCurrentStateValues, string> = {
  [ApplicationCurrentStateValues.Applied]: 'common:filterTray.applicationCurrentStageValues.applied',
  [ApplicationCurrentStateValues.Offered]: 'common:filterTray.applicationCurrentStageValues.offered',
  [ApplicationCurrentStateValues.Accepted]: 'common:filterTray.applicationCurrentStageValues.accepted',
  [ApplicationCurrentStateValues.Rejected]: 'common:filterTray.applicationCurrentStageValues.rejected',
  [ApplicationCurrentStateValues.Screening]: 'common:filterTray.applicationCurrentStageValues.screening',
  [ApplicationCurrentStateValues.Joined]: 'common:filterTray.applicationCurrentStageValues.joined',
  [ApplicationCurrentStateValues.Interview]: 'common:filterTray.applicationCurrentStageValues.interview',
  [ApplicationCurrentStateValues.FirstInterview]: 'common:filterTray.applicationCurrentStageValues.firstInterview',
  [ApplicationCurrentStateValues.FirstInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.firstInterviewPassed',
  [ApplicationCurrentStateValues.FirstInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.firstInterviewFailed',
  [ApplicationCurrentStateValues.SecondInterview]: 'common:filterTray.applicationCurrentStageValues.secondInterview',
  [ApplicationCurrentStateValues.SecondInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.secondInterviewPassed',
  [ApplicationCurrentStateValues.SecondInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.secondInterviewFailed',
  [ApplicationCurrentStateValues.ThirdInterview]: 'common:filterTray.applicationCurrentStageValues.thirdInterview',
  [ApplicationCurrentStateValues.ThirdInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.thirdInterviewPassed',
  [ApplicationCurrentStateValues.ThirdInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.thirdInterviewFailed',
  [ApplicationCurrentStateValues.FourthInterview]: 'common:filterTray.applicationCurrentStageValues.fourthInterview',
  [ApplicationCurrentStateValues.FourthInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.fourthInterviewPassed',
  [ApplicationCurrentStateValues.FourthInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.fourthInterviewFailed',
  [ApplicationCurrentStateValues.FifthInterview]: 'common:filterTray.applicationCurrentStageValues.fifthInterview',
  [ApplicationCurrentStateValues.FifthInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.fifthInterviewPassed',
  [ApplicationCurrentStateValues.FifthInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.fifthInterviewFailed',
  [ApplicationCurrentStateValues.CeoInterview]: 'common:filterTray.applicationCurrentStageValues.ceoInterview',
  [ApplicationCurrentStateValues.CeoInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.ceoInterviewPassed',
  [ApplicationCurrentStateValues.CeoInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.ceoInterviewFailed',
  [ApplicationCurrentStateValues.FinalInterview]: 'common:filterTray.applicationCurrentStageValues.finalInterview',
  [ApplicationCurrentStateValues.FinalInterviewPassed]:
    'common:filterTray.applicationCurrentStageValues.finalInterviewPassed',
  [ApplicationCurrentStateValues.FinalInterviewFailed]:
    'common:filterTray.applicationCurrentStageValues.finalInterviewFailed',
};

export const genderTranslationKeyMap: Record<GenderValues, string> = {
  [GenderValues.Male]: 'common:commonValues.misc.male',
  [GenderValues.Female]: 'common:commonValues.misc.female',
};

export const managerOrICTranslationKeyMap: Record<ManagerOrIcValues, string> = {
  [ManagerOrIcValues.Manager]: 'common:commonValues.misc.manager',
  [ManagerOrIcValues.Ic]: 'common:commonValues.misc.IC',
  [ManagerOrIcValues.EO]: 'common:commonValues.misc.EO',
};

export const maritalStatusTranslationKeyMap: Record<MartialStatusValues, string> = {
  [MartialStatusValues.Single]: 'common:filterTray.maritalStatusValues.single',
  [MartialStatusValues.Married]: 'common:filterTray.maritalStatusValues.married',
};

export const regionalLocalTranslationKeyMap: Record<RegionalOrLocalValues, string> = {
  [RegionalOrLocalValues.Regional]: 'common:filterTray.regionalLocalValues.regional',
  [RegionalOrLocalValues.Local]: 'common:filterTray.regionalLocalValues.local',
};

export const localNonLocalTranslationKeyMap: Record<LocalOrNonLocalValues, string> = {
  [LocalOrNonLocalValues.Local]: 'common:filterTray.localNonLocalValues.local',
  [LocalOrNonLocalValues.NonLocal]: 'common:filterTray.localNonLocalValues.nonLocal',
};

export const regretNonRegretTranslationKeyMap: Record<RegretNonRegretValues, string> = {
  [RegretNonRegretValues.Regret]: 'common:filterTray.regretNonRegretValues.regret',
  [RegretNonRegretValues.NonRegret]: 'common:filterTray.regretNonRegretValues.nonRegret',
};

export const voluntaryNonVoluntaryTranslationKeyMap: Record<VoluntaryInvoluntaryValues, string> = {
  [VoluntaryInvoluntaryValues.Voluntary]: 'common:filterTray.voluntaryInvoluntaryValues.voluntary',
  [VoluntaryInvoluntaryValues.Involuntary]: 'common:filterTray.voluntaryInvoluntaryValues.involuntary',
};

export const employmentRegularityTranslationKeyMap: Record<EmploymentRegularityValues, string> = {
  [EmploymentRegularityValues.Regular]: 'common:filterTray.employmentRegularity.regular',
  [EmploymentRegularityValues.NonRegular]: 'common:filterTray.employmentRegularity.nonRegular',
  [EmploymentRegularityValues.Undefined]: 'common:filterTray.employmentRegularity.undefined',
};
