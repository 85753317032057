import { action, autorun, computed, observable, runInAction } from 'mobx';
import { DOMAIN_KEY, LatestDomainPreferences, LatestDomainSettings } from '../api/api-interfaces';
import { companyApiService } from '../api/company-service';
import { ApiCompany, DomainPreferences } from '../api/zod-schemas';
import { localStore } from '../local-store';
import { trackAPIError } from '../sentry/sentry';
import { InitialDependencyStore } from '../startup/initial-dependency-store';
import { StateType } from '../user/user-store';
import { Domains } from '../v2/constants';
import { PartnerDomains } from './../constants/constants';

/**
 * Note that we also use a new pattern to handle and cache the data that we store in here, see src/app/common/queryHooks/company-query-hooks.ts
 * This means the state is currently duplicated and needs to be kept in sync!
 */
export class CompanyStore {
  public japaneseEnterpriseClientDomains: Domains[] = Object.values(Domains);

  public isJapaneseEnterpriseClient = (): boolean => {
    const isJapaneseEnterpriseClient = this.japaneseEnterpriseClientDomains.includes(this.domain as Domains);
    return isJapaneseEnterpriseClient;
  };

  @observable
  public availableDomains: ApiCompany[] = [];

  @observable
  public domain: string = '';

  @computed
  public get selectedCompany(): ApiCompany | null {
    // Nomenclature note: selectedCompany is basically domainSettings
    return this.domainSettingsObject?.settings ?? null;
  }

  @computed
  public get isCrextaDomain(): boolean {
    return this.selectedCompany?.domainOfPartner === PartnerDomains.CREXTA;
  }

  @observable
  public domainSettingsObject: LatestDomainSettings | null = null;

  @observable
  public domainPreferencesObject: LatestDomainPreferences | null = null;

  @computed
  public get domainPreferences(): DomainPreferences | null {
    return this.domainPreferencesObject?.settings ?? null;
  }

  @observable
  public message: string = '';

  @observable
  public state: StateType | null = null;

  @observable
  public noCompanyForUser: boolean = false;

  @observable
  public selectedCompanyFetched: boolean = false;

  @observable
  public domainSettingsError = false;

  private initialDependencyStore: InitialDependencyStore;

  constructor(initialDependencyStore: InitialDependencyStore) {
    this.initialDependencyStore = initialDependencyStore;

    const domainFromLocalStorage = localStore.get(DOMAIN_KEY);
    if (domainFromLocalStorage) {
      this.domain = domainFromLocalStorage;
    }
    autorun(() => {
      if (this.domain && this.availableDomains.length) {
        // This situation happens when we change localEnv but the old domain is still in localStore
        const isValidLocalDomain = this.availableDomains.find((d) => d.domain === this.domain) !== undefined;
        if (!isValidLocalDomain) {
          runInAction(() => (this.domain = ''));
        }
      }
    });
  }

  public get getCompanies(): ApiCompany[] {
    return this.availableDomains;
  }

  @action
  public async getSelectedCompany() {
    if (this.domain) {
      const domainData = this.initialDependencyStore
        .getAllowedDomainsWithLatestSettingsAndPreferences()
        .find((d) => d.domain === this.domain);

      const domainSettingsResult = domainData?.latestSettings;
      const domainPreferencesResult = domainData?.latestPreferences;

      if (domainSettingsResult) {
        this.domainSettingsObject = domainSettingsResult;
        this.domainSettingsObject.settings = this.mapSurvey(this.domainSettingsObject.settings);
        if (this.domainSettingsObject) {
          this.selectedCompanyFetched = true;
        }
      } else {
        runInAction(() => (this.domainSettingsError = true));
        throw new Error(`There are no domain settings defined for ${this.domain}`);
      }
      if (domainPreferencesResult) {
        this.domainPreferencesObject = domainPreferencesResult;
      } else {
        throw new Error(`There are no domain preference defined for ${this.domain}`);
      }
    }
  }

  @action
  public reset() {
    this.state = null;
    this.message = '';
  }

  @action
  public loadAvailableDomains = async () => {
    const domainsWithData = this.initialDependencyStore.getAllowedDomainsWithLatestSettingsAndPreferences();

    if (domainsWithData.length === 0) {
      runInAction(() => {
        this.noCompanyForUser = true;
      });
      const error = new Error('The user does not have access to any domain');
      trackAPIError(error);
      throw error;
    } else if (!this.domain && domainsWithData.length === 1) {
      runInAction(() => {
        this.setDomain(domainsWithData[0].domain);
      });
      await this.getSelectedCompany();
      runInAction(() => {
        this.availableDomains = domainsWithData.map((d) => d.latestSettings?.settings) || [];
      });
    } else {
      runInAction(() => {
        this.availableDomains = domainsWithData.map((d) => d.latestSettings?.settings) || [];
      });
    }
    return this.availableDomains;
  };

  @action
  public setDomain(domain: string) {
    if (this.domain !== domain) {
      localStore.removeSavedStores();
    }
    localStore.set(DOMAIN_KEY, domain);
    if (this.domain) {
      location.reload();
    } else {
      this.domain = domain;
      this.getSelectedCompany();
    }
  }

  public getCompany(domain: string) {
    return this.getCompanies.find((company) => company.domain === domain) || null;
  }

  @action
  public async updateDomainSettings(company: ApiCompany) {
    if (company.domain) {
      const result = await companyApiService.updateDomainSettings(company.domain, {
        ...company,
      });
      if (result) {
        runInAction(() => {
          this.domainSettingsObject = result;
          this.setStateAndMessage(result, `Updated Domain Settings Successfully`);
        });
      }
      return result;
    }
  }

  @action
  public async updateDomainPreferences(company: DomainPreferences) {
    const result = await companyApiService.updateDomainPreferences(this.domain, {
      ...company,
    });
    if (result) {
      runInAction(() => {
        this.domainPreferencesObject = result;
        this.setStateAndMessage(result, `Updated Domain Preferences Successfully`);
      });
    }
    return result;
  }

  // TODO: modify the id pulse to survey in the backend
  private mapSurvey(result: ApiCompany) {
    return {
      ...result,
      allowedDashboards: result.allowedDashboards?.map((db) => (db.id === 'pulse' ? { ...db, id: 'survey' } : db)),
    };
  }

  @action
  private setStateAndMessage(result: any, successMessage: string) {
    const { error = {} } = result;
    const hasError = Object.keys(error).length;
    this.state = hasError ? 'ERROR' : 'DONE';
    this.message = hasError ? error.message : successMessage;
  }
}
