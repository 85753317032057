import React from 'react';
import { MarkElementProps } from '@mui/x-charts';
import { useLineSeries } from '@mui/x-charts/hooks/useSeries';
import { animated, useSpring } from '@react-spring/web';

export const MarkWithLabel = (props: MarkElementProps) => {
  const { x, y, id, dataIndex } = props;

  const series = useLineSeries()?.series[id];

  const position = useSpring({ to: { x, y }, immediate: true });
  const value = series?.data?.[dataIndex];
  const formattedValue = value ? series?.valueFormatter(value, { dataIndex }) : null;

  return (
    <React.Fragment>
      <animated.text
        x={position.x}
        y={position.y}
        transform="translate(0, -8)"
        textAnchor="middle"
        alignmentBaseline="baseline"
        fontSize={12}
      >
        {formattedValue || ''}
      </animated.text>
    </React.Fragment>
  );
};
