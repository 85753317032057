import range from 'lodash.range';
import { Moment } from 'moment';
import { DataFieldWithDataType, getDataFieldWithDataTypeFromKey } from '../../../common-types';
import {
  ApplicationDataFields,
  DataFields,
  DataTypes,
  EmployeeDataFields,
  JobDataFields,
  Languages,
  Operations,
} from '../constants/constants';
import { MetricGroupId, MetricResultMetaData } from '../graphql/generated/graphql-sdk';
import { DataValue } from '../v2/api/types';
import { HierarchicalFields as V2HierarchicalFields } from '../v2/constants';
import { ApiMasterDataQueryFilterItem } from '../v2/types';
import { ApiCompany, DomainPreferences } from './zod-schemas';

export enum MedianBaseToggleOptions {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}
// TODO: Permissions: will be removed
export enum ApiUserPermissionLevels {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
}

export const DOMAIN_KEY = 'DOMAIN';

export const SIMULATED_ROLE_KEY = 'SIMULATED_ROLE';

export const EXECUTOR_ROLE_KEY = 'EXECUTOR_ROLE';

export const FAVORITE_DASHBOARDS_KEY = 'FAVORITE_DASHBOARDS';

export const $UndefinedValueIndicator$ = '$UndefinedValueIndicator$';

export const UndefinedDisplayKey = 'common:commonValues.missingValue';

export const AllDisplayString = 'common:commonValues.misc.all';

export const DATA_NOT_AVAILABLE_VALUE = '-';

export const SEGMENT_OTHERS_INDICATOR = `$SEGMENT_OTHERS$`;

export const $HierarchicalFilterSeparator$ = '$HierarchicalFilterSeparator$';

export const $SEGMENT_SEPARATOR$ = '$SEGMENT_SEPARATOR$';

export const DataTypesList = [
  'EMPLOYEE',
  'APPLICATION',
  'JOB',
  'OFFER',
  'RECRUITERJOBS',
  'QUESTIONANSWER',
  'TIMEANDATTENDANCEMONTHLY',
  'PAYROLL',
  'EVALUATION',
  'RECRUITMENTINPUT',
  'RECRUITMENTOUTPUT',
  'JOINERS_VIEW',
  'ATTRITIONPREDICTION',
  'FINANCIALMETRICS',
  'INTERVIEW',
  'MANAGERENPS',
] as const;

export const OperationsList = [
  'LESS_THAN',
  'LESS_THAN_OR_EQUAL_TO',
  'GREATER_THAN',
  'GREATER_THAN_OR_EQUAL_TO',
  'COUNT',
  'EQUAL',
  'NOT_EQUAL',
  'AVG',
  'COUNT_DISTINCT',
  'MAX',
  'MIN',
  'MEDIAN',
  'SUM',
  'LIKE',
] as const;

export type ApiMasterDataTypes = typeof DataTypesList[number];

export type ApiMasterDataQueryFilterItemOperation = typeof OperationsList[number];

export interface LatestDomainSettings {
  createdAt: string;
  creator: string | null; // email
  settings: ApiCompany;
  version: number;
}

export interface LatestDomainPreferences {
  createdAt: string;
  creator: string | null; // email
  settings: DomainPreferences;
  version: number;
}

export interface FilterGroup {
  name: string;
  filters: ApiMasterDataQueryFilterItem[];
}

export interface AllowedDashboard {
  id: string;
  permissions: ApiDashboardPermission;
}

interface ApiDashboardPermission {
  createdBy?: string;
  permittedPermissionLevel: ApiUserPermissionLevels;
  permittedUsers?: string[];
}

export interface ApiUser {
  email: string | undefined;
}

type ApiEmployeeDataField =
  | 'AGE'
  | 'AGE_GROUP'
  | 'ANNUAL_CASH_BONUS_1'
  | 'ANNUAL_CASH_BONUS_1_CURRENCY'
  | 'ATTRITION_SCORE'
  | 'BASE_SALARY'
  | 'BASE_SALARY_CURRENCY'
  | 'BASE_SALARY_FREQUENCY'
  | 'BASE_SALARY_PER_YEAR'
  | 'CAREER_CHANGE_INTENTION'
  | 'CAREER_COURSE'
  | 'CAREER_LADDER'
  | 'CLIENT_EFFECTIVE_DATE'
  | 'COMMUNICATION_SKILL_SCORE'
  | 'COMMUNICATION_SKILL_SCORE_QUARTILE'
  | 'COMPANY_COUNTRY'
  | 'COMPANY_EMAIL'
  | 'COST_CENTER'
  | 'CUSTOM_FIELD_1'
  | 'CUSTOM_FIELD_10'
  | 'CUSTOM_FIELD_10_LEVEL_1'
  | 'CUSTOM_FIELD_10_LEVEL_10'
  | 'CUSTOM_FIELD_10_LEVEL_2'
  | 'CUSTOM_FIELD_10_LEVEL_3'
  | 'CUSTOM_FIELD_10_LEVEL_4'
  | 'CUSTOM_FIELD_10_LEVEL_5'
  | 'CUSTOM_FIELD_10_LEVEL_6'
  | 'CUSTOM_FIELD_10_LEVEL_7'
  | 'CUSTOM_FIELD_10_LEVEL_8'
  | 'CUSTOM_FIELD_10_LEVEL_9'
  | 'CUSTOM_FIELD_1_LEVEL_1'
  | 'CUSTOM_FIELD_1_LEVEL_10'
  | 'CUSTOM_FIELD_1_LEVEL_2'
  | 'CUSTOM_FIELD_1_LEVEL_3'
  | 'CUSTOM_FIELD_1_LEVEL_4'
  | 'CUSTOM_FIELD_1_LEVEL_5'
  | 'CUSTOM_FIELD_1_LEVEL_6'
  | 'CUSTOM_FIELD_1_LEVEL_7'
  | 'CUSTOM_FIELD_1_LEVEL_8'
  | 'CUSTOM_FIELD_1_LEVEL_9'
  | 'CUSTOM_FIELD_2'
  | 'CUSTOM_FIELD_2_LEVEL_1'
  | 'CUSTOM_FIELD_2_LEVEL_10'
  | 'CUSTOM_FIELD_2_LEVEL_2'
  | 'CUSTOM_FIELD_2_LEVEL_3'
  | 'CUSTOM_FIELD_2_LEVEL_4'
  | 'CUSTOM_FIELD_2_LEVEL_5'
  | 'CUSTOM_FIELD_2_LEVEL_6'
  | 'CUSTOM_FIELD_2_LEVEL_7'
  | 'CUSTOM_FIELD_2_LEVEL_8'
  | 'CUSTOM_FIELD_2_LEVEL_9'
  | 'CUSTOM_FIELD_3'
  | 'CUSTOM_FIELD_3_LEVEL_1'
  | 'CUSTOM_FIELD_3_LEVEL_10'
  | 'CUSTOM_FIELD_3_LEVEL_2'
  | 'CUSTOM_FIELD_3_LEVEL_3'
  | 'CUSTOM_FIELD_3_LEVEL_4'
  | 'CUSTOM_FIELD_3_LEVEL_5'
  | 'CUSTOM_FIELD_3_LEVEL_6'
  | 'CUSTOM_FIELD_3_LEVEL_7'
  | 'CUSTOM_FIELD_3_LEVEL_8'
  | 'CUSTOM_FIELD_3_LEVEL_9'
  | 'CUSTOM_FIELD_4'
  | 'CUSTOM_FIELD_4_LEVEL_1'
  | 'CUSTOM_FIELD_4_LEVEL_10'
  | 'CUSTOM_FIELD_4_LEVEL_2'
  | 'CUSTOM_FIELD_4_LEVEL_3'
  | 'CUSTOM_FIELD_4_LEVEL_4'
  | 'CUSTOM_FIELD_4_LEVEL_5'
  | 'CUSTOM_FIELD_4_LEVEL_6'
  | 'CUSTOM_FIELD_4_LEVEL_7'
  | 'CUSTOM_FIELD_4_LEVEL_8'
  | 'CUSTOM_FIELD_4_LEVEL_9'
  | 'CUSTOM_FIELD_5'
  | 'CUSTOM_FIELD_5_LEVEL_1'
  | 'CUSTOM_FIELD_5_LEVEL_10'
  | 'CUSTOM_FIELD_5_LEVEL_2'
  | 'CUSTOM_FIELD_5_LEVEL_3'
  | 'CUSTOM_FIELD_5_LEVEL_4'
  | 'CUSTOM_FIELD_5_LEVEL_5'
  | 'CUSTOM_FIELD_5_LEVEL_6'
  | 'CUSTOM_FIELD_5_LEVEL_7'
  | 'CUSTOM_FIELD_5_LEVEL_8'
  | 'CUSTOM_FIELD_5_LEVEL_9'
  | 'CUSTOM_FIELD_6'
  | 'CUSTOM_FIELD_6_LEVEL_1'
  | 'CUSTOM_FIELD_6_LEVEL_10'
  | 'CUSTOM_FIELD_6_LEVEL_2'
  | 'CUSTOM_FIELD_6_LEVEL_3'
  | 'CUSTOM_FIELD_6_LEVEL_4'
  | 'CUSTOM_FIELD_6_LEVEL_5'
  | 'CUSTOM_FIELD_6_LEVEL_6'
  | 'CUSTOM_FIELD_6_LEVEL_7'
  | 'CUSTOM_FIELD_6_LEVEL_8'
  | 'CUSTOM_FIELD_6_LEVEL_9'
  | 'CUSTOM_FIELD_7'
  | 'CUSTOM_FIELD_7_LEVEL_1'
  | 'CUSTOM_FIELD_7_LEVEL_10'
  | 'CUSTOM_FIELD_7_LEVEL_2'
  | 'CUSTOM_FIELD_7_LEVEL_3'
  | 'CUSTOM_FIELD_7_LEVEL_4'
  | 'CUSTOM_FIELD_7_LEVEL_5'
  | 'CUSTOM_FIELD_7_LEVEL_6'
  | 'CUSTOM_FIELD_7_LEVEL_7'
  | 'CUSTOM_FIELD_7_LEVEL_8'
  | 'CUSTOM_FIELD_7_LEVEL_9'
  | 'CUSTOM_FIELD_8'
  | 'CUSTOM_FIELD_8_LEVEL_1'
  | 'CUSTOM_FIELD_8_LEVEL_10'
  | 'CUSTOM_FIELD_8_LEVEL_2'
  | 'CUSTOM_FIELD_8_LEVEL_3'
  | 'CUSTOM_FIELD_8_LEVEL_4'
  | 'CUSTOM_FIELD_8_LEVEL_5'
  | 'CUSTOM_FIELD_8_LEVEL_6'
  | 'CUSTOM_FIELD_8_LEVEL_7'
  | 'CUSTOM_FIELD_8_LEVEL_8'
  | 'CUSTOM_FIELD_8_LEVEL_9'
  | 'CUSTOM_FIELD_9'
  | 'CUSTOM_FIELD_9_LEVEL_1'
  | 'CUSTOM_FIELD_9_LEVEL_10'
  | 'CUSTOM_FIELD_9_LEVEL_2'
  | 'CUSTOM_FIELD_9_LEVEL_3'
  | 'CUSTOM_FIELD_9_LEVEL_4'
  | 'CUSTOM_FIELD_9_LEVEL_5'
  | 'CUSTOM_FIELD_9_LEVEL_6'
  | 'CUSTOM_FIELD_9_LEVEL_7'
  | 'CUSTOM_FIELD_9_LEVEL_8'
  | 'CUSTOM_FIELD_9_LEVEL_9'
  | 'CUSTOM_FILTER_1'
  | 'CUSTOM_FILTER_10'
  | 'CUSTOM_FILTER_11'
  | 'CUSTOM_FILTER_12'
  | 'CUSTOM_FILTER_13'
  | 'CUSTOM_FILTER_14'
  | 'CUSTOM_FILTER_15'
  | 'CUSTOM_FILTER_16'
  | 'CUSTOM_FILTER_17'
  | 'CUSTOM_FILTER_18'
  | 'CUSTOM_FILTER_19'
  | 'CUSTOM_FILTER_2'
  | 'CUSTOM_FILTER_20'
  | 'CUSTOM_FILTER_3'
  | 'CUSTOM_FILTER_4'
  | 'CUSTOM_FILTER_5'
  | 'CUSTOM_FILTER_6'
  | 'CUSTOM_FILTER_7'
  | 'CUSTOM_FILTER_8'
  | 'CUSTOM_FILTER_9'
  | 'DATE_OF_BIRTH'
  | 'DAYS_IN_COMPANY'
  | 'DEPARTMENT'
  | 'DEPTH_OF_CONTROL'
  | 'EFFECTIVE_DATE'
  | 'EFFECTIVE_LEAVER_DATE'
  | 'EFFECTIVE_LEAVER_DATE_NORMALIZED'
  | 'EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH'
  | 'EMPLOYEE_ID'
  | 'EMPLOYING_ENTITY'
  | 'EMPLOYMENT_TEMPORALITY'
  | 'EMPLOYMENT_TYPE'
  | 'EMPLOYMENT_TYPE_LEVEL_1'
  | 'EMPLOYMENT_TYPE_LEVEL_10'
  | 'EMPLOYMENT_TYPE_LEVEL_2'
  | 'EMPLOYMENT_TYPE_LEVEL_3'
  | 'EMPLOYMENT_TYPE_LEVEL_4'
  | 'EMPLOYMENT_TYPE_LEVEL_5'
  | 'EMPLOYMENT_TYPE_LEVEL_6'
  | 'EMPLOYMENT_TYPE_LEVEL_7'
  | 'EMPLOYMENT_TYPE_LEVEL_8'
  | 'EMPLOYMENT_TYPE_LEVEL_9'
  | 'FIRST_NAME'
  | 'FULL_NAME'
  | 'FUNCTION'
  | 'FUNCTION_LEVEL_1'
  | 'FUNCTION_LEVEL_10'
  | 'FUNCTION_LEVEL_2'
  | 'FUNCTION_LEVEL_3'
  | 'FUNCTION_LEVEL_4'
  | 'FUNCTION_LEVEL_5'
  | 'FUNCTION_LEVEL_6'
  | 'FUNCTION_LEVEL_7'
  | 'FUNCTION_LEVEL_8'
  | 'FUNCTION_LEVEL_9'
  | 'GENDER'
  | 'GENERAL_COGNITIVE_ABILITY_SCORE'
  | 'GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE'
  | 'ICR'
  | 'INTERNAL_TRANSFER_INTENTION'
  | 'JOB_GRADE'
  | 'JOB_GRADE_LEVEL_1'
  | 'JOB_GRADE_LEVEL_10'
  | 'JOB_GRADE_LEVEL_2'
  | 'JOB_GRADE_LEVEL_3'
  | 'JOB_GRADE_LEVEL_4'
  | 'JOB_GRADE_LEVEL_5'
  | 'JOB_GRADE_LEVEL_6'
  | 'JOB_GRADE_LEVEL_7'
  | 'JOB_GRADE_LEVEL_8'
  | 'JOB_GRADE_LEVEL_9'
  | 'JOB_TITLE'
  | 'JOINING_AGE'
  | 'JOINING_AGE_GROUP'
  | 'LAST_NAME'
  | 'LOCAL_FIRST_NAME'
  | 'LOCAL_FIRST_NAME_PRONUNCIATION'
  | 'LOCAL_FULL_NAME'
  | 'LOCAL_FULL_NAME_PRONUNCIATION'
  | 'LOCAL_LAST_NAME'
  | 'LOCAL_LAST_NAME_PRONUNCIATION'
  | 'LOCAL_OR_NON_LOCAL'
  | 'LOCAL_SALARY'
  | 'LOCAL_SALARY_CURRENCY'
  | 'LOCATION'
  | 'LOCATION_LEVEL_1'
  | 'LOCATION_LEVEL_10'
  | 'LOCATION_LEVEL_2'
  | 'LOCATION_LEVEL_3'
  | 'LOCATION_LEVEL_4'
  | 'LOCATION_LEVEL_5'
  | 'LOCATION_LEVEL_6'
  | 'LOCATION_LEVEL_7'
  | 'LOCATION_LEVEL_8'
  | 'LOCATION_LEVEL_9'
  | 'MANAGER_EMAIL'
  | 'MANAGER_FEEDBACK'
  | 'MANAGER_ID'
  | 'MANAGER_OR_IC'
  | 'MARITAL_STATUS'
  | 'MEMBER_ID'
  | 'NATIONALITY_HIERARCHICAL'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_1'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_10'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_2'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_3'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_4'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_5'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_6'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_7'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_8'
  | 'NATIONALITY_HIERARCHICAL_LEVEL_9'
  | 'ONBOARDING_SURVEY_SCORE'
  | 'ONBOARDING_SURVEY_SCORE_GROUP'
  | 'ORGANIZATION'
  | 'ORGANIZATION_LEVEL_1'
  | 'ORGANIZATION_LEVEL_10'
  | 'ORGANIZATION_LEVEL_2'
  | 'ORGANIZATION_LEVEL_3'
  | 'ORGANIZATION_LEVEL_4'
  | 'ORGANIZATION_LEVEL_5'
  | 'ORGANIZATION_LEVEL_6'
  | 'ORGANIZATION_LEVEL_7'
  | 'ORGANIZATION_LEVEL_8'
  | 'ORGANIZATION_LEVEL_9'
  | 'PERFORMANCE_BONUS_CYCLE'
  | 'PERFORMANCE_CYCLE'
  | 'PERFORMANCE_RATING'
  | 'PERFORMANCE_REGULAR_CYCLE'
  | 'POSITION'
  | 'POSITION_LEVEL_1'
  | 'POSITION_LEVEL_10'
  | 'POSITION_LEVEL_2'
  | 'POSITION_LEVEL_3'
  | 'POSITION_LEVEL_4'
  | 'POSITION_LEVEL_5'
  | 'POSITION_LEVEL_6'
  | 'POSITION_LEVEL_7'
  | 'POSITION_LEVEL_8'
  | 'POSITION_LEVEL_9'
  | 'PREFERRED_NAME'
  | 'PRE_MERGER_ORGANIZATION'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_1'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_10'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_2'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_3'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_4'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_5'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_6'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_7'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_8'
  | 'PRE_MERGER_ORGANIZATION_LEVEL_9'
  | 'PULSE_SURVEY_CYCLE_1'
  | 'PULSE_SURVEY_CYCLE_2'
  | 'PULSE_SURVEY_CYCLE_3'
  | 'PULSE_SURVEY_SCORE_1'
  | 'PULSE_SURVEY_SCORE_2'
  | 'RECRUITMENT_CATEGORY'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_1'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_10'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_2'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_3'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_4'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_5'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_6'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_7'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_8'
  | 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_9'
  | 'REGIONAL_OR_LOCAL'
  | 'REGRET_ATTRITION'
  | 'REVENUE_SALARY'
  | 'REVENUE_SALARY_CURRENCY'
  | 'ROLE_RELATED_KNOWLEDGE_SCORE'
  | 'ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE'
  | 'SOCIAL_TYPE'
  | 'SPAN_OF_CONTROL'
  | 'STANDARDIZED_BASE_SALARY'
  | 'STANDARDIZED_BASE_SALARY_PER_YEAR'
  | 'START_DATE'
  | 'SUPERIOR_ID'
  | 'TENURE_GROUP'
  | 'TERM_DATE'
  | 'TOTAL_AVERAGE_SCORE'
  | 'TOTAL_AVERAGE_SCORE_QUARTILE'
  | 'TOTAL_COMPENSATION'
  | 'TOTAL_COMPENSATION_CURRENCY'
  | 'VERSION_ID'
  | 'VOLUNTARY_OR_INVOLUNTARY_ATTRITION'
  | 'WORK_CONSIDERATION'
  | 'DEFINED_MANAGER'
  | 'MOVE_FROM'
  | 'MOVE_TO';

type ApiEvaluationDataField =
  | 'EVALUATION_AS_OF'
  | 'EVALUATION_CYCLE_NAME'
  | 'EVALUATION_CYCLE_TYPE'
  | 'EVALUATION_FROM'
  | 'EVALUATION_ID'
  | 'EVALUATION_SCORE';

type ApiOfferDataField = 'OFFER_ID' | 'APPLICATION_ID' | 'JOB_ID' | 'STARTS_AT' | 'STATUS';

type ApiJobDataField =
  | 'CLOSED_AT'
  | 'JOB_AGE'
  | 'JOB_ID'
  | 'JOB_NAME'
  | 'JOB_NAME_LEVEL_1'
  | 'JOB_NAME_LEVEL_10'
  | 'JOB_NAME_LEVEL_2'
  | 'JOB_NAME_LEVEL_3'
  | 'JOB_NAME_LEVEL_4'
  | 'JOB_NAME_LEVEL_5'
  | 'JOB_NAME_LEVEL_6'
  | 'JOB_NAME_LEVEL_7'
  | 'JOB_NAME_LEVEL_8'
  | 'JOB_NAME_LEVEL_9'
  | 'LOCATION'
  | 'OFFICE'
  | 'OFFICE_LEVEL_1'
  | 'OFFICE_LEVEL_10'
  | 'OFFICE_LEVEL_2'
  | 'OFFICE_LEVEL_3'
  | 'OFFICE_LEVEL_4'
  | 'OFFICE_LEVEL_5'
  | 'OFFICE_LEVEL_6'
  | 'OFFICE_LEVEL_7'
  | 'OFFICE_LEVEL_8'
  | 'OFFICE_LEVEL_9'
  | 'OPENED_AT'
  | 'RECRUITMENT_CATEGORY'
  | 'STATUS';

type ApiApplicationDataField =
  | 'APPLICATION_AGE'
  | 'APPLICATION_CURRENT_STAGE'
  | 'APPLICATION_CURRENT_STAGE_LEVEL_1'
  | 'APPLICATION_CURRENT_STAGE_LEVEL_2'
  | 'APPLICATION_CURRENT_STAGE_LEVEL_3'
  | 'APPLICATION_ID'
  | 'APPLIED_AT'
  | 'CANDIDATE_EMAIL_ADDRESS'
  | 'CANDIDATE_FIRST_NAME'
  | 'CANDIDATE_ID'
  | 'CANDIDATE_LAST_NAME'
  | 'CURRENT_JOB_TITLE'
  | 'CURRENT_STAGE'
  | 'REJECTED_AT'
  | 'REJECTION_REASON'
  | 'REJECTION_REASON_LEVEL_1'
  | 'REJECTION_REASON_LEVEL_2'
  | 'RESOLVED_AT'
  | 'SOURCE'
  | 'SOURCE_ID'
  | 'SOURCE_LEVEL_1'
  | 'SOURCE_LEVEL_10'
  | 'SOURCE_LEVEL_2'
  | 'SOURCE_LEVEL_3'
  | 'SOURCE_LEVEL_4'
  | 'SOURCE_LEVEL_5'
  | 'SOURCE_LEVEL_6'
  | 'SOURCE_LEVEL_7'
  | 'SOURCE_LEVEL_8'
  | 'SOURCE_LEVEL_9'
  | 'SOURCE_NAME'
  | 'SOURCE_TYPE'
  | 'STANDARDIZED_CURRENT_STAGE'
  | 'START_MONTH'
  | 'START_YEAR';

type ApiPayrollDataField =
  | 'ACTUAL_BASE_SALARY'
  | 'ACTUAL_BASE_SALARY_CURRENCY'
  | 'COMPUTED_ACTUAL_BASE_SALARY_CURRENCY'
  | 'CONTRACTUAL_BASE_SALARY'
  | 'CONTRACTUAL_BASE_SALARY_CURRENCY'
  | 'CUSTOM_BONUS_1'
  | 'CUSTOM_BONUS_10'
  | 'CUSTOM_BONUS_10_CURRENCY'
  | 'CUSTOM_BONUS_11'
  | 'CUSTOM_BONUS_11_CURRENCY'
  | 'CUSTOM_BONUS_12'
  | 'CUSTOM_BONUS_12_CURRENCY'
  | 'CUSTOM_BONUS_13'
  | 'CUSTOM_BONUS_13_CURRENCY'
  | 'CUSTOM_BONUS_14'
  | 'CUSTOM_BONUS_14_CURRENCY'
  | 'CUSTOM_BONUS_15'
  | 'CUSTOM_BONUS_15_CURRENCY'
  | 'CUSTOM_BONUS_16'
  | 'CUSTOM_BONUS_16_CURRENCY'
  | 'CUSTOM_BONUS_17'
  | 'CUSTOM_BONUS_17_CURRENCY'
  | 'CUSTOM_BONUS_18'
  | 'CUSTOM_BONUS_18_CURRENCY'
  | 'CUSTOM_BONUS_19'
  | 'CUSTOM_BONUS_19_CURRENCY'
  | 'CUSTOM_BONUS_1_CURRENCY'
  | 'CUSTOM_BONUS_2'
  | 'CUSTOM_BONUS_20'
  | 'CUSTOM_BONUS_20_CURRENCY'
  | 'CUSTOM_BONUS_2_CURRENCY'
  | 'CUSTOM_BONUS_3'
  | 'CUSTOM_BONUS_3_CURRENCY'
  | 'CUSTOM_BONUS_4'
  | 'CUSTOM_BONUS_4_CURRENCY'
  | 'CUSTOM_BONUS_5'
  | 'CUSTOM_BONUS_5_CURRENCY'
  | 'CUSTOM_BONUS_6'
  | 'CUSTOM_BONUS_6_CURRENCY'
  | 'CUSTOM_BONUS_7'
  | 'CUSTOM_BONUS_7_CURRENCY'
  | 'CUSTOM_BONUS_8'
  | 'CUSTOM_BONUS_8_CURRENCY'
  | 'CUSTOM_BONUS_9'
  | 'CUSTOM_BONUS_9_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9'
  | 'EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9'
  | 'EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY'
  | 'EMPLOYEE_ID'
  | 'EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL'
  | 'EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY'
  | 'EMPLOYEE_STATUTORY_PAYMENT_TOTAL'
  | 'EMPLOYEE_STATUTORY_PAYMENT_TOTAL_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9'
  | 'EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9'
  | 'EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY'
  | 'EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL'
  | 'EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY'
  | 'EMPLOYER_STATUTORY_PAYMENT_TOTAL'
  | 'EMPLOYER_STATUTORY_PAYMENT_TOTAL_CURRENCY'
  | 'NON_STATUTORY_PAYMENT_TOTAL'
  | 'NON_STATUTORY_PAYMENT_TOTAL_CURRENCY'
  | 'OVERTIME'
  | 'OVERTIME_CURRENCY'
  | 'RECEIVED_BASE_SALARY_CURRENCY'
  | 'STANDARDIZED_ACTUAL_BASE_SALARY'
  | 'STANDARDIZED_CONTRACTUAL_BASE_SALARY'
  | 'STANDARDIZED_CUSTOM_BONUS_1'
  | 'STANDARDIZED_CUSTOM_BONUS_10'
  | 'STANDARDIZED_CUSTOM_BONUS_11'
  | 'STANDARDIZED_CUSTOM_BONUS_12'
  | 'STANDARDIZED_CUSTOM_BONUS_13'
  | 'STANDARDIZED_CUSTOM_BONUS_14'
  | 'STANDARDIZED_CUSTOM_BONUS_15'
  | 'STANDARDIZED_CUSTOM_BONUS_16'
  | 'STANDARDIZED_CUSTOM_BONUS_17'
  | 'STANDARDIZED_CUSTOM_BONUS_18'
  | 'STANDARDIZED_CUSTOM_BONUS_19'
  | 'STANDARDIZED_CUSTOM_BONUS_2'
  | 'STANDARDIZED_CUSTOM_BONUS_20'
  | 'STANDARDIZED_CUSTOM_BONUS_3'
  | 'STANDARDIZED_CUSTOM_BONUS_4'
  | 'STANDARDIZED_CUSTOM_BONUS_5'
  | 'STANDARDIZED_CUSTOM_BONUS_6'
  | 'STANDARDIZED_CUSTOM_BONUS_7'
  | 'STANDARDIZED_CUSTOM_BONUS_8'
  | 'STANDARDIZED_CUSTOM_BONUS_9'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9'
  | 'STANDARDIZED_EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_EMPLOYEE_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9'
  | 'STANDARDIZED_EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_EMPLOYER_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_EMPLOYER_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_NON_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_OVERTIME'
  | 'STANDARDIZED_STATUTORY_PAYMENT_TOTAL'
  | 'STANDARDIZED_TOTAL_ALLOWANCE'
  | 'STANDARDIZED_TOTAL_BOARD_COMPENSATION'
  | 'STANDARDIZED_TOTAL_BONUS'
  | 'STANDARDIZED_TOTAL_EMPLOYEE_AWARD'
  | 'STANDARDIZED_TOTAL_PAYROLL_COST'
  | 'STANDARDIZED_TOTAL_REIMBURSEMENT'
  | 'STANDARDIZED_TRAVEL_AND_COMMUTING'
  | 'STATUTORY_PAYMENT_TOTAL'
  | 'STATUTORY_PAYMENT_TOTAL_CURRENCY'
  | 'TOTAL_ALLOWANCE'
  | 'TOTAL_ALLOWANCE_CURRENCY'
  | 'TOTAL_BOARD_COMPENSATION'
  | 'TOTAL_BOARD_COMPENSATION_CURRENCY'
  | 'TOTAL_BONUS'
  | 'TOTAL_BONUS_CURRENCY'
  | 'TOTAL_EMPLOYEE_AWARD'
  | 'TOTAL_EMPLOYEE_AWARD_CURRENCY'
  | 'TOTAL_PAYROLL_COST'
  | 'TOTAL_PAYROLL_COST_CURRENCY'
  | 'TOTAL_REIMBURSEMENT'
  | 'TOTAL_REIMBURSEMENT_CURRENCY'
  | 'TRAVEL_AND_COMMUTING'
  | 'TRAVEL_AND_COMMUTING_CURRENCY'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19'
  | 'STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_1'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_2'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_3'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_4'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_5'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_6'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_7'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_8'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_9'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_10'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_11'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_12'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_13'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_14'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_15'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_16'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_17'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_18'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_19'
  | 'STANDARDIZED_CUSTOM_ALLOWANCE_20'
  | 'CUSTOM_ALLOWANCE_1'
  | 'CUSTOM_ALLOWANCE_2'
  | 'CUSTOM_ALLOWANCE_3'
  | 'CUSTOM_ALLOWANCE_4'
  | 'CUSTOM_ALLOWANCE_5'
  | 'CUSTOM_ALLOWANCE_6'
  | 'CUSTOM_ALLOWANCE_7'
  | 'CUSTOM_ALLOWANCE_8'
  | 'CUSTOM_ALLOWANCE_9'
  | 'CUSTOM_ALLOWANCE_10'
  | 'CUSTOM_ALLOWANCE_11'
  | 'CUSTOM_ALLOWANCE_12'
  | 'CUSTOM_ALLOWANCE_13'
  | 'CUSTOM_ALLOWANCE_14'
  | 'CUSTOM_ALLOWANCE_15'
  | 'CUSTOM_ALLOWANCE_16'
  | 'CUSTOM_ALLOWANCE_17'
  | 'CUSTOM_ALLOWANCE_18'
  | 'CUSTOM_ALLOWANCE_19'
  | 'CUSTOM_ALLOWANCE_20'
  | 'CUSTOM_ALLOWANCE_1_CURRENCY'
  | 'CUSTOM_ALLOWANCE_2_CURRENCY'
  | 'CUSTOM_ALLOWANCE_3_CURRENCY'
  | 'CUSTOM_ALLOWANCE_4_CURRENCY'
  | 'CUSTOM_ALLOWANCE_5_CURRENCY'
  | 'CUSTOM_ALLOWANCE_6_CURRENCY'
  | 'CUSTOM_ALLOWANCE_7_CURRENCY'
  | 'CUSTOM_ALLOWANCE_8_CURRENCY'
  | 'CUSTOM_ALLOWANCE_9_CURRENCY'
  | 'CUSTOM_ALLOWANCE_10_CURRENCY'
  | 'CUSTOM_ALLOWANCE_11_CURRENCY'
  | 'CUSTOM_ALLOWANCE_12_CURRENCY'
  | 'CUSTOM_ALLOWANCE_13_CURRENCY'
  | 'CUSTOM_ALLOWANCE_14_CURRENCY'
  | 'CUSTOM_ALLOWANCE_15_CURRENCY'
  | 'CUSTOM_ALLOWANCE_16_CURRENCY'
  | 'CUSTOM_ALLOWANCE_17_CURRENCY'
  | 'CUSTOM_ALLOWANCE_18_CURRENCY'
  | 'CUSTOM_ALLOWANCE_19_CURRENCY'
  | 'CUSTOM_ALLOWANCE_20_CURRENCY'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19'
  | 'STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20'
  | 'CUSTOM_PAYROLL_COST_1_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_2_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_3_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_4_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_5_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_6_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_7_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_8_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_9_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_10_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_11_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_12_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_13_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_14_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_15_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_16_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_17_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_18_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_19_CURRENCY'
  | 'CUSTOM_PAYROLL_COST_20_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_1_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_2_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_3_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_4_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_5_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_6_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_7_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_8_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_9_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_10_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_11_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_12_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_13_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_14_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_15_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_16_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_17_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_18_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_19_CURRENCY'
  | 'CUSTOM_EMPLOYER_CONTRIBUTION_20_CURRENCY'
  | 'BOARD_COMPENSATION_1_CURRENCY'
  | 'BOARD_COMPENSATION_2_CURRENCY'
  | 'BOARD_COMPENSATION_3_CURRENCY'
  | 'BOARD_COMPENSATION_4_CURRENCY'
  | 'BOARD_COMPENSATION_5_CURRENCY'
  | 'BOARD_COMPENSATION_6_CURRENCY'
  | 'BOARD_COMPENSATION_7_CURRENCY'
  | 'BOARD_COMPENSATION_8_CURRENCY'
  | 'BOARD_COMPENSATION_9_CURRENCY'
  | 'BOARD_COMPENSATION_10_CURRENCY'
  | 'BOARD_COMPENSATION_11_CURRENCY'
  | 'BOARD_COMPENSATION_12_CURRENCY'
  | 'BOARD_COMPENSATION_13_CURRENCY'
  | 'BOARD_COMPENSATION_14_CURRENCY'
  | 'BOARD_COMPENSATION_15_CURRENCY'
  | 'BOARD_COMPENSATION_16_CURRENCY'
  | 'BOARD_COMPENSATION_17_CURRENCY'
  | 'BOARD_COMPENSATION_18_CURRENCY'
  | 'BOARD_COMPENSATION_19_CURRENCY'
  | 'BOARD_COMPENSATION_20_CURRENCY'
  | 'EMPLOYEE_AWARD_1_CURRENCY'
  | 'EMPLOYEE_AWARD_2_CURRENCY'
  | 'EMPLOYEE_AWARD_3_CURRENCY'
  | 'EMPLOYEE_AWARD_4_CURRENCY'
  | 'EMPLOYEE_AWARD_5_CURRENCY'
  | 'EMPLOYEE_AWARD_6_CURRENCY'
  | 'EMPLOYEE_AWARD_7_CURRENCY'
  | 'EMPLOYEE_AWARD_8_CURRENCY'
  | 'EMPLOYEE_AWARD_9_CURRENCY'
  | 'EMPLOYEE_AWARD_10_CURRENCY'
  | 'EMPLOYEE_AWARD_11_CURRENCY'
  | 'EMPLOYEE_AWARD_12_CURRENCY'
  | 'EMPLOYEE_AWARD_13_CURRENCY'
  | 'EMPLOYEE_AWARD_14_CURRENCY'
  | 'EMPLOYEE_AWARD_15_CURRENCY'
  | 'EMPLOYEE_AWARD_16_CURRENCY'
  | 'EMPLOYEE_AWARD_17_CURRENCY'
  | 'EMPLOYEE_AWARD_18_CURRENCY'
  | 'EMPLOYEE_AWARD_19_CURRENCY'
  | 'EMPLOYEE_AWARD_20_CURRENCY'
  | 'REIMBURSEMENT_1_CURRENCY'
  | 'REIMBURSEMENT_2_CURRENCY'
  | 'REIMBURSEMENT_3_CURRENCY'
  | 'REIMBURSEMENT_4_CURRENCY'
  | 'REIMBURSEMENT_5_CURRENCY'
  | 'REIMBURSEMENT_6_CURRENCY'
  | 'REIMBURSEMENT_7_CURRENCY'
  | 'REIMBURSEMENT_8_CURRENCY'
  | 'REIMBURSEMENT_9_CURRENCY'
  | 'REIMBURSEMENT_10_CURRENCY'
  | 'REIMBURSEMENT_11_CURRENCY'
  | 'REIMBURSEMENT_12_CURRENCY'
  | 'REIMBURSEMENT_13_CURRENCY'
  | 'REIMBURSEMENT_14_CURRENCY'
  | 'REIMBURSEMENT_15_CURRENCY'
  | 'REIMBURSEMENT_16_CURRENCY'
  | 'REIMBURSEMENT_17_CURRENCY'
  | 'REIMBURSEMENT_18_CURRENCY'
  | 'REIMBURSEMENT_19_CURRENCY'
  | 'REIMBURSEMENT_20_CURRENCY';

type ApiFinancialMetricsDataField =
  | 'FINANCIAL_METRICS_ID'
  | 'GROSS_PROFIT'
  | 'NET_INCOME'
  | 'OPERATING_PROFIT'
  | 'REVENUE'
  | 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES'
  | 'TRAINING_COST';

type ApiInterviewDataField = 'INTERVIEW_ID' | 'APPLICATION_ID' | 'INTERVIEWER_ID' | 'INTERVIEWER_NAME' | 'SCORE';

export type ApiQuestionAnswerDataField =
  | 'IS_ANONYMOUS'
  | 'QUESTION'
  | 'QUESTION_TYPE'
  | 'ANSWER'
  | 'ANSWER_TYPE'
  | 'SENTIMENT'
  | 'SCORE'
  | 'THEME_NAME'
  | 'START_DATE'
  | 'CYCLE'
  | 'CYCLE_END_DATE'
  | 'CYCLE_START_DATE';

type ApiTimeAndAttendanceMonthlyDataField =
  | 'NUM_DAYS_WORKED'
  | 'NUM_HOURS_WORKED'
  | 'NUM_OVERTIME_HOURS'
  | 'NUM_WORKING_DAYS'
  | 'NUM_LEAVES';

export type ApiMasterDataField =
  | ApiEmployeeDataField
  | ApiEvaluationDataField
  | ApiInterviewDataField
  | ApiJobDataField
  | ApiOfferDataField
  | ApiApplicationDataField
  | ApiQuestionAnswerDataField
  | ApiFinancialMetricsDataField
  | ApiTimeAndAttendanceMonthlyDataField
  | ApiPayrollDataField;

// TODO: remove this soon
export const HierarchicalFields: Set<DataFieldWithDataType> =
  V2HierarchicalFields as unknown as Set<DataFieldWithDataType>;

export enum ApiDataQueryType {
  ApiMasterDataQuery = 'ApiMasterDataQuery',
  ApiMasterDataAdvancedQuery = 'ApiMasterDataAdvancedQuery',
  ApiMasterDataMovementQuery = 'ApiMasterDataMovementQuery',
}
export type ApiDataQueryWithTypeInfo =
  | { type: ApiDataQueryType.ApiMasterDataQuery; query: ApiMasterDataQuery }
  | { type: ApiDataQueryType.ApiMasterDataAdvancedQuery; query: ApiMasterDataAdvancedQuery }
  | { type: ApiDataQueryType.ApiMasterDataMovementQuery; query: ApiMasterDataMovementQuery };

export type ApiDataViewQueryWithTypeInfo =
  | { type: ApiDataQueryType.ApiMasterDataAdvancedQuery; query: ApiMasterDataAdvancedQuery }
  | { type: ApiDataQueryType.ApiMasterDataMovementQuery; query: ApiMasterDataMovementQuery };

export const toApiMasterDataAdvancedQueryWithTypeInfo: (
  query: ApiMasterDataAdvancedQuery
) => ApiDataViewQueryWithTypeInfo = (query: ApiMasterDataAdvancedQuery) => ({
  type: ApiDataQueryType.ApiMasterDataAdvancedQuery,
  query,
});

export type ApiMasterDataQueryMeasureOperation = 'SUM' | 'AVG' | 'MIN' | 'MAX' | 'COUNT' | 'COUNT_DISTINCT' | 'MEDIAN';

export interface ApiEmployeeSearchResponse {
  employees: ApiEmployeeSearchResponseItem[];
}

export interface ApiGetManagerResponse {
  items: ApiGetManagerHierarchy[];
}

export interface ApiGetManagerHierarchy {
  id: string;
  fullname: string;
  subordinates?: ApiGetManagerResponseSubordinateItem[];
}

export interface ApiGetManagerResponseSubordinateItem {
  id: string;
  fullname: string;
  hierarchyLevel: number;
  managerId: string;
  managerOrIc: string;
  subordinatesCount: number;
  path: string;
}

export interface ApiEmployeeSearchResponseItem {
  companyEmail: string;
  employeeId: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;

  localFirstName?: string;
  localLastName?: string;
  localPreferredName?: string;
  localFullName?: string;

  jobTitle?: string;
}
export interface ApiHierarchyItem {
  name: string;
  displayName?: string;
  displayKey?: string;
  subItems?: ApiHierarchyItem[];
  filterValues?: ApiMasterDataQueryFilterItem[];
  isSelectable?: boolean;
  employeeFields?: Record<string, string | number>;
  noDefaultSubItems?: boolean;
}
export interface ApiHierarchyItemList {
  items: ApiHierarchyItem[];
}

export interface ApiMasterDataAdvancedQueryDimension {
  dataType: ApiMasterDataTypes | DataTypes;
  property: ApiMasterDataField | DataFields;
}

export enum ApiMasterDataQuerySortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ApiMasterDataQueryOrderBy {
  dimension: ApiMasterDataAdvancedQueryDimension;
  sortOrder: ApiMasterDataQuerySortOrder;
}
export interface ApiMasterDataQuery {
  // should this be changed as there is a dataType also? Also some properties are optional but the rules for that is not straightforward. Apparently its something like you can only have one of measures or dimensions being empty
  dimensions?: string[];
  filterItems?: ApiMasterDataQueryFilterItem[];
  measures?: ApiMasterDataQueryMeasure[];
  dataType: DataTypes | ApiMasterDataTypes;
  limitedToPermittedPopulation?: boolean;
  limit?: number;
  offset?: number;
  disableNestLoop?: boolean;
}

export interface ApiMasterDataAdvancedQuery {
  dimensions?: ApiMasterDataAdvancedQueryDimension[];
  joins?: ApiMasterDataAdvancedQueryJoins[];
  filterItems?: ApiMasterDataQueryFilterItem[];
  measures?: ApiMasterDataQueryMeasure[];
  dataType?: DataTypes;
  limitedToPermittedPopulation?: boolean;
  orderBy?: ApiMasterDataQueryOrderBy[];
  limit?: number;
  offset?: number;
  disableNestLoop?: boolean;
}

export interface ApiMasterDataAdvancedQueryJoins {
  joinType: JoinTypes;
  joinDataType: DataTypes;
  joinConditions?: ApiMasterDataAdvancedQueryJoinConditions[];
}

interface ApiMasterDataAdvancedQueryJoinConditions {
  leftDataType: DataTypes;
  leftProperty: DataFields;
  rightDataType: DataTypes;
  rightProperty: DataFields;
  operation: Operations;
}

export enum JoinTypes {
  INNER = 'INNER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface ApiMasterDataMovementQuery {
  movementCriteria: { dataType: DataTypes; property: DataFields };
  dimensions?: ApiMasterDataAdvancedQueryDimension[];
  filterItems?: ApiMasterDataQueryFilterItem[];
  measures?: ApiMasterDataQueryMeasure[];
  dataType?: ApiMasterDataTypes;
  fromFilter?: ApiMasterDataQueryFilterItem;
  toFilter?: ApiMasterDataQueryFilterItem;
  orderBy?: ApiMasterDataQueryOrderBy[];
  limit?: number;
  offset?: number;
  limitedToPermittedPopulation?: boolean;
  disableNestLoop?: boolean;
}

export interface ApiMasterDataQueryMeasure {
  operation: ApiMasterDataQueryMeasureOperation;
  property: string;
  name?: string;
  dataType: ApiMasterDataTypes | DataTypes;
}

export interface ApiMasterDataQueryResponse {
  dataPoints: ApiMasterDataQueryResponseDataPoint[];
}

export interface ApiMasterDataQueryInfiniteLoadingResponse extends ApiMasterDataQueryResponse {
  recordsLoaded: number;
  recordsTotal: number;
}

export interface ApiMasterDataBatchQueryResponse {
  responseListItems: ApiMasterDataBatchQueryResponseItem[];
}

export enum ApiQueryTypes {
  MOVEMENT = 'executeMovementQuery',
  SQL = 'executeCustomSqlQuery',
}

export interface ApiMasterDataBatchQueryResponseItem {
  query: ApiMasterDataMovementQuery | string;
  response: ApiMasterDataQueryResponse;
}

export interface ApiMasterDataBatchAdvancedQueryResponse {
  responseListItems: ApiMasterDataBatchAdvancedQueryResponseItem[];
}

export interface ApiMasterDataBatchAdvancedQueryResponseItem {
  query: ApiMasterDataAdvancedQuery;
  response: ApiMasterDataQueryResponse;
}

export interface ApiMasterDataQueryResponseDataPoint {
  dimensions: ApiMasterDataQueryResponseDataPointDimensionData[];
  measures: ApiMasterDataQueryResponseDataPointMeasureData[];
}

export interface ApiMasterDataQueryResponseDataPointDimensionData {
  dataType: ApiMasterDataTypes;
  property: ApiMasterDataField;
  value: any;
}

export interface ApiMasterDataQueryResponseDataPointMeasureData {
  dataType: ApiMasterDataTypes;
  operation: ApiMasterDataQueryMeasureOperation;
  property: string;
  value: any;
}

export interface ApiMetricQueryResponse {
  dataPoints: ApiMetricQueryResponseDataPoint[]; // Should also be undefined but then it has to be handled everywhere and the code needs to be fixed accordingly
  message?: string;
  errorReference?: string | null;
}

export interface ApiCustomSqlQueryResponse {
  dataPoints: ApiCustomSqlQueryResponseDataPoint[]; // Should also be undefined but then it has to be handled everywhere and the code needs to be fixed accordingly
  message?: string;
  errorReference?: string | null;
}

export interface ApiMetricQueryResponseDataPoint {
  dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[];
  measures: ApiCustomSqlQueryResponseDataPointMeasureData[];
  meta: MetricResultMetaData;
}

export interface ApiCustomSqlQueryResponseDataPoint {
  dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[];
  measures: ApiCustomSqlQueryResponseDataPointMeasureData[];
}

export interface ApiCustomSqlQueryResponseDataPointDimensionData {
  dataType: ApiMasterDataTypes;
  property: ApiMasterDataField;
  value: DataValue;
}

export interface ApiCustomSqlQueryResponseDataPointMeasureData {
  dataType: ApiMasterDataTypes;
  operation: ApiMasterDataQueryMeasureOperation;
  property: ApiMasterDataField;
  value: DataValue;
}

export interface NewApiAliasFor {
  translations: NewApiAliasTranslation[];
}

export interface NewApiAliasForDataField extends NewApiAliasFor {
  dataFieldWithDataType: DataFieldWithDataType;
}

export type MetricGroup = string;
export type MetricCategory = string;

export interface NewApiAliasForMetricGroupId extends NewApiAliasFor {
  metricGroupId: MetricGroupId;
}

export interface NewApiAliasForMetricCategoryId extends NewApiAliasFor {
  metricCategoryId: MetricCategory;
}

export enum AliasTypes {
  DATAFIELD = 'datafield',
  METRIC_CATEGORY_ID = 'metricCategoryId',
  METRIC_GROUP_ID = 'metricGroupId',
}

export const toNewApiAliasForDataFields = (aliases: NewApiAlias[]): NewApiAliasForDataField[] => {
  return aliases
    .filter((a) => a.type === AliasTypes.DATAFIELD)
    .map((a) => ({
      dataFieldWithDataType: getDataFieldWithDataTypeFromKey(a.key),
      translations: a.translations,
    }));
};

export const toNewApiAliasForMetricGroupId = (aliases: NewApiAlias[]): NewApiAliasForMetricGroupId[] => {
  return aliases
    .filter((a) => a.type === AliasTypes.METRIC_GROUP_ID)
    .map((a) => ({
      metricGroupId: a.key as MetricGroupId,
      translations: a.translations,
    }));
};

export const toNewApiAliasForMetricCategoryId = (aliases: NewApiAlias[]): NewApiAliasForMetricCategoryId[] => {
  return aliases
    .filter((a) => a.type === AliasTypes.METRIC_CATEGORY_ID)
    .map((a) => ({
      metricCategoryId: a.key,
      translations: a.translations,
    }));
};

export interface NewApiAlias {
  key: string;
  type: AliasTypes;
  translations: NewApiAliasTranslation[];
}

export interface NewApiAliasTranslation {
  language: Languages;
  text: string;
}
