import { Grid, Snackbar, styled } from '@mui/material';
import React, { useCallback, useState } from 'react';
import ChatBot, { ChatBotProvider, useChatWindow } from 'react-chatbotify';
import chappiLogo from '../../../assets/image/chappi/chappi.png';
import { rootStore } from '../store/root-store';
import { ChartComponent } from '../v2/common/components/charts/ChartComponent';
import {
  BarChartComponentConfig,
  KpiComponentConfig,
  LineChartComponentConfig,
  PieChartComponentConfig,
  TableComponentConfig,
} from '../v2/common/components/dashboards/types';
import { ChappiCategorizationResponse, ChappiResponse, parseVisualization, VisualizationBackend } from './chappi_types';

const VisualizationDiv = styled(Grid)`
  position: absolute;
  background-color: white;
  border-top: 2em solid #007565;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: width 1s ease-in-out;
  overflow-y: auto;
  gap: 2em;
  padding: 1em;

  /* Styling the scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #007565;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #00a599;
  }
`;

const VisualizationItem = styled('div')`
  padding: 0.2em;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 0.3em;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  /* Add subtle shadow and rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 40vh;
  width: 55vw;
`;

export const ChappiChatbot = () => {
  return (
    <ChatBotProvider>
      <ChappiChatbotInner />
    </ChatBotProvider>
  );
};

const ChappiChatbotInner = () => {
  const { isChatWindowOpen } = useChatWindow();
  const [form, setForm] = useState({});
  const [showVisualization, setShowVisualization] = useState(false);
  const [visualizationData, setVisualizationData] = useState<
    Array<
      | LineChartComponentConfig
      | PieChartComponentConfig
      | TableComponentConfig
      | KpiComponentConfig
      | BarChartComponentConfig
    >
  >([]);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const visualizationRef = React.useRef<HTMLDivElement>(null);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  // Function to copy conversation link to clipboard
  const copyConversationLink = useCallback(() => {
    if (!conversationId) return;

    const url = `${window.location.origin}/panalyt-superadmin/chappi-conversations?conversationId=${conversationId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopySnackbarOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy conversation link:', err);
      });
  }, [conversationId]);

  // Handle snackbar close
  const handleSnackbarClose = useCallback(() => {
    setCopySnackbarOpen(false);
  }, []);

  // Function to scroll to bottom of visualization div
  const scrollToBottom = () => {
    const ref = visualizationRef.current;
    if (ref) {
      setTimeout(() => {
        if (ref) {
          // Double-check ref is still valid after timeout
          ref.scrollTo({
            top: ref.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 350);
    }
  };

  // Effect to scroll to bottom when new visualization is added
  React.useEffect(() => {
    if (showVisualization && visualizationData.length > 0) {
      scrollToBottom();
    }
  }, [visualizationData.length, showVisualization]);

  const handleChappiResponse = (response: ChappiResponse[]) => {
    try {
      // Check if response is valid
      if (!response || !Array.isArray(response) || response.length === 0) {
        console.error('Invalid Chappi response received:', response);
        return 'Sorry, I received an unexpected response format. Please try again.';
      }

      // Check if the first element is a categorization response
      const firstResponse = response[0];
      if (!firstResponse) {
        console.error('First response is missing');
        return 'Sorry, I received an unexpected response format. Please try again.';
      }

      // Check if it's a categorization response either by __typename or by checking for expected properties
      const isCategorizationResponse =
        firstResponse.__typename === 'ChappiCategorizationResponse' ||
        ((firstResponse as any).questionCategory !== undefined && (firstResponse as any).questionSummary !== undefined);

      if (!isCategorizationResponse) {
        console.error('Expected first response to be ChappiCategorizationResponse but got:', firstResponse);
        return 'Sorry, I received an unexpected response format. Please try again.';
      }

      const categorizationResponse = firstResponse as ChappiCategorizationResponse;

      if (categorizationResponse.questionCategory === 'finding_specific_employees') {
        return (
          categorizationResponse.questionSummary +
          " This seems to be about finding specific individuals. I'm still in training and cannot help you with that yet. Do you have another question?"
        );
      } else if (categorizationResponse.questionCategory === 'other') {
        return categorizationResponse.questionSummary;
      } else if (categorizationResponse.questionCategory === 'how_to_use_the_application') {
        return (
          categorizationResponse.questionSummary +
          " Sorry but I'm still in training and cannot yet help you with questions about the usage of the Panalyt app. Do you have another question?"
        );
      } else if (categorizationResponse.questionCategory === 'analytics') {
        try {
          // For analytics, there should be a second response with the visualization
          if (response.length > 1) {
            const vizResponse = response[1];

            // Check if the second element is a valid visualization
            if (!vizResponse) {
              console.warn('Visualization response is missing');
              return (
                categorizationResponse.questionSummary +
                " Sorry, I couldn't generate a visualization for this question. Can you try asking in a different way?"
              );
            }

            // Check if it's a visualization by either __typename or by checking for expected properties
            const isVisualization =
              (vizResponse.__typename &&
                vizResponse.__typename !== 'ChappiCategorizationResponse' &&
                vizResponse.__typename !== 'ChappiNoVisualization') ||
              // Alternative checks for visualization types if __typename is missing
              (vizResponse as any).lineChartMetrics !== undefined ||
              (vizResponse as any).pieChartMetric !== undefined ||
              (vizResponse as any).kpiMetric !== undefined ||
              (vizResponse as any).barChartMetrics !== undefined ||
              (vizResponse as any).tableMetrics !== undefined;

            if (!isVisualization) {
              console.warn('Expected visualization response but got:', vizResponse);
              return (
                categorizationResponse.questionSummary +
                " Sorry, I couldn't generate a visualization for this question. Can you try asking in a different way?"
              );
            }

            const chappiViz = vizResponse as VisualizationBackend;
            const viz = parseVisualization(chappiViz);
            console.log('chappi chart component', viz);
            setVisualizationData((prevData) => [...prevData, viz]);
            setShowVisualization(true);
            return (
              categorizationResponse.questionSummary +
              ' I think this visualization might help you. Is there something else I can do for you?'
            );
          } else {
            return (
              categorizationResponse.questionSummary +
              " Sorry, I couldn't generate a visualization for this question. Can you try asking in a different way?"
            );
          }
        } catch (e) {
          console.warn('Error processing visualization:', e);
          return (
            categorizationResponse.questionSummary +
            ' Sorry but I got a headache while thinking about the question. Can you ask another question?'
          );
        }
      } else {
        // Handle unknown question category
        console.warn('Unknown question category:', categorizationResponse.questionCategory);
        return (
          categorizationResponse.questionSummary ||
          "I understood your question, but I'm not sure how to respond. Can you try asking in a different way?"
        );
      }
    } catch (error) {
      console.error('Error handling Chappi response:', error);
      return 'Sorry, something went wrong while processing your question. Please try again.';
    }
  };

  const flow = {
    start: {
      message:
        "I'm Chappi. Ask me anything! For example: Hey Chappi, how did our headcount change over the last years?",
      path: 'ask_question',
    },
    ask_question: {
      message: async (params: any) => {
        try {
          // If this is the first question (no conversation ID), start a new conversation
          if (!conversationId) {
            const startResult = await rootStore.graphQlRequestService.graphQlSdk.startConversation({
              domain: rootStore.companyStore.domain,
              firstQuestion: params.userInput,
            });

            if (startResult.startConversation) {
              setConversationId(startResult.startConversation.conversation.id);
              return handleChappiResponse(startResult.startConversation.firstResponse as ChappiResponse[]);
            }
          } else {
            // For subsequent questions, use the existing conversation ID
            const result = await rootStore.graphQlRequestService.graphQlSdk.askChappi({
              prompt: params.userInput,
              conversationId: conversationId,
            });

            if (result.askChappi) {
              return handleChappiResponse(result.askChappi as ChappiResponse[]);
            }
          }

          return "Sorry, that didn't work. Please try again.";
        } catch (error) {
          console.error('Error in Chappi conversation:', error);
          return 'I encountered an error. Please try again.';
        }
      },
      function: (params: any) => setForm({ ...form, age: params.userInput }),
      path: 'ask_question',
    },
  };
  return (
    <>
      <ChatBot
        flow={flow}
        settings={{
          general: { primaryColor: '#00a599', secondaryColor: '#007565' },
          notification: { disabled: true },
          header: {
            title: <b style={{ fontSize: '20px' }}>Chappi</b>,
            avatar: chappiLogo,
          },
          chatButton: { icon: chappiLogo },
          footer: { text: '', buttons: [] },
          tooltip: { mode: 'NEVER' },
        }}
      />
      {isChatWindowOpen && showVisualization && (
        <VisualizationDiv
          ref={visualizationRef}
          sx={{
            bottom: '20px',
            right: '405px',
            width: '60vw',
            height: '550px',
            opacity: 1,
          }}
          container
          direction="column"
          alignItems="center"
          flexWrap="nowrap"
        >
          {/* Copy Link top bar that stretches across the full width */}
          {conversationId && (
            <div
              onClick={copyConversationLink}
              title="Copy link to this conversation"
              style={{
                width: 'calc(100% + 16px)',
                marginLeft: '-8px',
                marginTop: '-8px',
                marginBottom: '8px',
                padding: '0 8px',
                height: '18px',
                backgroundColor: 'rgba(0, 117, 101, 0.1)',
                borderBottom: '1px solid rgba(0, 117, 101, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                fontSize: '10px',
                color: '#007565',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#007565"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginRight: '4px' }}
              >
                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
              </svg>
              Click here to copy a link to the conversation
            </div>
          )}

          {visualizationData.map((config, index) => {
            return (
              <Grid item key={index}>
                <VisualizationItem key={index}>
                  <ChartComponent pageOptions={{ timeSlider: { enabled: false } }} componentConfig={config} />
                </VisualizationItem>
              </Grid>
            );
          })}
        </VisualizationDiv>
      )}

      {/* Snackbar for copy notifications */}
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Conversation link copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};
