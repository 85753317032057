import { useEffect, useState } from 'react';

export const Delayed = ({
  delay,
  onDelayEnd,
  children,
}: {
  delay: number;
  onDelayEnd: () => void;
  children: React.ReactElement;
}) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => {
      onDelayEnd();
      setShowLoader(true);
    }, delay);

    return () => clearTimeout(id);
  }, [delay, onDelayEnd]);
  return showLoader ? children : null;
};
