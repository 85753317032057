import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { match, P } from 'ts-pattern';
import { useAliases } from '../../../api/alias/hooks';
import { MetricDetails } from '../../../api/metrics/types';
import { toTimeSegmentLabel } from '../../../api/utils';
import { UNDEFINED_DISPLAY_KEY } from '../../../constants';
import { useAliasServiceContext, useGlobalLocaleContext, useMetricDetailsMapContext } from '../../../context/contexts';
import { MetricIdType } from '../../../types';
import { formatMetricValue } from '../../../utils-display';
import { formatHierarchicalLabel } from '../filter/utils-display';
import { showMetric } from '../utils-display';
import { TableView } from './TableView';
import { MetricTableHeader, TableData, TableMetricsData, TableViewHeader, TableViewRow } from './types';

export const TableViewMetrics = <T extends MetricIdType>({
  tableData,
  fullScreenHandle,
  chartHeight,
}: {
  tableData: TableMetricsData<T>;
  fullScreenHandle: FullScreenHandle;
  chartHeight: string;
}) => {
  const { t } = useTranslation();
  const metricDetailsMap = useMetricDetailsMapContext();
  const aliasService = useAliasServiceContext();
  const { data: aliases } = useAliases(aliasService);
  const locale = useGlobalLocaleContext();
  const getAliasForMetric = aliasService.getAliasForMetricGroupId(aliases, locale.selected);
  const getColumnCellDataFromHeader = (header: MetricTableHeader<T>) => {
    const columnCellData: TableViewHeader = match(header)
      .with({ type: 'blank' }, () => {
        return { value: '' };
      })
      .with(P.union({ type: 'non-segmented' }, { type: 'segmented-by-metric' }), (h) => {
        const metricDetails = metricDetailsMap[h.metricId.value];
        const alias = getAliasForMetric(metricDetails?.metricGroupId);
        return { value: showMetric(h.metricId, metricDetails?.metricGroupId, alias, t) };
      })
      .with({ type: 'segmented-by-datafield' }, (h) => {
        return { value: formatHierarchicalLabel(h.value.value, h.value.dataField, t(UNDEFINED_DISPLAY_KEY), t) };
      })
      .otherwise(() => {
        throw new Error('Invalid header value');
      });
    return columnCellData;
  };
  const headers: TableViewHeader[] = tableData.headers.map((header) => {
    return getColumnCellDataFromHeader(header);
  });
  const rows: TableViewRow[] = tableData.rows.map((row) => {
    const rowTitle = match(row.title)
      .with({ __typename: P.string }, (timeSegment) => toTimeSegmentLabel(timeSegment, locale.selected))
      .with(
        P.union(
          { __typename: 'CalendarYearMonthlySegment' },
          { __typename: 'CalendarYearQuarterlySegment' },
          { __typename: 'CalendarYearYearlySegment' },
          { __typename: 'FinancialYearQuarterlySegment' },
          { __typename: 'FinancialYearYearlySegment' }
        ),
        (timeSegment) => toTimeSegmentLabel(timeSegment, locale.selected)
      )
      .with({ value: P.array(P.union(P.string, P.nullish)) }, (title) => {
        return formatHierarchicalLabel(title.value, title.dataField, t(UNDEFINED_DISPLAY_KEY), t);
      })
      .otherwise(() => {
        throw new Error('Invalid row title');
      });
    const cells = row.cells.map((cell) => {
      return match(cell)
        .with({ type: 'table-cell-empty' }, () => {
          return { value: '' };
        })
        .with({ type: 'table-cell-metric' }, (cell) => {
          const metricDetails: MetricDetails = metricDetailsMap[cell.metricId.value];
          const formatType = metricDetails?.formatType;
          return { value: formatMetricValue(cell.value, formatType) };
        })
        .with({ type: 'table-cell-time' }, (cell) => {
          return { value: toTimeSegmentLabel(cell.value, locale.selected) };
        })
        .exhaustive();
    });
    return { title: rowTitle, cells };
  });
  const tableViewData: TableData = {
    rows,
    headers,
  };
  return <TableView tableData={tableViewData} fullScreenHandle={fullScreenHandle} chartHeight={chartHeight} />;
};
