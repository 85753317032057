import orderBy from 'lodash.orderby';
import { match, P } from 'ts-pattern';
import {
  CohortMetricResult,
  DataValue,
  DomainPreferences,
  EmployeeCohortMetricResult,
  MetricResult,
  RegularMetricResult,
} from '../../../../api/types';
import { compareApiOverTimeTimeSegmentType } from '../../../../api/utils';
import { Segmentation } from '../../../../types';
import { getSortIndices, sortByIndices, sortFieldValues, sortValuesAlphabetically } from '../../../../utils';
import { SortTypes } from '../../../types';
import { timeSegmentOrder } from '../overtime/utils';
import {
  ChartData,
  DoubleLevelSegmentedTimePeriodChartInput,
  MuiTimePeriodChartData,
  NonSegmentedOverTimeChartInput,
  NonSegmentedTimePeriodChartInput,
  PartialDoubleLevelSegmentedTimePeriodChartInput,
  PartialNonSegmentedOverTimeChartInput,
  PartialSegmentedOverTimeChartInput,
  SegmentedOverTimeChartInput,
  SingleLevelSegmentedTimePeriodChartInput,
} from './types';

export const toSegmentedOverTimeChartInput = (
  resultOrErrors: Array<MetricResult | Error>,
  toChartData: (
    processedResult: PartialSegmentedOverTimeChartInput,
    result: RegularMetricResult | CohortMetricResult
  ) => PartialSegmentedOverTimeChartInput
): SegmentedOverTimeChartInput => {
  const partialResult = resultOrErrors.reduce<PartialSegmentedOverTimeChartInput>(
    (data: PartialSegmentedOverTimeChartInput, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          return toChartData(data, res);
        })
        .with({ type: 'CohortMetricResult' }, (res: CohortMetricResult) => {
          return toChartData(data, res);
        })
        .otherwise(() => {
          throw new Error('Invalid result');
        });
    },
    { type: 'SegmentedSeries', xAxisData: [], series: [] }
  );
  return {
    ...partialResult,
    xAxisData: partialResult.xAxisData.sort(timeSegmentOrder),
    series: partialResult.series.map((s) => {
      const missingTimeSegments = partialResult.xAxisData.filter((timeSegment) => {
        return !s.values.some((v) => compareApiOverTimeTimeSegmentType(v.timeSegment, timeSegment));
      });
      return {
        ...s,
        values: [...s.values, ...missingTimeSegments.map((ts) => ({ timeSegment: ts, value: null }))]
          .sort((s1, s2) => timeSegmentOrder(s1.timeSegment, s2.timeSegment))
          .map((v) => v.value),
      };
    }),
  };
};

export const toSingleLevelSegmentedTimePeriodChartInput = (
  resultOrErrors: Array<MetricResult | Error>,
  toChartData: (
    processedResult: SingleLevelSegmentedTimePeriodChartInput,
    result: RegularMetricResult
  ) => SingleLevelSegmentedTimePeriodChartInput
): SingleLevelSegmentedTimePeriodChartInput => {
  return resultOrErrors.reduce<SingleLevelSegmentedTimePeriodChartInput>(
    (data: SingleLevelSegmentedTimePeriodChartInput, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          return toChartData(data, res);
        })
        .with({ type: 'CohortMetricResult' }, () => {
          throw new Error('Cohort metrics not implemented');
        })
        .otherwise(() => {
          throw new Error('Invalid result');
        });
    },
    { type: 'SingleLevelSegmentedSeries', xAxisData: [], series: [] }
  );
};

export const toDoubleLevelSegmentedTimePeriodChartInput = (
  resultOrErrors: Array<MetricResult | Error>,
  toChartData: (
    processedResult: PartialDoubleLevelSegmentedTimePeriodChartInput,
    result: RegularMetricResult,
    segmentations: Segmentation[]
  ) => PartialDoubleLevelSegmentedTimePeriodChartInput,
  segmentations: Segmentation[]
): DoubleLevelSegmentedTimePeriodChartInput => {
  const partialResult = resultOrErrors.reduce<PartialDoubleLevelSegmentedTimePeriodChartInput>(
    (data: PartialDoubleLevelSegmentedTimePeriodChartInput, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          return toChartData(data, res, segmentations);
        })
        .with({ type: 'CohortMetricResult' }, () => {
          throw new Error('Cohort metrics not implemented');
        })
        .otherwise(() => {
          throw new Error('Invalid result');
        });
    },
    { type: 'DoubleLevelSegmentedSeries', xAxisData: [], series: [] }
  );
  return {
    ...partialResult,
    series: partialResult.series.map((s) => ({ ...s, values: s.values.map((v) => v.value) })),
  };
};

export const toNonSegmentedOverTimeChartInput = (
  resultOrErrors: Array<MetricResult | Error>,
  toChartData: (
    processedResult: PartialNonSegmentedOverTimeChartInput,
    result: MetricResult
  ) => PartialNonSegmentedOverTimeChartInput
): NonSegmentedOverTimeChartInput => {
  const partialResult = resultOrErrors.reduce<PartialNonSegmentedOverTimeChartInput>(
    (data: PartialNonSegmentedOverTimeChartInput, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          return toChartData(data, res);
        })
        .with({ type: 'CohortMetricResult' }, (res: CohortMetricResult) => {
          return toChartData(data, res);
        })
        .with({ type: 'EmployeeCohortMetricResult' }, (res: EmployeeCohortMetricResult) => {
          return toChartData(data, res);
        })
        .otherwise(() => {
          throw new Error('Invalid result');
        });
    },
    { type: 'NonSegmentedSeries', xAxisData: [], series: [] }
  );
  return {
    ...partialResult,
    xAxisData: partialResult.xAxisData.sort(timeSegmentOrder),
    series: partialResult.series.map((s) => {
      const missingTimeSegments = partialResult.xAxisData.filter((timeSegment) => {
        return !s.values.some((v) => compareApiOverTimeTimeSegmentType(v.timeSegment, timeSegment));
      });
      return {
        ...s,
        values: [...s.values, ...missingTimeSegments.map((ts) => ({ timeSegment: ts, value: null }))]
          .sort((s1, s2) => timeSegmentOrder(s1.timeSegment, s2.timeSegment))
          .map((v) => v.value),
      };
    }),
  };
};

export const toNonSegmentedTimePeriodChartInput = (
  resultOrErrors: Array<MetricResult | Error>,
  toChartData: (
    processedResult: NonSegmentedTimePeriodChartInput,
    result: RegularMetricResult
  ) => NonSegmentedTimePeriodChartInput
): NonSegmentedTimePeriodChartInput => {
  return resultOrErrors.reduce<NonSegmentedTimePeriodChartInput>(
    (data: NonSegmentedTimePeriodChartInput, resultOrError: MetricResult | Error) => {
      return match(resultOrError)
        .with(P.instanceOf(Error), (error: Error) => {
          throw error;
        })
        .with({ type: 'RegularMetricResult' }, (res: RegularMetricResult) => {
          return toChartData(data, res);
        })
        .with({ type: 'CohortMetricResult' }, () => {
          throw new Error('Cohort metrics not implemented');
        })
        .otherwise(() => {
          throw new Error('Invalid result');
        });
    },
    { type: 'NonSegmentedSeries' }
  );
};

export const sortTimePeriodData = (
  data: MuiTimePeriodChartData,
  selectedSortOrder: SortTypes,
  segmentations: Segmentation[] | undefined,
  preferences: DomainPreferences,
  rawXAxisData: DataValue[] // Raw xAxis data for identifying nulls
): MuiTimePeriodChartData => {
  // Early return if no data or no xAxisConfig
  if (!data || !data.xAxisConfig) {
    return data;
  }

  // Get the formatted xAxisData
  const xAxisData = data.xAxisConfig.data || [];

  // Find the null index from raw data if available
  const nullIndex: number | undefined = rawXAxisData.findIndex((value) => value === null);

  // Determine new indices based on sort order
  let newIndices: number[] = [];

  // Helper function to sort and append null index
  const sortAndAppendNull = (items: { index: number }[], sortKey: string, sortDirection: 'asc' | 'desc') => {
    const sortedItems = orderBy(items, [sortKey], [sortDirection]);
    const sortedIndices = sortedItems.map((item) => item.index);

    // Add null index at the end if it exists
    if (nullIndex !== undefined && nullIndex >= 0) {
      sortedIndices.push(nullIndex);
    }

    return sortedIndices;
  };

  switch (selectedSortOrder) {
    case SortTypes.ASC:
    case SortTypes.DESC: {
      newIndices = getSortIndices(
        xAxisData,
        (values) => {
          const sorted =
            segmentations && segmentations.length > 0
              ? sortFieldValues(values, segmentations?.[0]?.dataField, preferences)
              : sortValuesAlphabetically(values);
          // TODO: improve performance by not reversing after sorting but directly sorting in the reverse way
          return selectedSortOrder === SortTypes.DESC ? sorted.reverse() : sorted;
        },
        nullIndex
      );
      break;
    }

    case SortTypes.LOW:
    case SortTypes.HIGH: {
      const sortDirection = selectedSortOrder === SortTypes.LOW ? 'desc' : 'asc';

      if (segmentations && segmentations.length > 1) {
        // Multi-segmentation case
        // Create an array to hold the sum for each index
        const indexSums: { index: number; sum: number; isNull: boolean }[] = [];

        // For each index in the x-axis
        for (let i = 0; i < xAxisData.length; i++) {
          // Skip if this is the null index
          if (i === nullIndex) {
            indexSums.push({ index: i, sum: 0, isNull: true });
            continue;
          }

          // Collect all values at this index across all series
          const valuesAtIndex = data.series
            .map((series) => {
              if (Array.isArray(series.data)) {
                return series.data[i];
              }
              return null;
            })
            .filter((value) => value !== null && typeof value === 'number') as number[];

          // Sum the values
          const totalSum = valuesAtIndex.reduce((sum, value) => sum + value, 0);

          indexSums.push({ index: i, sum: totalSum, isNull: false });
        }

        // Filter out the null item
        const nonNullSums = indexSums.filter((item) => !item.isNull);

        // Sort by sum
        newIndices = sortAndAppendNull(nonNullSums, 'sum', sortDirection);
      } else {
        // Single segmentation case
        // Get values from first series
        const seriesValues = data.series[0].data ?? [];

        // Create indexed values
        const indexedValues = seriesValues.map((value, index) => ({
          value,
          index,
          isNull: index === nullIndex,
        }));

        // Filter out the null item
        const nonNullItems = indexedValues.filter((item) => !item.isNull);

        // Sort by value
        newIndices = sortAndAppendNull(nonNullItems, 'value', sortDirection);
      }
      break;
    }

    default:
      return data;
  }

  // If we didn't determine new indices, return original data
  if (newIndices.length === 0) {
    return data;
  }

  // Apply the sorting to data
  const applySort = (data: MuiTimePeriodChartData, indices: number[]): MuiTimePeriodChartData => {
    // Apply the sorting to xAxisConfig data
    const newXAxisConfig = {
      ...data.xAxisConfig,
      data: sortByIndices(data.xAxisConfig?.data || [], indices, false),
    };

    // Apply the same sorting to all series data arrays
    const newSeries = data.series.map((series) => {
      if (Array.isArray(series.data)) {
        return {
          ...series,
          data: sortByIndices(series.data, indices, false),
        };
      }
      return series;
    });

    return {
      ...data,
      series: newSeries,
      xAxisConfig: newXAxisConfig,
    };
  };

  return applySort(data, newIndices);
};

export const removeNullsOrZeros = <
  T extends SingleLevelSegmentedTimePeriodChartInput | DoubleLevelSegmentedTimePeriodChartInput
>(
  data: T
): T => {
  const zeroIndices = data.xAxisData.flatMap((_, i) => {
    const allZero = data.series.every((s) => s.values[i] === 0 || s.values[i] === null);
    return allZero ? [i] : [];
  });
  return {
    ...data,
    xAxisData: data.xAxisData.filter((_, i) => !zeroIndices.includes(i)),
    series: data.series.map((s) => ({
      ...s,
      values: s.values.filter((_, i) => !zeroIndices.includes(i)),
    })),
  };
};

// TODO: fix this
export const calculateLabelRotation = (
  data: ChartData[],
  index: string
): {
  angle: number;
  verticalShift?: number;
  xAxisHeight?: number;
} => {
  const getIndex = (d: ChartData) => {
    return (d[index] as string) ?? '';
  };

  const longestLabelLength = data.reduce((l, d) => (getIndex(d).length > l ? getIndex(d).length : l), 0);
  const totalLabelLength = data.reduce((l, d) => l + getIndex(d).length, 0);

  return {
    angle: Math.max(Math.min(-(totalLabelLength - 40), 0), -90),
    verticalShift: longestLabelLength * 6,
    xAxisHeight: longestLabelLength * 12,
  };
};

/**
 * Helper faking an element bounding box for the Popper.
 */
export function generateVirtualElement(mousePosition: { x: number; y: number } | null) {
  if (mousePosition === null) {
    return {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        toJSON: () => '',
      }),
    };
  }
  const { x, y } = mousePosition;
  const boundingBox = {
    width: 0,
    height: 0,
    x,
    y,
    top: y,
    right: x,
    bottom: y,
    left: x,
  };
  return {
    getBoundingClientRect: () => ({
      ...boundingBox,
      toJSON: () => JSON.stringify(boundingBox),
    }),
  };
}
