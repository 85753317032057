import { TFunction } from 'i18next';
import { DataValue } from '../../../api/types';
import { ASCII_ARROW, UNDEFINED_DISPLAY_KEY } from '../../../constants';
import { DataFieldWithDataType } from '../../../types';
import { translateLabel } from '../../utils';

export const formatHierarchicalLabel = (
  label: DataValue[],
  dataField: DataFieldWithDataType,
  defaultValue: string,
  t: TFunction
): string => {
  return label
    .map((v: DataValue) => {
      const translatedLabel = translateLabel(v, dataField);
      return translatedLabel
        ? t(translatedLabel, { defaultValue: UNDEFINED_DISPLAY_KEY }) ?? defaultValue
        : v === null || v === undefined
        ? defaultValue
        : v;
    })
    .join(ASCII_ARROW);
};
