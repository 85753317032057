import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { enumKeys } from '../../../utils';
import { SortIcon } from '../../styles';
import { dropdownMenuItemHoverColor } from '../../theme';
import { SortTypes } from '../../types';
import { sortTypeTranslationKeys } from '../../utils';
import { TimePeriodDisplayHandle } from '../charts/timeperiod/types';
import { StyledIconButton } from './shared-styles';

interface SortOrderToggleProps {
  displayHandle: TimePeriodDisplayHandle;
}

export const SortOrderToggle = (props: SortOrderToggleProps) => {
  const { displayHandle } = props;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowModal(true);
  };

  return (
    <Tooltip title={t('common:commonValues.tooltips.sortOrder')} placement="top">
      <>
        <StyledIconButton
          onClick={handleClick}
          size="small"
          aria-label="Sort Order"
          className="intercom_toggle_sort_order"
        >
          <SortIcon />
        </StyledIconButton>
        <SortOrderMenuPopup open={showModal} onClose={() => setShowModal(false)} displayHandle={displayHandle} />
      </>
    </Tooltip>
  );
};

interface SortOrderMenuPopupProps {
  open: boolean;
  onClose: () => void;
  displayHandle: TimePeriodDisplayHandle;
}

const SelectedMenu = styled(MenuItem)<{
  $isselected: boolean;
}>`
  background-color: ${(props) => (props.$isselected ? `${dropdownMenuItemHoverColor}!important` : 'inherit')};
`;

export const SortOrderMenuPopup = (props: SortOrderMenuPopupProps) => {
  const { open, onClose, displayHandle } = props;
  const [state, dispatcher] = displayHandle;
  const { t } = useTranslation();
  return (
    <Popper
      open={open}
      placement="right-end"
      anchorEl={null}
      transition
      disablePortal
      style={{ position: 'absolute', zIndex: 999 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper style={{ zIndex: 999 }}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {enumKeys(sortTypeTranslationKeys).map((sortType: SortTypes) => {
                  return (
                    <SelectedMenu
                      key={sortType}
                      $isselected={sortType === state.selectedSortOrder}
                      selected={sortType === state.selectedSortOrder}
                      onClick={() => {
                        dispatcher({ type: 'sort-order', selectedSortOrder: sortType });
                      }}
                      style={{ color: 'inherit' }}
                    >
                      {t(sortTypeTranslationKeys[sortType])}
                    </SelectedMenu>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
