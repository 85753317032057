import { TFunction } from 'i18next';
import { match, P } from 'ts-pattern';
import { getTranslation } from '../../../constants/systemValuesTranslation';
import { MetricGroupId, SingleValueTimeSelectionInput } from '../../api/types-graphql';
import { MetricIdType, Segmentation, TimeSelection, TimeSelectionType } from '../../types';

export const showTimeSelection = (timeSelection: TimeSelection, t: TFunction) => {
  return match(timeSelection)
    .with(
      { type: TimeSelectionType.CalendarYearSingleValueByMonths, input: P.select() },
      (input: SingleValueTimeSelectionInput) => {
        if (input.start === input.end) {
          return t('common:customDashboard.timeSelection.pointInTime', { selection: input.start as string });
        } else {
          return t('common:customDashboard.timeSelection.range', {
            start: input.start as string,
            end: input.end as string,
          });
        }
      }
    )
    .otherwise(() => {
      throw Error('not implemented');
    });
};

export const showTimeSelectionShortFormat = (timeSelection: TimeSelection, t: TFunction) => {
  return match(timeSelection)
    .with(
      { type: TimeSelectionType.CalendarYearSingleValueByMonths, input: P.select() },
      (input: SingleValueTimeSelectionInput) => {
        if (input.start === input.end) {
          return t('common:customDashboard.timeSelection.pointInTime', { selection: input.start as string });
        } else {
          return t('common:customDashboard.timeSelection.range', {
            start: input.start as string,
            end: input.end as string,
          });
        }
      }
    )
    .otherwise(() => {
      throw Error('not implemented');
    });
};

export const showMetric = (metric: MetricIdType, metricGroup: MetricGroupId, alias: string | null, t: TFunction) => {
  return `${alias ?? t(`common:metrics.metricGroupIds.${metricGroup}`, { defaultValue: metricGroup })} (${t(
    `common:metrics.metricIds.${metric.value}`,
    { defaultValue: metric.value }
  )})`;
};

export const showTitle = (
  metrics: MetricIdType[],
  metricGroups: MetricGroupId[],
  timeSelection: TimeSelection,
  alias: (string | null)[],
  t: TFunction,
  title?: string,
  segmentations?: Segmentation[]
): string => {
  if (title) {
    return title;
  } else if (metrics.length === 1) {
    return `${showMetric(metrics[0], metricGroups[0], alias[0], t)}${
      segmentations ? ` by ${segmentations.map((s) => getTranslation(s.dataField)).join(' ')}` : ''
    }`;
  }
  return '';
};
