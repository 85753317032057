import { DataFieldWithDataType, MetricIdType } from '../types';
import { enumValues } from '../utils';
import { MetricGroupDetails } from './metrics/types';
import { MetricCategoryId, MetricCategoryTree, MetricGroupId, MetricId, MetricTree, MetricType } from './types-graphql';

const metricIdsSet = new Set(enumValues(MetricId));
const metricGroupsSet = new Set(enumValues(MetricGroupId));
const metricCategoriesSet = new Set(enumValues(MetricCategoryId));

export const cleanupMetricTree = (metricTreeFromBackend: MetricTree): MetricTree => {
  // This function will remove any items that don't have ids in graphql-sdk.ts
  // This will happen if there is new data on the backend. Since frontend might not
  // support that fully(in translation, data validation in zod, etc), we just remove them from
  // the fetched list
  const cleanedMetricTree: MetricTree = {
    ...metricTreeFromBackend,
    categoryTrees: metricTreeFromBackend.categoryTrees
      .map((cTree) => {
        if (!metricCategoriesSet.has(cTree.metricCategoryId)) {
          return null;
        } else {
          return {
            ...cTree,
            groupTrees: cTree.groupTrees
              .map((gTree) => {
                if (!metricGroupsSet.has(gTree.metricGroupId)) {
                  return null;
                } else {
                  return {
                    ...gTree,
                    metricDefs: gTree.metricDefs
                      .map((mDef) => {
                        if (!metricIdsSet.has(mDef.id)) {
                          return null;
                        } else {
                          return mDef;
                        }
                      })
                      .filter(Boolean),
                  };
                }
              })
              .filter(Boolean),
          };
        }
      })
      .filter(Boolean) as MetricCategoryTree[],
  };
  return cleanedMetricTree;
};

export const convertMetricTreeToMetricDetails = (metricTree: MetricTree): MetricGroupDetails[] => {
  const metricGroupDetails = metricTree.categoryTrees.flatMap((catTree) => {
    const { metricCategoryId, groupTrees, metricCategory } = catTree;
    const metricsWithDimensions: MetricGroupDetails[] = groupTrees.map((group) => {
      const { metricGroupId, metricDefs } = group;
      const metricDetails = metricDefs.map((def) => {
        return {
          id: (def.metricType === MetricType.MetricTypeCohort
            ? { type: 'CohortMetricId', value: def.id }
            : { type: 'RegularMetricId', value: def.id }) as unknown as MetricIdType,
          metricGroupId: def.metricGroupId,
          formatType: def.formatType.id,
          underlyingFields: def.underlyingDataFields as unknown as DataFieldWithDataType[],
          isCohort: def.metricType === MetricType.MetricTypeCohort,
          defaultPosition: def.defaultPosition,
        };
      });
      return {
        id: metricGroupId,
        defaultCategory: metricCategoryId,
        dimensions: metricDetails,
        defaultPosition: group.metricGroup.defaultPosition,
        defaultCategoryPosition: metricCategory.defaultPosition,
      };
    });
    return metricsWithDimensions;
  });
  return metricGroupDetails;
};
