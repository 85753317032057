import {
  ApiMasterDataType,
  ChappiPieChartSegmentation,
  ChappiPieChartVariant,
  MetricId,
  RegularMetricId,
} from '../graphql/generated/graphql-sdk';
import {
  KpiComponentConfig,
  LineChartComponentConfig,
  OverTimeBarChartComponentConfig,
  PieChartComponentConfig,
  TableComponentConfig,
  TimePeriodBarChartComponentConfig,
} from '../v2/common/components/dashboards/types';
import {
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  OverTimeTimeSelection,
  Segmentation,
  SingleValueTimeSelection,
  TimeSelectionType,
} from '../v2/types';

type ChappiLineChartTimeSelection =
  | { __typename: 'CalendarYearMonthlyTimeSelection'; startDate: any; endDate: any }
  | {
      __typename: 'CalendarYearQuarterlyTimeSelection';
      startQuarter: { __typename?: 'Quarter'; quarterOfYear: number; year: number };
      endQuarter: { __typename?: 'Quarter'; quarterOfYear: number; year: number };
    }
  | { __typename: 'CalendarYearYearlyTimeSelection'; startYear: number; endYear: number }
  | {
      __typename: 'FinancialYearQuarterlyTimeSelection';
      startQuarter: { __typename?: 'Quarter'; quarterOfYear: number; year: number };
      endQuarter: { __typename?: 'Quarter'; quarterOfYear: number; year: number };
    }
  | { __typename: 'FinancialYearYearlyTimeSelection'; startYear: number; endYear: number };

type ChappiLineChartSegmentationPart = {
  __typename?: 'ChappiLineChartSegmentation';
  datafieldAndDatatype: { __typename?: 'DatafieldAndDatatype'; dataField: any; dataType: ApiMasterDataType };
};
type ChappiFilters = {
  sql: string;
} | null;

type ChappiLineChartVisualization = {
  __typename: 'ChappiLineChartVisualization';
  lineChartMetrics: Array<MetricId>;
  lineChartTimeSelection: ChappiLineChartTimeSelection;
  lineChartSegmentation: ChappiLineChartSegmentationPart[];
  lineChartVariant:
    | 'ChappiLineChartVariantLineChart'
    | 'ChappiLineChartVariantAreaChart'
    | 'ChappiLineChartVariantStackedAreaChart';
  filters: ChappiFilters;
};

type ChappyPieChartTimeSelection =
  | { __typename: 'SingleValueByCalendarMonthsTimeSelection'; startDate: any; endDate: any }
  | { __typename: 'SingleValueByCalendarYearsTimeSelection'; startYear: number; endYear: number }
  | {
      __typename: 'SingleValueByFinancialQuartersTimeSelection';
      startQuarter: { __typename?: 'Quarter'; quarterOfYear: number; year: number };
      endQuarter: { __typename?: 'Quarter'; quarterOfYear: number; year: number };
    }
  | { __typename: 'SingleValueByFinancialYearsTimeSelection'; startYear: number; endYear: number };
type ChappiPieChartVisualization = {
  __typename?: 'ChappiPieChartVisualization';
  pieChartMetric: MetricId;
  pieChartSegmentation: ChappiPieChartSegmentation;
  pieChartTimeSelection: ChappyPieChartTimeSelection;
  pieChartVariant: ChappiPieChartVariant;
  filters: ChappiFilters;
};

type ChappiKpiVisualization = {
  __typename: 'ChappiKpiVisualization';
  kpiMetric: Array<MetricId>;
  kpiTimeSelection: ChappyPieChartTimeSelection;
  kpiSegmentation: ChappiLineChartSegmentationPart[];
  alignment: 'ChappiKpiAlignmentColumn' | 'ChappiKpiAlignmentRow';
  filters: ChappiFilters;
};

type ChappiOverTimeBarChartVisualization = {
  __typename: 'ChappiOverTimeBarChartVisualization';
  barChartMetrics: Array<MetricId>;
  barChartTimeSelection: ChappiLineChartTimeSelection;
  barChartSegmentation: ChappiLineChartSegmentationPart[];
  stack: boolean;
  relative: boolean;
  filters: ChappiFilters;
};

type ChappiTableVisualization = {
  __typename: 'ChappiTableVisualization';
  tableMetrics: Array<MetricId>;
  tableTimeSelection: ChappiLineChartTimeSelection;
  tableSegmentation: ChappiLineChartSegmentationPart[];
  filters: ChappiFilters;
};

type ChappiTimePeriodBarChartVisualization = {
  __typename: 'ChappiTimePeriodBarChartVisualization';
  barChartMetrics: Array<MetricId>;
  barChartTimeSelection: ChappyPieChartTimeSelection;
  barChartSegmentation: ChappiLineChartSegmentationPart[];
  stack: boolean;
  relative: boolean;
  filters: ChappiFilters;
};

export const convertChappiTimeSelection = (timeSelection: ChappiLineChartTimeSelection): OverTimeTimeSelection => {
  switch (timeSelection.__typename) {
    case 'CalendarYearMonthlyTimeSelection':
      return {
        type: TimeSelectionType.CalendarYearMonthly,
        input: {
          start: timeSelection.startDate,
          end: timeSelection.endDate,
        },
      };
    case 'CalendarYearQuarterlyTimeSelection':
      return {
        type: TimeSelectionType.CalendarYearQuarterly,
        input: {
          start: {
            year: timeSelection.startQuarter.year,
            quarterOfYear: timeSelection.startQuarter.quarterOfYear,
          },
          end: {
            year: timeSelection.endQuarter.year,
            quarterOfYear: timeSelection.endQuarter.quarterOfYear,
          },
        },
      };
    case 'CalendarYearYearlyTimeSelection':
      return {
        type: TimeSelectionType.CalendarYearYearly,
        input: {
          start: timeSelection.startYear,
          end: timeSelection.endYear,
        },
      };
    case 'FinancialYearQuarterlyTimeSelection':
      return {
        type: TimeSelectionType.FinancialYearQuarterly,
        input: {
          start: {
            year: timeSelection.startQuarter.year,
            quarterOfYear: timeSelection.startQuarter.quarterOfYear,
          },
          end: {
            year: timeSelection.endQuarter.year,
            quarterOfYear: timeSelection.endQuarter.quarterOfYear,
          },
        },
      };
    case 'FinancialYearYearlyTimeSelection':
      return {
        type: TimeSelectionType.FinancialYearYearly,
        input: {
          start: timeSelection.startYear,
          end: timeSelection.endYear,
        },
      };
    default:
      throw new Error(`Unsupported time selection type: ${(timeSelection as any).__typename}`);
  }
};

export const convertChappiSegmentation = (
  segmentationPart: ChappiLineChartSegmentationPart | ChappiPieChartSegmentation
): Segmentation => {
  const dataFieldWithDataType: DataFieldWithDataType = {
    dataType: segmentationPart.datafieldAndDatatype.dataType as unknown as DataTypes, // TODO: fix conversion
    dataField: segmentationPart.datafieldAndDatatype.dataField as DataFields,
  };
  return {
    type: 'non-hierarchical',
    dataField: dataFieldWithDataType,
  };
};

export const convertChappiPieTimeSelection = (timeSelection: ChappyPieChartTimeSelection): SingleValueTimeSelection => {
  switch (timeSelection.__typename) {
    case 'SingleValueByCalendarMonthsTimeSelection':
      return {
        type: TimeSelectionType.CalendarYearSingleValueByMonths,
        input: {
          start: timeSelection.startDate,
          end: timeSelection.endDate,
        },
      };
    case 'SingleValueByCalendarYearsTimeSelection':
      return {
        type: TimeSelectionType.CalendarYearSingleValueByYears,
        input: {
          start: timeSelection.startYear,
          end: timeSelection.endYear,
        },
      };
    case 'SingleValueByFinancialQuartersTimeSelection':
      return {
        type: TimeSelectionType.FinancialYearSingleValueByQuarters,
        input: {
          start: {
            year: timeSelection.startQuarter.year,
            quarterOfYear: timeSelection.startQuarter.quarterOfYear,
          },
          end: {
            year: timeSelection.endQuarter.year,
            quarterOfYear: timeSelection.endQuarter.quarterOfYear,
          },
        },
      };
    case 'SingleValueByFinancialYearsTimeSelection':
      return {
        type: TimeSelectionType.FinancialYearSingleValueByYears,
        input: {
          start: timeSelection.startYear,
          end: timeSelection.endYear,
        },
      };
    default:
      throw new Error(`Unsupported time selection type: ${(timeSelection as any).__typename}`);
  }
};

export type VisualizationBackend =
  | ChappiLineChartVisualization
  | ChappiPieChartVisualization
  | ChappiKpiVisualization
  | ChappiOverTimeBarChartVisualization
  | ChappiTableVisualization
  | ChappiTimePeriodBarChartVisualization
  | { __typename: 'ChappiNoVisualization' };

export type ChappiCategorizationResponse = {
  __typename: 'ChappiCategorizationResponse';
  questionSummary: string;
  questionCategory: string;
  visualizationType: string | null;
};

export type ChappiResponse = ChappiCategorizationResponse | VisualizationBackend;

export const parseVisualization = (
  value: VisualizationBackend
):
  | LineChartComponentConfig
  | PieChartComponentConfig
  | KpiComponentConfig
  | OverTimeBarChartComponentConfig
  | TableComponentConfig
  | TimePeriodBarChartComponentConfig => {
  if (value.__typename === 'ChappiLineChartVisualization') {
    const val = value as ChappiLineChartVisualization;
    const segmentations =
      val.lineChartSegmentation.length > 0 ? val.lineChartSegmentation.map(convertChappiSegmentation) : undefined;

    // Map backend chart type to frontend chart type
    const chartType = (() => {
      switch (val.lineChartVariant) {
        case 'ChappiLineChartVariantLineChart':
          return 'line';
        case 'ChappiLineChartVariantAreaChart':
          return 'area';
        case 'ChappiLineChartVariantStackedAreaChart':
          return 'area';
        default:
          throw new Error(`Unsupported chart type: ${val.lineChartVariant}`);
      }
    })();

    const lineChartComponentConfigFromChappiLineChartVisualization: LineChartComponentConfig = {
      chartId: 'chappi-linechart',
      chartTypeConfig: {
        chartType,
        // Add stack property for StackedAreaChart
        ...(val.lineChartVariant === 'ChappiLineChartVariantStackedAreaChart' ? { stack: true } : {}),
      },
      queries: [
        {
          metrics: val.lineChartMetrics.map((metric) => {
            return {
              value: metric as unknown as RegularMetricId,
              type: 'RegularMetricId',
            };
          }),
          timeSelection: convertChappiTimeSelection(val.lineChartTimeSelection),
          segmentations,
          filters: val.filters?.sql,
        },
      ],
    };
    return lineChartComponentConfigFromChappiLineChartVisualization;
  } else if (value.__typename === 'ChappiPieChartVisualization') {
    const val = value as ChappiPieChartVisualization;
    const segmentation = convertChappiSegmentation(val.pieChartSegmentation);

    const pieChartComponentConfigFromChappiPieChartVisualization: PieChartComponentConfig = {
      chartId: 'chappi-piechart',
      chartTypeConfig: {
        chartType: 'pie',
        variant: val.pieChartVariant.replace('ChappiPieChartVariant', '').toLowerCase() as 'donut' | 'pie',
      },
      query: {
        metrics: [
          {
            value: val.pieChartMetric as unknown as RegularMetricId,
            type: 'RegularMetricId',
          },
        ],
        timeSelection: convertChappiPieTimeSelection(val.pieChartTimeSelection),
        segmentations: [segmentation],
        filters: val.filters?.sql,
      },
    };
    return pieChartComponentConfigFromChappiPieChartVisualization;
  } else if (value.__typename === 'ChappiKpiVisualization') {
    const val = value as ChappiKpiVisualization;
    const kpiComponentConfig: KpiComponentConfig = {
      chartId: 'chappi-kpi',
      chartTypeConfig: {
        chartType: 'kpi',
      },
      align: val.alignment === 'ChappiKpiAlignmentColumn' ? 'column' : 'row',
      sections: [
        {
          key: 'chappi-kpi-section',
          queries: [
            {
              metrics: val.kpiMetric.map((metric) => ({
                value: metric as unknown as RegularMetricId,
                type: 'RegularMetricId',
              })),
              timeSelection: convertChappiPieTimeSelection(val.kpiTimeSelection),
              segmentations:
                val.kpiSegmentation.length > 0 ? val.kpiSegmentation.map(convertChappiSegmentation) : undefined,
              filters: val.filters?.sql,
            },
          ],
        },
      ],
    };
    return kpiComponentConfig;
  } else if (value.__typename === 'ChappiOverTimeBarChartVisualization') {
    const val = value as ChappiOverTimeBarChartVisualization;
    const overTimeBarChartConfig: OverTimeBarChartComponentConfig = {
      chartId: 'chappi-overtime-bar',
      chartTypeConfig: {
        chartType: 'bar',
        timeframe: 'overTime',
        stack: val.stack,
        relative: val.relative,
      },
      queries: [
        {
          metrics: val.barChartMetrics.map((metric) => ({
            value: metric as unknown as RegularMetricId,
            type: 'RegularMetricId',
          })),
          timeSelection: convertChappiTimeSelection(val.barChartTimeSelection),
          segmentations:
            val.barChartSegmentation.length > 0 ? val.barChartSegmentation.map(convertChappiSegmentation) : undefined,
          filters: val.filters?.sql,
        },
      ],
    };
    return overTimeBarChartConfig;
  } else if (value.__typename === 'ChappiTableVisualization') {
    const val = value as ChappiTableVisualization;
    const tableConfig: TableComponentConfig = {
      chartId: 'chappi-table',
      chartTypeConfig: {
        chartType: 'table',
        timeframe: 'overTime',
      },
      queries: [
        {
          metrics: val.tableMetrics.map((metric) => ({
            value: metric as unknown as RegularMetricId,
            type: 'RegularMetricId',
          })),
          timeSelection: convertChappiTimeSelection(val.tableTimeSelection),
          segmentations:
            val.tableSegmentation.length > 0 ? val.tableSegmentation.map(convertChappiSegmentation) : undefined,
          filters: val.filters?.sql,
        },
      ],
    };
    return tableConfig;
  } else if (value.__typename === 'ChappiTimePeriodBarChartVisualization') {
    const val = value as ChappiTimePeriodBarChartVisualization;
    const timePeriodBarChartConfig: TimePeriodBarChartComponentConfig = {
      chartId: 'chappi-timeperiod-bar',
      chartTypeConfig: {
        chartType: 'bar',
        timeframe: 'timePeriod',
        stack: val.stack,
        relative: val.relative,
      },
      queries: [
        {
          metrics: val.barChartMetrics.map((metric) => ({
            value: metric as unknown as RegularMetricId,
            type: 'RegularMetricId',
          })),
          timeSelection: convertChappiPieTimeSelection(val.barChartTimeSelection),
          segmentations:
            val.barChartSegmentation.length > 0 ? val.barChartSegmentation.map(convertChappiSegmentation) : undefined,
          filters: val.filters?.sql,
        },
      ],
    };
    return timePeriodBarChartConfig;
  } else if (value.__typename === 'ChappiNoVisualization') {
    throw new Error('No visualization available');
  } else {
    throw new Error('Invalid visualization type: ' + value.__typename);
  }
};
